import { Injectable } from "@angular/core";
import { ModalDismissReasons, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Observable, ReplaySubject } from "rxjs";
import Swal, { SweetAlertIcon, SweetAlertOptions } from "sweetalert2";
import { UserLoginComponent } from "../internal-components/userlogin/userlogin.component";
import { LayoutViewModel, LoaderInfo } from "../internal-models/common-models";
import { RoleTypeSM } from "../service-models/app/enums/role-type-s-m.enum";
import { BaseService } from "./base.service";
import { RegisterComponent } from "../components/end-user/register/register.component";
import { ForgotpasswordComponent } from "../internal-components/forgotpassword/forgotpassword.component";
import { ThemeClient } from "../clients/theme.client";



@Injectable({
  providedIn: "root",
})

export class CommonService extends BaseService {

  layoutViewModel: LayoutViewModel = new LayoutViewModel();

  // toasts: ToastInfo[] = [];
  public loaderInfo: LoaderInfo = { message: "", showLoader: false };
  constructor(
    private modalService: NgbModal,
    private themeClient: ThemeClient,
  ) {

    super();

    //update this according to user type logged in
    // this.layoutViewModel = {
    //   leftMenuClass: '',
    //   showMenu: false,
    //   showAdminLeftMenu: true,
    //   showAdminTopBar: true,
    //   showTopNavBar: false,
    // }
  }

  async presentLoading(message: string = "") {
    this.loaderInfo = { message, showLoader: true };
  }


  // async presentToast(toastInfo: ToastInfo) {
  //   this.toasts.push(toastInfo);
  // }

  // removeToast(toast: any) {
  //   this.toasts = this.toasts.filter((t) => t !== toast);
  // }

  // clearAllToasts() {
  //   this.toasts.splice(0, this.toasts.length);
  // }

  // async presentAlert() {

  // }

  // async presentConfirmAlert(modalInfo: ConfirmModalInfo): Promise<boolean> {
  //   const modalRef = this.modalService.open(ConfirmModalComponent);
  //   modalRef.componentInstance.confirmModalInfo = modalInfo;
  //   return await modalRef.result;
  // }


  async dismissLoader() {
    this.loaderInfo.showLoader = false;
    this.loaderInfo.message = "";
    // this.layoutViewModel.showFooter = true;
    // this.layoutViewModel.showTopNav = true;

  }


  async presentLoginModal(redirectToPage: string): Promise<boolean> {
    const modalRef = this.modalService.open(UserLoginComponent, { fullscreen: 'lg', size: 'xl', windowClass: 'modal-holder', centered: true });
    modalRef.componentInstance.redirectToPage = redirectToPage;
    return await modalRef.result;
  }


  async presentCompanySignUpModal(redirectToPage: string) {
    this.modalService.open(RegisterComponent, { fullscreen: 'lg', size: 'xl', windowClass: 'modal-holder', centered: true }).result.then((result) => { }, (reason) => {
      if (reason === ModalDismissReasons.ESC || reason === ModalDismissReasons.BACKDROP_CLICK) {
        window.location.reload();
      }
      reason.componentInstance.redirectToPage = redirectToPage;
    });
  }
  async presentForgotPasswordModal(redirectToPage: string) {
    this.modalService.open(ForgotpasswordComponent, { fullscreen: 'lg', size: 'xl', windowClass: 'modal-holder', centered: true }).result.then((result) => { }, (reason) => {
      if (reason === ModalDismissReasons.ESC || reason === ModalDismissReasons.BACKDROP_CLICK) {
        window.location.reload();
      }
      reason.componentInstance.redirectToPage = redirectToPage;
    });
  }

  async closeModal() {
    await this.modalService.dismissAll();
  }


  /**Show custom sweet alert*/
  async showSweetAlertToast(alertOptions: SweetAlertOptions) {
    alertOptions.toast = true;
    alertOptions.position = "bottom";
    alertOptions.showConfirmButton = false;
    alertOptions.timer = 3000;
    alertOptions.timerProgressBar = true;
    alertOptions.didOpen = (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    };
    return await Swal.fire(alertOptions);
  }

  // Show information in Modal popup
  async showInfoInModalPopup(icon: SweetAlertIcon, title: string, text?: string) {
    Swal.fire({
      position: 'top-end',
      width: '30%',
      icon: icon,
      title: title,
      text: text,
      showConfirmButton: false,
      timer: 2000,
    })
  }

  async showSuccessModalPopup(icon: SweetAlertIcon, title: string, text?: string) {
    Swal.fire({
      position: 'center',
      icon: icon,
      title: title,
      text: text,
      showConfirmButton: false,
      timer: 1500
    })
  }


  /**Show custom sweet alert*/
  async showSweetAlertConfirmation(alertOptions: SweetAlertOptions) {
    return (await Swal.fire(alertOptions)).isConfirmed;
  }


  /**Change Enum value from api to String*/
  enumToStringArray(enumType: any) {
    const StringIsNumber = (value: any) => isNaN(Number(value)) === false;
    return Object.keys(enumType)
      .filter(StringIsNumber)
      .map((key) => enumType[key]);
  }



  /** */
  // Convert file to Base64
  convertFileToBase64(file: File): Observable<string> {
    const result = new ReplaySubject<string>(1);
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = (event: any) =>
      result.next(btoa(event.target.result.toString()));
    return result;
  }


  // Convert From Base64 to File Extension
  public convertFromBase64ToPDF(b64Data: string, contentType: any) {
    contentType = contentType || "";
    let sliceSize = 512;
    var byteCharacters = atob(b64Data);
    var byteArrays = [];
    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);
      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      var byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }


  //download document
  downloadDocument(base64data: string, fileExtension: string, fileName: string) {
    var blob = this.convertFromBase64ToPDF(base64data, "application/docx");
    let a = document.createElement("a");
    document.body.appendChild(a);
    var url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = String(fileName + "." + fileExtension);
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }


  async updateLayout(roleType: RoleTypeSM, url: string) {
    // url can be used to set active link
    // this.layoutViewModel = new LayoutViewModel();
    switch (roleType) {
      case RoleTypeSM.IndividualInspector: {
        // this.layoutViewModel.leftMenuClass = 'leftSideMenuToggle';
        this.layoutViewModel.showMenuBtn = false;
        this.layoutViewModel.showInspectorLoginMenu = true;
        this.layoutViewModel.showLogin = false;
        this.layoutViewModel.showProfile = true;
        this.layoutViewModel.showOrganisationLoginMenu = false;
        this.layoutViewModel.showSuperAdminLeftMenu = false;
        this.layoutViewModel.showSuperAdminTopBar = false;
        this.layoutViewModel.showSystemAdminLeftMenu = false;
        this.layoutViewModel.showSystemAdminTopBar = false;

        this.updateLayoutByUrl(url);
        break;
      }

      case RoleTypeSM.CompanyAdmin:
      case RoleTypeSM.CompanyInspector: {
        // this.layoutViewModel.leftMenuClass = 'leftSideMenuToggle';
        this.layoutViewModel.showMenuBtn = false;
        this.layoutViewModel.showInspectorLoginMenu = false;
        this.layoutViewModel.showLogin = false;
        this.layoutViewModel.showProfile = true;
        this.layoutViewModel.showOrganisationLoginMenu = true;
        this.layoutViewModel.showSuperAdminLeftMenu = false;
        this.layoutViewModel.showSuperAdminTopBar = false;
        this.layoutViewModel.showSystemAdminLeftMenu = false;
        this.layoutViewModel.showSystemAdminTopBar = false;

        this.updateLayoutByUrl(url);
        break;
      }
      // case RoleTypeSM.SystemAdmin:
      //   {
      //     this.layoutViewModel.leftMenuClass = '';
      //     this.layoutViewModel.showInspectorLoginMenu = false;
      //     this.layoutViewModel.showLogin = false;
      //     this.layoutViewModel.showOrganisationLoginMenu = false;
      //     this.layoutViewModel.showSuperadminLeftMenu = false;
      //     this.layoutViewModel.showSuperadminTopBar = false;
      //     this.layoutViewModel.showSystemadminLeftMenu = true;
      //     this.layoutViewModel.showSystemadminTopBar = true;
      //     break;
      //   }
      case RoleTypeSM.SystemAdmin:
      case RoleTypeSM.SuperAdmin: {
        // if (url.includes('admin')) {
        //   this.layoutViewModel.menuItems.forEach(item => {
        //     item.isActive = false;
        //     if (item.itemRoute == url)
        //       item.isActive = true;
        //   });
        // }
        this.layoutViewModel.showFooter = false;
        this.layoutViewModel.showInspectorLoginMenu = false;
        this.layoutViewModel.showLogin = false;
        this.layoutViewModel.showProfile = false;
        this.layoutViewModel.showOrganisationLoginMenu = false;
        this.layoutViewModel.showSuperAdminLeftMenu = true;
        this.layoutViewModel.showSuperAdminTopBar = true;
        this.layoutViewModel.showSystemAdminLeftMenu = false;
        this.layoutViewModel.showSystemAdminTopBar = false;
        this.layoutViewModel.toggleContent = 'superAdminContent';
        // this.layoutViewModel.toggleWrapper =  'superAdminWrapper'
        this.updateLayoutByUrl(url);
        break;
      }
      case RoleTypeSM.Unknown:
      default: {
        // this.layoutViewModel.leftMenuClass = 'leftSideMenuToggle';
        this.layoutViewModel.showMenuBtn = false;
        this.layoutViewModel.showInspectorLoginMenu = false;
        this.layoutViewModel.showLogin = true;
        this.layoutViewModel.showProfile = false;
        this.layoutViewModel.showOrganisationLoginMenu = false;
        this.layoutViewModel.showSuperAdminLeftMenu = false;
        this.layoutViewModel.showSuperAdminTopBar = false;
        this.layoutViewModel.showSystemAdminLeftMenu = false;
        this.layoutViewModel.showSystemAdminTopBar = false;
        this.presentLoading();
        this.updateLayoutByUrl(url);
        break;
      }

    }
  }


  async updateLayoutByUrl(url: string) {
    if (url === "/profile") {
      this.layoutViewModel.showFooter = false;
      this.layoutViewModel.showMenuBtn = true;
      this.layoutViewModel.footer = 'disableFooter';
      this.layoutViewModel.toggleContent = 'content';
    } else {
      this.layoutViewModel.footer = 'footer';
    }
  }

  // AreDatesEqual(largerDate: Date, smallerDate: Date, compareType: 'Y' | 'M' | 'D' | 'h' | 'm' | 's' | 'ms'): boolean {
  //   let res = false;
  //   switch (compareType) {
  //     case 'Y':
  //       {
  //         return largerDate.getFullYear() == smallerDate.getFullYear();
  //       }
  //     case 'M':
  //       {
  //         let yearComparison = largerDate.getFullYear() == smallerDate.getFullYear();
  //         let monthComparison = largerDate.getMonth() == smallerDate.getMonth();
  //         return yearComparison == monthComparison == true;
  //       }
  //     case 'D':
  //       {
  //         let yearComparison = largerDate.getFullYear() == smallerDate.getFullYear();
  //         let monthComparison = largerDate.getMonth() == smallerDate.getMonth();
  //         let dateComparison = largerDate.getDate() == smallerDate.getDate();
  //         return yearComparison == monthComparison == dateComparison == true;
  //       }
  //     case 'h':
  //       {
  //         let yearComparison = largerDate.getFullYear() == smallerDate.getFullYear();
  //         let monthComparison = largerDate.getMonth() == smallerDate.getMonth();
  //         let dateComparison = largerDate.getDate() == smallerDate.getDate();
  //         let hourComparison = largerDate.getHours() == smallerDate.getHours();
  //         return yearComparison == monthComparison == dateComparison == hourComparison == true;
  //       }
  //     case 'm':
  //       {
  //         let yearComparison = largerDate.getFullYear() == smallerDate.getFullYear();
  //         let monthComparison = largerDate.getMonth() == smallerDate.getMonth();
  //         let dateComparison = largerDate.getDate() == smallerDate.getDate();
  //         let hourComparison = largerDate.getHours() == smallerDate.getHours();
  //         let minComparison = largerDate.getMinutes() == smallerDate.getMinutes();
  //         return yearComparison == monthComparison == dateComparison == hourComparison == minComparison == true;
  //       }
  //       case '':
  //         {
  //           let yearComparison = largerDate.getFullYear() == smallerDate.getFullYear();
  //           let monthComparison = largerDate.getMonth() == smallerDate.getMonth();
  //           let dateComparison = largerDate.getDate() == smallerDate.getDate();
  //           let hourComparison = largerDate.getHours() == smallerDate.getHours();
  //           let minComparison = largerDate.getMinutes() == smallerDate.getMinutes();
  //           return yearComparison == monthComparison == dateComparison == hourComparison == minComparison == true;
  //         }


  //     default:
  //       break;
  //   }
  //   return res;
  // }
  async loadDefaultTheme() {
    try {
    
      let resp = await this.themeClient.GetDefaultTheme();
      const style = document.createElement('style');
      if (style) {
        style.remove();
      }
      style.id = 'theme-style';
      style.innerHTML = `:root${resp.successData.css}`;
      document.head.appendChild(style);

    } catch (error) {
      throw error;
    }
  }


  async applyThemeGlobally() {
    try {
      let style = document.getElementById('theme-style');
      if (style) {
        style.remove();
      }
      let resp = await this.themeClient.GetMineTheme();
      let newStyle = document.createElement('style');
      newStyle.id = 'theme-style';
      newStyle.innerHTML = `:root${resp.successData.css}`;
      document.head.appendChild(newStyle);

    }
    catch (error) {
      throw error;
    }
  }
}

