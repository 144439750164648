<div [ngClass]="[_commonService.layoutViewModel.toggleWrapper]">

    <!-- <app-toast-container aria-live="polite" aria-atomic="true"></app-toast-container> -->

    <app-spinner *ngIf="_commonService.loaderInfo.showLoader"></app-spinner>

    <app-left-side-bar *ngIf="_commonService.layoutViewModel.showSuperAdminLeftMenu"></app-left-side-bar>

    <app-top-nav *ngIf=" _commonService.layoutViewModel.showTopNav ||_commonService.layoutViewModel.showSuperAdminTopBar "></app-top-nav>

    <div [ngClass]="[_commonService.layoutViewModel.toggleContent]">
        <router-outlet></router-outlet>
    </div>

    <app-footer *ngIf="_commonService.layoutViewModel.showFooter"> </app-footer>

</div>