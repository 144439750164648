import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AccountSettingComponent } from './components/end-user/account-setting/account-setting.component';
import { DummyTeacherComponent } from './components/end-user/dummy-teacher/dummy-teacher.component';
import { HomeComponent } from './components/end-user/home/home.component';
import { PricingComponent } from './components/end-user/pricing/pricing.component';
import { ReportsComponent } from './components/end-user/reports/reports.component';
import { SamplePageComponent } from './components/end-user/sample-page/sample-page.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { AuthGuard } from './guard/auth.guard';
import { AdminLoginComponent } from './components/admin/login/adminlogin.component';
import { NewsFeedComponent } from './components/admin/news-feed/news-feed.component';
import { AdminFreqAskedQuesComponent } from './components/admin/admin-freq-asked-ques/admin-freq-asked-ques.component';
import { AdminDashboardComponent } from './components/admin/dashboard/admin-dashboard.component';
import { UserProfileMenuComponent } from './components/end-user/user-profile-menu/user-profile-menu.component';
import { WebsiteResourceComponent } from './components/admin/website-resource/website-resource.component';
import { ContactUsComponent } from './components/admin/contact-us/contact-us.component';
import { FeatureGroupComponent } from './components/admin/feature-group/feature-group.component';
import { FeaturesComponent } from './components/admin/features/features.component';
import { LicenseInfoComponent } from './components/admin/license-info/license-info.component';
import { RoleTypeSM } from './service-models/app/enums/role-type-s-m.enum';
import { CompaniesComponent } from './components/admin/companies/companies.component';
import { CompanyOverviewComponent } from './components/admin/company-overview/company-overview.component';
import { ProductInfoComponent } from './components/end-user/product-info/product-info.component';
import { PricingInfoComponent } from './components/end-user/pricing-info/pricing-info.component';
import { FailedPaymentComponent } from './components/end-user/failed-payment/failed-payment.component';
import { SuccessPaymentComponent } from './components/end-user/success-payment/success-payment.component';
import { HowToInstallComponent } from './components/end-user/how-to-install/how-to-install.component';
import { ResetPasswordComponent } from './components/end-user/reset-password/reset-password.component';
import { DemoVideosComponent } from './components/end-user/demo-videos/demo-videos.component';



const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },

  {
    path: 'home', component: HomeComponent,
    canActivate: [AuthGuard],
    data: {
      allowedRole: [
        RoleTypeSM.CompanyAdmin,
        RoleTypeSM.IndividualInspector,
        RoleTypeSM.CompanyInspector,
        RoleTypeSM.Unknown,
      ]
    }
  },
  
  {
    path: 'ResetPassword', component: ResetPasswordComponent,
    canActivate: [AuthGuard],
    data: {
      allowedRole: [
        RoleTypeSM.Unknown,
      ]
    }
  },
  {
    path: 'how-to-install', component: HowToInstallComponent,
    canActivate: [AuthGuard],
    data: {
      allowedRole: [
        RoleTypeSM.CompanyAdmin,
        RoleTypeSM.IndividualInspector,
        RoleTypeSM.CompanyInspector,
        RoleTypeSM.Unknown,
      ]
    }
  },
  {
    path:'demo-videos',component:DemoVideosComponent,
    canActivate:[AuthGuard],
    data:{
      allowedRole:[
        RoleTypeSM.CompanyAdmin,
        RoleTypeSM.IndividualInspector,
        RoleTypeSM.CompanyInspector,
        RoleTypeSM.Unknown,
      ]
    }
  },

  {
    path: 'profile', component: UserProfileMenuComponent,
    canActivate: [AuthGuard],
    data: {
      allowedRole: [
        RoleTypeSM.CompanyAdmin,
        RoleTypeSM.IndividualInspector,
        RoleTypeSM.CompanyInspector,
      ]
    }
  },

  {
    path: 'dashboard', component: AdminDashboardComponent,
    canActivate: [AuthGuard],
    data: {
      allowedRole: [
        RoleTypeSM.CompanyAdmin
      ]
    }
  },

  {
    path: 'pricing', component: PricingComponent,
    canActivate: [AuthGuard],
    data: {
      allowedRole: [
        RoleTypeSM.CompanyAdmin,
        RoleTypeSM.IndividualInspector,
        RoleTypeSM.CompanyInspector,
        RoleTypeSM.Unknown,
      ]
    }
  },

  {
    path: 'product-info', component: ProductInfoComponent,
    canActivate: [AuthGuard],
    data: {
      allowedRole: [
        RoleTypeSM.CompanyAdmin,
        RoleTypeSM.IndividualInspector,
        RoleTypeSM.CompanyInspector,
        RoleTypeSM.Unknown,
      ]
    }
  },

  {
    path: 'reports', component: ReportsComponent,
    canActivate: [AuthGuard],
    data: {
      allowedRole: [
        RoleTypeSM.CompanyAdmin,
        RoleTypeSM.IndividualInspector,
        RoleTypeSM.CompanyInspector,
        RoleTypeSM.Unknown,
      ]
    }
  },

  {
    path: 'pricing', component: PricingComponent,
    canActivate: [AuthGuard],
    data: {
      allowedRole: [
        RoleTypeSM.CompanyAdmin,
        RoleTypeSM.IndividualInspector,
        RoleTypeSM.CompanyInspector,
        RoleTypeSM.Unknown,
      ]
    }
  },

  {
    path: 'failure/:info', component: FailedPaymentComponent,
    canActivate: [AuthGuard],
    data: {
      allowedRole: [
        RoleTypeSM.CompanyAdmin,
        RoleTypeSM.IndividualInspector,

      ]
    }
  },
  {
    path: 'success/:info', component: SuccessPaymentComponent,
    canActivate: [AuthGuard],
    data: {
      allowedRole: [
        RoleTypeSM.CompanyAdmin,
        RoleTypeSM.IndividualInspector,
      ]
    }
  },

  {
    path: 'pricing-info/:Id', component: PricingInfoComponent,
    canActivate: [AuthGuard],
    data: {
      allowedRole: [
        RoleTypeSM.CompanyAdmin,
        RoleTypeSM.IndividualInspector
      ]
    }
  },

  { path: 'account-setting', component: AccountSettingComponent },

  {
    path: 'admin/login', component: AdminLoginComponent,
    canActivate: [AuthGuard],
    data: {
      allowedRole: [
        RoleTypeSM.Unknown,
      ]
    }
  },

  {
    path: 'admin',
    children: [
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      { path: 'dashboard', component: AdminDashboardComponent },
      { path: 'newsfeed', component: NewsFeedComponent },
      { path: 'faqs', component: AdminFreqAskedQuesComponent },
      { path: 'WebsiteResource', component: WebsiteResourceComponent },
      { path: 'ContactUs', component: ContactUsComponent },
      { path: 'featuregroup', component: FeatureGroupComponent },
      { path: 'features', component: FeaturesComponent },
      { path: 'licenseInfo', component: LicenseInfoComponent },
      { path: 'companies', component: CompaniesComponent },
      { path: 'companyOverview/:Id', component: CompanyOverviewComponent },
    ],
    canActivate: [AuthGuard],
    data: {
      allowedRole: [
        RoleTypeSM.SuperAdmin,
        RoleTypeSM.SystemAdmin,
      ]
    }
  },

  {
    path: 'teachers',
    component: DummyTeacherComponent,
    canActivate: [AuthGuard],
    data: {
      allowedRole: [
        RoleTypeSM.CompanyAdmin
      ]
    }
  },

  { path: 'sample', component: SamplePageComponent },

  {
    path: '**', pathMatch: 'full',
    component: NotFoundComponent
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
  exports: [RouterModule]
})


export class AppRoutingModule { }
