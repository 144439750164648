import { ContactUsSM } from "src/app/service-models/app/v1/general/contact-us-s-m";
import { BaseViewModel } from "../base.viewmodel";

export class ContactUsViewModel implements BaseViewModel {
  PageTitle: string = 'Contact Us';
  PageNo: number = 1;
  PageSize: number = 10;
  totalCount!:number;
  AddEditModalTitle: string = '';
  contactList: ContactUsSM[] = [];
  singleContact: ContactUsSM = new ContactUsSM();
  FormSubmitted: boolean = false;
  ValidationData = {
    title: [
      { type: 'required', message: 'Title is required' },
    ]
  }



}