import { Injectable } from '@angular/core';
import { AppConstants } from 'src/app-constants';
import { NewsFeedClient } from '../clients/news-feed.client';
import { NewsFeedSM } from '../service-models/app/v1/general/news-feed-s-m';
import { ApiRequest } from '../service-models/foundation/api-contracts/base/api-request';
import { ApiResponse } from '../service-models/foundation/api-contracts/base/api-response';
import { OdataQueryFilter } from '../service-models/foundation/api-contracts/odata-query-filter';
import { DeleteResponseRoot } from '../service-models/foundation/common-response/delete-response-root';
import { IntResponseRoot } from '../service-models/foundation/common-response/int-response-root';

import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class NewsFeedService extends BaseService {

  constructor(private newsFeedClient: NewsFeedClient) {
    super();
  }

  async getAllNewsbyOdata(queryFilter: OdataQueryFilter): Promise<ApiResponse<NewsFeedSM[]>> {
    return await this.newsFeedClient.GetAllNewsByOdata(queryFilter);
 }
  /**get all newsFeed data  request to client */

  async getAllNews(): Promise<ApiResponse<NewsFeedSM[]>> {
    return await this.newsFeedClient.GetAllNews();
  }
  /**get all newsFeed data by id  request to client */

  async getNewsById(id: number): Promise<ApiResponse<NewsFeedSM>> {
    if (id <= 0) {
      throw new Error(AppConstants.ErrorPrompts.Delete_Data_Error)
    }
    return await this.newsFeedClient.GetNewsById(id);
  }
  /**add  websiteresources  request to client */

  async Send_News(news: NewsFeedSM): Promise<ApiResponse<NewsFeedSM>> {
    if (news == null) {
      throw new Error(AppConstants.ErrorPrompts.Invalid_Input_Data);
    }
    else {
      let apiRequest = new ApiRequest<NewsFeedSM>();
      apiRequest.reqData = news;
      return await this.newsFeedClient.AddNews(apiRequest);
    }


  }
  /**get all websiteresources data  request to client */

  async update_News(news: NewsFeedSM): Promise<ApiResponse<NewsFeedSM>> {
    if (news == null) {
      throw new Error(AppConstants.ErrorPrompts.Invalid_Input_Data);
    }
    else {
      let apiRequest = new ApiRequest<NewsFeedSM>();
      apiRequest.reqData = news;
      return await this.newsFeedClient.UpdateNews(apiRequest);
    }


  }


  /**delete newsFeed by id  request to client */

  async deleteNewsById(id: number): Promise<ApiResponse<DeleteResponseRoot>> {
    if (id <= 0) {
      throw new Error(AppConstants.ErrorPrompts.Delete_Data_Error)
    }
    return await this.newsFeedClient.DeleteNewsById(id);
  }
  async getNewsCount():Promise<ApiResponse<IntResponseRoot>>{
    return await this.newsFeedClient.GetAllNewsCount()
  }
}