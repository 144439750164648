import { Component, EventEmitter,OnInit, Output, TemplateRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppConstants } from 'src/app-constants';
import { BaseComponent } from 'src/app/components/base.component';
import { ClientCompanyDetailSM } from 'src/app/service-models/app/v1/client/client-company-detail-s-m';
import { ApiResponse } from 'src/app/service-models/foundation/api-contracts/base/api-response';
import { CommonService } from 'src/app/services/common.service';
import { CompanyProfileService } from 'src/app/services/company-profile.service';
import { LogHandlerService } from 'src/app/services/log-handler.service';
import { CompanyInfoViewModel } from 'src/app/view-models/company-profile.viewmodel';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-company-profile',
  templateUrl: './company-profile.component.html',
  styleUrls: ['./company-profile.component.scss']
})


export class CompanyProfileComponent extends BaseComponent<CompanyInfoViewModel> implements OnInit {

  @Output() newItemEvent = new EventEmitter<ClientCompanyDetailSM>();

  constructor(commonService: CommonService, logService: LogHandlerService,
    public activeModal: NgbActiveModal,
    private companyProfileService: CompanyProfileService,

    private modalService: NgbModal,
  ) {
    super(commonService, logService)
    this.viewModel = new CompanyInfoViewModel();
       this._commonService.layoutViewModel.showProfile = true;
  }


  async ngOnInit() {
    await this._commonService.presentLoading();
    setTimeout(async () => {
      await this._commonService.dismissLoader();
    await this.loadPageData();
    }, environment.animationTimeoutinMS);
   
  }


  override async loadPageData() {
    try {
      await this._commonService.presentLoading();
      let resp = await this.companyProfileService.getCompanyProfile();
      if (resp.isError) {
        await this._exceptionHandler.logObject(resp.errorData);
        this._exceptionHandler.logObject(resp.errorData);
        this._commonService.showSweetAlertToast({ title: resp.errorData.displayMessage, icon: 'error' });
        return;
      }
      this.viewModel.companyDetails = resp.successData;
      if (this.viewModel.companyDetails.companyLogoPath == "" || this.viewModel.companyDetails.companyLogoPath == null) {
        this.viewModel.isProfilePicUploaded = false;
        this.viewModel.showDeleteBtn = false;
      } else {
        this.viewModel.isProfilePicUploaded = true;
        this.viewModel.showDeleteBtn = true;
      }
      this.sendToParent(this.viewModel.companyDetails);
      let respAddress = await this.companyProfileService.getCompanyAddress();
      if (respAddress.isError) {
        await this._exceptionHandler.logObject(respAddress.errorData);
        this._exceptionHandler.logObject(resp.errorData);
        this._commonService.showSweetAlertToast({ title: respAddress.errorData.displayMessage, icon: 'error' });
        return;
      }
      if (respAddress.successData !== null) {
        this.viewModel.companyAddressDetails = respAddress.successData;
      } else {
        this._commonService.showInfoInModalPopup('info',"Address Not Found", "Please Update Your Address Details");
      }
    } catch (error) {
      this._commonService.showSweetAlertToast({ title: AppConstants.ErrorPrompts.Unknown_Error, icon: 'error' });
      throw error;
    } finally {
      await this._commonService.dismissLoader();
    }

  }

  async update() {
    this.viewModel.isDisabled = false;
  }

  /**
   * Update Company Address
   * @param id 
   * @returns success Data
   */
  async saveProfileDetails() {
    if (this.viewModel.companyAddressDetails.id == 0 || this.viewModel.companyAddressDetails.id == undefined) {
      // POST Call
      await this.addCompanyAddress();
    } else {
      // PUT Call
      await this.updateCompanyAddress();
    }

  }

  async addCompanyAddress() {
    try {
      await this._commonService.presentLoading();
      let resp = await this.companyProfileService.addCompanyAddress(this.viewModel.companyAddressDetails);
      if (resp.isError) {
        await this._exceptionHandler.logObject(resp.errorData);
        this._commonService.showSweetAlertToast({ title: resp.errorData.displayMessage, icon: 'error' });
        return;
      } else {
        this.viewModel.companyAddressDetails = resp.successData;
        this._commonService.showSweetAlertToast({ title: "Success", icon: 'success' });
      }
    } catch (error) {
      throw error;
    } finally {
      await this._commonService.dismissLoader();
    }
  }
  
  async updateCompanyAddress() {
    try {
      await this._commonService.presentLoading();
      let resp = await this.companyProfileService.updateCompanyAddress(this.viewModel.companyAddressDetails);
      if (resp.isError) {
        await this._exceptionHandler.logObject(resp.errorData);
        this._exceptionHandler.logObject(resp.errorData);
        this._commonService.showSweetAlertToast({ title: resp.errorData.displayMessage, icon: 'error' });
        return;
      }
      else {
        if (resp.successData != null) {
          this._commonService.showSweetAlertToast({ title: 'Success', icon: 'success' });
          this.viewModel.companyAddressDetails = resp.successData;
        }
      }
    } catch (error) {
      this._commonService.showSweetAlertToast({ title: AppConstants.ErrorPrompts.Unknown_Error, icon: 'error' });
      throw error;
    } finally {
      await this._commonService.dismissLoader()
    }
  }

  sendToParent(companyDetail: ClientCompanyDetailSM) {
    this.newItemEvent.emit(companyDetail)
  }
  async click_OpenAddEditModal(InspectorModal: TemplateRef<any>, inspectorId: number) {
    this.viewModel.AddEditModalTitle = inspectorId > 0 ? 'Update Inspector' : 'Upload Picture';
    // this.viewModel.companyDetails = new ClientCompanyDetailSM()

    if (inspectorId > 0) {
      // await this.getInspectorById(inspectorId);
      // await this.getInspectorAddressById(inspectorId)
    }
    await this.modalService.open(InspectorModal).result.then(
      (result: any) => {
      },
      (reason: any) => {
      },
    );
  }

  async form_addEditInspector(inspectorForm: NgForm) {
    try {
      await this._commonService.presentLoading();
      let resp: ApiResponse<string>;

      resp = await this.companyProfileService.uploadProfilePictureOfComapny(this.viewModel.companyDetails.companyLogoPath);
      if (resp.isError) {
        await this._exceptionHandler.logObject(resp.errorData);
        this._commonService.showSweetAlertToast({ title: resp.errorData.displayMessage, icon: 'error' });
      }
      else {
        this.modalService.dismissAll();
        this._commonService.showSweetAlertToast({ title: 'Success', icon: 'success' });
        this.loadPageData();
      }
    } catch (error) {
      this._commonService.showSweetAlertToast({
        title: AppConstants.ErrorPrompts.Unknown_Error,
        icon: "error",
      });
      throw error;
    } finally {
      await this._commonService.dismissLoader()
    }
  }

  async deleteProfile() {
    let deleteConfirmation = await this._commonService.showSweetAlertConfirmation({
      title: `${AppConstants.DefaultMessages.DeleteAlertConfirmation} picture?`,
      text: " ",
      showCancelButton: true,
      icon: "warning"
    }
    );
    if (deleteConfirmation) {
      try {
        let resp = await this.companyProfileService.deleteProfilePicture();
        if (resp.isError) {
          await this._exceptionHandler.logObject(resp.errorData);
          this._commonService.showSweetAlertToast({ title: resp.errorData.displayMessage, icon: 'error' });
        } else {
          this.modalService.dismissAll();
          this._commonService.showSweetAlertToast({ title: 'Success', icon: 'success' });
          this.loadPageData();
          return;
        }
      } catch (error) {
        this._commonService.showSweetAlertToast({ title: 'Error', icon: 'error' });
        throw error;
      } finally {
        await this._commonService.dismissLoader();
      }
    }
  }


  async getCompanyAdminProfilePicture() {
    try {
      await this._commonService.presentLoading();
      let resp1 = await this.companyProfileService.uploadProfilePictureOfComapny(this.viewModel.companyDetails.companyLogoPath);
      if (resp1.isError) {
        this._exceptionHandler.logObject(resp1.errorData);
        this._commonService.showSweetAlertToast({ title: resp1.errorData.displayMessage, icon: 'error' });
      } else
        this._commonService.layoutViewModel.companyAdminProfilePicture = resp1.successData;
    } catch (error) {

    }
  }

  uploadFile(event: any) {
    this._commonService.convertFileToBase64(event.target.files[0]).subscribe((base64) => {
      let fileName = event.target.files[0].name;
      fileName.split("?")[0].split(".").pop();
      this.viewModel.companyDetails.companyLogoPath = base64;
      if (this.viewModel.companyDetails.companyLogoPath !== "" || this.viewModel.companyDetails.companyLogoPath !== undefined) {
        this.viewModel.isProfilePicUploaded = true;
      }
    });
  }


}
