import { SurveyBoxServiceModelBase } from '../../base/survey-box-service-model-base';
import { ResourceTypeSM } from '../../enums/resource-type-s-m.enum';
import { ResourceLocationSM } from '../../enums/resource-location-s-m.enum';

export class WebsiteResourceSM extends SurveyBoxServiceModelBase<number> {
    resourceTitle!: string;
    resourceFile!: string;
    extension!: string;
    resourceType!: ResourceTypeSM;
    resourceLocation!: ResourceLocationSM;
    additionalDetails!: string;
}
