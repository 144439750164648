import { Injectable } from '@angular/core';
import { AppConstants } from 'src/app-constants';
import { FrequentlyAskedQuestionClient } from '../clients/frequently-asked-question.client';
import { FrequentlyAskedQuestionsSM } from '../service-models/app/v1/general/frequently-asked-questions-s-m';
import { ApiRequest } from '../service-models/foundation/api-contracts/base/api-request';
import { ApiResponse } from '../service-models/foundation/api-contracts/base/api-response';
import { OdataQueryFilter } from '../service-models/foundation/api-contracts/odata-query-filter';
import { DeleteResponseRoot } from '../service-models/foundation/common-response/delete-response-root';
import { IntResponseRoot } from '../service-models/foundation/common-response/int-response-root';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class FrequentlyAskedQuestionService extends BaseService {

  constructor(private faqClient: FrequentlyAskedQuestionClient) {
    super();
  }
  /**get all faq's data  request to client */

  async getAllFaq(): Promise<ApiResponse<FrequentlyAskedQuestionsSM[]>> {
    return await this.faqClient.GetAllFaq();
  }

  async getAllFaqByOdata(queryFilter: OdataQueryFilter): Promise<ApiResponse<FrequentlyAskedQuestionsSM[]>> {
    return await this.faqClient.GetAllFaqByOdata(queryFilter);
  }

  async getFaqCount():Promise<ApiResponse<IntResponseRoot>>{
    return await this.faqClient.GetAllFaqCount()
  }
  /**get  faq by id  request to client */

  async getFaqById(id: number): Promise<ApiResponse<FrequentlyAskedQuestionsSM>> {
    if (id <= 0) {
      throw new Error(AppConstants.ErrorPrompts.Delete_Data_Error)
    }
    return await this.faqClient.GetFaqById(id);
  }
  /**ADD faq's data  request to client */
  async AddFaq(faq: FrequentlyAskedQuestionsSM): Promise<ApiResponse<FrequentlyAskedQuestionsSM>> {
    if (faq == null) {
      throw new Error(AppConstants.ErrorPrompts.Invalid_Input_Data);
    }
    else {
      let apiRequest = new ApiRequest<FrequentlyAskedQuestionsSM>();
      apiRequest.reqData = faq;
      return await this.faqClient.AddFaq(apiRequest);
    }
  }
  /**update faq's data  request to client */

  async update_Faq(Faq: FrequentlyAskedQuestionsSM): Promise<ApiResponse<FrequentlyAskedQuestionsSM>> {
    if (Faq == null) {
      throw new Error(AppConstants.ErrorPrompts.Invalid_Input_Data);
    }
    else {
      let apiRequest = new ApiRequest<FrequentlyAskedQuestionsSM>();
      apiRequest.reqData = Faq;
      return await this.faqClient.UpdateFaq(apiRequest);
    }

  }


  /**delete  faq by id  request to client */

  async deleteFaqById(id: number): Promise<ApiResponse<DeleteResponseRoot>> {
    if (id <= 0) {
      throw new Error(AppConstants.ErrorPrompts.Delete_Data_Error)
    }
    return await this.faqClient.DeleteFaqById(id);
  }
}