import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppConstants } from 'src/app-constants';
import { OdataQueryFilter } from 'src/app/service-models/foundation/api-contracts/odata-query-filter';
import { CommonService } from 'src/app/services/common.service';
import { ContactUsService } from 'src/app/services/contact-us.service';
import { LogHandlerService } from 'src/app/services/log-handler.service';
import { ContactUsViewModel } from 'src/app/view-models/admin/contact-us.viewmodel';
import { BaseComponent } from '../../base.component';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent extends BaseComponent<ContactUsViewModel> implements OnInit {

  constructor(
    private modalService: NgbModal, private contactUsService: ContactUsService,
    commonService: CommonService, logService: LogHandlerService) {
    super(commonService, logService);
    this.viewModel = new ContactUsViewModel();
  }
  
  async ngOnInit() {
    await this.loadPageData();
  }
/**
 * get all FAQ's data 
 * @returns success.data
 */
  override async loadPageData() {
    try {
      await this._commonService.presentLoading();
      //have to do paging
      let queryFilter = new OdataQueryFilter();
      queryFilter.skip = (this.viewModel.PageNo - 1) * this.viewModel.PageSize;
      queryFilter.top = this.viewModel.PageSize;
      let resp = await this.contactUsService.getAllContactsbyOdata(queryFilter);
      if (resp.isError) {
        this._exceptionHandler.logObject(resp.errorData);
        this._commonService.showSweetAlertToast({ title: resp.errorData.displayMessage, icon: 'error' });
        return;
      }
      else {
        await this.getContactCount();
        this.viewModel.contactList = resp.successData;
      }
    } catch (error) {
      this._commonService.showSweetAlertToast({ title: AppConstants.ErrorPrompts.Unknown_Error, icon: 'error' });
      throw error;
    }
    finally {
      await this._commonService.dismissLoader()
    }
  }

  async getContactCount(){
    try {
      await this._commonService.presentLoading();
       let resp = await this.contactUsService.getAllContactsCount();
       if (resp.isError) {
        this._exceptionHandler.logObject(resp.errorData);
        this._commonService.showSweetAlertToast({ title: resp.errorData.displayMessage, icon: 'error' });
        return;
      }
      else {
        this.viewModel.totalCount = resp.successData.intResponse;
      }
    } catch (error) {
      this._commonService.showSweetAlertToast({ title: AppConstants.ErrorPrompts.Unknown_Error, icon: 'error' });
      throw error;
    }
    finally {
      await this._commonService.dismissLoader()
    }

  }
  async loadPagedataWithPagination(event: any) {
    this.viewModel.PageNo = event;
    await this.loadPageData();
  }
}
