import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppConstants } from 'src/app-constants';
import { LoginStatusSM } from 'src/app/service-models/app/enums/login-status-s-m.enum';
import { ClientUserSM } from 'src/app/service-models/app/v1/app-users/client-user-s-m';
import { OdataQueryFilter } from 'src/app/service-models/foundation/api-contracts/odata-query-filter';
import { CommonService } from 'src/app/services/common.service';
import { CompanyOverviewService } from 'src/app/services/company-overview.service';
import { LogHandlerService } from 'src/app/services/log-handler.service';
import { CompanyOverviewTabs, CompanyOverviewViewModel } from 'src/app/view-models/admin/company-overview.viewmodel';
import { BaseComponent } from '../../base.component';

@Component({
  selector: 'app-company-overview',
  templateUrl: './company-overview.component.html',
  styleUrls: ['./company-overview.component.scss']
})
export class CompanyOverviewComponent extends BaseComponent<CompanyOverviewViewModel> implements OnInit {
  constructor(
    private modalService: NgbModal,
    commonService: CommonService, logService: LogHandlerService, private companyOverviewService: CompanyOverviewService,
    private activatedRoute: ActivatedRoute) {
    super(commonService, logService);
    this.viewModel = new CompanyOverviewViewModel();

  }
  async ngOnInit() {


    const Id = Number(this.activatedRoute.snapshot.paramMap.get("Id"));
    this.viewModel.companyDetail.id = Id;
    if (Id == undefined) {
      // await this._commonService.ShowToastAtTopEnd(
      //   "Something Went Wrong",
      //   "error"
      // );
      alert("undefined");
    } else {
      await this.getCompanyProfileById(Id);
    


    }
  }


  async getAllUsersOfCompanyById(id: number) {
    try {
      await this._commonService.presentLoading();
      //have to do paging 
      let queryFilter = new OdataQueryFilter();
      queryFilter.skip = (this.viewModel.PageNo - 1) * this.viewModel.PageSize;
      queryFilter.top = this.viewModel.PageSize;
      let resp = await this.companyOverviewService.getAllCompaniesByOdata(id, queryFilter);
      if (resp.isError) {
        await this._exceptionHandler.logObject(resp.errorData);
        this._commonService.showSweetAlertToast({ title: 'Error!', icon: 'error' });
      }
      else {
        await this.getUsersCount(id)
        this.viewModel.companyUserList = resp.successData;
      }
    } catch (error) {
      throw error;
    }
    finally {
      await this._commonService.dismissLoader()
    }
  }

  async getCompanyProfileById(Id: number) {
    try {
      await this._commonService.presentLoading();
      //have to do paging
      let resp = await this.companyOverviewService.getCompanyProfile(Id);
      if (resp.isError) {
        await this._exceptionHandler.logObject(resp.errorData);
        this._commonService.showSweetAlertToast({ title: 'Error!', icon: 'error' });
      }
      else {
        this.viewModel.companyDetail = resp.successData;
      }
    } catch (error) {
      throw error;
    }
    finally {
      await this._commonService.dismissLoader()
    }
  }

  async getCompanyLicensesById(Id: number) {

    try {
      await this._commonService.presentLoading();
      //have to do paging
      let resp = await this.companyOverviewService.getCompanyLicenses(Id);
      if (resp.isError) {
        await this._exceptionHandler.logObject(resp.errorData);
        this._commonService.showSweetAlertToast({ title: 'Error!', icon: 'error' });
      }
      else {
        this.viewModel.allCompanyLicenseDetails = resp.successData;
      }
    } catch (error) {
      throw error;
    }
    finally {
      await this._commonService.dismissLoader()
    }
  }


  async updateTabLocation(tabLocation: CompanyOverviewTabs) {
    switch (tabLocation) {
      case CompanyOverviewTabs.users:
        await this.getAllUsersOfCompanyById(this.viewModel.companyDetail.id);
        break;

      case CompanyOverviewTabs.license:
        await this.getCompanyLicensesById(this.viewModel.companyDetail.id);
        break;

      case CompanyOverviewTabs.overview:
        // default:
        break;
    } this.viewModel.tabLocation = tabLocation;

  }

  async updateLoginStatus(event: any, status: ClientUserSM) {
    try {
      if (event == 'Enabled') {
        this.viewModel.companyUser.loginStatus = LoginStatusSM.Enabled
        this.viewModel.companyUser.id = status.id
      }
      else if (event == 'Disabled') {
        this.viewModel.companyUser.loginStatus = LoginStatusSM.Disabled
      }
      else {
        this.viewModel.companyUser.loginStatus = LoginStatusSM.PasswordResetRequired
      }
      this.viewModel.companyUser.id = status.id
      let resp = await this.companyOverviewService.updateLoginStatus(this.viewModel.companyUser);
      if (resp.isError) {
        this._exceptionHandler.logObject(resp.errorData);
        this._commonService.showSweetAlertToast({ title: resp.errorData.displayMessage, icon: 'error' });
        return;
      }
      else {
        this._commonService.showSweetAlertToast({ title: resp.successData.responseMessage ,icon:'success'});
        await this.getAllUsersOfCompanyById(this.viewModel.companyDetail.id);
      }
    } catch (error) {

    }

  }


  async getUsersCount(id: number) {
    try {
      await this._commonService.presentLoading();

      let resp = await this.companyOverviewService.getUsersCount(id);

      if (resp.isError) {
        this._exceptionHandler.logObject(resp.errorData);
        this._commonService.showSweetAlertToast({ title: resp.errorData.displayMessage, icon: 'error' });
        return;
      }
      else {
        this.viewModel.totalCount = resp.successData.intResponse;
      }
    } catch (error) {
      this._commonService.showSweetAlertToast({ title: AppConstants.ErrorPrompts.Unknown_Error, icon: 'error' });
      throw error;
    }
    finally {
      await this._commonService.dismissLoader()
    }

  }

  async loadPagedataWithPagination(event: any) {
    this.viewModel.PageNo = event;
    // await this.loadPageData();
    await this.getAllUsersOfCompanyById(this.viewModel.companyDetail.id);
  }
}

