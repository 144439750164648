<div class="container-fluid">
    <div class="row">
        <div class="col-lg-2 col-md-12 col-sm-12 col-xs-12 text-center ms-0 ps-0 me-0 pe-0 ">
            <!-- Accounts Sidebar Menu -->
            <div class="sidebar all-menus nav-side-menu ">
                <div class="menu-list">
                    <img src="assets/images/spyGlassHomePage1.jpg" class="mt-lg-5  prifile-avatar " alt="profile"
                        loading="lazy" />
                    <div class="mb-lg-5 mb-md-3 mb-sm-2">
                        <i class="fa-solid fa-pen " style="color: #ffff;margin: auto; ">
                            <input type="file" id="files" style="display: none;" />
                        </i>
                    </div>
                    <a class="nav-link" (click)="personalInfoTable()">
                        PERSONAL INFO
                    </a>

                    <a class="nav-link" (click)="orderDeatailsTable()">
                        ORDER DETAILS
                    </a>
                    <a class="nav-link" (click)="licenseInfoTable()">
                        LICENSE INFO
                    </a>
                    <!-- BOOTSTRAP DROPDOWN -->
                    <!-- <ul>
                        <li class="list-group dropdown ">
                            <a class="nav-link dropdown-toggle collapse" href="#" id="navbarDropdown" role="button"
                                data-bs-toggle="dropdown" aria-expanded="true">
                                SELECT THEME
                            </a>
                            <ul class="dropdown-menu  collapse checkbox " aria-labelledby="navbarDropdown">
                                <li> <a class=" dropdown-item theme-dropown-item  "
                                        *ngFor="let themeName of themeService.themeNames"
                                        (click)="themeService.set(themeName)"
                                        [class.active]="themeService.activeTheme === themeName">&nbsp;
                                        {{ themeName }}
                                    </a></li>
                            </ul>
                        </li>
                    </ul> -->

                </div>
            </div>
        </div>
          <!-- Personal Information  Section -->
          <div class="col-lg-10 col-md-12 col-sm-12 col-xs-12 me-0 pe-0"
          *ngIf="this.viewModel.isShowPersonalInfoTable">
          <h3 class="pb-2  ps-5 py-3 border-bottom">PERSONAL INFORMATION</h3>
          <div class="row mt-5 pt-5 me-0 pe-0">

              <div class="col-lg-2"></div>
              <div class="col-lg-2 col-md-6 col-sm-6 col-xs-6  ps-sm-3 ms-3 ">
                  <label>Company Code:</label>
              </div>
              <div class="col-lg-1 col-md-6 col-sm-6 col-xs-6  ps-sm-3 ms-3 ">
                  <span>12355</span><br>
              </div>
              <div class="col-lg-2"></div>
              <div class="col-lg-2 col-md-6 col-sm-6 col-xs-6  ps-sm-3 ms-3 ">
                  <label>Role:</label>
              </div>
              <div class="col-lg-2 col-md-6 col-sm-6 col-xs-6  ps-sm-3 ms-3">
                  <span>User</span><br>
              </div>
          </div>
          <div class="row py-3 me-0 pe-0">
              <div class="col-lg-2"></div>
              <div class="col-lg-2 col-md-6 col-sm-6 col-xs-6  ps-sm-3 ms-3 ">
                  <label>Username:</label>
              </div>
              <div class="col-lg-1 col-md-6 col-sm-6 col-xs-6  ps-sm-3 ms-3 ">
                  <span>Aurooj</span><br>
              </div>
              <div class="col-lg-2"></div>
              <div class="col-lg-2 col-md-6 col-sm-6 col-xs-6  ps-sm-3 ms-3 ">
                  <label>Ph. number:</label>
              </div>
              <div class="col-lg-2 col-md-6 col-sm-6 col-xs-6  ps-sm-3 ms-3 ">
                  <span>+1 22234455666</span><br>

              </div>

          </div>&nbsp;&nbsp;&nbsp;&nbsp;
          <button type="button" class="btn btn-success" (click)="editProfileDetails()"> Edit profile
          </button>
      </div>
        <!-- Edit Profile Section -->
        <div class="col-lg-10 col-md-12 col-sm-12 col-xs-12 me-lg-0 " *ngIf="this.viewModel.isShowEditProfile">
            <h3 class="pb-2  ps-5  py-3 border-bottom">EDIT PROFILE</h3>
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="py-5 ">
                        <div class="row py-2">
                            <div class="col-lg-2 pe-2"></div>
                            <div class="col-lg-4 col-md-12 col-sm-12 col-xs-12 p-2 ">
                                <label for="email">New Username</label>
                                <input type="text" class="bg-light form-control p-2 mt-2" placeholder="Enter UserName">
                            </div>
                            <div class=" col-lg-4 col-md-12 col-sm-12 col-xs-12 p-2 ">
                                <label for="phone">Phone Number</label>
                                <input type="tel" class="bg-light form-control p-2 mt-2" placeholder="+2 213-548-6015">
                            </div>
                        </div>
                        <div class="row py-2">
                            <div class="col-lg-2 pe-2"></div>
                            <div class="col-lg-4 col-md-12 col-sm-12 col-xs-12 p-2">
                                <label for="firstname">New Password</label>
                                <input type="password" class="bg-light form-control p-2 mt-2"
                                    placeholder="Change Password">
                            </div>
                            <div class=" col-lg-4 col-md-12 col-sm-12 col-xs-12  p-2">
                                <label for="lastname">Confirm Password</label>
                                <input type="password" class="bg-light form-control p-2 mt-2"
                                    placeholder="confirm password">
                            </div>
                        </div>

                        <div class="row py-2">
                            <div class="col-lg-2  pe-2"></div>
                            <div class="col-lg-4 col-md-6 col-sm-4 pe-2">
                                <div class="py-3 pb-4 pt-4  p-2">
                                    <button class="btn  btn-success"> Save Changes </button>&nbsp;
                                    <button type="button" class="btn btn-danger">Cancel </button>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-12 col-sm-12 pe-2 ">

                                <div class=" float-right pt-4 p-2 " id="deactivate">
                                    <div>
                                        <b>Deactivate your account</b>&nbsp;&nbsp;&nbsp;&nbsp;
                                        <button type="button" class="btn btn-danger "> Deactivate
                                        </button>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
      
        <!-- Order Details Table Section -->
        <div class="col-lg-10 col-md-12 col-sm-12 col-xs-12" *ngIf="viewModel.isShowOrderDetailsInfoTable">
            <h3 class="pb-2  ps-5 py-3 border-bottom">ORDER DETAILS</h3>
            <table class="table table-hover table-responsive text-center">
                <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">Order id:</th>
                        <th scope="col">Order Date:</th>
                        <th scope="col">Payment-Status:</th>
                        <th scope="col">Transaction Id:</th>

                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th>1</th>
                        <td class="ordertablehead">234456</td>
                        <td class="ordertablehead">Thursday,12/11/2022,12:00 AM</td>
                        <td class="ordertablehead">Sccess</td>
                        <td class="ordertablehead">ref:54646464553</td>
                    </tr>
                    <tr>
                        <th>2</th>
                        <td class="ordertablehead">234456</td>
                        <td class="ordertablehead">Thursday,12/11/2022,12:00 AM</td>
                        <td class="ordertablehead">Sccess</td>
                        <td class="ordertablehead">ref:54646464553</td>
                    </tr>
                    <tr>
                        <th>3</th>
                        <td class="ordertablehead">234456</td>
                        <td class="ordertablehead">Thursday,12/11/2022,12:00 AM</td>
                        <td class="ordertablehead">Sccess</td>
                        <td class="ordertablehead">ref:54646464553</td>
                    </tr>
                    <tr>
                        <th>4</th>
                        <td class="ordertablehead">234456</td>
                        <td class="ordertablehead">Thursday,12/11/2022,12:00 AM</td>
                        <td class="ordertablehead">Sccess</td>
                        <td class="ordertablehead">ref:54646464553</td>
                    </tr>
                    <tr>
                        <th>5</th>
                        <td class="ordertablehead">234456</td>
                        <td class="ordertablehead">Thursday,12/11/2022,12:00 AM</td>
                        <td class="ordertablehead">Sccess</td>
                        <td class="ordertablehead">ref:54646464553</td>
                    </tr>
                    <tr>
                        <th>6</th>
                        <td class="ordertablehead">234456</td>
                        <td class="ordertablehead">Thursday,12/11/2022,12:00 AM</td>
                        <td class="ordertablehead">Sccess</td>
                        <td class="ordertablehead">ref:54646464553</td>
                    </tr>

                </tbody>
            </table>

        </div>
        <!-- License Table Section -->
        <div class="col-lg-10 col-md-12 col-sm-12 col-xs-12 me-lg-0" *ngIf="this.viewModel.isShowLicenseInfoTable">
            <h3 class="pb-2  ps-5 py-3 border-bottom">LICENSE</h3>
            <table class="table table2 table-hover table-responsive text-center ">
                <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">License Type:</th>
                        <th scope="col">License No:</th>
                        <th scope="col">Start Date:</th>
                        <th scope="col">Valid-up-to:</th>

                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th>1</th>
                        <td class="licensetablehead">234456</td>
                        <td class="licensetablehead">Thursday,12/11/2022,12:00 AM</td>
                        <td class="licensetablehead">Sccess</td>
                        <td class="licensetablehead">ref:54646464553</td>
                    </tr>
                    <tr>
                        <th>2</th>
                        <td class="licensetablehead">234456</td>
                        <td class="licensetablehead">Thursday,12/11/2022,12:00 AM</td>
                        <td class="licensetablehead">Sccess</td>
                        <td class="licensetablehead">ref:54646464553</td>
                    </tr>
                    <tr>
                        <th>2</th>
                        <td class="licensetablehead">234456</td>
                        <td class="licensetablehead">Thursday,12/11/2022,12:00 AM</td>
                        <td class="licensetablehead">Sccess</td>
                        <td class="licensetablehead">ref:54646464553</td>
                    </tr>
                    <tr>
                        <th>3</th>
                        <td class="licensetablehead">234456</td>
                        <td class="licensetablehead">Thursday,12/11/2022,12:00 AM</td>
                        <td class="licensetablehead">Sccess</td>
                        <td class="licensetablehead">ref:54646464553</td>
                    </tr>
                    <tr>
                        <th>4</th>
                        <td class="licensetablehead">234456</td>
                        <td class="licensetablehead">Thursday,12/11/2022,12:00 AM</td>
                        <td class="licensetablehead">Sccess</td>
                        <td class="licensetablehead">ref:54646464553</td>
                    </tr>

                </tbody>
            </table>

        </div>
    </div>
</div>