import { RoleTypeSM } from "../service-models/app/enums/role-type-s-m.enum";
import { TokenRequestSM } from "../service-models/app/token/token-request-s-m";
import { ClientUserSM } from "../service-models/app/v1/app-users/client-user-s-m";
import { ClientCompanyDetailSM } from "../service-models/app/v1/client/client-company-detail-s-m";
import { BoolResponseRoot } from "../service-models/foundation/common-response/bool-response-root";
import { BaseViewModel } from "./base.viewmodel";
import { RegisterViewModel } from "./register.viewmodel";
import { TopNavViewModel } from "./top-nav.viewmodel";


export class UserLoginViewModel implements BaseViewModel {
    PageTitle: string = 'Login';
    tokenRequest: TokenRequestSM = new TokenRequestSM();
    topnav:TopNavViewModel = new TopNavViewModel();
    register: RegisterViewModel = new RegisterViewModel();
    userDetails: ClientUserSM = new ClientUserSM();
    companyDetails: ClientCompanyDetailSM = new ClientCompanyDetailSM();
    rememberMe: boolean = false;
    hidePassword: boolean = true;
    eyeDefault = 'default';
    containerClass = 'container';
    addInvalidFormClass = '';
    roleTypes: string[] = [];

    validUser: BoolResponseRoot = new BoolResponseRoot();
    showSuccessMsg: boolean = false;
    showErrorMsg: boolean = false;

    LoginFormSubmitted = false;
    RegisterFormSubmitted = false;

    currentRole!: RoleTypeSM;

    loginValidationData = {
        login: [
            { type: 'required', message: 'Login Id is Required' },
            { type: 'maxlength', message: 'Login Id is max' },
            { type: 'minlength', message: 'Login Id is min' },
        ],
        password: [
            { type: 'required', message: 'Password is Required' },
            { type: 'maxlength', message: 'password  is max' },
            { type: 'minlength', message: 'password  is min' },
        ],
    }

    registerValidationData = {
        loginId: [
            { type: 'required', message: 'Login Id is required' },
            { type: 'isValid', message: 'This user is not valid' }
        ],
        firstName: [
            { type: 'required', message: 'First Name is required' },
            { type: 'pattern', message: 'Only digits is not a valid input.' }


        ],
        middleName: [
            { type: 'required', message: 'Middle Name is required' },

        ],
        lastName: [
            { type: 'required', message: 'Last Name is required' },
            { type: 'pattern', message: 'Only digits is not a valid input' }
        ],
        userphoneNumber: [
            { type: 'required', message: 'Phone Number is required' },
            { type: 'minlength', message: 'Enter valid phone number' },
        ],
        userEmailId: [
            { type: 'required', message: 'Email is required' },
            { type: 'pattern', message: 'please enter a valid email' }
        ],
        usercountry: [
            { type: 'required', message: 'Country is required' },
        ],
        userCity: [
            { type: 'required', message: 'City is required' },
        ],
        userState: [
            { type: 'required', message: 'State is required' },
        ],
        userpincode: [
            { type: 'required', message: 'Pincode is required' },
            { type: 'maxlength', message: 'Pincode exceed maximum length' },
            { type: 'pattern', message: 'Enter numerical values' }
        ],
        useraddress: [
            { type: 'required', message: 'Address is required' },
        ],
        PasswordHash: [
            { type: 'required', message: 'Password is required' },
        ],
        CompanyCode: [
            { type: 'required', message: 'Company Code is required' },
        ],
        CompanyName: [
            { type: 'required', message: 'Company Name is required' },
        ],
        CompanyWebsite: [
            { type: 'required', message: 'Company Website is required' },
        ],
        CompanyAddress: [
            { type: 'required', message: 'Company Address is required' },
        ],

        companyCountry: [
            { type: 'required', message: '  Country is required' },
        ],
        companyState: [
            { type: 'required', message: ' State is required' },
        ],

        companyPincode: [
            { type: 'required', message: ' Pincode is required' },
            { type: 'maxlength', message: ' Pincode exceed maximum length' },
        ],
        companyEmailId: [
            { type: 'required', message: 'Company Email is required' },
            { type: 'pattern', message: 'Company Please enter a valid email' }
        ],

        companyCity: [
            { type: 'required', message: ' City is required' },
        ],
        companyphoneNumber: [
            { type: 'required', message: 'Phone Number is required' },
            { type: 'minlength', message: 'Enter valid phone number' },
        ],

    }
}