
import { ClientCompanyDetailSM } from "src/app/service-models/app/v1/client/client-company-detail-s-m";
import { IntResponseRoot } from "src/app/service-models/foundation/common-response/int-response-root";
import { BaseViewModel } from "../base.viewmodel";


export class CompaniesViewModel implements BaseViewModel {
    PageTitle: string = 'Companies';
    PageNo: number = 1;
    PageSize: number = 10;
    allCompanies:ClientCompanyDetailSM[]=[]
    companyDetails: ClientCompanyDetailSM = new ClientCompanyDetailSM();
    isDisabled: boolean = true;
    totalCompanyCount!:number;

}