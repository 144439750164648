<div>
    <ngx-spinner bdColor="rgba(0, 0, 0, 1)" size="medium" color="#fff" type="ball-elastic-dots"  [fullScreen]="true">
        <p style="color: white"> Loading.. </p>
    </ngx-spinner>
</div>

    <!-- <div class="container">

        <div class="row">

            <div id="loader">
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="loading"></div>
            </div>

            <p class="spinner-message"
                *ngIf="_commonService.loaderInfo.showLoader && _commonService.loaderInfo.message !== ''">
                {{_commonService.loaderInfo.message}}
            </p>

        </div>

    </div> -->
