<div class="container-fluid">

    <div class="row signInSection">

        <div [ngClass]="[viewModel.containerClass]">

            <div class="form-container sign-up-container">

                <button type="button" name="close-modal" class="closeModal" (click)="closeModal()">
                    <span> <i class="bi bi-x-lg"></i> </span>
                </button>

                <form #RegisterIndividualForm="ngForm" [ngClass]="[viewModel.addInvalidFormClass]" ngbAutofocus>

                    <h3> Register With Us </h3>

                    <input type="text" class="form-control" placeholder="Your First Name"
                        [(ngModel)]="viewModel.register.userDetails.firstName" #firstName="ngModel" required
                        pattern="^[a-zA-Z]+$"
                        name="firstName" minlength="3" maxlength="20" (blur)='invalidForm()' />

                    <ng-container *ngFor="let validation of viewModel.registerValidationData.firstName">
                        <div class="text-danger ms-lg-2 ms-md-2 ms-sm-2 ms-2"
                            *ngIf="(firstName.touched || viewModel.RegisterFormSubmitted) && firstName.hasError(validation.type)">
                            {{ validation.message }}
                        </div>
                    </ng-container>

                    <input type="text" class="form-control" placeholder="Your Last Name"
                        [(ngModel)]="viewModel.register.userDetails.lastName" #lastName="ngModel" required
                        pattern="^[a-zA-Z]+$"
                        name="lastName" minlength="3" maxlength="20" (blur)='invalidForm()' />

                    <ng-container *ngFor="let validation of viewModel.registerValidationData.lastName">
                        <div class="text-danger ms-lg-2 ms-md-2 ms-sm-2 ms-2"
                            *ngIf="(lastName.touched||viewModel.RegisterFormSubmitted) && lastName.hasError(validation.type)">
                            {{ validation.message }}
                        </div>
                    </ng-container>

                    <input type="number" class="form-control" placeholder="Your phone Number"
                        [(ngModel)]="viewModel.register.userDetails.phoneNumber" #userphoneNumber="ngModel" required
                        maxlength="10" name="phoneNumber" (blur)='invalidForm()' />

                    <ng-container *ngFor="let validation of viewModel.registerValidationData.userphoneNumber">
                        <div class="text-danger ms-lg-2 ms-md-2 ms-sm-2 ms-2"
                            *ngIf="(userphoneNumber.touched||viewModel.RegisterFormSubmitted) && userphoneNumber.hasError(validation.type)">
                            {{ validation.message }}
                        </div>
                    </ng-container>

                    <input type="email" class="form-control" placeholder="Your email Id"
                        [(ngModel)]="viewModel.register.userDetails.emailId" #userEmailId="ngModel" required
                        name="emailId" pattern="^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$"
                        (blur)='invalidForm()' />

                    <ng-container *ngFor="let validation of viewModel.registerValidationData.userEmailId">
                        <div class="text-danger ms-lg-2 ms-md-2 ms-sm-2 ms-2"
                            *ngIf="(userEmailId.touched||viewModel.RegisterFormSubmitted) &&  userEmailId.hasError(validation.type)">
                            {{ validation.message }}
                        </div>
                    </ng-container>

                    <input type="text" class="form-control" placeholder="Enter login Id"
                        [(ngModel)]="viewModel.register.userDetails.loginId" #loginId="ngModel" required name="loginId"
                        (ngModelChange)="change_LoginId($event)" (blur)='invalidForm()' />

                    <ng-container *ngFor="let validation of viewModel.registerValidationData.loginId">
                        <div class="text-danger ms-lg-2 ms-md-2 ms-sm-2 ms-2"
                            *ngIf="(loginId.touched||viewModel.RegisterFormSubmitted) && loginId.hasError(validation.type)">
                            {{ validation.message }}
                        </div>
                    </ng-container>

                    <ng-container *ngIf="viewModel.showSuccessMsg">
                        <div class="text-success">
                            {{viewModel.validUser.responseMessage}}
                        </div>
                    </ng-container>

                    <ng-container *ngIf="viewModel.showErrorMsg">
                        <div class="text-danger ms-lg-2 ms-md-2 ms-sm-2 ms-2">
                            {{viewModel.validUser.responseMessage}}
                        </div>
                    </ng-container>

                    <div class="password-container">

                        <input [type]="viewModel.hidePassword ? 'password' : 'text'" class="form-control"
                            placeholder="Enter Password" [(ngModel)]="viewModel.register.userDetails.passwordHash"
                            #PasswordHash="ngModel" required name="passwordHash" (blur)='invalidForm()' />

                        <i *ngIf="viewModel.hidePassword" class="bi bi-eye-fill" (click)="click_TogglePassword()">
                        </i>

                        <i *ngIf="!viewModel.hidePassword" class="bi bi-eye-slash-fill"
                            (click)="click_TogglePassword()">
                        </i>

                    </div>

                    <ng-container *ngFor="let validation of viewModel.registerValidationData.PasswordHash">
                        <div class="text-danger ms-lg-2 ms-md-2 ms-sm-2 ms-2"
                            *ngIf="(PasswordHash.touched||viewModel.RegisterFormSubmitted) && PasswordHash.hasError(validation.type)">
                            {{ validation.message }}
                        </div>
                    </ng-container>

                    <div class="text-center">

                        <button type="button" name="sign-up-button" class="spyGlassBtn"
                            (click)="registerIndividualUser(RegisterIndividualForm)"
                            (window:keyup.enter)="registerIndividualUser(RegisterIndividualForm)">
                            Sign Up
                        </button>
                        <br>
                        <a (click)="openCompanySignUpModal()" class="companySignUplink" data-bs-dismiss="modal"> Sign Up As Company
                        </a>

                    </div>

                </form>

            </div>

            <div class="form-container sign-in-container">

                <button type="button" name="close-modal" class="closeModal d-lg-none d-md-none d-sm-none d-inline-block"
                    (click)="closeModal()">
                    <span> <i class="bi bi-x-lg"></i> </span>
                </button>

                <form #loginForm="ngForm">

                    <h1> Sign In </h1>

                    <input [(ngModel)]="viewModel.tokenRequest.loginId" class="form-control"
                        placeholder="Enter Login Id" #login="ngModel" required minlength="3" maxlength="20"
                        name="login" />

                    <ng-container *ngFor="let validation of viewModel.loginValidationData.login">

                        <div class="text-danger"
                            *ngIf="(login.touched||viewModel.LoginFormSubmitted) && login.hasError(validation.type)">
                            {{ validation.message }}
                        </div>

                    </ng-container>

                    <div class="password-container">

                        <input [(ngModel)]="viewModel.tokenRequest.password" class="form-control passwordField"
                            placeholder="Enter Password" [type]="viewModel.hidePassword ? 'password' : 'text'"
                            #password="ngModel" required minlength="3" name="password" />

                        <i *ngIf="viewModel.hidePassword" class="bi bi-eye-fill" (click)="click_TogglePassword()">
                        </i>

                        <i *ngIf="!viewModel.hidePassword" class="bi bi-eye-slash-fill"
                            (click)="click_TogglePassword()">
                        </i>

                    </div>

                    <ng-container *ngFor="let validation of viewModel.loginValidationData.password">
                        <div class="text-danger"
                            *ngIf="(password.touched||viewModel.LoginFormSubmitted) && password.hasError(validation.type)">
                            {{ validation.message }}
                        </div>
                    </ng-container>

                    <div class="checkbox-container">
                        <input type="checkbox" [(ngModel)]="viewModel.rememberMe" [ngModelOptions]="{standalone: true}"
                            name="check">
                        <label for="remember-me"> Keep me signed in! </label>
                    </div>

                    <button type="button" name="sign-in-button" class="spyGlassBtn"
                        (window:keyup.enter)="click_Login(loginForm)" (click)="click_Login(loginForm)"> Sign In
                    </button>


                    <a class="forgotPasswordLink" (click)="openForgotPasswordModal() "> Forgot your password? </a>

                    <button class="spyGlassBtn d-lg-none d-md-none d-sm-none d-inline-block" (click)="switchToSignUp()">
                        Sign Up As Individual
                    </button>

                    <button class="spyGlassBtn d-lg-none d-md-none d-sm-none d-inline-block mt-1"
                        (click)="openCompanySignUpModal()"> Sign Up As Company
                    </button>

                </form>

            </div>

            <div class="overlay-container">

                <div class="overlay">

                    <div class="overlay-panel overlay-left">

                        <h1>Welcome Back!</h1>
                        <p>To keep connected with us please login with your personal info</p>
                        <button class="spyGlassBtn" (click)="switchToSignIn()"> Sign In </button>

                    </div>

                    <div class="overlay-panel overlay-right">
                        <button type="button" name="close-modal" class="closeModal" (click)="closeModal()">
                            <span> <i class="bi bi-x-lg"></i> </span>
                        </button>

                        <h1>Hello, Friend!</h1>
                        <p>Enter your personal details and start journey with us</p>
                        <button class="spyGlassBtn" (click)="switchToSignUp()"> Sign Up as Individual </button>
                        <button class="spyGlassBtn mt-4" data-bs-toggle="modal" data-bs-target="#exampleModal"
                            (click)="openCompanySignUpModal()"> Sign Up as Company
                        </button>
                    </div>



                </div>

            </div>

        </div>

    </div>
</div>


<!-- <div class="row companyRegisterSection" *ngIf="viewModel.showCompanyRegisterForm">

        <div class="col-lg-3 col-md-3 d-lg-block d-md-block d-sm-none d-none">

            <div class="overlay-container">
                <div class="overlay-panel">
                    <h2> Hello <br> Friend! </h2>
                    <p> Enter your personal details and start journey with us </p>
                </div>
            </div>

        </div>

        <div class="col-lg-9 col-md-9 col-sm-12 col-12 pb-5 registerFormColumn">

            <button type="button" name="close-modal" class="closeModal" (click)="closeModal()">
                <span> <i class="bi bi-x-lg"></i> </span>
            </button>

            <h1> Create Account with us </h1>

            <form #form_RegisterCompany="ngForm" ngbAutofocus>

                <div class="row">

                    <div class="col-lg-6 col-md-6">

                        <input type="text" class="form-control" placeholder="First Name"
                            [(ngModel)]="viewModel.userDetails.firstName" #firstName="ngModel" required name="firstName"
                            minlength="3" maxlength="20" />

                        <ng-container *ngFor="let validation of viewModel.registerValidationData.firstName">
                            <div class="text-danger ms-lg-3 ms-md-3 ms-sm-3 ms-3"
                                *ngIf="(firstName.touched || viewModel.FormSubmitted) && firstName.hasError(validation.type)">
                                {{ validation.message }}
                            </div>
                        </ng-container>

                    </div>

                    <div class="col-lg-6 col-md-6">

                        <input type="text" class="form-control" placeholder="Last Name"
                            [(ngModel)]="viewModel.userDetails.lastName" #lastName="ngModel" required name="lastName"
                            minlength="3" maxlength="20" />

                        <ng-container *ngFor="let validation of viewModel.registerValidationData.lastName">
                            <div class="text-danger ms-lg-3 ms-md-3 ms-sm-3 ms-3"
                                *ngIf="(lastName.touched||viewModel.FormSubmitted) && lastName.hasError(validation.type)">
                                {{ validation.message }}
                            </div>
                        </ng-container>

                    </div>

                    <div class="col-lg-6 col-md-6">

                        <input type="number" class="form-control" placeholder="Your Phone Number"
                            [(ngModel)]="viewModel.userDetails.phoneNumber" #userphoneNumber="ngModel" required
                            maxlength="10" name="phoneNumber" />

                        <ng-container *ngFor="let validation of viewModel.registerValidationData.userphoneNumber">
                            <div class="text-danger ms-lg-3 ms-md-3 ms-sm-3 ms-3"
                                *ngIf="(userphoneNumber.touched||viewModel.FormSubmitted) && userphoneNumber.hasError(validation.type)">
                                {{ validation.message }}
                            </div>
                        </ng-container>

                    </div>

                    <div class="col-lg-6 col-md-6">

                        <input type="email" class="form-control" placeholder="Your Email Id"
                            [(ngModel)]="viewModel.userDetails.emailId" #userEmailId="ngModel" required name="emailId"
                            pattern="^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$" />

                        <ng-container *ngFor="let validation of viewModel.registerValidationData.userEmailId">
                            <div class="text-danger ms-lg-3 ms-md-3 ms-sm-3 ms-3"
                                *ngIf="(userEmailId.touched||viewModel.FormSubmitted) &&  userEmailId.hasError(validation.type)">
                                {{ validation.message }}
                            </div>
                        </ng-container>

                    </div>


                    <div class="col-lg-6 col-md-6">

                        <input type="text" class="form-control" placeholder="Enter login Id"
                            [(ngModel)]="viewModel.userDetails.loginId" #loginId="ngModel" required name="loginId"
                            (ngModelChange)="change_LoginId($event)" />

                        <ng-container *ngFor="let validation of viewModel.registerValidationData.loginId">
                            <div class="text-danger ms-lg-3 ms-md-3 ms-sm-3 ms-3"
                                *ngIf="(loginId.touched||viewModel.FormSubmitted) && loginId.hasError(validation.type)">
                                {{ validation.message }}
                            </div>
                        </ng-container>

                        <ng-container *ngIf="viewModel.showSuccessMsg">
                            <div class="text-success ms-lg-3 ms-md-3 ms-sm-3 ms-3">
                                {{viewModel.validUser.responseMessage}}
                            </div>
                        </ng-container>

                        <ng-container *ngIf="viewModel.showErrorMsg">
                            <div class="text-danger ms-lg-3 ms-md-3 ms-sm-3 ms-3">
                                {{viewModel.validUser.responseMessage}}
                            </div>
                        </ng-container>

                    </div>


                    <div class="col-lg-6 col-md-6">

                        <div class="password-container">

                            <input [type]="viewModel.hidePassword ? 'password' : 'text'" class="form-control"
                                placeholder="Enter Password" [(ngModel)]="viewModel.userDetails.passwordHash"
                                #PasswordHash="ngModel" required name="passwordHash" />

                            <i *ngIf="viewModel.hidePassword" class="bi bi-eye-fill" (click)="click_TogglePassword()">
                            </i>

                            <i *ngIf="!viewModel.hidePassword" class="bi bi-eye-slash-fill"
                                (click)="click_TogglePassword()">
                            </i>

                        </div>

                        <ng-container *ngFor="let validation of viewModel.registerValidationData.PasswordHash">
                            <div class="text-danger ms-lg-3 ms-md-3 ms-sm-3 ms-3"
                                *ngIf="(PasswordHash.touched||viewModel.FormSubmitted) && PasswordHash.hasError(validation.type)">
                                {{ validation.message }}
                            </div>
                        </ng-container>

                    </div>

                    <div class="col-lg-6 col-md-6">

                        <input type="text" class="form-control" placeholder="Company Name"
                            [(ngModel)]="viewModel.companyDetails.name" #CompanyName="ngModel" required
                            name="CompanyName" />

                        <ng-container *ngFor="let validation of viewModel.registerValidationData.CompanyName">
                            <div class="text-danger ms-lg-2 ms-md-2 ms-sm-2 ms-2"
                                *ngIf="(CompanyName.touched || viewModel.FormSubmitted)&& CompanyName.hasError(validation.type)">
                                {{validation.message}}
                            </div>
                        </ng-container>

                    </div>

                    <div class="col-lg-6 col-md-6">

                        <input type="email" class="form-control" placeholder="Company Email Id"
                            [(ngModel)]="viewModel.companyDetails.contactEmail" #companyEmailId="ngModel" required
                            pattern="^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$"
                            name="companyEmailId" />

                        <ng-container *ngFor="let validation of viewModel.registerValidationData.companyEmailId">
                            <div class="text-danger ms-lg-2 ms-md-2 ms-sm-2 ms-2"
                                *ngIf="(companyEmailId.touched || viewModel.FormSubmitted)&& companyEmailId.hasError(validation.type)">
                                {{validation.message}}
                            </div>
                        </ng-container>

                    </div>

                    <div class="col-lg-6 col-md-6">

                        <input type="text" class="form-control" placeholder=" Company Website"
                            [(ngModel)]="viewModel.companyDetails.companyWebsite" #CompanyWebsite="ngModel" required
                            name="CompanyWebsite" />

                        <ng-container *ngFor="let validation of viewModel.registerValidationData.CompanyWebsite">
                            <div class="text-danger ms-lg-2 ms-md-2 ms-sm-2 ms-2"
                                *ngIf="(CompanyWebsite.touched || viewModel.FormSubmitted)&& CompanyWebsite.hasError(validation.type)">
                                {{validation.message}}

                            </div>
                        </ng-container>

                    </div>

                    <div class="col-lg-6 col-md-6">

                        <input type="number" class="form-control" placeholder="Company Phone Number"
                            [(ngModel)]="viewModel.companyDetails.companyMobileNumber" #companyphoneNumber="ngModel"
                            required maxlength="10" name="companyphoneNumber" />

                        <ng-container *ngFor="let validation of viewModel.registerValidationData.companyphoneNumber">
                            <div class="text-danger ms-lg-2 ms-md-2 ms-sm-2 ms-2"
                                *ngIf="(companyphoneNumber.touched || viewModel.FormSubmitted)&& companyphoneNumber.hasError(validation.type)">
                                {{validation.message}}
                            </div>
                        </ng-container>

                    </div>

                </div>

                <div class="text-center mt-5">

                    <button type="submit" name="sign-up-button" class="spyGlassBtn"
                        (click)="registerCompany(form_RegisterCompany)"> Sign Up
                    </button>

                    <br>

                    <span> Already a User! </span>
                    <br>
                    <a (click)="click_showLoginModal()" class="signInLink"> Sign In </a>

                </div>

            </form>

        </div>

    </div> -->