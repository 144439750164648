import { Component, OnInit } from "@angular/core";
import { CommonService } from "src/app/services/common.service";
import { LogHandlerService } from "src/app/services/log-handler.service";
import { ProductInfoViewModel } from "src/app/view-models/product-info.viewmodel";
import { BaseComponent } from "../../base.component";
import { WebsiteResourceService } from "src/app/services/website-resource.service";
import { environment } from "src/environments/environment";


@Component({
  selector: 'app-product-info',
  templateUrl: './product-info.component.html',
  styleUrls: ['./product-info.component.scss']
})

export class ProductInfoComponent extends BaseComponent<ProductInfoViewModel> implements OnInit {

  constructor(
    commonService: CommonService,
    logService: LogHandlerService,
    private websiteResourceService: WebsiteResourceService,
  ) {
    super(commonService, logService);
    this.viewModel = new ProductInfoViewModel();
    this._commonService.layoutViewModel.toggleWrapper = 'homeWrapper';
    this._commonService.layoutViewModel.toggleTopNav = 'sampleReportsTopNav';
    this._commonService.layoutViewModel.showFooter = true;
    this._commonService.layoutViewModel.showTopNav = true;
  }


  async ngOnInit() {
    await this._commonService.presentLoading();
    setTimeout(async () => {
      await this._commonService.dismissLoader();
      await this.loadPageData();
    }, environment.animationTimeoutinMS);
  }


  override async loadPageData() {
    try {
      // await this._commonService.presentLoading();
      let resp = await this.websiteResourceService.getWebsiteResourceProductPageImages();
      if (resp.isError) {
        await this._exceptionHandler.logObject(resp.errorData);
        await this._commonService.showSweetAlertToast({ title: resp.errorData.displayMessage, icon: 'error' });
      } else {
        this.viewModel.productMainImage = resp.successData[0].resourceFile;
        this.viewModel.productImage1 = resp.successData[1].resourceFile;
        this.viewModel.productImage2 = resp.successData[2].resourceFile;
      }
    } catch (error) {
      throw error;
    } finally {
      await this._commonService.dismissLoader();
    }
  }
}
