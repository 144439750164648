import { DemoVideoSM } from "../service-models/app/v1/general/demo-video-s-m";
import { BaseViewModel } from "./base.viewmodel";

export class DemoVideosViewModel implements BaseViewModel {
    PageTitle: string = 'Demo videos';
    demoVideoMainImage: string = '';
    demoVideoReportTitle:string='';
    demoVideoReport!: string ;
    demoVideoPrintSetting:string='';
    demoVideoPrintSettingTitle: string = '';
    demoVideoPrintSettingDashboard:string='';
    demoVideoPrintSettingDashboardTitle:string=''
    demoVideoSchedular:string='';
    demoVideoSchedularTitle:string=''

}
    