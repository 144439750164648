import { Injectable } from "@angular/core";
import { AppConstants } from "src/app-constants";
import { ClientCompanyAddressSM } from "../service-models/app/v1/client/client-company-address-s-m";
import { ClientCompanyDetailSM } from "../service-models/app/v1/client/client-company-detail-s-m";
import { ApiRequest } from "../service-models/foundation/api-contracts/base/api-request";
import { ApiResponse } from "../service-models/foundation/api-contracts/base/api-response";
import { DeleteResponseRoot } from "../service-models/foundation/common-response/delete-response-root";
import { StorageService } from "../services/storage.service";
import { BaseApiClient } from "./base-client/base-api.client";
import { CommonResponseCodeHandler } from "./helpers/common-response-code-handler.helper";
import { StorageCache } from "./helpers/storage-cache.helper";


@Injectable({
    providedIn: 'root'
})


export class CompanyProfileClient extends BaseApiClient {
    constructor(storageService: StorageService, storageCache: StorageCache,
        commonResponseCodeHandler: CommonResponseCodeHandler) {
        super(storageService, storageCache, commonResponseCodeHandler)

    }
    GetCompanyProfile = async (): Promise<ApiResponse<ClientCompanyDetailSM>> => {
        let resp = await this.GetResponseAsync<number, ClientCompanyDetailSM>
            (`${AppConstants.ApiUrls.COMPANY_URL}/my`, 'GET');
        return resp;
    }

    GetCompanyAddress = async (): Promise<ApiResponse<ClientCompanyAddressSM>> => {
        let resp = await this.GetResponseAsync<number, ClientCompanyAddressSM>
            (`${AppConstants.ApiUrls.COMPANY_ADDRESS_API_URL}/my`, 'GET');
        return resp;
    }


    AddCompanyAddress = async (companyAddressRequest: ApiRequest<ClientCompanyAddressSM>): Promise<ApiResponse<ClientCompanyAddressSM>> => {
        let resp = await this.GetResponseAsync<ClientCompanyAddressSM, ClientCompanyAddressSM>
        (`${AppConstants.ApiUrls.COMPANY_ADDRESS_API_URL}/my`, 'POST', companyAddressRequest);
        return resp;
    }


    UpdateCompanyAddress = async (updateRequest: ApiRequest<ClientCompanyAddressSM>): Promise<ApiResponse<ClientCompanyAddressSM>> => {
        let resp = await this.GetResponseAsync<ClientCompanyAddressSM, ClientCompanyAddressSM>
            (`${AppConstants.ApiUrls.COMPANY_ADDRESS_API_URL}/${updateRequest.reqData.id}`, 'PUT', updateRequest);
        return resp;
    }
    
    UplodPictureForCompany = async (companyPictureRequest: ApiRequest<string>): Promise<ApiResponse<string>> => {
        let resp = await this.GetResponseAsync<string, string>
        (`${AppConstants.ApiUrls.COMPANY_URL}/mine/CompanyLogo`,'POST', companyPictureRequest);
        return resp;
    }
    DeletePictureForCompany = async (): Promise<ApiResponse<DeleteResponseRoot>> => {
        let resp = await this.GetResponseAsync<number, DeleteResponseRoot>
            (`${AppConstants.ApiUrls.COMPANY_URL}/mine/DeleteCompanyLogo`, 'DELETE');
        return resp;
    }
    

}