import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit {
  
  constructor(  private _commonService: CommonService,
    
  ) {
    
   
    this._commonService.layoutViewModel.toggleWrapper = 'homeWrapper';
    this._commonService.layoutViewModel.showFooter = false;
  }

  ngOnInit(): void {
    // alert("route not found")
  }

}
