import { Component, OnInit, TemplateRef } from "@angular/core";
import { NgForm } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AppConstants } from "src/app-constants";
import { ResourceLocationSM } from "src/app/service-models/app/enums/resource-location-s-m.enum";
import { ResourceTypeSM } from "src/app/service-models/app/enums/resource-type-s-m.enum";
import { WebsiteResourceSM } from "src/app/service-models/app/v1/general/website-resource-s-m";
import { ApiResponse } from "src/app/service-models/foundation/api-contracts/base/api-response";
import { OdataQueryFilter } from "src/app/service-models/foundation/api-contracts/odata-query-filter";
import { CommonService } from "src/app/services/common.service";
import { LogHandlerService } from "src/app/services/log-handler.service";
import { WebsiteResourceService } from "src/app/services/website-resource.service";
import { WebsiteResourceViewmodel, WRadditionalData } from "src/app/view-models/admin/website-resource.viewmodel";
import { BaseComponent } from "../../base.component";

@Component({
  selector: "app-website-resource",
  templateUrl: "./website-resource.component.html",
  styleUrls: ["./website-resource.component.scss"],
})
export class WebsiteResourceComponent extends BaseComponent<WebsiteResourceViewmodel>implements OnInit {
  // page = 1;
  // pageSize = 2;
  constructor(
    private modalService: NgbModal,
    commonService: CommonService,
    logService: LogHandlerService,
    private websiteResourceService: WebsiteResourceService
  ) {
    super(commonService, logService);

    this.viewModel = new WebsiteResourceViewmodel();
  }
  async ngOnInit() {
    await this.loadPageData();
  }

  /**
   * get all website resource data
   * @returns success.data
   */
  override async loadPageData() {
    try {
      //enum value to string
      await this._commonService.presentLoading();
      this.viewModel.resourceType = this._commonService.enumToStringArray(ResourceTypeSM);
      this.viewModel.resourceLocation = this._commonService.enumToStringArray(ResourceLocationSM);
      let queryFilter=new OdataQueryFilter();
      queryFilter.skip=(this.viewModel.PageNo -1)* this.viewModel.PageSize;
      queryFilter.top=this.viewModel.PageSize;
      let resp = await this.websiteResourceService.getAllWebsiteResourcesbyOdata(queryFilter);
      if (resp.isError) {
        await this._exceptionHandler.logObject(resp.errorData);
        this._commonService.showSweetAlertToast({
          title: resp.errorData.displayMessage,
          icon: "error",
        });
      } else {
        await this.getWebsiteResourceCount();
        this.viewModel.websiteResourceList = resp.successData;
      }
    } catch (error) {
      this._commonService.showSweetAlertToast({
        title: AppConstants.ErrorPrompts.Unknown_Error,
        icon: "error",
      });
      throw error;
    } finally {
      await this._commonService.dismissLoader();
    }
  }
  /**
   * open ADD, Edit Modal
   * @param content
   * @param id
   */
  async click_OpenAddEditModal(content: TemplateRef<any>, id: any) {
    this.viewModel.AddEditModalTitle =
      id > 0 ? "Update Resources" : "Add Resources";
    this.viewModel.FormSubmitted = false;
    this.viewModel.singleWebsiteResource = new WebsiteResourceSM();
    this.viewModel.WRAdditionalData=new WRadditionalData();
    if (id > 0)
    { await this.getWebsiteResourceById(id);
      
    }
    let resourceType = this.viewModel.singleWebsiteResource.resourceType;
    let resource: any = ResourceTypeSM[resourceType]
    if (ResourceTypeSM.Pdf == resource){
      this.viewModel.additionalReportsFields = true;
      }
      else{
        this.viewModel.additionalReportsFields = false;
      }
    await this.modalService.open(content).result.then();
   
    
    }
  /**
   * get website resource by id
   * @param id
   * @returns success message
   */

  async getWebsiteResourceById(id: number) {
    try {
      await this._commonService.presentLoading();
      ;
      this.viewModel.resourceType = this._commonService.enumToStringArray(ResourceTypeSM);
      this.viewModel.resourceLocation = this._commonService.enumToStringArray(ResourceLocationSM);
      //have to do paging
      let resp = await this.websiteResourceService.getWebsiteResourceById(id);
      if (resp.isError) {
        await this._exceptionHandler.logObject(resp.errorData);
        this._commonService.showSweetAlertToast({
          title: resp.errorData.displayMessage,
          icon: "error",
        });
      } else {

        this.viewModel.singleWebsiteResource = resp.successData;

        this.viewModel.WRAdditionalData = JSON.parse(resp.successData.additionalDetails);
      }
    } catch (error) {
      this._commonService.showSweetAlertToast({
        title: AppConstants.ErrorPrompts.Unknown_Error,
        icon: "error",
      });
      throw error;
    } finally {
      await this._commonService.dismissLoader();
    }
  }

  /**
   * ADD ,Edit website resource
   * @param form_WebsiteResource
   * @returns success message
   */
  async form_addEditWebsiteResource(form_WebsiteResource: NgForm) {
    this.viewModel.FormSubmitted = true;
   
    try {
      if (form_WebsiteResource.invalid) return;
      await this._commonService.presentLoading();
      let resp: ApiResponse<WebsiteResourceSM>;
      //TODO Musaib
      let values = this.viewModel.WRAdditionalData;
      let data = JSON.stringify(values);
      this.viewModel.singleWebsiteResource.additionalDetails = data;

      if (this.viewModel.singleWebsiteResource.id && this.viewModel.singleWebsiteResource.id > 0 ) {
        this.viewModel.singleWebsiteResource.additionalDetails = data;
        resp = await this.websiteResourceService.updateWebsiteResource(this.viewModel.singleWebsiteResource);
      } else {
         resp = await this.websiteResourceService.addWebsiteResource( this.viewModel.singleWebsiteResource );
      }
      if (resp.isError) {
        await this._exceptionHandler.logObject(resp.errorData);
        this._commonService.showSweetAlertToast({
          title: resp.errorData.displayMessage,
          icon: "error",
        });
      } else {
        this.modalService.dismissAll();
        this._commonService.showSweetAlertToast({
          title: "Success",
          icon: "success",
        });
        this.loadPageData();
      }
    } catch (error) {
      this._commonService.showSweetAlertToast({
        title: AppConstants.ErrorPrompts.Unknown_Error,
        icon: "error",
      });
      throw error;
    } finally {
      await this._commonService.dismissLoader();
    }
  }
  /**
   * Delete website resource by id
   * @param id
   * @returns
   */
  async deleteWebsiteResource(id: any) {
    let deleteConfirmation =
      await this._commonService.showSweetAlertConfirmation({
        title: `${AppConstants.DefaultMessages.DeleteAlertConfirmation} RESOURCE?`,
        text: " ",
        showCancelButton: true,
        icon: "warning",
      });
    if (deleteConfirmation) {
      try {
        let resp = await this.websiteResourceService.deleteWebsiteResource(id);
        if (resp.isError) {
          await this._exceptionHandler.logObject(resp.errorData);
          this._commonService.showSweetAlertToast({
            title: resp.errorData.displayMessage,
            icon: "error",
          });
        } else {
          this._commonService.showSweetAlertToast({
            title: "Success",
            icon: "success",
          });
          this.loadPageData();
          return;
        }
      } catch (error) {
        this._commonService.showSweetAlertToast({
          title: AppConstants.ErrorPrompts.Unknown_Error,
          icon: "error",
        });

        throw error;
      } finally {
        await this._commonService.dismissLoader();
      }
    }
  }
  /**
   * choose file (image,video,pdf)
   * converts selected file into base64 string
   * @param event
   */
  uploadFile(event: any) {
    this._commonService
      .convertFileToBase64(event.target.files[0])
      .subscribe((base64) => {
        var fileName = event.target.files[0].name;
        var fileExtension = fileName.split("?")[0].split(".").pop();
        this.viewModel.singleWebsiteResource.extension = fileExtension;
        this.viewModel.singleWebsiteResource.resourceFile = base64;

      });
  }

  change_resourceType() {
    let resourceType = this.viewModel.singleWebsiteResource.resourceType;
    let resourceLocation = this.viewModel.singleWebsiteResource.resourceLocation;
    let resourceloc: any = ResourceLocationSM[resourceLocation]
    let resource: any = ResourceTypeSM[resourceType]
    if (ResourceTypeSM.Pdf == resource && ResourceLocationSM.SampleReports == resourceloc) {
      this.viewModel.additionalReportsFields = true;
      this.viewModel.chooseFileType = ".pdf";
    }
    else if (ResourceTypeSM.Image == resource) {
      this.viewModel.chooseFileType = ".png,.jpg,.jpeg,.jpe,.jfif,.JPG,.PNG,.JPEG,.JPE,.JFIF,.BMP,.bmp"
    }

    else if (ResourceTypeSM.Doc == resource) {
      this.viewModel.chooseFileType = ".docx";
    }
    else if (ResourceTypeSM.Video == resource) {
      this.viewModel.chooseFileType = "video/*";
    }
}

async getWebsiteResourceCount() {
  try {
    await this._commonService.presentLoading();
    let resp = await this.websiteResourceService.getWebsiteResourceCount();
    if (resp.isError) {
      this._exceptionHandler.logObject(resp.errorData);
      this._commonService.showSweetAlertToast({title: resp.errorData.displayMessage, icon: 'error' });
      return;
    }
    else {
      this.viewModel.totalCount = resp.successData.intResponse;
    }
  } catch (error) {
    this._commonService.showSweetAlertToast({title: AppConstants.ErrorPrompts.Unknown_Error, icon: 'error' });
    throw error;
  }
  finally {
    await this._commonService.dismissLoader()
  }
}
async loadPagedataWithPagination(event: any) {
  this.viewModel.PageNo = event;
  await this.loadPageData();
}
}

