import { Component, OnInit } from "@angular/core";
import { RoleTypeSM } from "src/app/service-models/app/enums/role-type-s-m.enum";
import { ClientCompanyDetailSM } from "src/app/service-models/app/v1/client/client-company-detail-s-m";
import { CommonService } from "src/app/services/common.service";
import { LogHandlerService } from "src/app/services/log-handler.service";
import { LinkName, UserProfileMenuViewModel } from "src/app/view-models/user-profile-menu.viewmodel";
import { BaseComponent } from "../../base.component";
import { ThemeService } from 'src/app/services/theme.service';

@Component({
  selector: "app-user-profile-menu",
  templateUrl: "./user-profile-menu.component.html",
  styleUrls: ["./user-profile-menu.component.scss"],
})

export class UserProfileMenuComponent extends BaseComponent<UserProfileMenuViewModel> implements OnInit {

  constructor(
    commonService: CommonService,
    logService: LogHandlerService,
    protected themeService: ThemeService

  ) {
    super(commonService, logService);
    this.viewModel = new UserProfileMenuViewModel();
    this._commonService.layoutViewModel.toggleUserProfileWrapper = 'userProfileWrapper';
    this._commonService.layoutViewModel.toggleTopNav = 'homeTopNav';
    this._commonService.layoutViewModel.showMenuBtn = true;
    this._commonService.layoutViewModel.showTopNav = true;
  }

  async ngOnInit() {
    this.loadPageData();

  }

  // @HostListener('window:scroll', [])
  // onWindowScroll() {
  //   this._commonService.layoutViewModel.stickyMenu = true;
  // }

  async recieveFromChild(companyDetail: ClientCompanyDetailSM) {
    if (companyDetail.id > 0) {
      this.viewModel.companyDetail = companyDetail;
    }
  }

  override async loadPageData() {

    try {
      await this._commonService.presentLoading();
      if (this._commonService.layoutViewModel.roleType == RoleTypeSM.IndividualInspector || this._commonService.layoutViewModel.roleType == RoleTypeSM.CompanyInspector)
      this.viewModel.selectedLink = LinkName.InspectorProfile;
    else if (
      this._commonService.layoutViewModel.roleType == RoleTypeSM.CompanyAdmin
    )
      this.viewModel.selectedLink = LinkName.CompanyProfile;
    } catch (error) {
      throw error
    } finally{
      await this._commonService.dismissLoader();
    }
    
  
  }

  async getAllThemes() {
    try {
      await this._commonService.presentLoading();
      let resp = await this.themeService.getAllThemes();
      if (resp.isError) {
        await this._exceptionHandler.logObject(resp.errorData);
        await this._commonService.showSweetAlertToast({ title: resp.errorData.displayMessage, icon: 'error' });
      } else {
        this.viewModel.allThemes = resp.successData;
      }
    } catch (error) {
      throw error;
    } finally {
      await this._commonService.dismissLoader();
    }
  }

  async updateTheme(theme: any) {
    try {
      await this._commonService.presentLoading();
      let themeId = theme.id;
      let resp = await this.themeService.updateThemeById(themeId);
      if (resp.isError) {
        await this._exceptionHandler.logObject(resp.errorData);
        await this._commonService.showSweetAlertToast({ title: resp.errorData.displayMessage, icon: 'error' });
      } else {
        this._commonService.showInfoInModalPopup("success", "Theme Updated Successfully");
        let theme = resp.successData.boolResponse;
        if (theme == true) {
          
          await this._commonService.applyThemeGlobally();
          
        }
      }
    } catch (error) {
      throw error;
    } finally {
      await this._commonService.dismissLoader();
    }
  }
}

