<div class="container-fluid">
    <div class="row forgotPasswordSection">
        <div class="container">
            <div class="form-container forgot-Password-container">

                <button type="button" name="close-modal" class="closeModal d-lg-none d-md-none d-sm-none d-inline-block"
                    (click)="closeModal()">
                    <span> <i class="bi bi-x-lg"></i> </span>
                </button>

                <form #forgotPasswordForm="ngForm">

                    <h1> Forgot Password </h1>

                    <input [(ngModel)]="viewModel.forgotPasswordDetails.userName" type="text" class="form-control"
                    placeholder="Enter Login Id *"  name="loginId" #loginId="ngModel" required />
                    <ng-container *ngFor="let validation of viewModel.ValidationData.loginId">
                        <div class="text-danger ms-lg-2 ms-md-2 ms-sm-2 ms-2"
                            *ngIf="(loginId.touched||viewModel.FormSubmitted) && loginId.hasError(validation.type)">
                            {{ validation.message }}
                        </div>
                    </ng-container>

                    <button type="button" class="spyGlassBtn" (click)="click_forgotPassword(forgotPasswordForm)"> Submit </button>
                </form>
            </div>

            <div class="overlay-container">
                <div class="overlay">
                    <div class="overlay-panel overlay-right">
                        <button type="button" name="close-modal" class="closeModal" (click)="closeModal()">
                            <span> <i class="bi bi-x-lg"></i> </span>
                        </button>
                        <h1>Hello, Friend!</h1>
                        <p>
                            If you forget your website password, click "Forgot password" on the login page, follow the
                            instructions to reset it, and create a new password to regain access.</p>

                    </div>
                </div>
            </div>

        </div>

    </div>