import { WebsiteResourceSM } from "src/app/service-models/app/v1/general/website-resource-s-m";
import { BaseViewModel } from "../base.viewmodel";

export class WebsiteResourceViewmodel implements BaseViewModel {
  PageTitle: string = "Website Resources";
  chooseFileType: string = '';
  PageNo: number = 1;
  PageSize: number = 10;
  totalCount!:number;
  websiteResourceList: WebsiteResourceSM[] = [];
  singleWebsiteResource: WebsiteResourceSM = new WebsiteResourceSM();
  resourceType = new Array<string>();
  resourceLocation = new Array<string>();
  AddEditModalTitle: string = "";
  FormSubmitted: boolean = false;
  additionalReportsFields: boolean = false;
  WRAdditionalData: WRadditionalData = new WRadditionalData();


  ValidationData = {
    title: [
      { type: "required", message: "Title is required" }],
    chooseFile: [
      { type: 'required', message: 'Choose file here' }],
    name: [
      { type: 'required', message: 'Name is required' }
    ],
    street: [
      { type: 'required', message: 'street  is required' }
    ],
    city: [
      { type: 'required', message: 'City  is required' }
    ],
    state: [
      { type: 'required', message: 'State  is required' }
    ],
    zipcode: [
      { type: 'required', message: 'zip Code  is required' },
      { type: 'pattern', message: 'only Numbers are allowed' }
    ],
    client: [
      { type: 'required', message: 'Client   is required' }
    ],
    agent: [
      { type: 'required', message: 'Agent   is required' }
    ],
    fee: [
      { type: 'required', message: 'fee   is required' },
      { type: 'pattern', message: 'only Numbers are allowed' }
    ],
  };
}

export class WRadditionalData {
  websiteReportsResourceId: number = 0;
  ReportTitle: string = "";
  ClientName: string = "";
  ClientStreet: string = "";
  ClientCity: string = "";
  ClientState: string = "";
  ClientZip: string = "";
  Inspector: string = "";
  Agent: string = "";
  Fee: string = "";
  ReportType: string = "";
  ReportPath: string = "";
}
