import { Injectable } from "@angular/core";
import { AppConstants } from "src/app-constants";
import { AdditionalRequestDetails, Authentication } from "../internal-models/additional-request-details";
import { FeatureSM } from "../service-models/app/v1/license/feature-s-m";
import { UserLicenseDetailsSM } from "../service-models/app/v1/license/user-license-details-s-m";
import { ApiRequest } from "../service-models/foundation/api-contracts/base/api-request";
import { ApiResponse } from "../service-models/foundation/api-contracts/base/api-response";
import { OdataQueryFilter } from "../service-models/foundation/api-contracts/odata-query-filter";
import { DeleteResponseRoot } from "../service-models/foundation/common-response/delete-response-root";
import { IntResponseRoot } from "../service-models/foundation/common-response/int-response-root";
import { StorageService } from "../services/storage.service";
import { BaseApiClient } from "./base-client/base-api.client";
import { CommonResponseCodeHandler } from "./helpers/common-response-code-handler.helper";
import { StorageCache } from "./helpers/storage-cache.helper";



@Injectable({
    providedIn: 'root'
})
export class FeaturesClient extends BaseApiClient {
    constructor(storageService: StorageService, storageCache: StorageCache,
        commonResponseCodeHandler: CommonResponseCodeHandler) {
        super(storageService, storageCache, commonResponseCodeHandler)

    }

    /**Get all Features */
    GetAllFeatures = async (): Promise<ApiResponse<FeatureSM[]>> => {
        let resp = await this.GetResponseAsync<null, FeatureSM[]>
            (`${AppConstants.ApiUrls.FEATURES_API_URL}`, 'GET', null,
                new AdditionalRequestDetails<FeatureSM[]>(false, Authentication.false));
        return resp;
    }
     GetAllFeaturesByOdata=async(queryFilter:OdataQueryFilter):Promise<ApiResponse<FeatureSM[]>>=>{
        let finalUrl=this.ApplyQueryFilterToUrl(`${AppConstants.ApiUrls.FEATURES_API_URL}/odata`,queryFilter);
        let resp= await this.GetResponseAsync<null,FeatureSM[]>(finalUrl,'GET')
        return resp;
    
    }

    GetAllFeaturesCount=async():Promise<ApiResponse<IntResponseRoot>>=>{
        let resp=await this.GetResponseAsync<null,IntResponseRoot>
        (`${AppConstants.ApiUrls.FEATURES_API_URL}/FeatureCountResponse`,'GET')
         return resp;
    }
    /**Get Feature by id */
    GetFeatureById = async (Id: number): Promise<ApiResponse<FeatureSM>> => {
        let resp = await this.GetResponseAsync<number, FeatureSM>
            (`${AppConstants.ApiUrls.FEATURES_API_URL}/${Id}`, 'GET', null,
                new AdditionalRequestDetails<FeatureSM>(false, Authentication.false));
        return resp;
    }

    /**Add a new Feature */
    AddFeature = async (addFeatureRequest: ApiRequest<FeatureSM>): Promise<ApiResponse<FeatureSM>> => {
        let resp = await this.GetResponseAsync<FeatureSM, FeatureSM>
            (AppConstants.ApiUrls.FEATURES_API_URL, 'POST', addFeatureRequest);
        return resp;
    }

    /**Update Feature*/
    UpdateFeature = async (updateFeatureRequest: ApiRequest<FeatureSM>): Promise<ApiResponse<FeatureSM>> => {
        let resp = await this.GetResponseAsync<FeatureSM, FeatureSM>
            (`${AppConstants.ApiUrls.FEATURES_API_URL}/${updateFeatureRequest.reqData.id}`, 'PUT', updateFeatureRequest);
        return resp;
    }
    /**delete FeatureGroup by id */
    DeleteFeatureById = async (Id: number): Promise<ApiResponse<DeleteResponseRoot>> => {
        let resp = await this.GetResponseAsync<number, DeleteResponseRoot>
            (`${AppConstants.ApiUrls.FEATURES_API_URL}/${Id}`, 'DELETE');
        return resp;
    }

    GetActiveLicense = async (): Promise<ApiResponse<UserLicenseDetailsSM>> => {
        let resp = await this.GetResponseAsync<null, UserLicenseDetailsSM>
            (`${AppConstants.ApiUrls.USER_LICENSEINFO_URL}/mine/Active`, 'GET');
        return resp;
    }
}