import { Component, OnInit, ViewChild } from '@angular/core';
import { BaseComponent } from '../../base.component';
import { DemoVideosViewModel } from 'src/app/view-models/demo-videos.viewmodel';
import { LogHandlerService } from 'src/app/services/log-handler.service';
import { CommonService } from 'src/app/services/common.service';
import { DemoVideoService } from 'src/app/services/demo-videos.service';

import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-demo-videos',
  templateUrl: './demo-videos.component.html',
  styleUrls: ['./demo-videos.component.scss']
})
export class DemoVideosComponent extends BaseComponent<DemoVideosViewModel> implements OnInit {

  constructor(
    commonService: CommonService,
    logService: LogHandlerService,

    private demoVideoService: DemoVideoService) {

    super(commonService, logService);
    this.viewModel = new DemoVideosViewModel();
    this._commonService.layoutViewModel.toggleWrapper = 'homeWrapper';
    this._commonService.layoutViewModel.toggleContent = 'content';
    this._commonService.layoutViewModel.showTopNav = true;
    this._commonService.layoutViewModel.showFooter = true;

  }
  async ngOnInit() {
    await this._commonService.presentLoading();
    await setTimeout(async () => {
      await this._commonService.dismissLoader();
      await this.loadPageData();
    }, environment.animationTimeoutinMS);

  }

  override async loadPageData() {
    try {
      
      let resp = await this.demoVideoService.getDemoVideos();
      if (resp.isError) {
        await this._exceptionHandler.logObject(resp.errorData);
        await this._commonService.showSweetAlertToast({ title: resp.errorData.displayMessage, icon: 'error' });
      }
      else {

        this.viewModel.demoVideoReport = resp.successData[0].videoPath;

        this.viewModel.demoVideoReportTitle = resp.successData[0].title;

        this.viewModel.demoVideoPrintSetting = resp.successData[1].videoPath;
        this.viewModel.demoVideoPrintSettingTitle = resp.successData[1].title;

        this.viewModel.demoVideoPrintSettingDashboard = resp.successData[2].videoPath;
        this.viewModel.demoVideoPrintSettingDashboardTitle = resp.successData[2].title;

        this.viewModel.demoVideoSchedular = resp.successData[3].videoPath;
        this.viewModel.demoVideoSchedularTitle = resp.successData[3].title;

      }
    } catch (error) {
      throw error;
    } finally {
      await this._commonService.dismissLoader();
    }
  }


}
