import { Component, OnInit } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { AppConstants } from "src/app-constants";
import { WebsiteResourceSM } from "src/app/service-models/app/v1/general/website-resource-s-m";
import { CommonService } from "src/app/services/common.service";
import { LogHandlerService } from "src/app/services/log-handler.service";
import { WebsiteResourceService } from "src/app/services/website-resource.service";
import { WRadditionalData } from "src/app/view-models/admin/website-resource.viewmodel";
import { ReportsViewModel } from "src/app/view-models/reports.viewmodel";
import { BaseComponent } from "../../base.component";
import { ViewportScroller } from "@angular/common";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-reports",
  templateUrl: "./reports.component.html",
  styleUrls: ["./reports.component.css"],
})

export class ReportsComponent extends BaseComponent<ReportsViewModel> implements OnInit {

  constructor(
    commonService: CommonService,
    logService: LogHandlerService,
    private santizer: DomSanitizer,
    private viewportScroller: ViewportScroller,
    private websiteResourceService: WebsiteResourceService
  ) {
    super(commonService, logService);
    this.viewModel = new ReportsViewModel();
    this._commonService.layoutViewModel.toggleWrapper = 'homeWrapper';
    this._commonService.layoutViewModel.toggleTopNav = 'sampleReportsTopNav';
    this._commonService.layoutViewModel.toggleContent = 'sampleReportContent';
    this._commonService.layoutViewModel.showFooter = true;
    this._commonService.layoutViewModel.showTopNav = true;
  }


  async ngOnInit() {
    const data = "some text";
    const blob = new Blob([data], { type: "application/octet-stream" });
    this.viewModel.fileUrl = this.santizer.bypassSecurityTrustResourceUrl(
      window.URL.createObjectURL(blob)
    );
    await this._commonService.presentLoading();
    await setTimeout(async () => {

      await this._commonService.dismissLoader();
      await this.loadPageData();
      await this.loadSampleReportImages();
    }, environment.animationTimeoutinMS);



  }


  override async loadPageData() {
    try {
      await this._commonService.presentLoading();
      let resp = await this.websiteResourceService.getSampleReportsPartialData();
      if (resp.isError) {
        this._exceptionHandler.logObject(resp.errorData);
        this._commonService.showSweetAlertToast({ title: resp.errorData.displayMessage, icon: "error" });
        this.viewModel.downloadReports = false;
        this.viewModel.downloadDummyReports = true;
        return;
      } else {
        if (resp.successData != null || resp.successData)
          this.viewModel.additionalDetailsList = [];
        this.viewModel.samplereportsDetailslist = resp.successData;
        let filterList: WebsiteResourceSM[] = this.viewModel.samplereportsDetailslist.filter((x) => x.extension == 'pdf');
        filterList.forEach((element) => {
          let addDetail: WRadditionalData = JSON.parse(element.additionalDetails);
          addDetail.websiteReportsResourceId = element.id;
          this.viewModel.additionalDetailsList.push(addDetail)
        });
      }
    } catch (error) {
      this._commonService.showSweetAlertToast({ title: AppConstants.ErrorPrompts.Unknown_Error, icon: "error", });
      this.viewModel.downloadReports = false;
      this.viewModel.downloadDummyReports = true;
      throw error;
    } finally {
      await this._commonService.dismissLoader();
    }

  }


  async loadSampleReportImages() {
    try {
      await this._commonService.presentLoading();
      let resp = await this.websiteResourceService.getWebsiteResourceSampleReportImages();
      if (resp.isError) {
        await this._exceptionHandler.logObject(resp.errorData);
        await this._commonService.showSweetAlertToast({ title: resp.errorData.displayMessage, icon: 'error' });
      } else {
        this.viewModel.sampleReportMainImage = resp.successData[0].resourceFile;
        this.viewModel.sampleReportImage = resp.successData[1].resourceFile;
        this.viewModel.sampleReportImage1 = resp.successData[2].resourceFile;
        this.viewModel.sampleReportImage2 = resp.successData[3].resourceFile;
        this.viewModel.sampleReportImage3 = resp.successData[4].resourceFile;
      }
    } catch (error) {
      throw error;
    } finally {
      await this._commonService.dismissLoader();
    }
  }


  async downloadSampleReportDocument(Id: number) {
    try {
      await this._commonService.presentLoading();
      let resp = await this.websiteResourceService.getSampleReportsDocumentById(Id);
      if (resp.isError) {
        this._exceptionHandler.logObject(resp.errorData);
        this._commonService.showSweetAlertToast({ title: resp.errorData.displayMessage, icon: "error" });
      } else {
        console.log(resp.successData);
        await this._commonService.downloadDocument(resp.successData, ".pdf", "report");
      }
    } catch (error) {
      throw error;
    } finally {
      await this._commonService.dismissLoader();
    }
  }
  public navigateToViewReport(elementId: string): void {
    this.viewportScroller.scrollToAnchor(elementId);
  }

}
