import { Component, OnInit, TemplateRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppConstants } from 'src/app-constants';
import { FeatureSM } from 'src/app/service-models/app/v1/license/feature-s-m';
import { ApiResponse } from 'src/app/service-models/foundation/api-contracts/base/api-response';
import { OdataQueryFilter } from 'src/app/service-models/foundation/api-contracts/odata-query-filter';
import { CommonService } from 'src/app/services/common.service';
import { FeatureService } from 'src/app/services/features.service';
import { LogHandlerService } from 'src/app/services/log-handler.service';
import { FeaturesVeiwModel } from 'src/app/view-models/admin/features.veiwmodel';
import { BaseComponent } from '../../base.component';

@Component({
  selector: 'app-features',
  templateUrl: './features.component.html',
  styleUrls: ['./features.component.scss']
})
export class FeaturesComponent extends BaseComponent<FeaturesVeiwModel> implements OnInit {
  constructor(private modalService: NgbModal, private featureService: FeatureService,
    commonService: CommonService, logService: LogHandlerService) {
    super(commonService, logService);
    this.viewModel = new FeaturesVeiwModel();
  }
  async ngOnInit(): Promise<void> {
    await this.loadPageData();

  }

  override async loadPageData() {
    try {
      await this._commonService.presentLoading();
     
      //have to do paging
      let queryFilter=new OdataQueryFilter();
      queryFilter.skip=(this.viewModel.PageNo -1)* this.viewModel.PageSize;
      queryFilter.top=this.viewModel.PageSize;
      let resp = await this.featureService.getAllFeaturesbyOdata(queryFilter);
      if (resp.isError) {
        this._exceptionHandler.logObject(resp.errorData);
        this._commonService.showSweetAlertToast({ title: resp.errorData.displayMessage, icon: 'error' });
        return;

      }
      else {
        await this.getFeaturesCount();
        this.viewModel.features = resp.successData;
      }
    } catch (error) {
      this._commonService.showSweetAlertToast({ title: AppConstants.ErrorPrompts.Unknown_Error, icon: 'error' });
      throw error;
    }
    finally {
      await this._commonService.dismissLoader()
    }
  }

  async getFeaturesCount(){
    try{
      await this._commonService.presentLoading();
      let resp=await this.featureService.getFeaturesCount();
      if (resp.isError) {
        this._exceptionHandler.logObject(resp.errorData);
        this._commonService.showSweetAlertToast({ title: resp.errorData.displayMessage, icon: 'error' });
        return;
      }
      else {
        this.viewModel.totalCount = resp.successData.intResponse;
      }
    } catch (error) {
      this._commonService.showSweetAlertToast({ title: AppConstants.ErrorPrompts.Unknown_Error, icon: 'error' });
      throw error;
    }
    finally {
      await this._commonService.dismissLoader()
    }
  }


  async getFeatureById(featureId: number) {
    try {
      await this._commonService.presentLoading();
      //have to do paging

      let resp = await this.featureService.getFeatureById(featureId);
      if (resp.isError) {
        await this._exceptionHandler.logObject(resp.errorData);
        this._commonService.showSweetAlertToast({ title: 'Error!', icon: 'error' });
      }
      else {
        this.viewModel.singleFeature = resp.successData;

      }
    } catch (error) {
      throw error;
    }
    finally {
      await this._commonService.dismissLoader()
    }
  }

  async form_addEditFeatures(featureForm: NgForm) {
    this.viewModel.FormSubmitted = true;
    try {
      if (featureForm.invalid)
        return;
      await this._commonService.presentLoading();
      let resp: ApiResponse<FeatureSM>;
      if (this.viewModel.singleFeature.id && this.viewModel.singleFeature.id > 0) {

        resp = await this.featureService.updateFeature(this.viewModel.singleFeature);
      }
      else {
        resp = await this.featureService.SendFeature(this.viewModel.singleFeature);

      }
      if (resp.isError) {
        await this._exceptionHandler.logObject(resp.errorData);
        this._commonService.showSweetAlertToast({ title: resp.errorData.displayMessage, icon: 'error' });

      }
      else {
        this.modalService.dismissAll();
        this._commonService.showSweetAlertToast({ title: 'Success', icon: 'success' });

        this.loadPageData();
      }
    } catch (error) {
      throw error;
    } finally {
      await this._commonService.dismissLoader()
    }
  }

  async deletefeatureById(featureId: number) {

    let deleteConfirmation = await this._commonService.showSweetAlertConfirmation({
      title: `${AppConstants.DefaultMessages.DeleteAlertConfirmation} Feature?`,
      text: " ",
      showCancelButton: true,
      icon: "warning"
    }
    );
    if (deleteConfirmation) {
      try {
        let resp = await this.featureService.deleteFeatureById(featureId);
        if (resp.isError) {
          await this._exceptionHandler.logObject(resp.errorData);
          this._commonService.showSweetAlertToast({ title: resp.errorData.displayMessage, icon: 'error' });

        } else {
          this._commonService.showSweetAlertToast({ title: 'Success', icon: 'success' });

          this.loadPageData();
          return;
        }
      } catch (error) {
        this._commonService.showSweetAlertToast({ title: 'Error', icon: 'error' });
        throw error;
      } finally {
        await this._commonService.dismissLoader();
      }
    }
  }

  async click_OpenAddEditModal(featuresModal: TemplateRef<any>, featureId: number) {
    this.viewModel.AddEditModalTitle = featureId > 0 ? 'Update Feature ' : 'Add Feature ';
    this.viewModel.singleFeature = new FeatureSM();
    this.viewModel.FormSubmitted = false;
    if (featureId > 0)
      await this.getFeatureById(featureId);
    await this.modalService.open(featuresModal).result.then(
      (result: any) => {
      },
      (reason: any) => {
      },
    );
  }
  async loadPagedataWithPagination(event: any) {
    this.viewModel.PageNo = event;
    await this.loadPageData();
  }


}
