<div class="container-fluid p-lg-3 p-md-3 p-sm-3 p-3 superTopNav"
    *ngIf="_commonService.layoutViewModel.showSuperAdminLeftMenu && _commonService.layoutViewModel.showSuperAdminTopBar">

    <div class="row ">
        <div class="col-lg-3 col-md-6 col-sm-6 col-8 px-md-1 m-auto superTopNavHead">
            <div class="d-lg-inline-block ps-lg-2">
                <button class="superSideBar d-lg-inline-block d-md-none d-sm-none d-none"
                    (click)="click_ToggleLeftMenu()">
                    <i class="bi bi-justify"></i>
                </button>
            </div>

            <div class=" d-lg-inline-block ps-lg-2">
                <span> Super Admin Dashboard </span>
            </div>

        </div>
        <div class="col-lg-8 col-md-2 col-sm-2 col-4 text-end m-auto ">

            <span class="logOutLink" (click)="click_Logout(true)">
                <i class="bi bi-box-arrow-left"></i>
                Log Out
            </span>

        </div>
    </div>



    <!-- <div class="row">
        <div class="col-lg-1 col-md-1 col-sm-1 col-2 ">
            <button class="btn toggleMenuBtn" (click)="click_ToggleLeftMenu()" title="Left Menu">
                <i class="bi bi-justify"></i>
            </button>
        </div>

        <div class="col-lg-8 col-md-8 col-sm-8 col-6 m-auto">
            <span class="topNavTitle"> Super Admin Dashboard </span>
        </div>

        <div class="col-lg-2 col-md-2 col-sm-2 col-4 text-end m-auto">

            <span class="logOutLink" (click)="click_Logout(true)">
                <i class="bi bi-box-arrow-left"></i>
                Log Out
            </span>

        </div>

    </div> -->

</div>


<div class="container-fluid" [ngClass]="[_commonService.layoutViewModel.toggleTopNav]"
    *ngIf="!_commonService.layoutViewModel.showSuperAdminTopBar"
     #stickyMenu [class.sticky]="_commonService.layoutViewModel.stickyMenu">

    <div class="row">
        <div class="col-lg-3 col-md-6 col-sm-6 col-8 px-md-4 m-auto topNavHead">
            <div class="d-lg-inline-block ps-lg-2">
                <button class="sideMenuTogglerBtn d-lg-inline-block d-md-none d-sm-none d-none" (click)="toggleMenu()"
                    *ngIf="_commonService.layoutViewModel.showMenuBtn">
                    <i class="bi bi-justify"></i>
                </button>
            </div>

            <div class="logo d-lg-inline-block ps-lg-2" routerLink="/home">
                <img src="/assets/images/Top-Nav&Footer-Images/surveyLogoTransparent.webp" alt="spyGlassImage"
                    class="img-fluid ms-lg-2 ms-md-2 ms-sm-2 ms-3" *ngIf="_commonService.layoutViewModel.showLogo">
                <span class="ms-lg-2 ms-md-2 ms-sm-2 ms-2"> SpyGlass </span>
            </div>

        </div>

        <div class="col-lg-9 col-md-6 col-sm-6 col-4 text-lg-end text-md-end text-sm-end text-end">

            <nav class="navbar navbar-expand-lg d-flex justify-content-end">

                <button class="navbar-toggler me-3" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
                    aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <i class="bi bi-justify"></i>
                </button>

                <div class="collapse navbar-collapse justify-content-lg-start" id="navbarNav">

                    <ul class="navbar-nav p-lg-0 p-md-2 p-sm-2 p-2">

                        <li class="nav-item mx-lg-2 p-lg-0 p-md-2 p-sm-2 p-2" routerLink="/home">
                            <a class="nav-link" routerLinkActive="active" data-bs-toggle="collapse"
                                data-bs-target=".navbar-collapse.show"> Home </a>
                        </li>

                        <li class="nav-item mx-lg-2 p-lg-0 p-md-2 p-sm-2 p-2" routerLink="/pricing">
                            <a class="nav-link" routerLinkActive="active" data-bs-toggle="collapse"
                                data-bs-target=".navbar-collapse.show"> Pricing </a>
                        </li>

                        <li class="nav-item mx-lg-2 p-lg-0 p-md-2 p-sm-2 p-2" routerLink="/product-info">
                            <a class="nav-link" routerLinkActive="active" data-bs-toggle="collapse"
                                data-bs-target=".navbar-collapse.show"> Product </a>
                        </li>

                        <li class="nav-item mx-lg-2 p-lg-0 p-md-2 p-sm-2 p-2" routerLink="/reports">
                            <a class="nav-link" routerLinkActive="active" data-bs-toggle="collapse"
                                data-bs-target=".navbar-collapse.show"> Sample Reports </a>
                        </li>

                        <li class="nav-item mx-lg-2 p-lg-0 p-md-2 p-sm-2 p-2" >
                            <a class="nav-link"  (click)="navigateToSection()" routerLinkActive="active"
                                data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
                                Contact Us </a>
                        </li>
                      
                        <li class="nav-item d-lg-inline-block d-md-none d-sm-none d-none">
                            <a class="nav-link" id="navbarDropdown" role="button" data-bs-toggle="dropdown"
                                aria-expanded="false">
                                Support
                            </a>

                            <ul class="sub-menu">

                                <li routerLink="/how-to-install">
                                    <a> <i class="bi bi-cloud-download"></i> How to Install </a>
                                </li>

                                <li routerLink="/demo-videos">
                                    <a> <i class="bi bi-play"></i> Demo Videos </a>
                                </li>

                            </ul>

                        </li>

                        <li
                            class="nav-item mx-lg-2 p-lg-0 p-md-2 p-sm-2 p-2 d-lg-none d-md-inline-block d-sm-inline-block d-inline-block">
                            <a class="nav-link" id="navbarDropdown" role="button" data-bs-toggle="dropdown"
                                aria-expanded="false">
                                Support
                            </a>
                            <div class="dropdown">
                                <ul class="dropdown-menu py-0" aria-labelledby="navbarDropdown">
                                    <li class="p-lg-1 p-md-1 p-sm-1 p-1 d-block" routerLink="/how-to-install">
                                        <i class="bi bi-cloud-download"></i>
                                        <span> How to install </span>
                                    </li>
                                    <li class="p-lg-1 p-md-1 p-sm-1 p-1 d-block" routerLink="/demo-videos">
                                        <i class="bi bi-play"></i>
                                        <span> Demo Videos </span>
                                    </li>
                                </ul>
                            </div>
                        </li>

                        <div class="login d-lg-none d-md-inline-block d-sm-inline-block d-inline-block"
                            *ngIf="_commonService.layoutViewModel.showLogin">

                            <button type="button" name="login-button" class="spyGlassBtn" data-bs-dismiss="modal"
                                (click)="click_showLoginModal()">
                                Login
                            </button>

                        </div>

                        <div class="profile d-lg-none d-md-inline-block d-sm-inline-block d-inline-block px-lg-2"
                            *ngIf="_commonService.layoutViewModel.showProfile">

                            <div class="dropdown">

                                <div *ngIf="_commonService.layoutViewModel.showInspectorLoginMenu ||_commonService.layoutViewModel.showOrganisationLoginMenu"
                                    data-bs-toggle="dropdown" aria-expanded="false">

                                    <div class="profileImage"
                                        *ngIf="_commonService.layoutViewModel.companyAdminProfilePicture!=null">
                                        <img *ngIf="_commonService.layoutViewModel.roleType==3"
                                            src="{{'data:image/jpeg;base64,' + _commonService.layoutViewModel.companyAdminProfilePicture }}"
                                            class="rounded-circle" height="35" width="40" alt="profile" loading="lazy"
                                            id="dropdownMenuLink" />
                                    </div>

                                    <div class="profileImage"
                                        *ngIf="_commonService.layoutViewModel.inspectorProfilePicture!=null">
                                        <img *ngIf="_commonService.layoutViewModel.roleType==5 || _commonService.layoutViewModel.roleType==4"
                                            src="{{'data:image/jpeg;base64,' + _commonService.layoutViewModel.inspectorProfilePicture }}"
                                            class="rounded-circle" height="35" width="40" alt="profile" loading="lazy"
                                            id="dropdownMenuLink" />
                                    </div>

                                    <div 
                                        *ngIf="_commonService.layoutViewModel.inspectorProfilePicture ==null|| _commonService.layoutViewModel.companyAdminProfilePicture ==null">
                                        <img src="/assets/images/Top-Nav&Footer-Images/avatar.webp"
                                            class="rounded-circle" height="35" width="40" alt="profile" loading="lazy"
                                            id="dropdownMenuLink" />
                                    </div>

                                </div>

                                <ul class="dropdown-menu dropdown-menu-end py-0" aria-labelledby="dropdownMenuLink">
                                    <li class="p-lg-1 p-md-1 p-sm-1 p-1 d-block" routerLink="/profile">
                                        <span
                                            *ngIf="_commonService.layoutViewModel.showInspectorLoginMenu || _commonService.layoutViewModel.showOrganisationLoginMenu">
                                            <i class="bi bi-person"></i> Profile Info
                                        </span>
                                    </li>
                                    <li class="p-lg-1 p-md-1 p-sm-1 p-1 d-block" (click)="click_Logout(false)">
                                        <span
                                            *ngIf="_commonService.layoutViewModel.showInspectorLoginMenu || _commonService.layoutViewModel.showOrganisationLoginMenu">
                                            <i class="bi bi-box-arrow-left"></i> Logout
                                        </span>
                                    </li>
                                </ul>

                            </div>

                        </div>

                    </ul>

                </div>

                <div class="login d-lg-inline-block d-md-none d-sm-none d-none px-lg-5"
                    *ngIf="_commonService.layoutViewModel.showLogin">

                    <button type="button" name="login-button" (keydown.enter)="$event.preventDefault()" data-bs-dismiss="modal" class="spyGlassBtn" tabindex="-1" (click)="click_showLoginModal()" #myEventRef (keyup.enter)="loseFocus()">
                        Login
                    </button>

                </div>

                <div class="profile d-lg-inline-block d-md-none d-sm-none d-none px-lg-2"
                    *ngIf="_commonService.layoutViewModel.showProfile">

                    <div class="dropdown">

                        <ul class="navbar-nav">

                            <li class="nav-item">

                                <div *ngIf="_commonService.layoutViewModel.showInspectorLoginMenu ||_commonService.layoutViewModel.showOrganisationLoginMenu"
                                    data-bs-toggle="dropdown" aria-expanded="false">

                                    <div class="profileImage"
                                        *ngIf="_commonService.layoutViewModel.companyAdminProfilePicture!=null">
                                        <img *ngIf="_commonService.layoutViewModel.roleType==3"
                                            src="{{'data:image/jpeg;base64,' + _commonService.layoutViewModel.companyAdminProfilePicture }}"
                                            class="rounded-circle" height="35" width="40" alt="profile" loading="lazy"
                                            id="dropdownMenuLink" />
                                        
                                           
                                    </div>
                                 

                                    <div class="profileImage"
                                        *ngIf="_commonService.layoutViewModel.inspectorProfilePicture!=null">
                                        <img *ngIf="_commonService.layoutViewModel.roleType==5 || _commonService.layoutViewModel.roleType==4"
                                            src="{{'data:image/jpeg;base64,' + _commonService.layoutViewModel.inspectorProfilePicture }}"
                                            class="rounded-circle" height="35" width="40" alt="profile" loading="lazy"
                                            id="dropdownMenuLink" />
                                    </div>

                                    <div 
                                        *ngIf="_commonService.layoutViewModel.inspectorProfilePicture == null || _commonService.layoutViewModel.companyAdminProfilePicture == null">
                                        <img src="/assets/images/Top-Nav&Footer-Images/avatar.webp" 
                                            class="rounded-circle" height="35" width="40" alt="profile" loading="lazys"
                                            id="dropdownMenuLink" />
                                    </div>

                                </div>
                                
                               

                                <ul class="sub-menu profileSubMenu">
                                    <li routerLink="/profile">
                                        <a
                                            *ngIf="_commonService.layoutViewModel.showInspectorLoginMenu || _commonService.layoutViewModel.showOrganisationLoginMenu">
                                            <i class="bi bi-person"></i> Profile Info </a>
                                    </li>
                                    <li (click)="click_Logout(false)">
                                        <a
                                            *ngIf="_commonService.layoutViewModel.showInspectorLoginMenu || _commonService.layoutViewModel.showOrganisationLoginMenu">
                                            <i class="bi bi-box-arrow-left"></i> Logout </a>
                                    </li>
                                </ul>

                            </li>

                        </ul>

                        <!-- <ul class="dropdown-menu dropdown-menu-end py-0" aria-labelledby="dropdownMenuLink">
                            <li class="p-lg-1 p-md-1 p-sm-1 p-1 d-block" routerLink="/profile">
                                <span
                                    *ngIf="_commonService.layoutViewModel.showInspectorLoginMenu || _commonService.layoutViewModel.showOrganisationLoginMenu">
                                    Profile Info
                                </span>
                            </li>
                            <li class="p-lg-1 p-md-1 p-sm-1 p-1 d-block" (click)="click_Logout(false)">
                                <span
                                    *ngIf="_commonService.layoutViewModel.showInspectorLoginMenu || _commonService.layoutViewModel.showOrganisationLoginMenu">
                                    Logout
                                </span>
                            </li>   
                        </ul> -->

                    </div>

                </div>

            </nav>

        </div>

    </div>

</div>