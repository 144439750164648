import { Component, OnInit, TemplateRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppConstants } from 'src/app-constants';
import { FeatureGroupSM } from 'src/app/service-models/app/v1/license/feature-group-s-m';
import { ApiResponse } from 'src/app/service-models/foundation/api-contracts/base/api-response';
import { OdataQueryFilter } from 'src/app/service-models/foundation/api-contracts/odata-query-filter';
import { CommonService } from 'src/app/services/common.service';
import { FeatureGroupService } from 'src/app/services/feature-group.service';
import { FeatureService } from 'src/app/services/features.service';
import { LogHandlerService } from 'src/app/services/log-handler.service';
import { FeatureGroupViewModel } from 'src/app/view-models/admin/feature-group.viewmodel';
import { BaseComponent } from '../../base.component';

@Component({
  selector: 'app-feature-group',
  templateUrl: './feature-group.component.html',
  styleUrls: ['./feature-group.component.scss']
})
export class FeatureGroupComponent extends BaseComponent<FeatureGroupViewModel> implements OnInit {
  constructor(private modalService: NgbModal, private featureGroupService: FeatureGroupService,
    private activatedRoute: ActivatedRoute,
    private featureService: FeatureService, commonService: CommonService, logService: LogHandlerService) {
    super(commonService, logService);
    this.viewModel = new FeatureGroupViewModel();
  }

  async ngOnInit(): Promise<void> {
    await this.loadPageData();
    await this.getAllFeatures();
  }


  override async loadPageData() {
    try {
      await this._commonService.presentLoading();
   
      //have to do paging
      let queryFilter = new OdataQueryFilter();
      queryFilter.skip = (this.viewModel.PageNo - 1) * this.viewModel.PageSize;
      queryFilter.top = this.viewModel.PageSize;
      let resp = await this.featureGroupService.getAllFeatureGroupbyOdata(queryFilter);
      if (resp.isError) {
        this._exceptionHandler.logObject(resp.errorData);
        this._commonService.showSweetAlertToast({ title: resp.errorData.displayMessage, icon: 'error' });
        return;

      }
      else {
        await this.getFeatureGroupCount();
        this.viewModel.featureGroupList = resp.successData;
      }
    } catch (error) {
      this._commonService.showSweetAlertToast({ title: AppConstants.ErrorPrompts.Unknown_Error, icon: 'error' });
      throw error;
    }
    finally {
      await this._commonService.dismissLoader()
    }
  }

  async getFeatureGroupCount() {
    try {
      await this._commonService.presentLoading();
      let resp = await this.featureGroupService.getFeatureGroupCount();
      if (resp.isError) {
        this._exceptionHandler.logObject(resp.errorData);
        this._commonService.showSweetAlertToast({ title: resp.errorData.displayMessage, icon: 'error' });
        return;
      }
      else {
        this.viewModel.totalCount = resp.successData.intResponse;
      }
    } catch (error) {
      this._commonService.showSweetAlertToast({ title: AppConstants.ErrorPrompts.Unknown_Error, icon: 'error' });
      throw error;
    }
    finally {
      await this._commonService.dismissLoader()
    }

  }



  async getFeatureGroupById(featureGroupId: number) {
    try {
      await this._commonService.presentLoading();
      //have to do paging

      let resp = await this.featureGroupService.getFeatureGroupById(featureGroupId);
      if (resp.isError) {
        await this._exceptionHandler.logObject(resp.errorData);
        this._commonService.showSweetAlertToast({ title: 'Error!', icon: 'error' });
      }
      else {
        this.viewModel.featureGroup = resp.successData;
        this.viewModel.featureGroup.featuresIds.forEach(element => {
          let featureItem = this.viewModel.allFeatures.filter(x => x.key == element);
          if (featureItem.length > 0)
            featureItem[0].isSelected = true;
        });
      }
    } catch (error) {
      throw error;
    }
    finally {
      await this._commonService.dismissLoader()
    }
  }

  async form_addEditFeatureGroup(featureGroupForm: NgForm) {
    this.viewModel.FormSubmitted = true;
    try {
      if (featureGroupForm.invalid)
        return;
      await this._commonService.presentLoading();
      let resp: ApiResponse<FeatureGroupSM>;
      if (this.viewModel.featureGroup.id && this.viewModel.featureGroup.id > 0) {
        let selectedItems = this.viewModel.allFeatures.filter(x => x.isSelected == true);
        let selectedIds = selectedItems.map(function (item) {
          return item.key;
        });
        this.viewModel.featureGroup.featuresIds = selectedIds;
        resp = await this.featureGroupService.updateFeatureGroup(this.viewModel.featureGroup);
        resp.successData.featuresIds = this.viewModel.featureGroup.featuresIds;

      }
      else {
        let selectedItems = this.viewModel.allFeatures.filter(x => x.isSelected == true);
        let selectedIds = selectedItems.map(function (item) {
          return item.key;
        });
        this.viewModel.featureGroup.featuresIds = selectedIds;
        resp = await this.featureGroupService.SendFeatureGroup(this.viewModel.featureGroup);

      }
      if (resp.isError) {
        await this._exceptionHandler.logObject(resp.errorData);
        this._commonService.showSweetAlertToast({ title: resp.errorData.displayMessage, icon: 'error' });

      }
      else {
        this.modalService.dismissAll();
        this._commonService.showSweetAlertToast({ title: 'Success', icon: 'success' });

        this.loadPageData();
      }
    } catch (error) {
      throw error;
    } finally {
      await this._commonService.dismissLoader()
    }
  }

  async deletefeatureGroup(featureGroupId: number) {

    let deleteConfirmation = await this._commonService.showSweetAlertConfirmation({
      title: `${AppConstants.DefaultMessages.DeleteAlertConfirmation} FeatureGroup?`,
      text: " ",
      showCancelButton: true,
      icon: "warning"
    }
    );
    if (deleteConfirmation) {
      try {
        let resp = await this.featureGroupService.deleteFeatureGroupById(featureGroupId);
        if (resp.isError) {
          await this._exceptionHandler.logObject(resp.errorData);
          this._commonService.showSweetAlertToast({ title: resp.errorData.displayMessage, icon: 'error' });

        } else {
          this._commonService.showSweetAlertToast({ title: 'Success', icon: 'success' });

          this.loadPageData();
          return;
        }
      } catch (error) {
        this._commonService.showSweetAlertToast({ title: 'Error', icon: 'error' });
        throw error;
      } finally {
        await this._commonService.dismissLoader();
      }
    }
  }

  async getAllFeatures() {
    try {
      await this._commonService.presentLoading();
      //have to do paging
      let resp = await this.featureService.getAllFeatures();
      if (resp.isError) {
        this._exceptionHandler.logObject(resp.errorData);
        this._commonService.showSweetAlertToast({ title: resp.errorData.displayMessage, icon: 'error' });
        return;
      }
      else {
        resp.successData.forEach(element => {
          this.viewModel.allFeatures.push({ key: element.id, value: element.title, isSelected: false });
        });
      }
    } catch (error) {
      this._commonService.showSweetAlertToast({ title: AppConstants.ErrorPrompts.Unknown_Error, icon: 'error' });
      throw error;
    }
    finally {
      await this._commonService.dismissLoader()
    }
  }


  async click_OpenAddEditModal(featuresModal: TemplateRef<any>, featureGroupId: number) {
    this.viewModel.AddEditModalTitle = featureGroupId > 0 ? 'Update Feature Group' : 'Add Feature Group';
    this.viewModel.featureGroup = new FeatureGroupSM();
    this.viewModel.allFeatures.map(x => x.isSelected = false);
    // await this.getAllFeatures();
    this.viewModel.FormSubmitted = false;
    if (featureGroupId > 0)
      await this.getFeatureGroupById(featureGroupId);
    await this.modalService.open(featuresModal).result.then(
      (result: any) => {
      },
      (reason: any) => {
      },
    );
  }


  async loadPagedataWithPagination(event: any) {
    this.viewModel.PageNo = event;
    await this.loadPageData();
  }
}


