import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { LogHandlerService } from 'src/app/services/log-handler.service';
import { ThemeService } from 'src/app/services/theme.service';
// import { ThemeService } from 'src/app/services/theme.service';
import { AccountSettingsViewModel } from 'src/app/view-models/account-setting.viewmodel';
import { BaseComponent } from '../../base.component';

@Component({
  selector: 'app-account-setting',
  templateUrl: './account-setting.component.html',
  styleUrls: ['./account-setting.component.css']
})
export class AccountSettingComponent extends BaseComponent<AccountSettingsViewModel> implements OnInit  {

  constructor(commonService: CommonService,
    logService: LogHandlerService,
    protected themeService:ThemeService) {
      super(commonService, logService);
      this.viewModel=new AccountSettingsViewModel;
      // this._commonService.layoutViewModel.showSuperAdminLeftMenu=false
  }

  ngOnInit(): void {
  }
  

//show/hide  edit profile details Section
  editProfileDetails() {  
    
    if(this.viewModel.isShowEditProfile=!this.viewModel.isShowEditProfile){
      this.viewModel.isShowPersonalInfoTable=false;
      this.viewModel.isShowOrderDetailsInfoTable=false; 
      this.viewModel.isShowLicenseInfoTable=false; 
    }
  }
  //show/hide personal information Section
  personalInfoTable(){
    if(this.viewModel.isShowPersonalInfoTable = !this.viewModel.isShowPersonalInfoTable){
      this.viewModel.isShowEditProfile=false; 
      this.viewModel.isShowOrderDetailsInfoTable=false; 
      this.viewModel.isShowLicenseInfoTable=false; 
    }
  
  }
  // show/hide order detalis Section
  orderDeatailsTable(){
    if(this.viewModel.isShowOrderDetailsInfoTable = !this.viewModel.isShowOrderDetailsInfoTable){
      this.viewModel.isShowEditProfile=false; 
      this.viewModel.isShowPersonalInfoTable=false; 
      this.viewModel.isShowLicenseInfoTable=false; 
    } 
   
  }
  // show/hide license order details
  licenseInfoTable(){
    if(this.viewModel.isShowLicenseInfoTable = !this.viewModel.isShowLicenseInfoTable){
      this.viewModel.isShowEditProfile=false; 
      this.viewModel.isShowOrderDetailsInfoTable=false; 
      this.viewModel.isShowPersonalInfoTable=false; 
    } 
  }
}
