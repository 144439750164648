import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
// import { th } from 'date-fns/locale';
import { AppConstants } from 'src/app-constants';
import { BaseComponent } from 'src/app/components/base.component';
import { UserLicenseDetailsSM } from 'src/app/service-models/app/v1/license/user-license-details-s-m';
import { CommonService } from 'src/app/services/common.service';
import { LicenseInfoService } from 'src/app/services/license-info.service';
import { LogHandlerService } from 'src/app/services/log-handler.service';
import { SubscriptionViewModel } from 'src/app/view-models/subscription.viewmodel';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-assign-license',
  templateUrl: './assign-license.component.html',
  styleUrls: ['./assign-license.component.scss']
})
export class AssignLicenseComponent extends BaseComponent<SubscriptionViewModel> implements OnInit {

  constructor(
    commonService: CommonService,
    logService: LogHandlerService,
    public activeModal: NgbActiveModal,
    private licenseInfoService: LicenseInfoService,
  ) {
    super(commonService, logService);
    this.viewModel = new SubscriptionViewModel();
    // this._commonService.layoutViewModel.showProfile = true;
  }
  async ngOnInit() {
    await this._commonService.presentLoading();
    setTimeout(async () => {
      await this._commonService.dismissLoader();
      await this.getActiveInspectorLicense();
      await this.getActiveLicense();
      await this.getAllCompanyInspectorsCount();
    }, environment.animationTimeoutinMS);
  }
 
  


  async AssignLicenseToInspector(id: number, event: any) {
    try {
      await this._commonService.presentLoading();
      this.viewModel.ActiveDeactiveLicense.companyInspectorId = id;
      this.viewModel.ActiveDeactiveLicense.status = event.target.checked;
      let resp = await this.licenseInfoService.AddLicenseToInspectors(this.viewModel.ActiveDeactiveLicense);
      if (resp.isError) {
        this._exceptionHandler.logObject(resp.errorData);
        this._commonService.showSweetAlertToast({
          title: resp.errorData.displayMessage,
          icon: "error",
        });
        return;

      }
      else {
        this.viewModel.LicenseDetails = resp.successData;
      }


    } catch (error) {
      this._commonService.showSweetAlertToast({
        title: AppConstants.ErrorPrompts.Unknown_Error,
        icon: "error",
      });
      throw error;
    } finally {
      await this._commonService.dismissLoader();
    }
    if (event.target.checked == true) {
      this._commonService.showSweetAlertToast({ title: 'Success', icon: 'success' });
    }
    else {
      this._commonService.showSweetAlertToast({ title: 'License has been deactivated', icon: 'success' });
    }
  }


  async getActiveInspectorLicense() {
    try {
      await this._commonService.presentLoading();

      let resp = await this.licenseInfoService.GetActiveInspectorLicense();
      if (resp.isError) {
        this._exceptionHandler.logObject(resp.errorData);
        this._commonService.showSweetAlertToast({
          title: resp.errorData.displayMessage,
          icon: "error",
        });
        return;
      } else {
        this.viewModel.MyLicenses = resp.successData;

      }

    } catch (error) {
      this._commonService.showSweetAlertToast({
        title: AppConstants.ErrorPrompts.Unknown_Error,
        icon: "error",
      });
      throw error;
    } finally {
      await this._commonService.dismissLoader();

    }
  }

  async getAllCompanyInspectorsCount(){
    try{
      await this._commonService.presentLoading();
      let resp=await this.licenseInfoService.getAllCompanyInspectorsCount();
      if(resp.successData ==null)
      return;
      if(resp.isError){
        this._exceptionHandler.logObject(resp.successData);
        this._commonService.showSweetAlertToast({title:resp.errorData.displayMessage,
        icon:'error'});
        return;
      }
      
      else{
        this.viewModel.LicenseCount=resp.successData.intResponse;
      }
    }
    catch(error){
      this._commonService.showSweetAlertToast({
        title:AppConstants.ErrorPrompts.Unknown_Error,
        icon:'error',
      });
      throw error;

    }
    finally{
      await this._commonService.dismissLoader();
    }
  }
  async getActiveLicense(){
    try {
      await this._commonService.presentLoading();
      let resp = await this.licenseInfoService.GetSubscription();
      if (resp.isError) {
        await this._exceptionHandler.logObject(resp.errorData);
        this._exceptionHandler.logObject(resp.errorData);
        this._commonService.showSweetAlertToast({
          title: resp.errorData.displayMessage,
          icon: "error",
        });
        return;
      } else {
        if (resp.successData == null)
          this.viewModel.LicenseDetails = new UserLicenseDetailsSM();
        else this.viewModel.LicenseDetails = resp.successData;


        if (resp.successData == null) {
          // show add subscription page
          this.viewModel.noActiveLicense = true;
          this.viewModel.emptydiv = true;
          this.viewModel.activeLicense = false;
        }
        else {
          // show  active License subscription page
          this.viewModel.activeLicense = true;
          this.viewModel.emptydiv = false;
          this.viewModel.noActiveLicense = false;
        }
      }
    } catch (error) {
      this._commonService.showSweetAlertToast({
        title: AppConstants.ErrorPrompts.Unknown_Error,
        icon: "error",
      });
      throw error;
    } finally {
      await this._commonService.dismissLoader();
    }
  }

}

