<div class="container">
    <div class="table-title">
        <div class="row">
            <div class="col-sm-7">
                <!-- <h5>Manage Inspectors</h5> -->
                <label class="text-start px-2"> Total Number of Inspectors: {{viewModel.totalCount}}</label>
            </div>
            <div class=" col-lg-5 col-md-12 col-12 col-sm-12 add-new">
                <button type="button" (click)="click_OpenAddEditModal(addEditModal,0)"><i class="bi bi-plus"></i> Add
                    Inspector</button>
            </div>
        </div>
    </div>
    <table class="table mt-2">
        <thead>
            <tr>
                <th class="text-center firstTableHead">First Name</th>
                <th class="text-center">Last Name</th>
                <th class="text-center">Phone Number</th>
                <th class="text-center">Login id</th>
                <th class="text-center lastTableHead" colspan="2">Actions</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let inspector of viewModel.inspectors ">
                <td class="text-center">{{inspector.firstName}}</td>
                <td class="text-center">{{inspector.lastName}}</td>
                <td class="text-center">{{inspector.phoneNumber}}</td>
                <td class="text-center">{{inspector.loginId}}</td>

                <td class="text-center">
                    <i class="bi bi-pencil-fill" (click)="click_OpenAddEditModal(addEditModal,inspector.id)">
                    </i>
                    &nbsp;&nbsp;
                    <i class="bi bi-trash-fill" (click)="deleteInspector(inspector.id)"></i>
                </td>
            </tr>
        </tbody>
    </table>


    <div id="pagination" class="col-lg-12 col-md-12 col-sm-12 col-12  d-flex justify-content-center fixed-bottom">
        <ngb-pagination [collectionSize]="viewModel.totalCount" [(page)]="viewModel.PageNo" 
            [pageSize]="viewModel.PageSize" (pageChange)="loadPagedataWithPagination($event)"></ngb-pagination>
    </div>


    <ng-template #addEditModal let-modal >
        <div #addEditModal class="col-sm-12 col-12 pb-5 InspectorForm ">
            <button type="button" name="close-modal" class="closeModal" (click)="modal.dismiss('Cross click')">
                <span> <i class="bi bi-x-lg"></i> </span>
            </button>
            <h3 class="text-center pt-3"> Add Inspector </h3>
            <form #form_Inspector="ngForm">

                <div class="row px-3">

                    <div class="col-lg-6 col-md-6">
                        <input type="text" class="form-control" placeholder="First Name"
                            [(ngModel)]="viewModel.singleInspector.firstName" #firstName="ngModel" required
                            name="firstName" minlength="3" maxlength="20" />
                        <ng-container *ngFor="let validation of viewModel.ValidationData.firstName">
                            <div class="text-danger ms-lg-3 ms-md-3 ms-sm-3 ms-3 small"
                                *ngIf="(firstName.touched || viewModel.FormSubmitted) && firstName.hasError(validation.type)">
                                {{ validation.message }}
                            </div>
                        </ng-container>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <input type="text" class="form-control" placeholder="Last Name"
                            [(ngModel)]="viewModel.singleInspector.lastName" #lastName="ngModel" required
                            name="lastName" minlength="3" maxlength="20" />
                        <ng-container *ngFor="let validation of viewModel.ValidationData.lastName">
                            <div class="text-danger ms-lg-3 ms-md-3 ms-sm-3 ms-3 small"
                                *ngIf="(lastName.touched||viewModel.FormSubmitted) && lastName.hasError(validation.type)">
                                {{ validation.message }}
                            </div>
                        </ng-container>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <input type="number" class="form-control" placeholder="Phone Number"
                            [(ngModel)]="viewModel.singleInspector.phoneNumber" #phoneNumber="ngModel" required
                            maxlength="10" name="phoneNumber" />
                        <ng-container *ngFor="let validation of viewModel.ValidationData.phone">
                            <div class="text-danger ms-lg-3 ms-md-3 ms-sm-3 ms-3 small"
                                *ngIf="(phoneNumber.touched||viewModel.FormSubmitted) && phoneNumber.hasError(validation.type)">
                                {{ validation.message }}
                            </div>
                        </ng-container>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <input type="email" class="form-control" placeholder="Email Id"
                            [(ngModel)]="viewModel.singleInspector.emailId" #email="ngModel" required name="email"
                            pattern="^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$" />
                        <ng-container *ngFor="let validation of viewModel.ValidationData.email">
                            <div class="text-danger ms-lg-3 ms-md-3 ms-sm-3 ms-3 small"
                                *ngIf="(email.touched||viewModel.FormSubmitted) &&  email.hasError(validation.type)">
                                {{ validation.message }}
                            </div>
                        </ng-container>
                    </div>


                    <div class="col-lg-6 col-md-6">
                        <input type="text" class="form-control" placeholder="Address"
                            [(ngModel)]="viewModel.singleInspectorAddress.address1 " name="address" #address="ngModel"
                            required />
                        <ng-container *ngFor="let validation of viewModel.ValidationData.address">
                            <div class="text-danger ms-lg-3 ms-md-3 ms-sm-3 ms-3 small"
                                *ngIf="(address.touched||viewModel.FormSubmitted) &&  address.hasError(validation.type)">
                                {{ validation.message }}
                            </div>
                        </ng-container>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <input type="text" class="form-control" placeholder="State"
                            [(ngModel)]="viewModel.singleInspectorAddress.state" #state="ngModel" required
                            name="state" />
                        <ng-container *ngFor="let validation of viewModel.ValidationData.state">
                            <div class="text-danger ms-lg-3 ms-md-3 ms-sm-3 ms-3 small"
                                *ngIf="(state.touched||viewModel.FormSubmitted) &&  state.hasError(validation.type)">
                                {{ validation.message }}
                            </div>
                        </ng-container>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <input type="text" class="form-control" placeholder="City"
                            [(ngModel)]="viewModel.singleInspectorAddress.city" #city="ngModel" required name="city" />
                        <ng-container *ngFor="let validation of viewModel.ValidationData.city">
                            <div class="text-danger ms-lg-3 ms-md-3 ms-sm-3 ms-3 small"
                                *ngIf="(city.touched||viewModel.FormSubmitted) &&  city.hasError(validation.type)">
                                {{ validation.message }}
                            </div>
                        </ng-container>
                    </div>


                    <div class="col-lg-6 col-md-6">
                        <input type="number" class="form-control" placeholder="Pin"
                            [(ngModel)]="viewModel.singleInspectorAddress.pinCode" #pincode="ngModel" required
                            name="pincode" />
                        <ng-container *ngFor="let validation of viewModel.ValidationData.pin">
                            <div class="text-danger ms-lg-3 ms-md-3 ms-sm-3 ms-3 small"
                                *ngIf="(pincode.touched||viewModel.FormSubmitted) &&  pincode.hasError(validation.type)">
                                {{ validation.message }}
                            </div>
                        </ng-container>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <input type="text" class="form-control" placeholder="Login Id"
                            [(ngModel)]="viewModel.singleInspector.loginId" #loginId="ngModel" required
                            name="loginId"  (ngModelChange)="change_LoginId($event)" />
                        <ng-container *ngFor="let validation of viewModel.ValidationData.loginId">
                            <div class="text-danger ms-lg-3 ms-md-3 ms-sm-3 ms-3 small"
                                *ngIf="(loginId.touched||viewModel.FormSubmitted) && loginId.hasError(validation.type)">
                                {{ validation.message }}
                            </div>
                        </ng-container>
                        <ng-container *ngIf="viewModel.showSuccessMsg">
                            <div class="text-success ms-lg-3 ms-md-3 ms-sm-3 ms-3">
                                {{viewModel.validUser.responseMessage}}
                            </div>
                        </ng-container>

                        <ng-container *ngIf="viewModel.showErrorMsg">
                            <div class="text-danger ms-lg-3 ms-md-3 ms-sm-3 ms-3">
                                {{viewModel.validUser.responseMessage}}
                            </div>
                        </ng-container>
                    </div>


                    <div class="col-lg-6 col-md-6"
                        *ngIf="!viewModel.singleInspector.id || viewModel.singleInspector.id <= 0">
                        <div class="password-container">
                            <input [type]="viewModel.hidePassword ? 'password' : 'text'" class="form-control"
                                placeholder="Password" [(ngModel)]="viewModel.singleInspector.passwordHash"
                                #PasswordHash="ngModel" required name="passwordHash" />
                            <i *ngIf="viewModel.hidePassword" class="bi bi-eye-fill" (click)="click_TogglePassword()">
                            </i>

                            <i *ngIf="!viewModel.hidePassword" class="bi bi-eye-slash-fill"
                                (click)="click_TogglePassword()">
                            </i>
                        </div>
                        <ng-container *ngFor="let validation of viewModel.ValidationData.password">
                            <div class="text-danger ms-lg-3 ms-md-3 ms-sm-3 ms-3 small"
                                *ngIf="(PasswordHash.touched||viewModel.FormSubmitted) && PasswordHash.hasError(validation.type)">
                                {{ validation.message }}
                            </div>
                        </ng-container>
                    </div>

                </div>

            </form>
            <div class="text-center">
                <button type="submit" class="spyGlassBtn" (click)="form_addEditInspector(form_Inspector)"> Save Details
                </button>
            </div>
        </div>
    </ng-template>
</div>
























<!-- <ng-template #addEditModal let-modal>
        <form #form_Inspector="ngForm" (ngSubmit)="form_addEditInspector(form_Inspector)">
            <div class="modal-header">
                <h4 class="modal-title">{{viewModel.AddEditModalTitle}}</h4>
                <button type="button" class="btn-close" aria-label="Close"
                    (click)="modal.dismiss('Cross click')"></button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-6">
                        <div class="form-group p-lg-2 mb-3">
                            <label class="form-label"> First Name </label>
                            <input [(ngModel)]="viewModel.singleInspector.firstName" class="form-control"
                                placeholder="First Name" name="firstname" #firstName="ngModel" required />
                            <ng-container *ngFor="let validation of viewModel.ValidationData.firstName">
                                <div class="text-danger ms-lg-2 ms-md-2 ms-sm-2 ms-2"
                                    *ngIf="(firstName.touched || viewModel.FormSubmitted) && firstName.hasError(validation.type)">
                                    {{ validation.message }}
                                </div>
                            </ng-container>
                        </div>

                    </div>
                    <div class="col-6">
                        <div class="form-group p-lg-2 mb-3">
                            <label class="form-label"> Last Name</label>
                            <input [(ngModel)]="viewModel.singleInspector.lastName" class="form-control"
                                placeholder="Last Name" name="lastname" #lastName="ngModel" required />
                            <ng-container *ngFor="let validation of viewModel.ValidationData.lastName">
                                <div class="text-danger ms-lg-2 ms-md-2 ms-sm-2 ms-2"
                                    *ngIf="(lastName.touched || viewModel.FormSubmitted) && lastName.hasError(validation.type)">
                                    {{ validation.message }}
                                </div>
                            </ng-container>

                        </div>

                    </div>
                </div>

                <div class="row">
                    <div class="col-6">
                        <div class="form-group p-lg-2 mb-3">
                            <label class="form-label"> Phone </label>
                            <input [(ngModel)]="viewModel.singleInspector.phoneNumber" class="form-control"
                                placeholder="Phone" #phone="ngModel" required name="phone" />
                            <ng-container *ngFor="let validation of viewModel.ValidationData.phone">
                                <div class="text-danger ms-lg-2 ms-md-2 ms-sm-2 ms-2"
                                    *ngIf="(phone.touched || viewModel.FormSubmitted) && phone.hasError(validation.type)">
                                    {{ validation.message }}
                                </div>
                            </ng-container>
                        </div>

                    </div>

                    <div class="col-6">
                        <div class="form-group p-lg-2 mb-3">
                            <label class="form-label"> Email Id </label>
                            <input [(ngModel)]="viewModel.singleInspector.emailId" class="form-control"
                                placeholder="Email Id" #email="ngModel" required name="emailId" />
                            <ng-container *ngFor="let validation of viewModel.ValidationData.email">
                                <div class="text-danger ms-lg-2 ms-md-2 ms-sm-2 ms-2"
                                    *ngIf="(email.touched || viewModel.FormSubmitted) && email.hasError(validation.type)">
                                    {{ validation.message }}
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>


                <div class="row">
                    <div class="col-6">
                        <div class="form-group p-lg-2 mb-3">
                            <label class="form-label"> Address </label>
                            <input [(ngModel)]="viewModel.singleInspectorAddress.address1" class="form-control"
                                placeholder="Enter Address" name="address" #address="ngModel" required />
                            <ng-container *ngFor="let validation of viewModel.ValidationData.address">
                                <div class="text-danger ms-lg-2 ms-md-2 ms-sm-2 ms-2"
                                    *ngIf="(address.touched || viewModel.FormSubmitted) && address.hasError(validation.type)">
                                    {{ validation.message }}
                                </div>
                            </ng-container>
                        </div>

                    </div>
                    <div class="col-6">
                        <div class="form-group p-lg-2 mb-3">
                            <label class="form-label"> State </label>
                            <input [(ngModel)]="viewModel.singleInspectorAddress.state" type="text" class="form-control"
                                placeholder="Enter State" #state="ngModel" required="" name="state" />
                            <ng-container *ngFor="let validation of viewModel.ValidationData.state">
                                <div class="text-danger ms-lg-2 ms-md-2 ms-sm-2 ms-2"
                                    *ngIf="(state.touched || viewModel.FormSubmitted) && state.hasError(validation.type)">
                                    {{ validation.message }}
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>


                <div class="row">
                    <div class="col-6">

                        <div class="form-group p-lg-2 mb-3">
                            <label class="form-label"> City </label>
                            <input [(ngModel)]="viewModel.singleInspectorAddress.city" class="form-control"
                                placeholder="Enter City" name="city" #city="ngModel" required />
                            <ng-container *ngFor="let validation of viewModel.ValidationData.city">
                                <div class="text-danger ms-lg-2 ms-md-2 ms-sm-2 ms-2"
                                    *ngIf="(city.touched || viewModel.FormSubmitted) && city.hasError(validation.type)">
                                    {{ validation.message }}
                                </div>
                            </ng-container>
                        </div>

                    </div>
                    <div class="col-6">
                        <div class="form-group p-lg-2 mb-3">
                            <label class="form-label"> Pin </label>
                            <input [(ngModel)]="viewModel.singleInspectorAddress.pinCode" type="text"
                                class="form-control" placeholder="Enter Pin" name="pin" #pin="ngModel" required />
                            <ng-container *ngFor="let validation of viewModel.ValidationData.pin">
                                <div class="text-danger ms-lg-2 ms-md-2 ms-sm-2 ms-2"
                                    *ngIf="(pin.touched || viewModel.FormSubmitted) && pin.hasError(validation.type)">
                                    {{ validation.message }}
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-6">

                        <div class="form-group p-lg-2 mb-3">
                            <label class="form-label"> Login id </label>
                            <input [(ngModel)]="viewModel.singleInspector.loginId" class="form-control"
                                placeholder="Login Id" #loginId="ngModel" required name="loginId" />
                            <ng-container *ngFor="let validation of viewModel.ValidationData.loginId">
                                <div class="text-danger ms-lg-2 ms-md-2 ms-sm-2 ms-2"
                                    *ngIf="(loginId.touched || viewModel.FormSubmitted) && loginId.hasError(validation.type)">
                                    {{ validation.message }}
                                </div>
                            </ng-container>
                        </div>

                    </div>
                    <div class="col-6" *ngIf="!viewModel.singleInspector.id || viewModel.singleInspector.id <= 0">
                        <div class="form-group p-lg-2 mb-3">
                            <label class="form-label"> Password </label>
                            <input [(ngModel)]="viewModel.singleInspector.passwordHash" type="password"
                                class="form-control" placeholder="Enter password" #password="ngModel" required
                                name="password" />
                            <ng-container *ngFor="let validation of viewModel.ValidationData.password">
                                <div class="text-danger ms-lg-2 ms-md-2 ms-sm-2 ms-2"
                                    *ngIf="(password.touched || viewModel.FormSubmitted) && password.hasError(validation.type)">
                                    {{ validation.message }}
                                </div>
                            </ng-container>
                        </div>
                    </div>


                </div>
            </div>
            <div class="modal-footer">
                <button type="submit" class="spyGlassBtn"> Save </button>
            </div>

        </form>
    </ng-template> -->