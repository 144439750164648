import { SurveyBoxServiceModelBase } from '../../base/survey-box-service-model-base';
export class FeatureSM extends SurveyBoxServiceModelBase<number> {
    title!: string;
    description!: string;
    price!: number;
    featureCode!: string;
    validityInDays!: number;
    usageCount!: number;
    isFeatureCountable!: boolean;
    startDate!: Date;
    endDate!: Date;
}
