import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../base.component';
import { LogHandlerService } from 'src/app/services/log-handler.service';
import { CommonService } from 'src/app/services/common.service';
import { ResetPasswordViewModel } from 'src/app/view-models/reset-password.viewmodel';
import { AppConstants } from 'src/app-constants';
import { ActivatedRoute, Router } from '@angular/router';
import { ResetPasswordRequestSM } from 'src/app/service-models/app/v1/app-users/login/reset-password-request-s-m';
import { NgForm } from '@angular/forms';
import { AccountService } from 'src/app/services/account.service';
import { ValidatePasswordLinkStatusSM } from 'src/app/service-models/app/enums/validate-password-link-status-s-m.enum';


@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent extends BaseComponent<ResetPasswordViewModel> implements OnInit {
  ValidatePasswordLinkStatusSM = ValidatePasswordLinkStatusSM;

  constructor(commonService: CommonService,
    logService: LogHandlerService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private accountService: AccountService
  ) {
    super(commonService, logService);
    this.viewModel = new ResetPasswordViewModel();
    this._commonService.layoutViewModel.showTopNav = false;
    this._commonService.layoutViewModel.showFooter = false;
    this._commonService.layoutViewModel.toggleContent = 'content';

  }
  async ngOnInit() {
    try {
      await this._commonService.presentLoading();
      let info = this.activatedRoute.snapshot.queryParams['authCode'];
      let resp = await this.accountService.validateAuthCode(info);
      if (resp.isError) {
        this._exceptionHandler.logObject(resp.errorData);
        this._commonService.showSweetAlertToast({ title: resp.errorData.displayMessage, icon: 'error' });
        return;
      } else {
        this.viewModel.validateAuthCode = resp.successData.intResponse;
        if (this.viewModel.validateAuthCode === ValidatePasswordLinkStatusSM.Invalid) {
          this._commonService.showSweetAlertToast({ title: 'Password reset link expired', icon: 'error' });
          this.router.navigate([`${AppConstants.WebRoutes.HOME}`]);
          return;
        } else if (this.viewModel.validateAuthCode === ValidatePasswordLinkStatusSM.Valid) {
          this._commonService.showSweetAlertToast({ title: 'Please continue to reset your password', icon: 'success' });
          return;
        }
      }

    } catch (error) {
      this._commonService.showSweetAlertToast({ title: AppConstants.ErrorPrompts.Unknown_Error, icon: 'error' });
      throw error;
    } finally {
      await this._commonService.dismissLoader();
    }
  }

  async click_resetPassword(resetPasswordForm: NgForm) {
    this.viewModel.FormSubmitted = true;
    try {
      await this._commonService.presentLoading();
      if (resetPasswordForm.invalid)
        return;
      let info = this.activatedRoute.snapshot.queryParams['authCode'];
      const resetPasswordRequest: ResetPasswordRequestSM = new ResetPasswordRequestSM();
      resetPasswordRequest.authCode = decodeURI(info) ?? '';
      // resetPasswordRequest.authCode=code.replace("%2B","+");   
      resetPasswordRequest.newPassword = this.viewModel.resetPasswordDetails.newPassword;
      let resp = await this.accountService.UpdatePassword(resetPasswordRequest);
      if (resp.isError) {
        this._exceptionHandler.logObject(resp.errorData);
        this._commonService.showSweetAlertToast({ title: resp.errorData.displayMessage, icon: 'error' });
        return;
      }
      this.viewModel.resetPasswordDetails = resp.successData;
      this._commonService.showSweetAlertToast({ title: 'Password updated Sucessfully', icon: 'success' });
      this.router.navigate([`${AppConstants.WebRoutes.HOME}`]);
    } catch (error) {
      this._commonService.showSweetAlertToast({ title: AppConstants.ErrorPrompts.Unknown_Error, icon: 'error' });
      throw error;
    } finally {
      await this._commonService.dismissLoader();
    }
  }
}


