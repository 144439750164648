<div class="container-fluid">

  <div class="row">

    <div class="col-lg-12 text-center m-auto pricingTopSection" data-aos="zoom-out" data-aos-duration="3000">
      <h1> Checkout our Pricing Plans </h1>
    </div>

    <div class="col-lg-10 col-md-10 col-sm-12 col-12 m-auto" >

      <div class="row pt-lg-5 pt-md-5 pt-sm-4 pt-4 pb-lg-5 pb-md-5 pb-sm-4 pb-4 cardsRow" #pricingCards >

        <div class="col-lg-4 col-md-6 col-sm-12 col-12 p-lg-3 p-md-3 p-sm-3 p-3 cardColumn"data-aos="fade-up" data-aos-duration="1500"
          *ngFor="let item of viewModel.FeatureGroupList">

          <div class="card m-lg-2 m-md-2 m-sm-2 m-2">

            <div class="card-body text-center py-0 px-1">

              <div class="cardHeading mb-lg-2 mb-md-2 mb-sm-2 mb-2">

                <span class="icon">
                  <i class="bi bi-send"> </i>
                </span>

                <h5>
                  {{item.title}}
                </h5>

                <label>
                  <b> ${{item.amount}} </b> /{{item.validityInDays}} Days
                </label>

                <button type="button" (click)="click_BuyLicense(item.id)"
                  [ngClass]="!IsValidForRole(item.validFor)? 'spyGlass' : 'spyGlassBtn'"
                  *ngIf="viewModel.activeLicense.featureGroupId!=item.id " [disabled]="!IsValidForRole(item.validFor)">
                  {{viewModel.buyBtn}}
                </button>

                <!-- <button type="button"[ngClass]="viewModel.purchasedBtn ? 'spyGlassPurchsedbtn' : 'spyGlassBtn'"
                  *ngIf="viewModel.activeLicense.featureGroupId==item.id && viewModel.checkValidUser && viewModel.checkActiveLicense">
                  {{viewModel.purchasedBtn}}
                </button> -->
                
                <button type="button" (click)="click_Renew()"
                  [ngClass]="viewModel.purchasedBtn === 'Purchased' ? 'spyGlassPurchsedbtn' : 'spyGlassBtn'"
                  *ngIf="viewModel.activeLicense.featureGroupId === item.id && viewModel.checkValidUser && viewModel.checkActiveLicense">
                  {{ viewModel.purchasedBtn }}
                </button>
              </div>

              <div class="features">

                <ul class="list-unstyled">

                  <li *ngFor="let features of item.features">
                    <i class="bi bi-check2-circle"> </i>
                    <span> {{features.title}} </span>
                  </li>

                </ul>

              </div>

            </div>

          </div>

        </div>

      </div>

    </div>

  </div>

  <button class="btn scrollLeftBtn d-lg-inline-block d-none" (click)="scrollLeft()">
    <i class="bi bi-chevron-left"></i>
  </button>

  <button class="btn scrollRightBtn d-lg-inline-block d-none" (click)="scrollRight()">
    <i class="bi bi-chevron-right"></i>
  </button>

  
  <div class="row tableRow" *ngIf="viewModel.showTable">

    <div class="col-lg-10 m-auto">

      <div class="section-title-wrap text-center ">
        <h2 class="text-white"> Plan Comparison </h2>
      </div>

    </div>


    <div class="col-lg-10 m-auto" >

      <div class="tableColumn">

        <table class="table table-light">

          <thead>
            <tr>
              <th class="text-center"> Features </th>
              <th *ngFor="let item of viewModel.FeatureGroupList">
                {{item.title}}
              </th>
            </tr>
          </thead>

          <tbody>

            <tr class="amountRow">
              <td class="fw-bold"> Monthly Pay </td>
              <td *ngFor="let item of viewModel.FeatureGroupList">
                <b> ${{item.amount}} </b> <small>/{{item.validityInDays}} Days </small>
              </td>
            </tr>

            <tr>
              <th colspan="7" class="tableHead">
                Library Features
              </th>
            <tr>

            <tr *ngFor="let item of viewModel.libraryFeatures">

              <ng-container>
                <td class="fw-bold">
                  {{item.title}}
                </td>
                <td *ngFor="let featureGroup of viewModel.FeatureGroupList">
                  <div style="display: none;">
                    {{getFeatureStatusForFeatureGroup(featureGroup,item)}}
                  </div>
                  <i [class]="this.viewModel.SelectedFeatureValue">
                    <div class="count" *ngIf="this.viewModel.SelectedFeatureEnabled">
                      {{this.viewModel.SelectedFeatureValueCount}}/month</div>
                  </i>
                </td>
              </ng-container>

            </tr>


            <tr>
              <th colspan="7">
                Inspector Features
              </th>
            <tr>

            <tr *ngFor="let item of viewModel.inspectorFeatures">

              <ng-container>
                <td class="fw-bold">
                  {{item.title}}
                </td>
                <td *ngFor="let featureGroup of viewModel.FeatureGroupList">
                  <div style="display: none;">
                    {{getFeatureStatusForFeatureGroup(featureGroup,item)}}
                  </div>
                  <i [class]="this.viewModel.SelectedFeatureValue">
                    <div class="count" *ngIf="this.viewModel.SelectedFeatureEnabled">
                      {{this.viewModel.SelectedFeatureValueCount}}/month</div>
                  </i>
                </td>
              </ng-container>

            </tr>

            <tr>
              <th colspan="7">
                Report Features
              </th>
            <tr>

            <tr *ngFor="let item of viewModel.reportFeatures">
              <ng-container>
                <td class="fw-bold">
                  {{item.title}}
                </td>
                <td *ngFor="let featureGroup of viewModel.FeatureGroupList">
                  <div style="display: none;">
                    {{getFeatureStatusForFeatureGroup(featureGroup,item)}}
                  </div>
                  <i [class]="this.viewModel.SelectedFeatureValue">
                    <div class="count" *ngIf="this.viewModel.SelectedFeatureEnabled">
                      {{this.viewModel.SelectedFeatureValueCount
                      }}/month</div>
                  </i>
                </td>
              </ng-container>
            </tr>

            <tr>
              <th colspan="7">
                Other Features
              </th>
            <tr>

            <tr *ngFor="let item of viewModel.otherFeatures">
              <ng-container>
                <td class="fw-bold">
                  {{item.title}}
                </td>
                <td *ngFor="let featureGroup of viewModel.FeatureGroupList">
                  <div style="display: none;">
                    {{getFeatureStatusForFeatureGroup(featureGroup,item)}}
                  </div>
                  <i [class]="this.viewModel.SelectedFeatureValue">
                    <div class="count" *ngIf="this.viewModel.SelectedFeatureEnabled">
                      {{this.viewModel.SelectedFeatureValueCount}}/month</div>
                  </i>
                </td>
              </ng-container>
            </tr>

          </tbody>

        </table>

      </div>

    </div>

  </div>

</div>