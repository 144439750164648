<section class="mainSection">

    <div class="container">

        <div class="row">

            <div class="col-lg-6 col-md-10 col-12 mt-lg-5 mb-md-5 mb-sm-5 mb-5">

                <div class="demoVideosMainSectionText">

                    <h2> {{viewModel.PageTitle}}: Your Insider's Guide </h2>

                    <span class="mt-3">
                        Introducing our series of demo videos designed to introduce you to Spyglass's world.
                        Our demo videos are like having a personal guide through our app's features


                    </span>

                </div>

            </div>

            <div class="col-lg-6 col-12">

                <div class="demoVideoSectionImage">
                    <img src="assets/images/demo-videos/mainimage.webp" class="img-fluid" alt="demo-video"
                        data-aos="zoom-out" data-aos-duration="1500">
                </div>

            </div>
            <!-- <p>Please take a moment to check out our training videos and see if they have the information you are
                looking for. There are also a number of help pages in the FAQ above, that are designed to answer some of
                the more common questions. If you can’t find what you are looking for, or need more explanation on a
                topic, please feel free to contact us and we will be more than happy to help</p> -->

        </div>

    </div>

</section>

<section class="secondSection">

    <div class="container scrollContent">
        <div class="row scrollContentStep ">
            <h1>Let's get started with our comprehensive demo videos.</h1>
            <div class="heading mt-5 mb-4">
                <h2 class="text-white ms-4 mb-0">{{viewModel.demoVideoReportTitle}} </h2>
            </div>
            <div *ngIf="viewModel.demoVideoReport.length>0" class="col-lg-8 col-md-8 col-sm-12 col-12 "
                data-aos="fade-up" data-aos-duration="1500">
                <video controls>
                    <source src={{viewModel.demoVideoReport}}  />
                   
                </video>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12 col-12">
                <div class="subHeading  mb-4 ">
                    <h2 class=" ms-4 mb-0" data-aos="fade-left" data-aos-duration="1500"> Step-by-step </h2>
                </div>
                <div class="card" data-aos="fade-up" data-aos-duration="1500">
                    <div class="card-body">
                        <ol>
                            <li>
                                Open the app and input report information
                            </li>
                            <li>
                                Click "Create Report" to create it
                            </li>
                            <li>
                                Fill in the necessary details on the "General Information" page

                            </li>
                            <li>
                                Add action nodes with their narratives and select the required ones for the report
                            </li>
                            <li>
                                Click "Generate Report"
                            </li>
                            <li>
                                A modal will appear with three options (summary, general, combined reports); choose
                                as
                                needed
                            </li>
                            <li>
                                Print the report as a PDF or DOC file
                            </li>

                            <!-- <a href="#top">Top</a> -->
                        </ol>
                    </div>
                </div>
            </div>
        </div>

        <div class="row scrollContentStep">
            <div class="heading mt-5 mb-4">
                <h2 class="text-white ms-4 mb-0">{{viewModel.demoVideoPrintSettingTitle}}</h2>
            </div>
            <div *ngIf="viewModel.demoVideoPrintSetting.length > 0" class="col-lg-8 col-md-8 col-sm-12 col-12"
                data-aos="fade-up" data-aos-duration="1500">
                <video controls>
                    <source src={{viewModel.demoVideoPrintSetting}} type="video/mp4" />
                </video>
            </div>
            <div class="col-lg-4 col-12">
                <div class="subHeading  mb-4">
                    <h2 class=" ms-4 mb-0" data-aos="fade-left" data-aos-duration="1500"> Step-by-step </h2>
                </div>
                <div class="card" data-aos="fade-up" data-aos-duration="1500">
                    <div class="card-body">
                        <ol>
                            <li class="mt-2">
                                Open the existing report.

                            </li>
                            <li class="mt-2">
                                Optionally select specific sections.
                            </li>
                            <li class="mt-2">
                                Click "Generate Report."
                            </li>
                            <li class="mt-2">
                                Tap the print setting icon.
                            </li>
                            <li class="mt-2">
                                Access print preview and settings.
                            </li>
                            <li class="mt-2">
                                Click print preview to view the report.
                            </li>
                            <li class="mt-2">
                                Click print setting to print the report.
                            </li>

                            <!-- <a href="#top">Top</a> -->
                        </ol>
                    </div>
                </div>
            </div>
        </div>

        <div class="row scrollContentStep">
            <div class="heading mt-5 mb-4">
                <h2 class="text-white ms-4 mb-0"> {{viewModel.demoVideoPrintSettingDashboardTitle}}</h2>
            </div>
            <div class="col-lg-8 col-12" data-aos="fade-up" data-aos-duration="1500"
                *ngIf="viewModel.demoVideoPrintSettingDashboard.length > 0">
                <video controls>
                    <source src={{viewModel.demoVideoPrintSettingDashboard}} type="video/mp4" />

                </video>


            </div>
            <div class="col-lg-4 col-12">
                <div class="subHeading  mb-4">
                    <h2 class=" ms-4 mb-0" data-aos="fade-left" data-aos-duration="1500"> Step-by-step </h2>
                </div>
                <div class="card" data-aos="fade-up" data-aos-duration="1500">
                    <div class="card-body">
                        <ol>
                            <li>
                                Begin by selecting the existing report.

                            </li>
                            <li>
                                Click the print button within the dashboard.
                            </li>
                            <li>
                                In the print options, you will find two choices for printing the report.
                            </li>
                            <li>
                                Choose "Print General Report."
                            </li>
                            <li>
                                You have the flexibility to print the report as either a DOC or PDF file.
                            </li>
                            <li>
                                If you want to print the complete report, click on "Full Report."
                            </li>

                            <!-- <a href="#top">Top</a> -->
                        </ol>
                    </div>
                </div>
            </div>
        </div>

        <div class="row scrollContentStep">
            <div class="heading mt-5 mb-4">
                <h2 class="text-white ms-4 mb-0"> {{viewModel.demoVideoSchedularTitle}}</h2>
            </div>
            <div *ngIf="viewModel.demoVideoSchedular.length > 0" class="col-lg-8 col-12" data-aos="fade-up"
                data-aos-duration="1500">
                <video controls>
                    <source [src]="viewModel.demoVideoSchedular" />
                </video>
            </div>
            <div class="col-lg-4 col-12">
                <div class="subHeading  mb-4">
                    <h2 class=" ms-4 mb-0" data-aos="fade-left" data-aos-duration="1500"> Step-by-step </h2>
                </div>
                <div class="card" data-aos="fade-up" data-aos-duration="1500">
                    <div class="card-body">
                        <ol>
                            <li>
                                Begin by opening the scheduler page.

                            </li>
                            <li>
                                Within the scheduler page, locate and click on an event to add it.
                            </li>
                            <li>
                                The selected event will be successfully added.
                            </li>
                            <li>
                                If you need to make changes, simply click on the edit icon to modify the event.
                            </li>
                            <li>
                                To remove an event, use the delete icon.
                            </li>
                            <li>
                                Additionally, you can create a report with the same event name by clicking on the +
                                icon.
                            </li>
                            <!-- <a href="#top">Top</a> -->
                        </ol>
                    </div>
                </div>
            </div>
        </div>
    </div>

</section>