import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { AppConstants } from 'src/app-constants';
import { LoginStatusSM } from 'src/app/service-models/app/enums/login-status-s-m.enum';
import { RoleTypeSM } from 'src/app/service-models/app/enums/role-type-s-m.enum';
import { AccountService } from 'src/app/services/account.service';
import { CommonService } from 'src/app/services/common.service';
import { LogHandlerService } from 'src/app/services/log-handler.service';
import { RegisterViewModel } from 'src/app/view-models/register.viewmodel';
import { BaseComponent } from '../../base.component';
import { ProfileInfoService } from 'src/app/services/profile-info.service';
import { CompanyProfileService } from 'src/app/services/company-profile.service';
import { AuthGuard } from 'src/app/guard/auth.guard';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})


export class RegisterComponent extends BaseComponent<RegisterViewModel> implements OnInit {

  constructor(
    commonService: CommonService,
    logService: LogHandlerService,
    public activeModal: NgbActiveModal,
    private router: Router,
    private accountService: AccountService,
    private profileInfoService: ProfileInfoService,
    private companyProfileService: CompanyProfileService,
    private authGuard: AuthGuard,
  ) {
    super(commonService, logService)
    this.viewModel = new RegisterViewModel();
    this._commonService.layoutViewModel.toggleWrapper = 'homeWrapper';
    this._commonService.layoutViewModel.toggleContent = 'registerContent';
    this._commonService.layoutViewModel.showTopNav = true;
  }

  ngOnInit(): void {

  }

  async click_showLoginModal() {
    this.activeModal.close(true);
    await this._commonService.presentLoginModal(this._commonService.layoutViewModel.currentRoute);
  }


  // async click_Register(registerInspectorForm: NgForm, registerCompanyForm: NgForm) {
  //   this.viewModel.FormSubmitted = true;
  //   try {
  //     await this._commonService.presentLoading();
  //     let roleType = RoleTypeSM.IndividualInspector;

  //     if (this.viewModel.companyRegister == 'true') {
  //       if (registerCompanyForm.invalid)
  //         return;
  //       roleType = RoleTypeSM.CompanyAdmin;
  //     }
  //     if (registerInspectorForm.invalid) {
  //       return;
  //     }
  //     this.viewModel.userDetails.roleType = roleType;
  //     this.viewModel.userDetails.clientCompanyDetailId = 0;
  //     this.viewModel.userDetails.stripeCustomerId = '';

  //     if (roleType == RoleTypeSM.CompanyAdmin) {
  //       this.viewModel.companyDetails.companyCode = Math.floor((Math.random() * 100000)).toString();
  //       let respCompany = await this.accountService.AddCompany(this.viewModel.companyDetails);
  //       if (respCompany.isError) {
  //         this._exceptionHandler.logObject(respCompany.errorData);
  //         this._commonService.showSweetAlertToast({ title: respCompany.errorData.displayMessage, icon: 'error' });
  //         return;
  //       }
  //       this.viewModel.companyAddressDetails.clientCompanyDetailId = respCompany.successData.id;
  //       this.viewModel.userDetails.clientCompanyDetailId = respCompany.successData.id;
  //       let respAddress = await this.accountService.AddCompanyAddress(this.viewModel.companyAddressDetails);
  //       if (respAddress.isError) {
  //         this._exceptionHandler.logObject(respAddress.errorData);
  //         this._commonService.showSweetAlertToast({ title: respAddress.errorData.displayMessage, icon: 'error' });
  //       }
  //     }

  //     this.viewModel.userDetails.loginStatus = LoginStatusSM.Enabled;
  //     this.viewModel.userDetails.isEmailConfirmed = true;
  //     this.viewModel.userDetails.isPhoneNumberConfirmed = true;
  //     let respUser = await this.accountService.AddUser(this.viewModel.userDetails);
  //     if (respUser.isError) {
  //       this._exceptionHandler.logObject(respUser.errorData);
  //       this._commonService.showSweetAlertToast({ title: respUser.errorData.displayMessage, icon: 'error' });
  //       return;
  //     }
  //     this.viewModel.userAddress.clientUserId = respUser.successData.id;
  //     let respUserAddress: ApiResponse<ClientUserAddressSM> = await this.accountService.AddUserAddress(this.viewModel.userAddress);
  //     if (respUserAddress.isError) {
  //       this._exceptionHandler.logObject(respUserAddress.errorData);
  //       this._commonService.showSweetAlertToast({ title: respUserAddress.errorData.displayMessage, icon: 'error' });
  //     }
  //     this._commonService.showSweetAlertToast({ title: 'Success', icon: 'success' });

  //     let tokenResp = await this.accountService.generateToken({
  //       companyCode: this.viewModel.companyDetails.companyCode,
  //       loginId: this.viewModel.userDetails.loginId,
  //       password: this.viewModel.userDetails.passwordHash,
  //       roleType: roleType
  //     }, false);
  //     if (tokenResp.isError)
  //       this._commonService.updateLayout(RoleTypeSM.Unknown, "");
  //     else
  //       this._commonService.updateLayout(roleType, "");
  //     this.router.navigate([AppConstants.WebRoutes.HOME]);

  //     this.viewModel.currentRole = await this.authGuard.GetRoleFromToken();
  //     if (this.viewModel.currentRole == RoleTypeSM.CompanyInspector || this.viewModel.currentRole == RoleTypeSM.IndividualInspector) {
  //       await this.getCompanyInspectorProfilePicture();
  //     } else if (this.viewModel.currentRole == RoleTypeSM.CompanyAdmin) {
  //       await this.getCompanyAdminProfilePicture();
  //     }

  //   } catch (error) {
  //     this._commonService.showSweetAlertToast({ title: AppConstants.ErrorPrompts.Unknown_Error, icon: 'error' });
  //     throw error;
  //   } finally {
  //     await this._commonService.dismissLoader()
  //   }

  // }


  // update_LoginId() {
  //   let emailId = this.viewModel.userDetails.emailId;
  //   this.viewModel.userDetails.loginId = emailId;
  // }


  async change_LoginId(loginId: string) {
    setTimeout(async () => {
      let resp = await this.accountService.ValidateLoginId(loginId);
      this.viewModel.validUser = resp.successData;
      if (resp.successData.boolResponse == true) {
        this.viewModel.showSuccessMsg = true;
        this.viewModel.showErrorMsg = false;
      }
      else {
        this.viewModel.showSuccessMsg = false;
        this.viewModel.showErrorMsg = true;
      }
    }, 2000);
  }

  async getCompanyInspectorProfilePicture() {
    try {
      await this._commonService.presentLoading();
      let resp = await this.profileInfoService.getInspectorProfile();
      if (resp.isError) {
        await this._exceptionHandler.logObject(resp.errorData);
        this._exceptionHandler.logObject(resp.errorData);
        this._commonService.showSweetAlertToast({ title: resp.errorData.displayMessage, icon: 'error' });
        return;
      }
      this._commonService.layoutViewModel.inspectorProfilePicture = resp.successData.profilePicturePath;
    } catch (error) {

    } finally {
      await this._commonService.dismissLoader();
    }
  }

  async getCompanyAdminProfilePicture() {
    try {
      await this._commonService.presentLoading();
      let resp1 = await this.companyProfileService.getCompanyProfile();
      if (resp1.isError) {
        this._exceptionHandler.logObject(resp1.errorData);
        this._commonService.showSweetAlertToast({ title: resp1.errorData.displayMessage, icon: 'error' });
      } else
        this._commonService.layoutViewModel.companyAdminProfilePicture = resp1.successData.companyLogoPath;
    } catch (error) {

    } finally {
      await this._commonService.dismissLoader();
    }
  }

  async registerCompany(registerCompanyForm: NgForm) {
    this.viewModel.FormSubmitted = true;
    try {
      await this._commonService.presentLoading();
      if (registerCompanyForm.invalid) {
        this.viewModel.addInvalidFormClass = 'invalidFormStyle';
        return;
      }
      this.viewModel.userDetails.roleType = RoleTypeSM.CompanyAdmin;
      this.viewModel.userDetails.clientCompanyDetailId = 0;
      this.viewModel.userDetails.stripeCustomerId = '';
      let respCompany = await this.accountService.AddCompany(this.viewModel.companyDetails);
      if (respCompany.isError) {
        this._exceptionHandler.logObject(respCompany.errorData);
        this._commonService.showSweetAlertToast({ title: respCompany.errorData.displayMessage, icon: 'error' });
      } else {
        this.viewModel.userDetails.clientCompanyDetailId = respCompany.successData.id;
        this.viewModel.userDetails.loginStatus = LoginStatusSM.Enabled;
        this.viewModel.userDetails.isEmailConfirmed = true;
        this.viewModel.userDetails.isPhoneNumberConfirmed = true;
        let respUser = await this.accountService.AddUser(this.viewModel.userDetails);
        if (respUser.isError) {
          this._exceptionHandler.logObject(respUser.errorData);
          this._commonService.showSweetAlertToast({ title: respUser.errorData.displayMessage, icon: 'error' });
        } else {
          // this._commonService.showSweetAlertToast({ title: 'Success', icon: 'success' });
          this._commonService.showInfoInModalPopup("success", "Company Added Successfully");
          let tokenResp = await this.accountService.generateToken({
            companyCode: this.viewModel.companyDetails.companyCode,
            loginId: this.viewModel.userDetails.loginId,
            password: this.viewModel.userDetails.passwordHash,
            roleType: RoleTypeSM.CompanyAdmin,
          }, false);
          if (tokenResp.isError)
            this._commonService.updateLayout(RoleTypeSM.Unknown, "");
          else {
            this.activeModal.dismiss();
            this._commonService.layoutViewModel.showProfile = true;
            this._commonService.layoutViewModel.isAuthenticated = true;
            this._commonService.layoutViewModel.showFooter = false;
            this._commonService.layoutViewModel.roleType = tokenResp.successData.loginUserDetails.roleType;
            if (this.router.url == AppConstants.WebRoutes.PRICING || this.viewModel.currentRole == RoleTypeSM.CompanyAdmin) {
              this.router.navigate([`${AppConstants.WebRoutes.PRICING}`]);
              window.location.reload();
            }
            else {
              this.router.navigate([`${AppConstants.WebRoutes.PROFILE}`]);
            }
          }
          this.viewModel.currentRole = await this.authGuard.GetRoleFromToken();
          if (this.viewModel.currentRole == RoleTypeSM.CompanyInspector || this.viewModel.currentRole == RoleTypeSM.IndividualInspector) {
            await this.getCompanyInspectorProfilePicture();
          } else if (this.viewModel.currentRole == RoleTypeSM.CompanyAdmin) {
            await this.getCompanyAdminProfilePicture();
          }
        }
      }
    } catch (error) {
      throw error;
    } finally {
      await this._commonService.dismissLoader();
    }
  }

  invalidForm() {
    this.viewModel.addInvalidFormClass = 'invalidFormStyle';
  }

  click_TogglePassword() {
    this.viewModel.hidePassword = !this.viewModel.hidePassword;
    if (this.viewModel.eyeDefault == 'default') {
      this.viewModel.eyeDefault = 'eyeChange';
    } else {
      this.viewModel.eyeDefault = 'default';
    }
    return;
  }

  async closeModal() {
    this.activeModal.close();
    window.location.reload();
  }

}

