
<div class="container-fluid">
    <div class="table-title">
             <label class="inpectorsCount"> Total No. of Companies: {{viewModel.totalCount}}</label>
        <table class="table mt-2">
            <thead>
                <tr class="text-center">
                    <!-- <th class="firstTableHead">Title</th> -->
                    <th class="firstTableHead">License Type</th>
                    <th>Paid Price</th>
                    <th>Issue Date</th>
                    <th  class="lastTableHead">Expiry Date</th>
                    <!-- <th class="lastTableHead">Actions</th> -->
                </tr>
            </thead>
            <tbody>
                <tr class="h5 fw-normal text-center" *ngFor="let license of viewModel.licenseInfo">
                    <!-- <td class="align-middle">{{license.loginId}}</td> -->
                    <td class="align-middle">{{license.featureGroupName}}</td>
                    <td class="align-middle">{{license.actualPaidPrice}}</td>
                    <td class="align-middle">{{license.startDateUTC | date :'short':'locale'}}</td>
                    <td class="align-middle">{{license.expiryDateUTC | date :'short':'locale'}}</td>
                 
                        <!-- <i class="bi bi-pencil-fill" (click)="click_OpenAddEditModal(addEditModal,features.id)"> </i> -->
                        <!-- &nbsp; <i class="bi bi-trash3-fill" (click)="deletefeatureById(features.id)"></i> -->
                   

                </tr>
            </tbody>
        </table>


        <div id="pagination" class="col-lg-12 col-md-12 col-sm-12 col-12  d-flex justify-content-center fixed-bottom">
            <ngb-pagination [collectionSize]="viewModel.totalCount" [(page)]="viewModel.PageNo"
                [pageSize]="viewModel.PageSize" (pageChange)="loadPagedataWithPagination($event)"></ngb-pagination>
        </div>

        
    </div>