import { SurveyBoxServiceModelBase } from '../../base/survey-box-service-model-base';
import { UserInvoiceSM } from './user-invoice-s-m';

export class UserLicenseDetailsSM extends SurveyBoxServiceModelBase<number> {
    featureGroupName!: string;
    validityInDays!: number;
    discountInPercentage!: number;
    actualPaidPrice!: number;
    currency!: string;
    status!: string;
    isSuspended!: boolean;
    isCancelled!: boolean;
    cancelAt?: Date;
    cancellledOn?: Date;
    clientUserId!: number;
    featureGroupId!: number;
    startDateUTC!: Date;
    expiryDateUTC!: Date;
    stripeSubscriptionId!: string;
    userInvoices!: Array<UserInvoiceSM>;
}
