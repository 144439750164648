<div class="container-fluid px-0">
    <section class="productInfoMainSection">

        <div class="container">
    
            <div class="row">
    
                <div class="col-lg-7 col-md-6 col-sm-12 col-12 productInfoMainSectionFirstColumn"data-aos="fade-right" data-aos-duration="3000">
                    <div class="topSectionText">
                        <h6> SPYGLASS INSPECTION SOFTWARE </h6>
                        <h2> Experience the convenience and efficiency </h2>
                        <p>
                            Welcome to SpyGlass Inspection Software, a comprehensive and versatile application designed
                            to revolutionize the inspection and reporting process for home inspectors, property
                            assessors, and maintenance professionals. With its advanced features and intuitive
                            interface, this application simplifies the creation of detailed inspection reports,
                            facilitates library management, offers scheduling capabilities, and provides essential tools
                            for organizing contacts, documents, contracts, and more
                        </p>
                    </div>
                </div>
    
                <div class="col-lg-4 col-md-5 col-sm-12 col-12 mx-auto productInfoMainSectionSecondColumn" data-aos="fade-down" data-aos-delay="1000" >
                    <div class="rightImage">
                        <img src="{{'data:image/webp;base64,' + viewModel.productMainImage }}" class="img-fluid"
                            alt="main-image">
                    </div>
                </div>
    
            </div>
    
        </div>
    
    </section>
    

    <!-- <section class="productInfoMainSection">

        <div class="container">

            <div class="row">

                <div class="col-lg-7 col-md-6 col-sm-12 col-12 productInfoMainSectionFirstColumn">
                    <div class="topSectionText">
                        <h6> SPYGLASS INSPECTION SOFTWARE </h6>
                        <h2> Experience the convenience and efficiency </h2>
                        <p>
                            Welcome to SpyGlass Inspection Software, a comprehensive and versatile application designed
                            to revolutionize the inspection and reporting process for home inspectors, property
                            assessors, and maintenance professionals. With its advanced features and intuitive
                            interface, this application simplifies the creation of detailed inspection reports,
                            facilitates library management, offers scheduling capabilities, and provides essential tools
                            for organizing contacts, documents, contracts, and more
                        </p>
                    </div>
                </div>

                <div class="col-lg-4 col-md-5 col-sm-12 col-12 mx-auto productInfoMainSectionSecondColumn">
                    <div class="rightImage">
                        <img src="{{'data:image/webp;base64,' + viewModel.productMainImage }}" class="img-fluid"
                            alt="main-image">
                    </div>
                </div>

            </div>

        </div>

    </section> -->

    <section class="topSectionCards"  data-aos="fade-up" data-aos-duration="2000">

        <div class="container">

            <div class="row">

                <div class="col-lg-3 col-md-6">
                    <div class="item">
                        <div class="image">
                            <i class="bi bi-people-fill"></i>
                        </div>
                        <h4>Scheduler</h4>
                        <div class="imageText">

                        <p>
                            Inspection related events are generated.
                        </p>

                    </div>
                    </div>
                    
                </div>

                <div class="col-lg-3 col-md-6">
                    <div class="item">
                        <div class="image">
                            <i class="bi bi-gear-fill"></i>
                        </div>
                        <h4>Newsfeed</h4>
                        <div class="imageText">

                            <p>
                                News are displayed for users like App updates etc.
                            </p>
    
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6">
                    <div class="item">
                        <div class="image">
                            <i class="bi bi-chat-left-dots-fill"></i>
                        </div>
                        <h4>Licensing Info</h4>
                        <div class="imageText">

                            <p>
                               Displays license related info like issue date,expiry date etc.
                            </p>
    
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6">
                    <div class="item">
                        <div class="image">
                            <i class="bi bi-lightbulb-fill"></i>
                        </div>
                        <h4>Print preview</h4>
                        <div class="imageText">

                            <p>
                                Displays preview of report doc before printing it.
                            </p>
    
                        </div>
                    </div>
                </div>

            </div>
        </div>

    </section>

    <section class="productFeatures"
   >

        <div class="container">

            <div class="row">

                <div class="col-lg-12 col-md-12 col-sm-12 col-12 mx-auto productFeaturesFirstColumn" data-aos="fade-up" data-aos-duration="2000">

                    <div class="productFeaturesHeading d-flex justify-content-center align-items-center">
                        <h2> Product </h2>
                    </div>

                    <span>
                        An intuitive and user-friendly interface, designed to optimize productivity and ease of use. The
                        application's interface is structured logically, making it easy to navigate through different
                        functionalities and access the required features effortlessly
                    </span>

                </div>

                <div class="col-lg-5 col-md-12 col-sm-12 col-12 mx-auto productFeaturesSecondColumn" >

                    <div class="card p-0" data-aos="flip-left" data-aos-easing="ease-out-cubic" data-aos-duration="1000">
                        <img src="{{'data:image/webp;base64,' + viewModel.productImage1 }}" class="img-fluid"
                            alt="image" loading="lazy">
                    </div>

                    <div class="card" data-aos="flip-left" data-aos-easing="ease-out-cubic" data-aos-duration="2000">
                        <h4>
                            <span class="icon-circle">
                                <i class="bi bi-lightbulb "></i>
                            </span>
                            Report Creation
                        </h4>
                        <p>
                            With SpyGlass, generating professional and comprehensive inspection reports
                            is a breeze. Users can record inspection findings, add narratives and annotate photos for
                            visual documentation thus enhances the overall accuracy and clarity of the reports
                        </p>
                    </div>

                    <div class="card" data-aos="flip-left" data-aos-easing="ease-out-cubic" data-aos-duration="3000">
                        <h4>
                            <span class="icon-circle">
                                <i class="bi bi-clipboard-check"></i>
                            </span>
                            Scheduler
                        </h4>
                        <p>
                             The built-in scheduler allows users to efficiently manage their inspection
                            appointments and deadlines. Users can schedule inspections and receive notifications,
                            helping them stay organized and ensuring timely completion of inspections

                        </p>
                    </div>

                </div>

                <div class="col-lg-5 col-md-12 col-sm-12 col-12 mx-auto productFeaturesThirdColumn" >

                    <div class="card" data-aos="flip-right" data-aos-easing="ease-out-cubic" data-aos-duration="1000">
                        <h4>
                            <span class="icon-circle">
                                <i class="bi bi-building"></i>
                            </span>
                            Library Management
                        </h4>
                        <p>
                           A powerful library management system that allows users to create, import
                            and maintain the libraries of commonly used items and inspection checklists. With the
                            ability to add, edit, and categorize library content, users can easily access and
                            incorporate pre-defined elements into their inspection reports
                        </p>
                    </div>

                    <div class="card" data-aos="flip-right" data-aos-easing="ease-out-cubic" data-aos-duration="2000">
                        <h4>
                            <span class="icon-circle">
                                <i class="bi bi-code-slash"></i>
                            </span>
                            Print Report
                        </h4>
                        <p>
                             Users can conveniently print inspection reports in a customized manner,
                            whether a standard report, full or combined one. The print feature ensures that users can
                            generate reports for clients, internal records, or regulatory compliance purposes
                        </p>
                    </div>

                    <div class="card p-0" data-aos="flip-right" data-aos-easing="ease-out-cubic" data-aos-duration="3000">
                        <img src="{{'data:image/webp;base64,' + viewModel.productImage2 }}" class="img-fluid"
                            alt="image" loading="lazy">
                    </div>

                    <div class="card"data-aos="flip-right" data-aos-easing="ease-out-cubic" data-aos-duration="4000">
                        <h4>
                            <span class="icon-circle">
                                <i class="bi bi-gear-wide"></i>
                            </span>
                            Regular Updates and Support
                        </h4>
                        <p>
                            Our team is dedicated to continuously improving the application
                            based on user feedback and industry advancements. Regular updates ensure that users have
                            access to the latest features, enhancements, and bug fixes. The application also provides
                            comprehensive technical support to assist users in resolving any issues or inquiries
                        </p>
                    </div>

                </div>

            </div>

        </div>

    </section>

</div>