import { AppConstants } from "src/app-constants";
import { RoleTypeSM } from "../service-models/app/enums/role-type-s-m.enum";

export interface ToastInfo {
  className?: string;
  header?: string;
  body: string;
  delay: number;
  animation?: boolean;

}

export interface LoaderInfo {
  message: string;
  showLoader: boolean;
}

export interface ConfirmModalInfo {
  title: string;
  subTitle: string;
  message: string;
  showModal: boolean;
}

export interface SelectItem {
  key: number;
  value: string;
  isSelected: boolean;
}

export class LayoutViewModel {
  currentRoute: string = '';
  isAuthenticated: boolean = false;
  loginId:string='';
  roleType: RoleTypeSM = RoleTypeSM.Unknown;
  showInspectorLoginMenu: boolean = false;
  showOrganisationLoginMenu: boolean = false;
  showSuperAdminTopBar: boolean = false;
  showSystemAdminTopBar: boolean = false;
  showSystemAdminLeftMenu: boolean = false;
  showSuperAdminLeftMenu: boolean = false;
  showTopNav:boolean=false;
  showFooter: boolean = false;
  showLogin: boolean = false;
  showLogo: boolean = true;
  showProfile: boolean = false;
  showMenuBtn: boolean = false;
  inspectorProfilePicture: string = '';
  companyInspectorProfilePicture:string='';
  companyAdminProfilePicture: string = '';
  toggleWrapper = 'wrapper';
  toggleTopNav = 'homeTopNav';
  toggleContent = 'content';
  footer = 'footer';

  toggleSuperAdminWrapper = 'superAdminWrapper';
  superAdminSideMenu = 'defaultSuperAdminSideMenu';

  toggleUserProfileWrapper = 'userProfileWrapper';
  toggleUserProfileContent = 'userProfileContent';
  toggleUserProfileSideMenu = 'userProfileSideMenu';
  stickyMenu: boolean = false;
  elementPosition: any;
  inputFieldLabel = ''



  menuItems: { itemRoute: string, isActive: boolean,iconName: string, itemName: string }[] = [
    { itemRoute: AppConstants.WebRoutes.ADMIN.DASHBOARD, isActive: true, iconName: 'bi bi-speedometer2 fa-2x', itemName: 'Dashboard'},
    { itemRoute: AppConstants.WebRoutes.ADMIN.NEWSFEED, isActive: false, iconName: 'bi bi-newspaper fa-2x', itemName: 'News Feed' },
    { itemRoute: AppConstants.WebRoutes.ADMIN.FAQS, isActive: false, iconName: 'bi bi-question-square fa-2x', itemName: 'FAQs'},
    { itemRoute: AppConstants.WebRoutes.ADMIN.WebsiteResource, isActive: false, iconName: 'bi bi-images fa-2x', itemName: 'Website Resource' },
    { itemRoute: AppConstants.WebRoutes.ADMIN.ContactUs, isActive: false, iconName: 'bi bi-envelope fa-2x', itemName: 'Contact Us'},
    { itemRoute: AppConstants.WebRoutes.ADMIN.FeatureGroup, isActive: false, iconName: 'bi bi-card-checklist', itemName: 'Feature Group' },
    { itemRoute: AppConstants.WebRoutes.ADMIN.Features, isActive: false, iconName: 'bi bi-key', itemName: 'Features'},
    { itemRoute: AppConstants.WebRoutes.ADMIN.LicenseInfo, isActive: false, iconName: 'bi bi-layout-text-window-reverse', itemName: 'License Info' },
    { itemRoute: AppConstants.WebRoutes.ADMIN.Companies, isActive: false, iconName: 'bi bi-building', itemName: 'Companies'},
  ];


}




