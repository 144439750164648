import { SurveyBoxServiceModelBase } from '../../base/survey-box-service-model-base';
import { RoleTypeSM } from '../../enums/role-type-s-m.enum';
import { FeatureSM } from './feature-s-m';

export class FeatureGroupSM extends SurveyBoxServiceModelBase<number> {
    title!: string;
    description!: string;
    validityInDays!: number;
    amount!: number;
    featureGroupCode!: string;
    stripePriceId!: string;
    isPredefined!: boolean;
    validFor!: RoleTypeSM;
    featuresIds!: Array<number>;
    features!: Array<FeatureSM>;
}
