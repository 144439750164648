import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { LogHandlerService } from './services/log-handler.service';
import { DummyTeacherComponent } from './components/end-user/dummy-teacher/dummy-teacher.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbActiveModal, NgbModule, NgbToastModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastContainerComponent } from './internal-components/toast-container/toast-container.component';
import { SpinnerComponent } from './internal-components/spinner/spinner.component';
import { TopNavComponent } from './internal-components/top-nav/top-nav.component';
import { CommonModule } from '@angular/common';
import { LeftSideBarComponent } from './internal-components/left-side-bar/left-side-bar.component';
import { FooterComponent } from './internal-components/footer/footer.component';
import { AccountSettingComponent } from './components/end-user/account-setting/account-setting.component';
import { JwtHelperService, JWT_OPTIONS } from '@auth0/angular-jwt';
import { HomeComponent } from './components/end-user/home/home.component';
import { RegisterComponent } from './components/end-user/register/register.component';
import { ReportsComponent } from './components/end-user/reports/reports.component';
import { SamplePageComponent } from './components/end-user/sample-page/sample-page.component';
import { ProfileInfoComponent } from './child-components/profile-info/profile-info.component';
import { CompanyProfileComponent } from './child-components/company-profile/company-profile.component';
import { NewsFeedComponent } from './components/admin/news-feed/news-feed.component';
import { ManageInspectorsComponent } from './child-components/manage-inspectors/manage-inspectors.component';
import { AdminLoginComponent } from './components/admin/login/adminlogin.component';
import { AdminFreqAskedQuesComponent } from './components/admin/admin-freq-asked-ques/admin-freq-asked-ques.component';
import { UserProfileMenuComponent } from './components/end-user/user-profile-menu/user-profile-menu.component';
import { ContactUsComponent } from './components/admin/contact-us/contact-us.component';
import { FeatureGroupComponent } from './components/admin/feature-group/feature-group.component';
import { FeaturesComponent } from './components/admin/features/features.component';
import { LicenseInfoComponent } from './components/admin/license-info/license-info.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CompaniesComponent } from './components/admin/companies/companies.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { CompanyOverviewComponent } from './components/admin/company-overview/company-overview.component';
import { ProductInfoComponent } from './components/end-user/product-info/product-info.component';
import { FailedPaymentComponent } from './components/end-user/failed-payment/failed-payment.component';
import { SuccessPaymentComponent } from './components/end-user/success-payment/success-payment.component';
import { SubscriptionInfoComponent } from './child-components/subscription-info/subscription-info.component';
import { PricingInfoComponent } from './components/end-user/pricing-info/pricing-info.component';
import { PricingComponent } from './components/end-user/pricing/pricing.component';
import { AssignLicenseComponent } from './child-components/assign-license/assign-license.component';
import { ForgotpasswordComponent } from './internal-components/forgotpassword/forgotpassword.component';
import { HowToInstallComponent } from './components/end-user/how-to-install/how-to-install.component';
import { WebsiteResourceComponent } from './components/admin/website-resource/website-resource.component';
import { AdminDashboardComponent } from './components/admin/dashboard/admin-dashboard.component';
import { ResetPasswordComponent } from './components/end-user/reset-password/reset-password.component';
import { HttpClientModule } from '@angular/common/http';
import { DemoVideosComponent } from './components/end-user/demo-videos/demo-videos.component';





@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        SamplePageComponent,
        NotFoundComponent,
        DummyTeacherComponent,
        ToastContainerComponent,
        SpinnerComponent,
        TopNavComponent,
        RegisterComponent,
        AdminLoginComponent,
        LeftSideBarComponent,
        FooterComponent,
        PricingComponent,
        ReportsComponent,
        AccountSettingComponent,
        NewsFeedComponent,
        ProfileInfoComponent,
        CompanyProfileComponent,
        ManageInspectorsComponent,
        AdminFreqAskedQuesComponent,
        UserProfileMenuComponent,
        WebsiteResourceComponent,
        ContactUsComponent,
        FeatureGroupComponent,
        FeaturesComponent,
        LicenseInfoComponent,
        CompaniesComponent,
        CompanyOverviewComponent,
        ProductInfoComponent,
        PricingInfoComponent,
        FailedPaymentComponent,
        SuccessPaymentComponent,
        SubscriptionInfoComponent,
        AssignLicenseComponent,
        ForgotpasswordComponent,
        HowToInstallComponent,
        AdminDashboardComponent,
        ResetPasswordComponent,
        DemoVideosComponent,
      
      
    
    ],
    providers: [
        { provide: ErrorHandler, useClass: LogHandlerService },
        NgbActiveModal,
        { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
        JwtHelperService,
        // CommonService,
        // BaseService,
        // StorageService,
        // AccountService,
        // ContactUsService,
        // FrequentlyAskedQuestionService,
        // ManageInspectorsService,
        // NewsFeedService,
        // PricingService,
        // ProfileInfoService,
        // ReportsService,
        // TeacherService,
        // ThemeService,
        // WebsiteResourceService
    ],
    bootstrap: [AppComponent],
    exports: [],
    imports: [
        FormsModule,
        BrowserModule,
        HttpClientModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        NgbToastModule,
        CommonModule,
        ReactiveFormsModule,
        NgbModule,
        NgxSpinnerModule.forRoot({ type: 'ball-elastic-dots' }),
    ]
})
export class AppModule { }
