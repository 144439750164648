import { Injectable } from '@angular/core';
import { AppConstants } from 'src/app-constants';
import { ProfileInfoClient } from '../clients/profile-info.client';
import { ClientUserSM } from '../service-models/app/v1/app-users/client-user-s-m';
import { ApiRequest } from '../service-models/foundation/api-contracts/base/api-request';
import { ApiResponse } from '../service-models/foundation/api-contracts/base/api-response';
import { DeleteResponseRoot } from '../service-models/foundation/common-response/delete-response-root';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class ProfileInfoService extends BaseService {



  constructor(private profileClient: ProfileInfoClient) {
    super();

  }
 async getInspectorProfile(): Promise<ApiResponse<ClientUserSM>> {
    return await this.profileClient.GetInspectorProfile();
  }
 async updateInspector(inspector: ClientUserSM): Promise<ApiResponse<ClientUserSM>> {
    if (inspector == null) {
      throw new Error(AppConstants.ErrorPrompts.Invalid_Input_Data);
    }
    else {
      let apiRequest = new ApiRequest<ClientUserSM>();
      apiRequest.reqData = inspector;
      return await this.profileClient.UpdateInspector(apiRequest);
    }
 }
 async uploadProfilePicture(companyProfilePictureRequest: string): Promise<ApiResponse<string>> {
  if (companyProfilePictureRequest == null) {
    throw new Error(AppConstants.ErrorPrompts.Invalid_Input_Data);
  }
  else {
    let apiRequest = new ApiRequest<string>();
    apiRequest.reqData = companyProfilePictureRequest;
    return await this.profileClient.UplodPicture(apiRequest);
  }
}

async deleteProfilePicture(id:number): Promise<ApiResponse<DeleteResponseRoot>> {
  
  return await this.profileClient.DeletePicture(id);
}

}