
import { FrequentlyAskedQuestionsSM } from "src/app/service-models/app/v1/general/frequently-asked-questions-s-m";
import { BaseViewModel } from "../base.viewmodel";
export class AdminFreqAskedQuesViewModel implements BaseViewModel {
  PageTitle: string = 'Frequently Asked Questions';
  AddEditModalTitle: string = '';
  PageNo: number = 1;
  PageSize: number = 10;
  totalCount!:number;
  faqs: FrequentlyAskedQuestionsSM[] = [];
  singleFaq: FrequentlyAskedQuestionsSM = new FrequentlyAskedQuestionsSM();
  showSaveBtn: boolean = false;
  FormSubmitted: boolean = false;
  ValidationData = {
    question: [
      { type: 'required', message: 'Login Id is required' },
 
    ],
    answer: [
      { type: 'required', message: 'Answer is required' },
      
    ],
   
    roleType: [
      { type: 'required', message: 'select one' }

    ]
  }
  // questionType!: string;
  // questionTypes: string[] = [];
  
}