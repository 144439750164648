import { Injectable } from '@angular/core';
import { AppConstants } from 'src/app-constants';
import { ContactUsClient } from '../clients/contact-us.client';
import { ContactUsSM } from '../service-models/app/v1/general/contact-us-s-m';
import { ApiRequest } from '../service-models/foundation/api-contracts/base/api-request';
import { ApiResponse } from '../service-models/foundation/api-contracts/base/api-response';
import { OdataQueryFilter } from '../service-models/foundation/api-contracts/odata-query-filter';
import { IntResponseRoot } from '../service-models/foundation/common-response/int-response-root';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class ContactUsService extends BaseService {

  constructor(private contactUsClient: ContactUsClient) {
    super();
  }

  /**get all contac us data data  request to client */

  async getAllContacts(): Promise<ApiResponse<ContactUsSM[]>> {
    return await this.contactUsClient.GetAllContacts();
  }
  
  async getAllContactsbyOdata(queryFilter:OdataQueryFilter):Promise<ApiResponse<ContactUsSM[]>>{
    return await this.contactUsClient.GetAllContactsyOdata(queryFilter);

  }
  async getAllContactsCount(): Promise<ApiResponse<IntResponseRoot>> {
    return await this.contactUsClient.GetAllContactsCount()
  }


  async Send_ContactUS(contact: ContactUsSM): Promise<ApiResponse<ContactUsSM>> {
    if (contact == null) {
      throw new Error(AppConstants.ErrorPrompts.Invalid_Input_Data);
    }
    else {
      let apiRequest = new ApiRequest<ContactUsSM>();
      apiRequest.reqData = contact;
      return await this.contactUsClient.AddContactUS(apiRequest);
    }

  }


}

