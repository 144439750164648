<div class="container-fluid">
    <div class="table-title">
            <label > Total No. of  Contact's: {{viewModel.totalCount}}</label>

        <table class="table mt-2">
            <thead>
                <tr class="text-center">
                    <th class="firstTableHead">Name</th>
                    <th >Email-ID</th>
                    <th>Message</th>
                    <th class="lastTableHead">Location</th>     
                </tr>
            </thead>
            <tbody>
                <tr class="h5 text-center" *ngFor="let item of viewModel.contactList">
                    <td class="align-middle">{{item.name}}</td>
                    <td class="align-middle"><a href="{{'mailto:'+item.emailId}}">{{item.emailId}}</a></td>
                    <td class="align-middle ">{{item.message}}</td>
                    <td class="align-middle">{{item.location}}</td>
                   
                </tr>
            </tbody>
        </table>


        <div id="pagination" class="col-lg-12 col-md-12 col-sm-12 col-12  d-flex justify-content-center fixed-bottom">
            <ngb-pagination [collectionSize]="viewModel.totalCount" [(page)]="viewModel.PageNo"
                [pageSize]="viewModel.PageSize" (pageChange)="loadPagedataWithPagination($event)"></ngb-pagination>
        </div>

        
    </div>

