import { Injectable } from '@angular/core';
import { PaymentClient } from '../clients/payment.client';
import { CheckoutSessionRequest } from '../service-models/app/v1/license/checkout-session-request';
import { CheckoutSessionResponse } from '../service-models/app/v1/license/checkout-session-response';
import { ApiRequest } from '../service-models/foundation/api-contracts/base/api-request';
import { ApiResponse } from '../service-models/foundation/api-contracts/base/api-response';


import { BaseService } from './base.service';
import { CustomerPortalResponse } from '../service-models/app/v1/license/customer-portal-response';
import { CustomerPortalRequest } from '../service-models/app/v1/license/customer-portal-request';
import { SubscriptionUpgradeResponseSM } from '../service-models/app/v1/license/subscription-upgrade-response-s-m';
import { SubscriptionUpgradeRequestSM } from '../service-models/app/v1/license/subscription-upgrade-request-s-m';
import { AppConstants } from 'src/app-constants';
import { UserInvoiceSM } from '../service-models/app/v1/license/user-invoice-s-m';
@Injectable({
  providedIn: 'root',
})
export class PaymentService extends BaseService {


  constructor(private paymentClient: PaymentClient) {
    super();
  }


  GenerateCheckoutSessionDetails(request: CheckoutSessionRequest): Promise<ApiResponse<CheckoutSessionResponse>> {
    let apiReq = new ApiRequest<CheckoutSessionRequest>();
    apiReq.reqData = request;
    return this.paymentClient.GenerateCheckoutSessionMine(apiReq);
  }

  ManageSubscription(returnUrl: string): Promise<ApiResponse<CustomerPortalResponse>> {
    let apiReq = new ApiRequest<CustomerPortalRequest>();
    apiReq.reqData = { returnUrl };
    return this.paymentClient.ManageSubscription(apiReq);
  }

  UpgradeSubscription(request: SubscriptionUpgradeRequestSM): Promise<ApiResponse<SubscriptionUpgradeResponseSM>> {
    let apiReq = new ApiRequest<SubscriptionUpgradeRequestSM>();
    apiReq.reqData = request;
    return this.paymentClient.UpgradeSubscription(apiReq);
  }
  UpgradeSubscriptionInfo(request: SubscriptionUpgradeRequestSM): Promise<ApiResponse<SubscriptionUpgradeResponseSM>> {
    let apiReq = new ApiRequest<SubscriptionUpgradeRequestSM>();
    apiReq.reqData = request;
    return this.paymentClient.UpgradeSubscriptionInfo(apiReq);
  }

 
  async getInvoiceReportsDocumentById(id: string): Promise<ApiResponse<string>> {
    if (id == null) {
      throw new Error(AppConstants.ErrorPrompts.Invalid_Input_Data);
    }
    else {
      let apiRequest = new ApiRequest<string>();
      apiRequest.reqData = id;
      return await this.paymentClient.GetInvoiceReportDocumentById(apiRequest);
    }
  }
  async getAllInvoices(): Promise<ApiResponse<UserInvoiceSM[]>> {
    return await this.paymentClient.GetAllInvoices();
  }

}
