import { Injectable } from '@angular/core';
import { AppConstants } from 'src/app-constants';
import { CompanyOverviewClient } from '../clients/company-overview.client';
import { LoginStatusSM } from '../service-models/app/enums/login-status-s-m.enum';
import { ClientUserSM } from '../service-models/app/v1/app-users/client-user-s-m'
import { ClientCompanyDetailSM } from '../service-models/app/v1/client/client-company-detail-s-m';
import { UserLicenseDetailsSM } from '../service-models/app/v1/license/user-license-details-s-m';

import { ApiRequest } from '../service-models/foundation/api-contracts/base/api-request';
import { ApiResponse } from '../service-models/foundation/api-contracts/base/api-response';
import { OdataQueryFilter } from '../service-models/foundation/api-contracts/odata-query-filter';
import { BoolResponseRoot } from '../service-models/foundation/common-response/bool-response-root';
import { IntResponseRoot } from '../service-models/foundation/common-response/int-response-root';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class CompanyOverviewService extends BaseService {

  constructor(private companyOverviewClient: CompanyOverviewClient) {
    super();
  }
  

  async getAllUsersOfCompanyById(id: number): Promise<ApiResponse<ClientUserSM[]>> {
    if (id <= 0) {
      throw new Error(AppConstants.ErrorPrompts.Delete_Data_Error)
    }
    return await this.companyOverviewClient.GetAllUsersOfCompany(id);
  }
  
  async getAllCompaniesByOdata(id: number,queryFilter: OdataQueryFilter): Promise<ApiResponse<ClientUserSM[]>> {
    return await this.companyOverviewClient.GetAllUsersByOdata(id,queryFilter);
  }

  async getCompanyProfile(id: number): Promise<ApiResponse<ClientCompanyDetailSM>> {
    if (id <= 0) {
      throw new Error(AppConstants.ErrorPrompts.Delete_Data_Error)
    }
    return await this.companyOverviewClient.GetCompanyProfile(id);
  }

  async getCompanyLicenses(id:number):Promise<ApiResponse<UserLicenseDetailsSM[]>>{
    if(id<=0){
      throw new Error(AppConstants.ErrorPrompts.Delete_Data_Error)
    }
    return await this.companyOverviewClient.GetCompanyLicenses(id);

  }
async updateLoginStatus(status: ClientUserSM): Promise<ApiResponse<BoolResponseRoot>> {
    if (status == null) {
      throw new Error(AppConstants.ErrorPrompts.Invalid_Input_Data);
    }
    else {
      let apiRequest = new ApiRequest<ClientUserSM>();
      apiRequest.reqData = status;
      return await this.companyOverviewClient.updateLoginStatus(apiRequest);
    }
 }

 async getUsersCount(id:number):Promise<ApiResponse<IntResponseRoot>>{
  return await this.companyOverviewClient.GetAllUsersCount(id)
}


 
}