
import { BaseViewModel } from "./base.viewmodel";
export class TopNavViewModel implements BaseViewModel {
   PageTitle: string = 'Top Nav';
   showLoginStuff: boolean = false;
   showLogOutStuff: boolean = false;
   // elementPosition: any;
   // stickyMenu: boolean = false;
   // login:boolean=true;
   // logout:boolean=false;
   // editProfile:boolean=false;
   // profilepicture:boolean=false;
   // userIcon:boolean=true;
   }
