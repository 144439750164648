<div class="container-fluid">
    <div class="row">
        <button (click)="click_OpenAddEditModal(addEditModal,0)">Add Teacher</button>
    </div>
    <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12">
            <!-- Table to get all Data -->
            <table class="table table-striped">
                <thead>
                    <tr>
                        <th scope="col">Question</th>
                        <th scope="col">Answer</th>
                        <th scope="col" class='collapsable'>Question Type</th>
                        <th colspan="2" scope="col">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="h5 fw-normal  text-center" *ngFor="let teacher of viewModel.teachers">
                        <!-- <td>{{data.id}}</td> -->
                        <td>{{teacher.firstName}}</td>
                        <td>{{teacher.lastName}}</td>
                        <td>{{teacher.emailAddress}}</td>
                        <!-- <td>
                            <bi name="pencil" class="smallIcon"
                                (click)="click_OpenAddEditModal(addEditModal,teacher.id)"> </bi>
                        </td>
                        <td>
                            <bi name="trash" class="smallIcon" (click)="deleteTeacher(teacher.id)"></bi>
                        </td> -->
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>

<ng-template #addEditModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title">{{viewModel.AddEditModalTitle}}</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
        <div class="mb-3">
            <label class="form-label">First Name</label>
            <input type="text" class="form-control" placeholder="First Name"
                [(ngModel)]="viewModel.singleTeacher.firstName" required="title">
        </div>
        <div class="mb-3">
            <label class="form-label">Last Name</label>
            <input type="text" class="form-control" placeholder="Last Name"
                [(ngModel)]="viewModel.singleTeacher.lastName" required="title">
        </div>
        <div class="mb-3">
            <label class="form-label">Email</label>
            <input type="text" class="form-control" placeholder="Email"
                [(ngModel)]="viewModel.singleTeacher.emailAddress" required="title">
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="modal.close()">Save</button>
    </div>
</ng-template>