import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { AppConstants } from 'src/app-constants';
import { CommonService } from 'src/app/services/common.service';
import { FeatureGroupService } from 'src/app/services/feature-group.service';
import { LogHandlerService } from 'src/app/services/log-handler.service';
import { FailedPaymentViewModel } from 'src/app/view-models/failed-payment.viewmodel';
import { PaymentStatusInfo } from 'src/app/view-models/success-payment.viewmodel';
import { BaseComponent } from '../../base.component';

@Component({
  selector: 'app-failed-payment',
  templateUrl: './failed-payment.component.html',
  styleUrls: ['./failed-payment.component.scss']
})
export class FailedPaymentComponent extends BaseComponent<FailedPaymentViewModel> implements OnInit {
  constructor(commonService: CommonService,
    logService: LogHandlerService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private featureGroupService: FeatureGroupService,
  ) {
    super(commonService, logService);
    this.viewModel = new FailedPaymentViewModel();
    this._commonService.layoutViewModel.toggleWrapper = 'homeWrapper';
    this._commonService.layoutViewModel.showFooter = false;
    this._commonService.layoutViewModel.showTopNav=true;
  }
  async ngOnInit() {
   let info = this.activatedRoute.snapshot.paramMap.get("info");
    if (info == undefined || info == null) {
      this.router.navigate([AppConstants.WebRoutes.UNAUTHORIZED]);
    } else {
      try {
        let details: PaymentStatusInfo = JSON.parse(this._commonService.decrypt(atob(info)));
        if (details == null)
          this.router.navigate([AppConstants.WebRoutes.UNAUTHORIZED]);
        this.viewModel.paymentInfo = details;
        this.loadPageData();
      } catch (error) {
        this._exceptionHandler.logObject(error);
      }
    }
   
  }
  override async loadPageData(): Promise<void> {
    try {
      await this._commonService.presentLoading();
      let timeDifference = moment().diff(this.viewModel.paymentInfo.paymentDate, 'minute');
      if (timeDifference > 10) {
        //problem
      }
      let resp = await this.featureGroupService.getFeatureGroupById(this.viewModel.paymentInfo.fgId);
      if (resp.isError) {
        await this._exceptionHandler.logObject(resp.errorData);
        this._commonService.showSweetAlertToast({ title: resp.errorData.displayMessage, icon: 'error' });
        return;
      } else {
        this.viewModel.featureGroup = resp.successData;
      }
    }
    catch (error) {
      throw error;
    } finally {
      await this._commonService.dismissLoader();
    }
  }

  async tryAgain(){
    try {
      this._commonService.presentLoading();
      let resp = await this.featureGroupService.getFeatureGroupByIdExtended(this.viewModel.featureGroup.id);
      if (resp.isError) {
        await this._exceptionHandler.logObject(resp.errorData);
        this._commonService.showSweetAlertToast({ title: resp.errorData.displayMessage, icon: 'error' });
        return;
      } else {
        this.viewModel.featureGroup = resp.successData;
      }
    } catch (error) {
      throw error;
    }
    finally {
      await this._commonService.dismissLoader();
   
    }
  }

}

