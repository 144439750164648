import { SurveyBoxServiceModelBase } from '../../base/survey-box-service-model-base';
import { ContactUsMessageLocationSM } from '../../enums/contact-us-message-location-s-m.enum';
export class ContactUsSM extends SurveyBoxServiceModelBase<number> {
    name!: string;
    subject!: string;
    message!: string;
    emailId!: string;
    phone!: string;
    status!: boolean;
    location!: ContactUsMessageLocationSM;
}
