import { Injectable } from '@angular/core';
import { AppConstants } from 'src/app-constants';
import { CompanyProfileClient } from '../clients/company-profile.client';
import { ClientCompanyAddressSM } from '../service-models/app/v1/client/client-company-address-s-m';
import { ClientCompanyDetailSM } from '../service-models/app/v1/client/client-company-detail-s-m';
import { ApiRequest } from '../service-models/foundation/api-contracts/base/api-request';
import { ApiResponse } from '../service-models/foundation/api-contracts/base/api-response';
import { DeleteResponseRoot } from '../service-models/foundation/common-response/delete-response-root';
import { BaseService } from './base.service';


@Injectable({
  providedIn: 'root'
})


export class CompanyProfileService extends BaseService {

  constructor(private companyProfileClient: CompanyProfileClient) {
    super();
  }


  /**get company profile */
  async getCompanyProfile(): Promise<ApiResponse<ClientCompanyDetailSM>> {
    return await this.companyProfileClient.GetCompanyProfile();
  }


  /**get company Address */
  async getCompanyAddress(): Promise<ApiResponse<ClientCompanyAddressSM>> {
    return await this.companyProfileClient.GetCompanyAddress();
  }


  /**Add company address */
  async addCompanyAddress(companyAddressRequest: ClientCompanyAddressSM): Promise<ApiResponse<ClientCompanyAddressSM>> {
    if (companyAddressRequest == null) {
      throw new Error(AppConstants.ErrorPrompts.Invalid_Input_Data);
    }
    else {
      let apiRequest = new ApiRequest<ClientCompanyAddressSM>();
      apiRequest.reqData = companyAddressRequest;
      return await this.companyProfileClient.AddCompanyAddress(apiRequest);
    }
  }


  /**update company address */
  async updateCompanyAddress(company: ClientCompanyAddressSM): Promise<ApiResponse<ClientCompanyAddressSM>> {
    if (company == null) {
      throw new Error(AppConstants.ErrorPrompts.Invalid_Input_Data);
    }
    else {
      let apiRequest = new ApiRequest<ClientCompanyAddressSM>();
      apiRequest.reqData = company;
      return await this.companyProfileClient.UpdateCompanyAddress(apiRequest);
    }
  }

  async uploadProfilePictureOfComapny(companyProfilePictureRequest: string): Promise<ApiResponse<string>> {
    if (companyProfilePictureRequest == null) {
      throw new Error(AppConstants.ErrorPrompts.Invalid_Input_Data);
    }
    else {
      let apiRequest = new ApiRequest<string>();
      apiRequest.reqData = companyProfilePictureRequest;
      return await this.companyProfileClient.UplodPictureForCompany(apiRequest);
    }
  }

  async deleteProfilePicture(): Promise<ApiResponse<DeleteResponseRoot>> {
    
    return await this.companyProfileClient.DeletePictureForCompany();
  }
}