<div class="container-fluid">

    <div class="row companyRegisterSection">

        <div class="col-lg-3 col-md-3 d-lg-block d-md-block d-sm-none d-none">

            <div class="overlay-container">
                <div class="overlay-panel">
                    <h2> Hello <br> Friend! </h2>
                    <p> Enter your personal details and start journey with us </p>
                </div>
            </div>

        </div>


        <div class="col-lg-9 col-md-9 col-sm-12 col-12 pb-5 registerFormColumn">

            <button type="button" name="close-modal" class="closeModal" (click)="closeModal()">
                <span> <i class="bi bi-x-lg"></i> </span>
            </button>

            <h1> Create Account with us </h1>

            <form #form_RegisterCompany="ngForm" ngbAutofocus>

                <div class="row">

                    <div class="col-lg-6 col-md-6">

                        <input type="text" class="form-control" placeholder="First Name"
                            [(ngModel)]="viewModel.userDetails.firstName" #firstName="ngModel" required name="firstName"
                            minlength="3" maxlength="20"pattern="^[a-zA-Z]+$" />

                        <ng-container *ngFor="let validation of viewModel.registerValidationData.firstName">
                            <div class="text-danger ms-lg-3 ms-md-3 ms-sm-3 ms-3"
                                *ngIf="(firstName.touched || viewModel.FormSubmitted) && firstName.hasError(validation.type)">
                                {{ validation.message }}
                            </div>
                        </ng-container>

                    </div>

                    <div class="col-lg-6 col-md-6">

                        <input type="text" class="form-control" placeholder="Last Name"
                            [(ngModel)]="viewModel.userDetails.lastName" #lastName="ngModel" required name="lastName"
                            minlength="3" maxlength="20" pattern="^[a-zA-Z]+$" />

                        <ng-container *ngFor="let validation of viewModel.registerValidationData.lastName">
                            <div class="text-danger ms-lg-3 ms-md-3 ms-sm-3 ms-3"
                                *ngIf="(lastName.touched||viewModel.FormSubmitted) && lastName.hasError(validation.type)">
                                {{ validation.message }}
                            </div>
                        </ng-container>

                    </div>

                    <div class="col-lg-6 col-md-6">

                        <input type="number" class="form-control" placeholder="Your Phone Number"
                            [(ngModel)]="viewModel.userDetails.phoneNumber" #userphoneNumber="ngModel" required
                            maxlength="10" name="phoneNumber" />

                        <ng-container *ngFor="let validation of viewModel.registerValidationData.userphoneNumber">
                            <div class="text-danger ms-lg-3 ms-md-3 ms-sm-3 ms-3"
                                *ngIf="(userphoneNumber.touched||viewModel.FormSubmitted) && userphoneNumber.hasError(validation.type)">
                                {{ validation.message }}
                            </div>
                        </ng-container>

                    </div>

                    <div class="col-lg-6 col-md-6">

                        <input type="email" class="form-control" placeholder="Your Email Id"
                            [(ngModel)]="viewModel.userDetails.emailId" #userEmailId="ngModel" required name="emailId"
                            pattern="^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$" />

                        <ng-container *ngFor="let validation of viewModel.registerValidationData.userEmailId">
                            <div class="text-danger ms-lg-3 ms-md-3 ms-sm-3 ms-3"
                                *ngIf="(userEmailId.touched||viewModel.FormSubmitted) &&  userEmailId.hasError(validation.type)">
                                {{ validation.message }}
                            </div>
                        </ng-container>

                    </div>


                    <div class="col-lg-6 col-md-6">

                        <input type="text" class="form-control" placeholder="Enter login Id"
                            [(ngModel)]="viewModel.userDetails.loginId" #loginId="ngModel" required name="loginId"
                            (ngModelChange)="change_LoginId($event)" />

                        <ng-container *ngFor="let validation of viewModel.registerValidationData.loginId">
                            <div class="text-danger ms-lg-3 ms-md-3 ms-sm-3 ms-3"
                                *ngIf="(loginId.touched||viewModel.FormSubmitted) && loginId.hasError(validation.type)">
                                {{ validation.message }}
                            </div>
                        </ng-container>

                        <ng-container *ngIf="viewModel.showSuccessMsg">
                            <div class="text-success ms-lg-3 ms-md-3 ms-sm-3 ms-3">
                                {{viewModel.validUser.responseMessage}}
                            </div>
                        </ng-container>

                        <ng-container *ngIf="viewModel.showErrorMsg">
                            <div class="text-danger ms-lg-3 ms-md-3 ms-sm-3 ms-3">
                                {{viewModel.validUser.responseMessage}}
                            </div>
                        </ng-container>

                    </div>


                    <div class="col-lg-6 col-md-6">

                        <div class="password-container">

                            <input [type]="viewModel.hidePassword ? 'password' : 'text'" class="form-control"
                                placeholder="Enter Password" [(ngModel)]="viewModel.userDetails.passwordHash"
                                #PasswordHash="ngModel" required name="passwordHash" />

                            <i *ngIf="viewModel.hidePassword" class="bi bi-eye-fill" (click)="click_TogglePassword()">
                            </i>

                            <i *ngIf="!viewModel.hidePassword" class="bi bi-eye-slash-fill"
                                (click)="click_TogglePassword()">
                            </i>

                        </div>

                        <ng-container *ngFor="let validation of viewModel.registerValidationData.PasswordHash">
                            <div class="text-danger ms-lg-3 ms-md-3 ms-sm-3 ms-3"
                                *ngIf="(PasswordHash.touched||viewModel.FormSubmitted) && PasswordHash.hasError(validation.type)">
                                {{ validation.message }}
                            </div>
                        </ng-container>

                    </div>

                    <div class="col-lg-6 col-md-6">

                        <input type="text" class="form-control" placeholder="Company Name"
                            [(ngModel)]="viewModel.companyDetails.name" #CompanyName="ngModel" required
                            name="CompanyName" />

                        <ng-container *ngFor="let validation of viewModel.registerValidationData.CompanyName">
                            <div class="text-danger ms-lg-2 ms-md-2 ms-sm-2 ms-2"
                                *ngIf="(CompanyName.touched || viewModel.FormSubmitted)&& CompanyName.hasError(validation.type)">
                                {{validation.message}}
                            </div>
                        </ng-container>

                    </div>

                    <div class="col-lg-6 col-md-6">

                        <input type="email" class="form-control" placeholder="Company Email Id"
                            [(ngModel)]="viewModel.companyDetails.contactEmail" #companyEmailId="ngModel" required
                            pattern="^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$"
                            name="companyEmailId" />

                        <ng-container *ngFor="let validation of viewModel.registerValidationData.companyEmailId">
                            <div class="text-danger ms-lg-2 ms-md-2 ms-sm-2 ms-2"
                                *ngIf="(companyEmailId.touched || viewModel.FormSubmitted)&& companyEmailId.hasError(validation.type)">
                                {{validation.message}}
                            </div>
                        </ng-container>

                    </div>

                    <div class="col-lg-6 col-md-6">

                        <input type="text" class="form-control" placeholder=" Company Website"
                            [(ngModel)]="viewModel.companyDetails.companyWebsite" #CompanyWebsite="ngModel" required
                            name="CompanyWebsite" />

                        <ng-container *ngFor="let validation of viewModel.registerValidationData.CompanyWebsite">
                            <div class="text-danger ms-lg-2 ms-md-2 ms-sm-2 ms-2"
                                *ngIf="(CompanyWebsite.touched || viewModel.FormSubmitted)&& CompanyWebsite.hasError(validation.type)">
                                {{validation.message}}

                            </div>
                        </ng-container>

                    </div>

                    <div class="col-lg-6 col-md-6">

                        <input type="number" class="form-control" placeholder="Company Phone Number"
                            [(ngModel)]="viewModel.companyDetails.companyMobileNumber" #companyphoneNumber="ngModel"
                            required maxlength="10" name="companyphoneNumber" />

                        <ng-container *ngFor="let validation of viewModel.registerValidationData.companyphoneNumber">
                            <div class="text-danger ms-lg-2 ms-md-2 ms-sm-2 ms-2"
                                *ngIf="(companyphoneNumber.touched || viewModel.FormSubmitted)&& companyphoneNumber.hasError(validation.type)">
                                {{validation.message}}
                            </div>
                        </ng-container>

                    </div>

                    <div class="text-center">

                        <button type="submit" name="sign-up-button" class="spyGlassBtn"
                            (click)="registerCompany(form_RegisterCompany)"
                            (window:keyup.enter)="registerCompany(form_RegisterCompany)"> Sign Up
                        </button>

                        <br>

                        <span> Already a User! <span (click)="click_showLoginModal()" class="signInLink"> Sign In
                            </span>
                        </span>

                    </div>

                </div>

            </form>

        </div>

    </div>

</div>