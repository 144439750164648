import { BaseViewModel } from "./base.viewmodel";

export class howToInstallViewModel implements BaseViewModel {
    PageTitle: string = 'How to Install';

    howToInstallMainImage: string = '';
    howToInstallImage1: string = '';
    howToInstallImage2: string = '';
    howToInstallImage3: string = '';
    howToInstallImage4: string = '';
}