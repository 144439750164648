import { Injectable } from "@angular/core";
import { AppConstants } from "src/app-constants";
import { AdditionalRequestDetails, Authentication } from "../internal-models/additional-request-details";
import { WebsiteResourceSM } from "../service-models/app/v1/general/website-resource-s-m";
import { ApiRequest } from "../service-models/foundation/api-contracts/base/api-request";
import { ApiResponse } from "../service-models/foundation/api-contracts/base/api-response";
import { OdataQueryFilter } from "../service-models/foundation/api-contracts/odata-query-filter";
import { BoolResponseRoot } from "../service-models/foundation/common-response/bool-response-root";
import { DeleteResponseRoot } from "../service-models/foundation/common-response/delete-response-root";
import { IntResponseRoot } from "../service-models/foundation/common-response/int-response-root";
import { StorageService } from "../services/storage.service";
import { BaseApiClient } from "./base-client/base-api.client";
import { CommonResponseCodeHandler } from "./helpers/common-response-code-handler.helper";
import { StorageCache } from "./helpers/storage-cache.helper";


@Injectable({
    providedIn: 'root'
})


export class WebsiteResourceClient extends BaseApiClient {

    constructor(storageService: StorageService, storageCache: StorageCache,
        commonResponseCodeHandler: CommonResponseCodeHandler) {
        super(storageService, storageCache, commonResponseCodeHandler)
    }


    /**Get WebsiteResource based on odata query */
    GetAllWebsiteResourceByOdata = async (queryFilter: OdataQueryFilter): Promise<ApiResponse<WebsiteResourceSM[]>> => {
        let finalUrl = this.ApplyQueryFilterToUrl(`${AppConstants.ApiUrls.WEBSITE_RESOURCE_URL}/odata`, queryFilter);
        let resp = await this.GetResponseAsync<null, WebsiteResourceSM[]>(finalUrl, 'GET')
        return resp;
    }


    /** Get Website Resource Home Images */
    GetWebsiteResourceHomeImages = async (): Promise<ApiResponse<WebsiteResourceSM[]>> => {
        let resp = await this.GetResponseAsync<null, WebsiteResourceSM[]>
            (`${AppConstants.ApiUrls.WEBSITE_RESOURCE_URL}/WebsiteHomeImage`, 'GET', null,
                new AdditionalRequestDetails<WebsiteResourceSM[]>(false, Authentication.false));
        return resp;
    }


    /** Get Website Resource Product Images */
    GetWebsiteResourceProductImages = async (): Promise<ApiResponse<WebsiteResourceSM[]>> => {
        let resp = await this.GetResponseAsync<null, WebsiteResourceSM[]>
            (`${AppConstants.ApiUrls.WEBSITE_RESOURCE_URL}/ProductImages`, 'GET', null,
                new AdditionalRequestDetails<WebsiteResourceSM[]>(false, Authentication.false));
        return resp;
    }

    /** Get Website Resource Sample Report Images */
    GetWebsiteResourceSampleReportImages = async (): Promise<ApiResponse<WebsiteResourceSM[]>> => {
        let resp = await this.GetResponseAsync<null, WebsiteResourceSM[]>
            (`${AppConstants.ApiUrls.WEBSITE_RESOURCE_URL}/SampleReportImages`, 'GET', null,
                new AdditionalRequestDetails<WebsiteResourceSM[]>(false, Authentication.false));
        return resp;
    }

    /** Get Website Resource How-to-Install Images */
    GetWebsiteResourceHowToInstallImages = async (): Promise<ApiResponse<WebsiteResourceSM[]>> => {
        let resp = await this.GetResponseAsync<null, WebsiteResourceSM[]>
            (`${AppConstants.ApiUrls.WEBSITE_RESOURCE_URL}/HowToInstallImages`, 'GET', null,
                new AdditionalRequestDetails<WebsiteResourceSM[]>(false, Authentication.false));
        return resp;
    }


    /** Get Website Resource Product Card Images */
    // GetWebsiteResourceProductCardImages = async (): Promise<ApiResponse<WebsiteResourceSM[]>> => {
    //     let resp = await this.GetResponseAsync<null, WebsiteResourceSM[]>
    //         (`${AppConstants.ApiUrls.WEBSITE_RESOURCE_URL}/ProductCardImages`, 'GET', null,
    //             new AdditionalRequestDetails<WebsiteResourceSM[]>(false, Authentication.false));
    //     return resp;
    // }


    /**Get WebsiteResource by id */
    GetWebsiteResourceById = async (Id: number): Promise<ApiResponse<WebsiteResourceSM>> => {
        let resp = await this.GetResponseAsync<number, WebsiteResourceSM>
            (`${AppConstants.ApiUrls.WEBSITE_RESOURCE_URL}/${Id}`, 'GET', null,
                new AdditionalRequestDetails<WebsiteResourceSM>(false, Authentication.false));
        return resp;
    }


    /**Add a new WebsiteResource */
    AddWebsiteResource = async (addWebsiteResourceRequest: ApiRequest<WebsiteResourceSM>): Promise<ApiResponse<WebsiteResourceSM>> => {
        let resp = await this.GetResponseAsync<WebsiteResourceSM, WebsiteResourceSM>
            (AppConstants.ApiUrls.WEBSITE_RESOURCE_URL, 'POST', addWebsiteResourceRequest);
        return resp;
    }


    /**Update WebsiteResource*/
    UpdateWebsiteResource = async (updateWebsiteResourceRequest: ApiRequest<WebsiteResourceSM>): Promise<ApiResponse<WebsiteResourceSM>> => {
        let resp = await this.GetResponseAsync<WebsiteResourceSM, WebsiteResourceSM>
            (`${AppConstants.ApiUrls.WEBSITE_RESOURCE_URL}/${updateWebsiteResourceRequest.reqData.id}`, 'PUT', updateWebsiteResourceRequest);
        return resp;
    }


    /**delete WebsiteResource by id */
    DeleteWebsiteResourceById = async (Id: number): Promise<ApiResponse<DeleteResponseRoot>> => {
        let resp = await this.GetResponseAsync<number, DeleteResponseRoot>
            (`${AppConstants.ApiUrls.WEBSITE_RESOURCE_URL}/${Id}`, 'DELETE');
        return resp;
    }


    getWebsiteResourceCount = async (): Promise<ApiResponse<IntResponseRoot>> => {
        let resp = await this.GetResponseAsync<null, IntResponseRoot>
            (`${AppConstants.ApiUrls.WEBSITE_RESOURCE_URL}/WebsiteResourceCountResponse`, 'GET')
        return resp;
    }





    /** Get Sample Reports Partial Data */
    GetSampleReportsPartialData = async (): Promise<ApiResponse<WebsiteResourceSM[]>> => {
        let resp = await this.GetResponseAsync<null, WebsiteResourceSM[]>
            (`${AppConstants.ApiUrls.WEBSITE_RESOURCE_URL}/SampleReports/PartialData`, 'GET', null,
                new AdditionalRequestDetails<WebsiteResourceSM[]>(false, Authentication.false))
        return resp;
    }


    /** Get Sample Reports Document By Id */
    GetSampleReportsDocumentById = async (id: number): Promise<ApiResponse<string>> => {
        let resp = await this.GetResponseAsync<number, string>
            (`${AppConstants.ApiUrls.WEBSITE_RESOURCE_URL}/SampleReports/${id}`, 'GET', null,
                new AdditionalRequestDetails<string>(false, Authentication.false));
        return resp;
    }











}