import { Injectable } from "@angular/core";
import { AppConstants } from "src/app-constants";
import {
  AdditionalRequestDetails,
  Authentication,
} from "../internal-models/additional-request-details";
import { UserLicenseDetailsSM } from "../service-models/app/v1/license/user-license-details-s-m";
import { ApiResponse } from "../service-models/foundation/api-contracts/base/api-response";
import { OdataQueryFilter } from "../service-models/foundation/api-contracts/odata-query-filter";
import { IntResponseRoot } from "../service-models/foundation/common-response/int-response-root";
import { StorageService } from "../services/storage.service";
import { BaseApiClient } from "./base-client/base-api.client";
import { CommonResponseCodeHandler } from "./helpers/common-response-code-handler.helper";
import { StorageCache } from "./helpers/storage-cache.helper";
import { ApiRequest } from "../service-models/foundation/api-contracts/base/api-request";
import { ClientUserExtededUserLicense } from "../service-models/app/v1/license/client-user-exteded-user-license";
import { ActiveDeactiveCompanyInspectorLicenseSM } from "../service-models/app/v1/license/active-deactive-company-inspector-license-s-m (1)";

@Injectable({
  providedIn: "root",
})
export class LicenseInfoClient extends BaseApiClient {
  constructor(
    storageService: StorageService,
    storageCache: StorageCache,
    commonResponseCodeHandler: CommonResponseCodeHandler
  ) {
    super(storageService, storageCache, commonResponseCodeHandler);
  }
  GetAllLicenseInfo = async (): Promise<
    ApiResponse<UserLicenseDetailsSM[]>
  > => {
    let resp = await this.GetResponseAsync<null, UserLicenseDetailsSM[]>(
      `${AppConstants.ApiUrls.USER_LICENSEINFO_URL}`,
      "GET",
      null,
      new AdditionalRequestDetails<UserLicenseDetailsSM[]>(
        false,
        Authentication.false
      )
    );
    return resp;
  };
  GetAllLicenseInfoByOdata = async (
    queryFilter: OdataQueryFilter
  ): Promise<ApiResponse<UserLicenseDetailsSM[]>> => {
    let finalUrl = await this.ApplyQueryFilterToUrl(
      `${AppConstants.ApiUrls.USER_LICENSEINFO_URL}/odata`,
      queryFilter
    );
    let resp = await this.GetResponseAsync<null, UserLicenseDetailsSM[]>(
      finalUrl,
      "GET"
    );
    return resp;
  };
  GetAllLicenseInfoCount = async (): Promise<ApiResponse<IntResponseRoot>> => {
    let resp = await this.GetResponseAsync<null, IntResponseRoot>(
      `${AppConstants.ApiUrls.USER_LICENSEINFO_URL}/UserLicenseDetailsCountResponse`,
      "GET"
    );
    return resp;
  };

  GetMySubscription = async (): Promise<ApiResponse<UserLicenseDetailsSM>> => {
    let resp = await this.GetResponseAsync<number, UserLicenseDetailsSM>(
      `${AppConstants.ApiUrls.USER_LICENSEINFO_URL}/mine/Active`,
      "GET"
    );
    return resp;
  };
  GetMyPreviousCompanySubscription = async (): Promise<
    ApiResponse<UserLicenseDetailsSM[]>
  > => {
    let resp = await this.GetResponseAsync<number, UserLicenseDetailsSM[]>(
      `${AppConstants.ApiUrls.USER_LICENSEINFO_URL}/my/Licenses`,
      "GET"
    );
    return resp;
  };
  GetMyPreviousIndividualInspectorSubscription = async (): Promise<
    ApiResponse<UserLicenseDetailsSM[]>
  > => {
    let resp = await this.GetResponseAsync<number, UserLicenseDetailsSM[]>(
      `${AppConstants.ApiUrls.USER_LICENSEINFO_URL}/mine/Licenses`,
      "GET"
    );
    return resp;
  };
  GetAllCompanyInspectorsCount = async (): Promise<
    ApiResponse<IntResponseRoot>
  > => {
    let resp = await this.GetResponseAsync<null, IntResponseRoot>(
      `${AppConstants.ApiUrls.USER_LICENSEINFO_URL}/NoOfInspectorsCountResponse`,
      "GET"
    );
    return resp;
  };

  AddLicenseToInspectors = async (req: ApiRequest<ActiveDeactiveCompanyInspectorLicenseSM>): Promise<ApiResponse<UserLicenseDetailsSM>> => {
    let resp = await this.GetResponseAsync<ActiveDeactiveCompanyInspectorLicenseSM, UserLicenseDetailsSM>(
      `${AppConstants.ApiUrls.USER_LICENSEINFO_URL}/CompanyInspector`, "POST", req);
    return resp;
  };
  
  GetActiveInspectorLicense = async (): Promise< ApiResponse<ClientUserExtededUserLicense[]> > => {
    let resp = await this.GetResponseAsync<number, ClientUserExtededUserLicense[]>(
      `${AppConstants.ApiUrls.USER_LICENSEINFO_URL}/my/Licenses`,"GET");
    return resp;
  };
}
