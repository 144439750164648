import { ClientCompanyAddressSM } from "../service-models/app/v1/client/client-company-address-s-m";
import { ClientCompanyDetailSM } from "../service-models/app/v1/client/client-company-detail-s-m";
import { BaseViewModel } from "./base.viewmodel";


export class CompanyInfoViewModel implements BaseViewModel {
    PageTitle: string = 'Company profile';
    AddEditModalTitle: string = '';
    companyDetails: ClientCompanyDetailSM = new ClientCompanyDetailSM();
    companyAddressDetails: ClientCompanyAddressSM = new ClientCompanyAddressSM();
    isDisabled: boolean = true;
    isProfilePicUploaded: boolean = false;
    showDeleteBtn: boolean = false;
}