import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { LogHandlerService } from 'src/app/services/log-handler.service';
import { AdminDashboardViewModel } from 'src/app/view-models/admin/admin-dashboard.viewmodel';
import { BaseComponent } from '../../base.component';
// import { Chart } from "chart.js";


@Component({
  selector: 'app-admindashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.css']

})
export class AdminDashboardComponent extends BaseComponent<AdminDashboardViewModel> implements OnInit {
  
//  public chart!: Chart;
  constructor(commonService: CommonService,
    logService: LogHandlerService,) {
  super(commonService, logService)
  // this._commonService.layoutViewModel.showSuperAdminTopBar=false;
  this._commonService.layoutViewModel.showSuperAdminLeftMenu=true;
 
   }
  public myChart:any;
  ngOnInit(): void {
 
      // this.userInfoChart()
  }


  
  // userInfoChart(){
  
  //   const myChart = new Chart("MyChart", {
  //     type: 'bar', //this denotes tha type of chart

  //     data: {// values on X-Axis
  //       labels: ['jan', 'feb', 'Mar','Apr','May','June','July',
	// 							  ], 
	//        datasets: [
  //         {
  //           label: "Sales",
  //           data: [65, 59, 80, 81, 56, 55, 40,65, 59, 80, 81, 56, 55, 40,65, 59, 80, 81, 56, 55, 40],
  //                backgroundColor: [
  //                 'rgba(255, 99, 132, 0.2)',
  //                 'rgba(255, 159, 64, 0.2)',
  //                 'rgba(255, 205, 86, 0.2)',
  //                 'rgba(75, 192, 192, 0.2)',
  //                 'rgba(54, 162, 235, 0.2)',
  //                 'rgba(153, 102, 255, 0.2)',
  //                 'rgba(201, 203, 207, 0.2)'
  //               ],
  //               borderColor: [
  //                 'rgb(255, 99, 132)',
  //                 'rgb(255, 159, 64)',
  //                 'rgb(255, 205, 86)',
  //                 'rgb(75, 192, 192)',
  //                 'rgb(54, 162, 235)',
  //                 'rgb(153, 102, 255)',
  //                 'rgb(201, 203, 207)'
  //               ],
  //               borderWidth: 1
  //         },
  //       ]
  //     },
  //     options: {
  //       aspectRatio:1.9,
  //       responsive:true,
  //       scales:{
  //         y:{
  //           beginAtZero:true 
  //         }
  //       }
        
  //     }
      
  //   });
  // }

}
