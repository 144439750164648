
import { RoleTypeSM } from "../service-models/app/enums/role-type-s-m.enum";
import { ClientUserAddressSM } from "../service-models/app/v1/app-users/client-user-address-s-m";
import { ClientUserSM } from "../service-models/app/v1/app-users/client-user-s-m";
import { ClientCompanyAddressSM } from "../service-models/app/v1/client/client-company-address-s-m";
import { ClientCompanyDetailSM } from "../service-models/app/v1/client/client-company-detail-s-m";
import { BoolResponseRoot } from "../service-models/foundation/common-response/bool-response-root";
import { BaseViewModel } from "./base.viewmodel"


export class RegisterViewModel implements BaseViewModel {
    PageTitle: string = 'Register';
    userDetails: ClientUserSM = new ClientUserSM();
    userAddress: ClientUserAddressSM = new ClientUserAddressSM();
    companyDetails: ClientCompanyDetailSM = new ClientCompanyDetailSM();
    companyAddressDetails: ClientCompanyAddressSM = new ClientCompanyAddressSM();
    validUser: BoolResponseRoot = new BoolResponseRoot();
    showSuccessMsg: boolean = false;
    showErrorMsg: boolean = false;
    showValidationMsg: boolean = false;
    currentRole!: RoleTypeSM;
    addInvalidFormClass = '';
    companyRegister: string = 'false';
    FormSubmitted = false;
    hidePassword: boolean = true;
    eyeDefault = 'default';

    registerValidationData = {
        loginId: [
            { type: 'required', message: 'Login Id is required' },
            { type: 'isValid', message: 'This user is not valid' }

        ],
        firstName: [
            { type: 'required', message: 'First Name is required' },
            { type: 'pattern', message: 'Only digits is not a valid input' }

        ],
        middleName: [
            { type: 'required', message: 'Middle Name is required' },

        ],
        lastName: [
            { type: 'required', message: 'Last Name is required' },
            { type: 'required', message: 'Only digits is not a valid input' }
        ],
        userphoneNumber: [
            { type: 'required', message: 'Phone Number is required' },
            { type: 'minlength', message: 'Enter valid phone number' },
        ],
        userEmailId: [
            { type: 'required', message: 'Email is required' },
            { type: 'pattern', message: 'please enter a valid email' }
        ],
        usercountry: [
            { type: 'required', message: 'Country is required' },
        ],
        userCity: [
            { type: 'required', message: 'City is required' },
        ],
        userState: [
            { type: 'required', message: 'State is required' },
        ],
        userpincode: [
            { type: 'required', message: 'Pincode is required' },
            { type: 'maxlength', message: 'Pincode exceed maximum length' },
            { type: 'pattern', message: 'Enter numerical values' }
        ],
        useraddress: [
            { type: 'required', message: 'Address is required' },
        ],
        PasswordHash: [
            { type: 'required', message: 'Password is required' },
        ],
        CompanyCode: [
            { type: 'required', message: 'Company Code is required' },
        ],
        CompanyName: [
            { type: 'required', message: 'Company Name is required' },
        ],
        CompanyWebsite: [
            { type: 'required', message: 'Company Website is required' },
            
        ],
        CompanyAddress: [
            { type: 'required', message: 'Company Address is required' },
        ],

        companyCountry: [
            { type: 'required', message: '  Country is required' },
        ],
        companyState: [
            { type: 'required', message: ' State is required' },
        ],

        companyPincode: [
            { type: 'required', message: ' Pincode is required' },
            { type: 'maxlength', message: ' Pincode exceed maximum length' },
        ],
        companyEmailId: [
            { type: 'required', message: 'Company Email is required' },
            { type: 'pattern', message: 'Company Please enter a valid email' }
        ],

        companyCity: [
            { type: 'required', message: ' City is required' },
        ],
        companyphoneNumber: [
            { type: 'required', message: 'Company Phone is required' },
            { type: 'minlength', message: 'Enter valid phone number' },
        ],

    }

}