import { FeatureSM } from "src/app/service-models/app/v1/license/feature-s-m";
import { BaseViewModel } from "../base.viewmodel";

export class FeaturesVeiwModel implements BaseViewModel {
  PageTitle: string = "Features";
  PageNo: number = 1;
  PageSize: number = 10;
  totalCount!:number;
  AddEditModalTitle: string = '';
  features: FeatureSM[] = [];
  singleFeature: FeatureSM = new FeatureSM();
  FormSubmitted: boolean = false;
  ValidationData = {
    title: [
      { type: 'required', message: 'Title is required' },
    ],
    description: [
      { type: 'required', message: 'Description is required' },
    ],
    price: [
      { type: 'required', message: 'Price is required' },
      { type: 'pattern', message: 'Only numbers are allowed' }
    ]
  }

}