import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppConstants } from 'src/app-constants';
import { OdataQueryFilter } from 'src/app/service-models/foundation/api-contracts/odata-query-filter';
import { CommonService } from 'src/app/services/common.service';
import { LicenseInfoService } from 'src/app/services/license-info.service';
import { LogHandlerService } from 'src/app/services/log-handler.service';
import { LicenseInfoViewModel } from 'src/app/view-models/license-info.viewmodel';
import { BaseComponent } from '../../base.component';

@Component({
  selector: 'app-license-info',
  templateUrl: './license-info.component.html',
  styleUrls: ['./license-info.component.scss']
})
export class LicenseInfoComponent extends BaseComponent<LicenseInfoViewModel> implements OnInit {
  constructor(private modalService: NgbModal, private licenseInfoService: LicenseInfoService,
    commonService: CommonService, logService: LogHandlerService) {
    super(commonService, logService);
    this.viewModel = new LicenseInfoViewModel();
  }
  async ngOnInit(): Promise<void> {
    await this.loadPageData();
  }


  override async loadPageData() {
    try {
      await this._commonService.presentLoading();
    
      //have to do paging
      let queryFilter = new OdataQueryFilter()
      queryFilter.skip = (this.viewModel.PageNo - 1) * this.viewModel.PageSize;
      queryFilter.top = this.viewModel.PageSize;
      let resp = await this.licenseInfoService.getAllLicenseInfoByOdata(queryFilter);
      if (resp.isError) {
        this._exceptionHandler.logObject(resp.errorData);
        this._commonService.showSweetAlertToast({ title: resp.errorData.displayMessage, icon: 'error' });
        return;
      }
      else {
        await this.getLicenseCount();
        this.viewModel.licenseInfo = resp.successData;
      }
    } catch (error) {
      this._commonService.showSweetAlertToast({ title: AppConstants.ErrorPrompts.Unknown_Error, icon: 'error' });
      throw error;
    }
    finally {
      await this._commonService.dismissLoader()
    }
  }


  async getLicenseCount() {
    try {
      await this._commonService.presentLoading();
      let resp = await this.licenseInfoService.getLicenseInfoCount();
      if (resp.isError) {
        this._exceptionHandler.logObject(resp.errorData);
        this._commonService.showSweetAlertToast({ title: resp.errorData.displayMessage, icon: 'error' });
        return;
      }
      else {
        this.viewModel.totalCount = resp.successData.intResponse;
      }
    } catch (error) {
      this._commonService.showSweetAlertToast({ title: AppConstants.ErrorPrompts.Unknown_Error, icon: 'error' });
      throw error;
    }
    finally {
      await this._commonService.dismissLoader()
    }
  }
  async loadPagedataWithPagination(event: any) {
    this.viewModel.PageNo = event;
    await this.loadPageData();
  }
}
