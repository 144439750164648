<div class="container-fluid  animated-image "
  *ngIf="viewModel.LicenseDetails.status=='active' || viewModel.activeLicense">
  <div class="row">
    <div class="col-lg-12 col-md-10 col-sm-12 col-12 pt-lg-1 mx-auto subscriptionDetailsCard">
      <div class="card  mt-2 pb-lg-2 pb-lg-2 pb-md-2 pb-sm-2 pb-2 pt-lg-1 cardone">
        <div class="card-header">
          <h2>Your Subscription Details</h2>
        </div>

        <div class="horizontal_centered__div mt-3 pb-3">
          <div class="row mid__card pt-4 ">
            <div class="col-12">
              <div class="row pb-3">
                <div class="col">
                  <h4 class="text-start">{{viewModel.LicenseDetails.featureGroupName}}</h4>
                </div>
                <div class="col">
                  <span class="active">{{viewModel.LicenseDetails.status}}</span>
                </div>
              </div>
              <div class="row pb-3">
                <div class="col">
                  <h4 class="text-start">${{viewModel.LicenseDetails.actualPaidPrice}}</h4>
                </div>
                <div class="col">
                  <h5>{{viewModel.LicenseDetails.validityInDays}}</h5>
                </div>
              </div>
              <div class="row pb-3">
                <div class="col">
                  <h4 class="text-start">Start Date</h4>
                </div>
                <div class="col">
                  <h5>{{viewModel.LicenseDetails.startDateUTC | date:'short'}}</h5>
                </div>
              </div>
              <div class="row pb-2">
                <div class="col">
                  <h4 class="text-start">End Date</h4>
                </div>
                <div class="col">
                  <h5 class="grey__text">{{viewModel.LicenseDetails.expiryDateUTC | date:'short' }}</h5>
                </div>
              </div>
              <div class="row text-end pb-2">
                <div class="col-12">
                  <button type="button" class="spyGlassBtn" (click)="click_ManageSubscription()">
                    Manage
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>


<div  class="container " *ngIf="viewModel.LicenseDetails.status=='active' || viewModel.activeLicense">
  <div class="center-container invoice-animation">
    <div class="heading text-white mt-2 mb-4"(click)="toggleInvoiceHistory()">
      Invoice History  
      <i class="bi bi-caret-down-fill"  *ngIf="!viewModel.showInvoiceHistoryTable"></i>
      <i class="bi bi-caret-up-fill" *ngIf="viewModel.showInvoiceHistoryTable"></i>
  </div>
  </div>

  <div #invoiceHistory  class="row tableRow">
    <div class="col-lg-10">
      <div class="text-center  tableColumn scrollable ">
        <table class="table  mt-2 invoice-animation"  *ngIf="viewModel.showInvoiceHistoryTable">
          <thead>
            <tr>
              <th class="text-center firstTableHead">Date</th>
              <th class="text-center">Actual paid Price</th>
              <th class="text-center">Curency</th>
              <th class="text-center">Amount Due</th>
              <th class="text-center lastTableHead" colspan="2"> Download Invoice </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let inspector of viewModel.invoices">
              <td class="text-center">{{inspector.startDateUTC | date :'short':'locale'}}</td>
              <td class="text-center">{{inspector.actualPaidPrice}}</td>
              <td class="text-center">{{inspector.currency}}</td>
              <td class="text-center">{{inspector.amountDue/100}}</td>
              <td class=" text-center" >
                <a class="downloadInvoiceLink"
                  (click)="downloadInvoiceReportDocument(inspector.stripeInvoiceId)">
                  <i class="bi bi-download " title="Download Invoice Report"></i>
                </a>
    
              </td>
    
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>



  </div>





<div *ngIf="viewModel.LicenseDetails==null||viewModel.LicenseDetails.status=='null'||viewModel.emptydiv"></div>




<div class="container-fluid animated-image"
  *ngIf="viewModel.LicenseDetails&&viewModel.LicenseDetails.status=='canceled' || viewModel.noActiveLicense ">
  <div class="row">
    <div class="col-lg-12 col-md-10 col-sm-12 col-12  mx-auto subscriptionDetailsCard">
      <div class="card  mt-2 pb-lg-2 pb-lg-2 pb-md-2 pb-sm-2 pb-2  cardone">
        <div class="card-header  ">
          <h2>Get your license here</h2>
        </div>

        <div class="row">
          <div class="col-lg-6 col-md-12 col-sm-12 col-12 mt-5 px-5">
            <h2 class="text">you haven't purchased any license yet</h2><br />
            <h6>We notice that you have not yet purchased a license for our software. Take the next step towards
              transforming your inspection process and unlocking the full potential of SpyGlass by purchasing a license
              today. Explore the licensing options available under pricing tab </h6>
            <button class="spyGlassBtn mt-5" routerLink="/pricing">Get Started</button>
          </div>
          <div class="col-lg-6 col-md-12 col-sm-12 col-12 d-flex justify-content-end m-0 mt-3 imageColumn">
            <img src="assets/images/other-images/ourProduct.webp" alt="Image" class="img-fluid">

          </div>
        </div>

      </div>
    </div>
  </div>
</div>