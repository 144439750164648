import { SurveyBoxServiceModelBase } from '../../base/survey-box-service-model-base';

export class ClientCompanyDetailSM extends SurveyBoxServiceModelBase<number> {
    companyCode!: string;
    name!: string;
    description!: string;
    contactEmail!: string;
    companyMobileNumber!: string;
    companyWebsite!: string;
    companyLogoPath!: string;
    isEnabled!: boolean;
    companyDateOfEstablishment!: Date;
    clientCompanyAddressId?: number;
}
