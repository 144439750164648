<section class="mainSection">

    <div class="container">

        <div class="row">

            <div class="col-lg-6 col-md-10 col-12 mt-lg-5 mb-md-5 mb-sm-5 mb-5"data-aos="fade-up" data-aos-duration="1500">

                <div class="howToInstallMainSectionText">

                    <h1> {{viewModel.PageTitle}} </h1>

                    <span>
                        Follow the steps below to see how to install the Software on your Local Machine
                    </span>

                </div>

            </div>

            <div class="col-lg-6 col-12"  >

                <div class="howToInstallMainSectionImage">
                    <img src="{{'data:image/webp;base64,' + viewModel.howToInstallMainImage}}" class="img-fluid"
                    data-aos="zoom-out" data-aos-duration="1500"
                     >
                </div>

            </div>

        </div>

    </div>

</section>


<section class="secondSection">

    <div class="container">

        <div class="row">

            <div class="col-lg-4 col-12 d-lg-inline-block d-none">

                <nav id="navbar-example3" class="h-100 flex-column align-items-stretch">

                    <nav class="nav navButtons flex-column">

                        <a class="nav-link" (click)="navigateToSteps('step1')">
                            <span> How to Install: Step 1 </span>
                        </a>

                        <a class="nav-link" (click)="navigateToSteps('step2')">
                            <span> How to Install: Step 2 </span>
                        </a>

                        <a class="nav-link" (click)="navigateToSteps('step3')">
                            <span> How to Install: Step 3 </span>
                        </a>

                        <a class="nav-link" (click)="navigateToSteps('step4')">
                            <span> How to Install: Step 4 </span>
                        </a>

                    </nav>

                </nav>

            </div>

            <div class="col-lg-8 col-12">

                <div class="smallerScreenHeading d-lg-none d-block text-center">
                    <h1> Follow how to Install Steps Below </h1>
                </div>

                <div data-bs-spy="scroll" data-bs-target="#navbar-example3" data-bs-smooth-scroll="true"
                    class="scrollContent" tabindex="0">

                    <div class="scrollContentStep" id="step1">

                        <div class="stepText">
                            <span>1: After Successful Download, Extract the content from Zip folder as shown
                                below to a separate folder.
                            </span>
                        </div>

                        <div class="card my-5"data-aos="fade-up" data-aos-duration="1500">
                            <img src="{{'data:image/webp;base64,' + viewModel.howToInstallImage1}}" alt="step1Image"
                                loading="lazy">
                        </div>

                    </div>

                    <div class="scrollContentStep" id="step2">

                        <div class="stepText">
                            <span>2: Open the Extracted folder and run SpyGlassUI.application to start Software Installation and
                                click install to proceed with software installation
                            </span>
                        </div>

                        <div class="card my-5"data-aos="fade-up" data-aos-duration="1500">
                            <img src="{{'data:image/webp;base64,' + viewModel.howToInstallImage2}}" alt="step2Image"
                                loading="lazy">
                        </div>

                        <div class="stepText">
                            <span>3: Follow necessary steps on installation wizard
                            </span>
                        </div>

                    </div>

                    <div class="scrollContentStep" id="step3">


                        <div class="stepText">
                            <span>4: After Successful Installation. Run the shortcut created on your desktop to
                                proceed
                                with the software.
                            </span>
                            <br>
                            <span>
                                <b> Note: </b> Required Software will update automatically (If Available) as
                                soon as you open the .exe extension shortcut.
                            </span>

                        </div>

                        <div class="card my-5"data-aos="fade-up" data-aos-duration="1500">
                            <img src="{{'data:image/webp;base64,' + viewModel.howToInstallImage3}}" alt="step3Image"
                                loading="lazy">
                        </div>

                    </div>

                    <div class="scrollContentStep" id="step4">

                        <div class="stepText">
                            <span> 5: Enter Login Credentials on login window for actual utilization of
                                Software.
                            </span>
                        </div>

                        <div class="card my-5"data-aos="fade-up" data-aos-duration="1500">
                            <img src="{{'data:image/webp;base64,' + viewModel.howToInstallImage4}}" alt="step4Image"
                                loading="lazy">
                        </div>

                    </div>

                </div>

            </div>

        </div>

    </div>

</section>