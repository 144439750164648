<div class="container-fluid" *ngIf="viewModel.validateAuthCode==1">
    <div class="row resetPasswordSection">
        <div class="container">
            <div class="form-container reset-Password-container">

                <form #resetPasswordForm="ngForm">

                    <h1> Reset Password </h1>

                    <input [(ngModel)]="viewModel.resetPasswordDetails.newPassword" type="text" class="form-control"
                        placeholder="Enter new password" name="newPassword" #newPassword="ngModel"  required />
                        <ng-container *ngFor="let validation of viewModel.ValidationData.newPassword">
                            <div class="text-danger ms-lg-2 ms-md-2 ms-sm-2 ms-2"
                                *ngIf="(newPassword.touched||viewModel.FormSubmitted) && newPassword.hasError(validation.type)">
                                {{ validation.message }}
                            </div>
                        </ng-container>

                    <button type="button" class="spyGlassBtn" (click)=" click_resetPassword(resetPasswordForm)"> Submit
                    </button>
                </form>
            </div>

            <div class="overlay-container">
                <div class="overlay">
                    <div class="overlay-panel overlay-right">
                        <h1>Hello, Friend!</h1>
                        <p>oops! Can't remember your password ? No worries, we'll help you get back in!
                        </p>

                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>



  