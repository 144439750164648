import { Injectable } from '@angular/core';
import { ApiResponse } from '../service-models/foundation/api-contracts/base/api-response';
import { BaseService } from './base.service';
import { ThemeClient } from '../clients/theme.client';
import { ClientThemeSM } from '../service-models/app/v1/client/client-theme-s-m';
import { AppConstants } from 'src/app-constants';
import { ApiRequest } from '../service-models/foundation/api-contracts/base/api-request';
import { BoolResponseRoot } from '../service-models/foundation/common-response/bool-response-root';

@Injectable({
  providedIn: 'root'
})
export class ThemeService extends BaseService {



  constructor(private themeClient: ThemeClient) {
    super();

  }
  async getAllThemes(): Promise<ApiResponse<ClientThemeSM[]>> {
    return await this.themeClient.GetAllThemes();
  }
  
  async updateThemeById(id: any): Promise<ApiResponse<BoolResponseRoot>> {
  
      return await this.themeClient.UpdateTheme(id);
    }
  }





// import { Injectable } from '@angular/core';
// import { BehaviorSubject } from 'rxjs';
// import { theme } from '../clients/helpers/theme/theme';

// @Injectable({
//   providedIn: 'root'

// })

// export class ThemeService {
//   private activeThemeSubject = new BehaviorSubject<string | undefined>(
//    undefined
//   );


//   // activeTheme$ = this.activeThemeSubject.asObservable();
//   // get activeTheme(): string | undefined {
//   //   return this.activeThemeSubject.getValue();
//   // }
//   // set(themeName: string): void {
//   //   if (
//   //     this.activeTheme === themeName ||
//   //     !this.themeNames.includes(themeName)
//   //   ) {
//   //     return;
//   //   }
//   //   this.activeThemeSubject.next(themeName);
//   //   document.documentElement.classList.remove(...Object.values(theme));
//   //   document.documentElement.classList.add(theme[themeName]);
//   // }
//   // get themeNames(): string[] {
//   //  return Object.keys(theme);
//   // }
// }
