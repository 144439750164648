<div [class]='_commonService.layoutViewModel.toggleSuperAdminWrapper'>

    <nav [ngClass]="[_commonService.layoutViewModel.superAdminSideMenu]">

        <ul class="list-unstyled">

            <ng-container *ngFor="let menuItem of _commonService.layoutViewModel.menuItems">
                <li routerLinkActive="active" [routerLink]="menuItem.itemRoute" >
                    <i [class]="menuItem.iconName" class="ms-3 fs-3"></i>
                        <span class="ms-3"> {{menuItem.itemName}} </span>
                </li>

            </ng-container>

        </ul>

    </nav>
</div>


<!-- <div [ngClass]="[_commonService.layoutViewModel.toggleSuperAdminWrapper]">
    <nav [ngClass]="[_commonService.layoutViewModel.toggleSuperAdminSideMenu]">
        <ul class="list-unstyled">
            <ng-container *ngFor="let menuItem of _commonService.layoutViewModel.menuItems">
                <li routerLinkActive="active"
                    [class]="_commonService.layoutViewModel.selectedLink == menuItem.linkValue?'active':''"
                    (click)="_commonService.layoutViewModel.selectedLink = menuItem.linkValue">

                    <div class="activeClass">
                        <i [class]="menuItem.iconName" class="ms-3"></i>
                        <span class="ms-3"> {{menuItem.itemName}} </span>
                    </div>
                </li>
            </ng-container>
        </ul>
    </nav> -->


<!-- <div [ngClass]="[_commonService.layoutViewModel.toggleSuperAdminWrapper]">
    <nav [ngClass]="[_commonService.layoutViewModel.toggleSuperAdminSideMenu]">
        <ul class="list-unstyled">
            <ng-container *ngFor="let menuItem of _commonService.layoutViewModel.menuItems">
                <li routerLinkActive="active"
                    [class]="_commonService.layoutViewModel.selectedLink == menuItem.linkValue?'active':''"
                    (click)="_commonService.layoutViewModel.selectedLink = menuItem.linkValue">

                    <div class="activeClass">
                        <i [class]="menuItem.iconName" class="ms-3"></i>
                        <span class="ms-3"> {{menuItem.itemName}} </span>
                    </div>
                </li>
            </ng-container>
        </ul>
    </nav> -->

<!-- <div [ngClass]="[_commonService.layoutViewModel.togglSuperAdminContent]">
        <app-admindashboard *ngIf="_commonService.layoutViewModel.selectedLink == 0"></app-admindashboard>
        <app-news-feed *ngIf="_commonService.layoutViewModel.selectedLink == 1"></app-news-feed>
        <app-admin-freq-asked-ques *ngIf="_commonService.layoutViewModel.selectedLink == 2"></app-admin-freq-asked-ques>
        <app-website-resource *ngIf="_commonService.layoutViewModel.selectedLink == 3"></app-website-resource>
        <app-contact-us *ngIf="_commonService.layoutViewModel.selectedLink == 4"></app-contact-us>
        <app-feature-group *ngIf="_commonService.layoutViewModel.selectedLink == 5"></app-feature-group>
        <app-features *ngIf="_commonService.layoutViewModel.selectedLink == 6"></app-features>
        <app-license-info *ngIf="_commonService.layoutViewModel.selectedLink == 7"></app-license-info>
        <app-companies *ngIf="_commonService.layoutViewModel.selectedLink == 8"></app-companies>
        <app-company-overview  *ngIf="_commonService.layoutViewModel.selectedLink == 9"></app-company-overview>
    </div> -->
<!-- </div> -->






<!-- <div class="list-group list-group-item" routerLinkActive="active" [routerLink]="menuItem.itemRoute"
        *ngFor="let menuItem of _commonService.layoutViewModel.menuItems">
        <i [class]="menuItem.iconName"></i>
        &nbsp;&nbsp; <a class="list-group-item-action anchor"
            [routerLink]="menuItem.itemRoute">{{menuItem.itemName}}</a>
    </div> - -->