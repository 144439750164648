import { TokenRequestSM } from "../service-models/app/token/token-request-s-m";
import { ClientUserSM } from "../service-models/app/v1/app-users/client-user-s-m";
import { FeatureGroupSM } from "../service-models/app/v1/license/feature-group-s-m";
import { SubscriptionUpgradeRequestSM } from "../service-models/app/v1/license/subscription-upgrade-request-s-m";
import { SubscriptionUpgradeResponseSM } from "../service-models/app/v1/license/subscription-upgrade-response-s-m";
import { UserLicenseDetailsSM } from "../service-models/app/v1/license/user-license-details-s-m";
import { BaseViewModel } from "./base.viewmodel";

export class PricingInfoViewModel implements BaseViewModel {
    PageTitle: string = 'License';
    featureGroup!: FeatureGroupSM;
    user:ClientUserSM=new ClientUserSM();
    // feature!:FeatureSM;
    // userDetails!:ClientUserSM;
    activeLicense: UserLicenseDetailsSM = new UserLicenseDetailsSM();
    upgradeSubscription:SubscriptionUpgradeRequestSM=new SubscriptionUpgradeRequestSM();
    upgradeSubscriptionResponse:SubscriptionUpgradeResponseSM=new SubscriptionUpgradeResponseSM();
    licenseList:UserLicenseDetailsSM[]=[]
    checkActiveLicense!: boolean;
    checkValidUser!: boolean;
    // selectedUserId!: number;
    // selectedUserId!: number;
    // selectedUserRole!: RoleTypeSM;
    customerName!: string;
    btnPayText: string = "Pay Now";
    buyBtn: string = 'Buy Now';
    buy:boolean=false;
    upgrade:boolean=false;
    emptydiv:boolean =false;
}