import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AppConstants } from 'src/app-constants';
import { AuthGuard } from 'src/app/guard/auth.guard';
import { RoleTypeSM } from 'src/app/service-models/app/enums/role-type-s-m.enum';
import { FeatureGroupSM } from 'src/app/service-models/app/v1/license/feature-group-s-m';
import { FeatureSM } from 'src/app/service-models/app/v1/license/feature-s-m';
import { UserLicenseDetailsSM } from 'src/app/service-models/app/v1/license/user-license-details-s-m';
import { ApiResponse } from 'src/app/service-models/foundation/api-contracts/base/api-response';
import { CommonService } from 'src/app/services/common.service';
import { FeatureGroupService } from 'src/app/services/feature-group.service';
import { FeatureService } from 'src/app/services/features.service';
import { LogHandlerService } from 'src/app/services/log-handler.service';
import { PricingViewModel } from 'src/app/view-models/pricing.viewmodel';
import { BaseComponent } from '../../base.component';
import { CompanyProfileService } from 'src/app/services/company-profile.service';
import { PaymentService } from 'src/app/services/payment.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.css'],
})

export class PricingComponent extends BaseComponent<PricingViewModel> implements OnInit {
  @Output() groupId: EventEmitter<number> = new EventEmitter();

  @ViewChild('pricingCards') pricingCards!: ElementRef;

  scrollRight() {
    this.pricingCards.nativeElement.scrollTo({ left: (this.pricingCards.nativeElement.scrollLeft + 1600), behavior: 'smooth' });
  }

  scrollLeft() {
    this.pricingCards.nativeElement.scrollTo({ left: (this.pricingCards.nativeElement.scrollLeft - 1600), behavior: 'smooth' });
  }

  constructor(commonService: CommonService,
    logService: LogHandlerService,
    private featureService: FeatureService,
    private featureGroupService: FeatureGroupService,
    private router: Router,
    private authGuard: AuthGuard,
    private companyProfileService: CompanyProfileService,
    private paymentService: PaymentService,
  ) {
    super(commonService, logService);
    this.viewModel = new PricingViewModel();
    this._commonService.layoutViewModel.showSuperAdminLeftMenu = false;
    this._commonService.layoutViewModel.toggleWrapper = 'homeWrapper';
    this._commonService.layoutViewModel.toggleTopNav = 'pricingTopNav';
    this._commonService.layoutViewModel.toggleContent = 'content';
    this._commonService.layoutViewModel.showTopNav = true;
    this._commonService.layoutViewModel.showFooter = false;
    this.viewModel.showTable = false;

  }


  async ngOnInit() {
    await this._commonService.presentLoading();
    await setTimeout(async () => {
      await this._commonService.dismissLoader();
      await this.loadPageData();
      await this.getActiveLicense();
    }, environment.animationTimeoutinMS);
  }


  override async loadPageData() {
    try {
      let resp = await this.featureGroupService.getAllFeatureGroupExtended();
      let resp1 = await this.featureService.getAllFeatures();
      if (resp.isError || resp1.isError) {
        await this._exceptionHandler.logObject(resp.errorData);
        this._commonService.showSweetAlertToast({ title: resp.errorData.displayMessage, icon: 'error' });
        await this._exceptionHandler.logObject(resp1.errorData);
        this._commonService.showSweetAlertToast({ title: resp1.errorData.displayMessage, icon: 'error' });
        return;
      } else {
        this.viewModel.showTable = true;
        this.viewModel.FeatureGroupList = resp.successData
        this.viewModel.allFeatures = resp1.successData;
        let libraryFeatures = this.viewModel.allFeatures.filter(x => x.featureCode.startsWith("LIB"));
        this.viewModel.libraryFeatures = libraryFeatures;
        let inspectorFeatures = this.viewModel.allFeatures.filter(x => x.featureCode.startsWith("INS"));
        this.viewModel.inspectorFeatures = inspectorFeatures;
        let reportFeatures = this.viewModel.allFeatures.filter(x => x.featureCode.startsWith("REP"));
        this.viewModel.reportFeatures = reportFeatures;
        let otherFeatures = this.viewModel.allFeatures.filter(x => x.featureCode.startsWith("OTH"));
        this.viewModel.otherFeatures = otherFeatures;
        this._commonService.layoutViewModel.showFooter = true;

      }
    } catch (error) {
      throw error;
    } finally {
      await this._commonService.dismissLoader();
    }
  }


  getFeatureStatusForFeatureGroup(featureGroup: FeatureGroupSM, feature: FeatureSM) {
    this.viewModel.SelectedFeatureValueCount = '';
    this.viewModel.SelectedFeatureEnabled = false;
    this.viewModel.SelectedFeatureValue = '';
    let selectedFeature = featureGroup.features.filter(x => x.id == feature.id);
    if (selectedFeature.length > 0) {
      this.viewModel.SelectedFeatureValue = 'bi bi-check-circle fs-3 d-inline-flex text-success';
      if (selectedFeature[0].isFeatureCountable) {
        this.viewModel.SelectedFeatureEnabled = true;
        this.viewModel.SelectedFeatureValueCount = selectedFeature[0].usageCount.toString();
      }
      return;
    }
    this.viewModel.SelectedFeatureEnabled = false;
    this.viewModel.SelectedFeatureValue = 'bi bi-x-circle fs-3 d-inline-flex text-danger';
    return;
  }


  async click_BuyLicense(licenseGroupId: number) {
    // let clickedItem = this.viewModel.FeatureGroupList.find(x => x.id == licenseGroupId);
    // if (clickedItem) {
    //   let id = clickedItem.id;
    //   console.log(id);
    //   let x = id;
    //   this.groupId.emit(id);
    // }
    if (!this._commonService.layoutViewModel.isAuthenticated) {
      let resp = await this._commonService.presentLoginModal(AppConstants.WebRoutes.PRICING);
      if (!resp)
        return;
    }
    if (this._commonService.layoutViewModel.isAuthenticated) {
      if (this._commonService.layoutViewModel.roleType == RoleTypeSM.CompanyInspector) {
        this._commonService.showSweetAlertToast({ title: "Please contact your administrator to update license.", icon: 'error' });
      }
      else {
        this.router.navigate([`${AppConstants.WebRoutes.PRICINGINFO}/${licenseGroupId}`]);
      }
    }
  }


  async getActiveLicense() {

    try {
      await this._commonService.presentLoading();
      let resp: ApiResponse<UserLicenseDetailsSM>;
      this.viewModel.checkValidUser = await this.authGuard.IsTokenValid();
      if (this.viewModel.checkValidUser) {
        resp = await this.featureService.getActiveLicense();
        if (resp.isError) {
          this._exceptionHandler.logObject(resp.errorData);
          this._commonService.showSweetAlertToast({ title: resp.errorData.displayMessage, icon: 'error' });
          return;
        }
        this.viewModel.activeLicense = resp.successData;
        if (resp.successData == null) {
          return;
        }
        else if (resp.successData.status == 'canceled' && resp.successData.isCancelled == true) {
          this.viewModel.buyBtn
          this.viewModel.checkActiveLicense = false;
          return;
        }
        else if (!resp.successData.isSuspended && resp.successData.status == 'active' && resp.successData.isCancelled == false) {
          this.viewModel.buyBtn = 'Upgrade';
          this.viewModel.checkActiveLicense = true;
        }

        else if (!resp.successData.isSuspended && resp.successData.status === 'active' && resp.successData.isCancelled === true) {
          this.viewModel.purchasedBtn = 'Renew';
          this.viewModel.buyBtn = 'Upgrade';
          this.viewModel.checkActiveLicense = true;
        }
        else if (!resp.successData.isSuspended && resp.successData.status === 'past_due' && resp.successData.isCancelled === false) {
          this.viewModel.purchasedBtn;
          this.viewModel.buyBtn = 'Upgrade';
          this.viewModel.checkActiveLicense = true;
        }
        else if (!resp.successData.isSuspended && resp.successData.status === 'incomplete' && resp.successData.isCancelled === false) {
          this.viewModel.purchasedBtn;
          this.viewModel.buyBtn = 'Upgrade';
          this.viewModel.checkActiveLicense = true;
        }


      }
    } catch (error) {
      this._commonService.showSweetAlertToast({ title: AppConstants.ErrorPrompts.Unknown_Error, icon: 'error' });
      throw error;
    } finally {
      await this._commonService.dismissLoader();
    }
  }


  IsValidForRole(role: any): boolean {
    if (this._commonService.layoutViewModel.roleType == RoleTypeSM.Unknown || Number(RoleTypeSM[role]) == RoleTypeSM.Unknown)
      return true;
    return this._commonService.layoutViewModel.roleType == Number(RoleTypeSM[role]);
  }


  async getCompanyAdminProfilePicture() {
    try {
      await this._commonService.presentLoading();
      let resp1 = await this.companyProfileService.getCompanyProfile();
      if (resp1.isError) {
        this._exceptionHandler.logObject(resp1.errorData);
        this._commonService.showSweetAlertToast({ title: resp1.errorData.displayMessage, icon: 'error' });
      } else
        this._commonService.layoutViewModel.companyAdminProfilePicture = resp1.successData.companyLogoPath;
    } catch (error) {
      throw error;
    } finally {
      await this._commonService.dismissLoader();
    }
  }

  async click_Renew() {
    if (this.viewModel.purchasedBtn !== 'Renew') {
      return;
    }
    try {
      await this._commonService.presentLoading();
      let resp = await this.paymentService.ManageSubscription(
        window.location.href
      );
      if (resp.isError) {
        await this._exceptionHandler.logObject(resp.errorData);
        this._exceptionHandler.logObject(resp.errorData);
        this._commonService.showSweetAlertToast({
          title: resp.errorData.displayMessage,
          icon: "error",
        });
        return;
      } else {
        window.location.href = resp.successData.url;
      }
    } catch (error) {
      this._commonService.showSweetAlertToast({
        title: AppConstants.ErrorPrompts.Unknown_Error,
        icon: "error",
      });
      throw error;
    } finally {
      await this._commonService.dismissLoader();
    }
  }


}


