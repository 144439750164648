import { ClientUserSM } from "../service-models/app/v1/app-users/client-user-s-m";
import { LoginUserSM } from "../service-models/app/v1/app-users/login/login-user-s-m";
import { BaseViewModel } from "./base.viewmodel";


export class ProfileInfoViewModel implements BaseViewModel {
    PageTitle: string = 'Register';
    AddEditModalTitle: string = '';
    userDetails: ClientUserSM = new ClientUserSM();
    loggedInUser: LoginUserSM = new LoginUserSM();
    isDisabled: boolean = true;
    isProfilePicUploaded: boolean = false;
    showDeleteBtn: boolean = false;
    FormSubmitted: boolean = false;

    ValidationData = {

        firstName: [
            { type: 'required', message: 'First Name is required' },
            { type: 'pattern', message: 'Only digits is not a valid input.' }

        ],
        lastName: [
            { type: 'required', message: 'Last Name is required' },
            { type: 'pattern', message: 'Only digits is not a valid input.' }

        ],
        emailId: [
            { type: 'required', message: 'Company Email is required' },
            { type: 'pattern', message: 'Company Please enter a valid email' }
        ],
        phone: [
            { type: 'required', message: 'Phone number is required' },
           

        ]
    }
}