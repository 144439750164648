import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppConstants } from 'src/app-constants';
import { ClientCompanyDetailSM } from 'src/app/service-models/app/v1/client/client-company-detail-s-m';
import { OdataQueryFilter } from 'src/app/service-models/foundation/api-contracts/odata-query-filter';
import { CommonService } from 'src/app/services/common.service';
import { CompaniesService } from 'src/app/services/companies.service';
import { LogHandlerService } from 'src/app/services/log-handler.service';
import { ThemeService } from 'src/app/services/theme.service';
import { CompaniesViewModel } from 'src/app/view-models/admin/companies.viewmodel';
import { BaseComponent } from '../../base.component';

@Component({
  selector: 'app-companies',
  templateUrl: './companies.component.html',
  styleUrls: ['./companies.component.scss']
})
export class CompaniesComponent extends BaseComponent<CompaniesViewModel> implements OnInit {

  constructor(
    private modalService: NgbModal, private companiesService: CompaniesService,
    commonService: CommonService, logService: LogHandlerService) {
    super(commonService, logService);
    this.viewModel = new CompaniesViewModel();
  }
  async ngOnInit() {
    await this.loadPageData();
    
  }

  override async loadPageData() {
    try {
      await this._commonService.presentLoading();
      //have to do paging
      let queryFilter = new OdataQueryFilter();
      queryFilter.skip = (this.viewModel.PageNo - 1) * this.viewModel.PageSize;
      queryFilter.top = this.viewModel.PageSize;
      // this.viewModel.allCompanies = [];
      let resp = await this.companiesService.getAllCompaniesByOdata(queryFilter);
    
      if (resp.isError) {
        this._exceptionHandler.logObject(resp.errorData);
        this._commonService.showSweetAlertToast({ title: resp.errorData.displayMessage, icon: 'error' });
        return;
      }
      else {
        await this.getCompanyCount();
        this.viewModel.allCompanies = resp.successData;
      }
    } catch (error) {
      this._commonService.showSweetAlertToast({ title: AppConstants.ErrorPrompts.Unknown_Error, icon: 'error' });
      throw error;
    }
    finally {
      await this._commonService.dismissLoader()
    }
  }

  async getCompanyCount(){
    try {
      await this._commonService.presentLoading();
     
      let resp = await this.companiesService.getCompanyCount();
    
      if (resp.isError) {
        this._exceptionHandler.logObject(resp.errorData);
        this._commonService.showSweetAlertToast({ title: resp.errorData.displayMessage, icon: 'error' });
        return;
      }
      else {
        this.viewModel.totalCompanyCount = resp.successData.intResponse;
      }
    } catch (error) {
      this._commonService.showSweetAlertToast({ title: AppConstants.ErrorPrompts.Unknown_Error, icon: 'error' });
      throw error;
    }
    finally {
      await this._commonService.dismissLoader()
    }

  }

  updateLoginStatus(event: any, status: ClientCompanyDetailSM) {
    if (event.target.checked) {
      this.viewModel.companyDetails.isEnabled = true
    }
    else {
      this.viewModel.companyDetails.isEnabled = false
    }
    this.viewModel.companyDetails.id = status.id
    let resp = this.companiesService.updateLoginStatus(this.viewModel.companyDetails);
 }


  async loadPagedataWithPagination(event: any) {
    this.viewModel.PageNo = event;
    await this.loadPageData();
  }
}
