
import { ValidatePasswordLinkStatusSM } from "../service-models/app/enums/validate-password-link-status-s-m.enum";
import { ForgotPasswordSM } from "../service-models/app/v1/app-users/forgot-password-s-m";
import { ResetPasswordRequestSM } from "../service-models/app/v1/app-users/login/reset-password-request-s-m";
import { BaseViewModel } from "./base.viewmodel";
export class ResetPasswordViewModel implements BaseViewModel {
    PageTitle: string = 'Reset Password';
    FormSubmitted = false;
    forgotPasswordDetails: ForgotPasswordSM = new ForgotPasswordSM();
    resetPasswordDetails: ResetPasswordRequestSM = new ResetPasswordRequestSM()
    validateAuthCode!:number;
invalid:boolean=false;
    ValidationData = {
        newPassword: [
            { type: 'required', message: 'password is Required' },
        ],
}

}



 