import { Injectable } from '@angular/core';
import { AppConstants } from 'src/app-constants';
import { ManageInspectorsClient } from '../clients/manage-inspectors.client';
import { AuthGuard } from '../guard/auth.guard';
import { LoginStatusSM } from '../service-models/app/enums/login-status-s-m.enum';
import { RoleTypeSM } from '../service-models/app/enums/role-type-s-m.enum';
import { ClientUserAddressSM } from '../service-models/app/v1/app-users/client-user-address-s-m';
import { ClientUserSM } from '../service-models/app/v1/app-users/client-user-s-m';
import { ApiRequest } from '../service-models/foundation/api-contracts/base/api-request';
import { ApiResponse } from '../service-models/foundation/api-contracts/base/api-response';
import { OdataQueryFilter } from '../service-models/foundation/api-contracts/odata-query-filter';
import { DeleteResponseRoot } from '../service-models/foundation/common-response/delete-response-root';
import { IntResponseRoot } from '../service-models/foundation/common-response/int-response-root';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class ManageInspectorsService extends BaseService {

  constructor(private manageInspectorClient: ManageInspectorsClient, private authGuard: AuthGuard) {
    super();
  }

  // async Add( newsfeed: NewsFeedViewModel): Promise<ApiResponse<ClientUserSM>>{
  //   return await this.newsFeedClient.AddNews(this.);
  // }
  async AddInspector(inspector: ClientUserSM): Promise<ApiResponse<ClientUserSM>> {
    if (inspector == null) {
      throw new Error(AppConstants.ErrorPrompts.Invalid_Input_Data);
    }
    else {
      let apiRequest = new ApiRequest<ClientUserSM>();
      let companyId = await this.authGuard.GetCompanyIdFromToken();
      if (!companyId || companyId <= 0)
        throw new Error('Error in getting data, please login again');
      inspector.clientCompanyDetailId = companyId;
      inspector.loginStatus = LoginStatusSM.Enabled;
      inspector.roleType = RoleTypeSM.CompanyInspector;
      apiRequest.reqData = inspector;
      return await this.manageInspectorClient.AddInspector(apiRequest);
    }
  }
  async getAllCompanyInspectorsByOdata(id: number,queryFilter: OdataQueryFilter): Promise<ApiResponse<ClientUserSM[]>> {
    return await this.manageInspectorClient.GetAllCompanyInspectorsByOdata(id,queryFilter);
  }
  async getCompanyInspectorsCount(id:number):Promise<ApiResponse<IntResponseRoot>>{
    return await this.manageInspectorClient.GetAllCompanyInspectorsCount(id)
  }
   

  async updateInspector(inspector: ClientUserSM,id:number): Promise<ApiResponse<ClientUserSM>> {
    if (inspector == null) {
      throw new Error(AppConstants.ErrorPrompts.Invalid_Input_Data);
    }
    else {
      let apiRequest = new ApiRequest<ClientUserSM>();
      apiRequest.reqData = inspector;
      return await this.manageInspectorClient.UpdateInspector(apiRequest,id);
    }
 }
  async addInspectorAddress(InspectorAddressRequest: ClientUserAddressSM,id:number): Promise<ApiResponse<ClientUserAddressSM>> {
    if (InspectorAddressRequest == null) {
      throw new Error(AppConstants.ErrorPrompts.Invalid_Input_Data);
    }
    else {
      let apiRequest = new ApiRequest<ClientUserAddressSM>();
      apiRequest.reqData = InspectorAddressRequest;
      return await this.manageInspectorClient.AddInspectorAddress(apiRequest,id);
    }
  }
  async updateInspectorAddress(inspector: ClientUserAddressSM,id:number): Promise<ApiResponse<ClientUserAddressSM>> {
    if (inspector == null) {
      throw new Error(AppConstants.ErrorPrompts.Invalid_Input_Data);
    }
    else {
      let apiRequest = new ApiRequest<ClientUserAddressSM>();
      apiRequest.reqData = inspector;
      return await this.manageInspectorClient.UpdateInspectorAddress(apiRequest,id);
    }
  }
  async getInspectorAddressById(id: number): Promise<ApiResponse<ClientUserAddressSM>> {
    if (id <= 0) {
      throw new Error(AppConstants.ErrorPrompts.Delete_Data_Error)
    }
    return await this.manageInspectorClient.GetInspectorAddressbyId(id);
  }
  async getCompanyInspectors(): Promise<ApiResponse<ClientUserSM[]>> {
    return await this.manageInspectorClient.GetCompanyInspectors();
  }

  async getInspectorById(id: number): Promise<ApiResponse<ClientUserSM>> {
    if (id <= 0) {
      throw new Error(AppConstants.ErrorPrompts.Delete_Data_Error)
    }
    return await this.manageInspectorClient.GetInspectorById(id);
  }

  //   async getInspectorById(): Promise<ApiResponse<ClientUserSM>> {
  //   // if (id <= 0) {
  //   //   throw new Error(AppConstants.ErrorPrompts.Delete_Data_Error)
  //   // }
  //   return await this.manageInspectorClient.GetInspectorById();
  // }



  async deleteInspectorById(id: number): Promise<ApiResponse<DeleteResponseRoot>> {
    if (id <= 0) {
      throw new Error(AppConstants.ErrorPrompts.Delete_Data_Error)
    }
    return await this.manageInspectorClient.DeleteInspectorById(id);
  }
}