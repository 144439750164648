<div class="container-fluid mt-5 pt-5 ">
    <div class="card one  mt-3">
        <div class="card second">
            <div style="border-radius:200px; height:80px; width:80px; background: #F8FAF5; margin:0 auto;">
                <i class="checkmark">✓</i>
            </div>
            <h3>Thankyou!</h3>
            <h4>Payment Successful</h4>
        </div><br><br><br>

        <div class="container-two">
            <div class="row mt-2">
                <div class="col-5">
                    <label for="amountPaid" class="form-label"> Amout paid:</label>
                </div>
                <div class="col-7 data">{{viewModel.featureGroup.amount}}</div>
            </div>

            <div class="row">
                <div class="col-5">
                    <label for="TransactionId" class="form-label"> License Type:</label>
                </div>
                <div class="col-7"  >{{viewModel.featureGroup.title}}</div>
            </div>

            <div class="row">
                <div class="col-5">
                    <label for="Date" class="form-label">Payment Date:</label>
                </div>
                <div class="col-7">{{viewModel.paymentInfo.paymentDate | date :'short':'locale'}}</div>
            </div>
        </div>
        <div class="row">

            <!-- <div class="col-7">
               <button class="spyGlassBtn" routerLink="/">Subscription</button> 
            </div> -->
            <div class="col-12">
                <button routerLink="/home" class="spyGlassBtn">HOME</button>
            </div>
        </div>


    </div>

</div>