import { Injectable } from "@angular/core";
import { AppConstants } from "src/app-constants";
import { AdditionalRequestDetails, Authentication } from "../internal-models/additional-request-details";
import { FeatureGroupSM } from "../service-models/app/v1/license/feature-group-s-m";
import { FeatureSM } from "../service-models/app/v1/license/feature-s-m";
import { ApiRequest } from "../service-models/foundation/api-contracts/base/api-request";
import { ApiResponse } from "../service-models/foundation/api-contracts/base/api-response";
import { OdataQueryFilter } from "../service-models/foundation/api-contracts/odata-query-filter";
import { DeleteResponseRoot } from "../service-models/foundation/common-response/delete-response-root";
import { IntResponseRoot } from "../service-models/foundation/common-response/int-response-root";
import { StorageService } from "../services/storage.service";
import { BaseApiClient } from "./base-client/base-api.client";
import { CommonResponseCodeHandler } from "./helpers/common-response-code-handler.helper";
import { StorageCache } from "./helpers/storage-cache.helper";



@Injectable({
    providedIn: 'root'
})
export class FeatureGroupClient extends BaseApiClient {
    constructor(storageService: StorageService, storageCache: StorageCache,
        commonResponseCodeHandler: CommonResponseCodeHandler) {
        super(storageService, storageCache, commonResponseCodeHandler)

    }


    GetFeatureGroupByOdata = async (queryFilter: OdataQueryFilter): Promise<ApiResponse<FeatureGroupSM[]>> => {
        let finalUrl = this.ApplyQueryFilterToUrl(`${AppConstants.ApiUrls.FEATURE_GROUP_URL}/odata`, queryFilter);
        let resp = await this.GetResponseAsync<null, FeatureGroupSM[]>(finalUrl, 'GET')
        return resp;
    }

    GetAllFeatureGroupCount = async (): Promise<ApiResponse<IntResponseRoot>> => {
        let resp = await this.GetResponseAsync<null, IntResponseRoot>
            (`${AppConstants.ApiUrls.FEATURE_GROUP_URL}/FeatureGroupCountResponse`, 'GET');
        return resp;

    }


    /**Get all FeatureGroup Extended*/
    GetAllFeatureGroupExtended = async (): Promise<ApiResponse<FeatureGroupSM[]>> => {
        let resp = await this.GetResponseAsync<null, FeatureGroupSM[]>
            (`${AppConstants.ApiUrls.FEATURE_GROUP_URL}/extended`, 'GET', null,
                new AdditionalRequestDetails<FeatureGroupSM[]>(false, Authentication.false));
        return resp;
    }

    /**Get all FeatureGroup */
    GetAllFeatureGroup = async (): Promise<ApiResponse<FeatureGroupSM[]>> => {
        let resp = await this.GetResponseAsync<null, FeatureGroupSM[]>
            (`${AppConstants.ApiUrls.FEATURE_GROUP_URL}`, 'GET', null,
                new AdditionalRequestDetails<FeatureGroupSM[]>(false, Authentication.false));
        return resp;
    }

    /**Get FeatureGroup by id */
    GetFeatureGroupById = async (Id: number): Promise<ApiResponse<FeatureGroupSM>> => {
        let resp = await this.GetResponseAsync<number, FeatureGroupSM>
            (`${AppConstants.ApiUrls.FEATURE_GROUP_URL}/${Id}`, 'GET', null,
                new AdditionalRequestDetails<FeatureGroupSM>(false, Authentication.false));
        return resp;
    }

    /**Get FeatureGroup by id */
    GetFeatureGroupByIdExtended = async (Id: number): Promise<ApiResponse<FeatureGroupSM>> => {
        let resp = await this.GetResponseAsync<number, FeatureGroupSM>
            (`${AppConstants.ApiUrls.FEATURE_GROUP_URL}/extended/${Id}`, 'GET', null,
                new AdditionalRequestDetails<FeatureGroupSM>(false, Authentication.false));
        return resp;
    }

    /**Add a new FeatureGroup */
    AddFeatureGroup = async (addFeatureGroupRequest: ApiRequest<FeatureGroupSM>): Promise<ApiResponse<FeatureGroupSM>> => {
        let resp = await this.GetResponseAsync<FeatureGroupSM, FeatureGroupSM>
            (AppConstants.ApiUrls.FEATURE_GROUP_URL, 'POST', addFeatureGroupRequest);
        return resp;
    }

    /**Update FeatureGroup*/
    UpdateFeatureGroup = async (updateFeatureGroupRequest: ApiRequest<FeatureGroupSM>): Promise<ApiResponse<FeatureGroupSM>> => {
        let resp = await this.GetResponseAsync<FeatureGroupSM, FeatureGroupSM>
            (`${AppConstants.ApiUrls.FEATURE_GROUP_URL}/${updateFeatureGroupRequest.reqData.id}`, 'PUT', updateFeatureGroupRequest);
        return resp;
    }

    /**delete FeatureGroup by id */
    DeleteFeatureGroupById = async (Id: number): Promise<ApiResponse<DeleteResponseRoot>> => {
        let resp = await this.GetResponseAsync<number, DeleteResponseRoot>
            (`${AppConstants.ApiUrls.FEATURE_GROUP_URL}/${Id}`, 'DELETE');
        return resp;
    }

    // GetAllFeatures = async (): Promise<ApiResponse<FeatureSM[]>> => {
    //     let resp = await this.GetResponseAsync<null, FeatureSM[]>
    //         (`${AppConstants.ApiUrls.FEATURES_API_URL}`, 'GET', null,
    //             new AdditionalRequestDetails<FeatureSM[]>(false, Authentication.false));
    //     return resp;
    // }




}