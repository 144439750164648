<div class="container-fluid">
    <div class="table-title">
        <div class="row">
            <div class="col-lg-4 col-md-12 col-12"> <label> Total No. of Feature Group: {{viewModel.totalCount}}</label>
            </div>
            <div class="col-lg-8 col-md-12 col-12"> <button type="button" class="addBtnSuperAdmin"
                    (click)="click_OpenAddEditModal(addEditModal,0)"><i class="bi bi-plus"></i> Add
                    feature group</button></div>
        </div>

        <table class="table mt-2">
            <thead>
                <tr class="text-center">
                    <th class="firstTableHead">Title</th>
                    <th>Description</th>
                    <th>Amount</th>
                    <th>Validity In Days</th>
                    <th class="lastTableHead">Actions</th>
                </tr>
            </thead>
            <tbody>
                <tr class="h5 fw-normal text-center" *ngFor="let features of viewModel.featureGroupList">
                    <td class="align-middle">{{features.title}}</td>
                    <td class="align-middle">{{features.description}}</td>
                    <td class="align-middle">{{features.amount}}</td>
                    <td class="align-middle">{{features.validityInDays}}</td>
                    <td class="align-middle">
                        <i class="bi bi-pencil-fill" (click)="click_OpenAddEditModal(addEditModal,features.id)"> </i>
                        &nbsp; <i class="bi bi-trash-fill" (click)="deletefeatureGroup(features.id)"></i>
                    </td>

                </tr>
            </tbody>
        </table>


        <div id="pagination" class="col-lg-12 col-md-12 col-sm-12 col-12  d-flex justify-content-center fixed-bottom">
            <ngb-pagination [collectionSize]="viewModel.totalCount" [(page)]="viewModel.PageNo"
                [pageSize]="viewModel.PageSize" (pageChange)="loadPagedataWithPagination($event)"></ngb-pagination>
        </div>

        <ng-template #addEditModal let-modal>
            <form #form_featureGroupForm="ngForm" (ngSubmit)="form_addEditFeatureGroup(form_featureGroupForm)">
                <div class="modal-header">
                    <h4 class="modal-title">{{viewModel.AddEditModalTitle}}</h4>
                    <button type="button" class="btn-close" aria-label="Close"
                        (click)="modal.dismiss('Cross click')"></button>
                </div>
                <div class="modal-body">
                    <div class="form-group p-lg-2 mb-3">
                        <label class="form-label"> Title </label>
                        <input [(ngModel)]="viewModel.featureGroup.title" class="form-control" placeholder="Add title"
                            #title="ngModel" required name="title" />
                        <ng-container *ngFor="let validation of viewModel.ValidationData.title">
                            <div class="alert alert-danger"
                                *ngIf="(title.touched || viewModel.FormSubmitted) && title.hasError(validation.type)">
                                {{ validation.message }}
                            </div>
                        </ng-container>
                    </div>
                    <div class="form-group p-lg-2 mb-3">
                        <label class="form-label"> Description</label>
                        <input [(ngModel)]="viewModel.featureGroup.description" class="form-control"
                            placeholder=Description #description="ngModel" required name="description" />
                        <ng-container *ngFor="let validation of viewModel.ValidationData.description">
                            <div class="alert alert-danger"
                                *ngIf="(description.touched || viewModel.FormSubmitted) && description.hasError(validation.type)">
                                {{ validation.message }}
                            </div>
                        </ng-container>
                    </div>

                    <div class="form-group p-lg-2 mb-3">
                        <label class="form-label"> Stripe Price Id </label>
                        <input [(ngModel)]="viewModel.featureGroup.stripePriceId" class="form-control"
                            placeholder="Stripe Price Id " #stripePriceId="ngModel" required name="description" />
                        <ng-container *ngFor="let validation of viewModel.ValidationData.stripePriceId">
                            <div class="alert alert-danger"
                                *ngIf="(stripePriceId.touched || viewModel.FormSubmitted) && stripePriceId.hasError(validation.type)">
                                {{ validation.message }}
                            </div>
                        </ng-container>
                    </div>


                    <div class="form-group p-lg-2 mb-3">
                        <label class="form-label"> Validity In Days</label>
                        <input type="text" [(ngModel)]="viewModel.featureGroup.validityInDays" class="form-control"
                            placeholder="Validity In Days (e.g:30)" #validity="ngModel" required pattern=^[0-9]+$
                            name="validity" />
                        <ng-container *ngFor="let validation of viewModel.ValidationData.validity">
                            <div class="alert alert-danger"
                                *ngIf="(validity.touched || viewModel.FormSubmitted) && validity.hasError(validation.type)">
                                {{ validation.message }}
                            </div>
                        </ng-container>
                    </div>


                    <div class="form-group  p-lg-2 mb-3">
                        <label class="form-label"> Select Features</label><br>
                        <div class="form-control select">
                            <label class="form-label " *ngFor="let feature of viewModel.allFeatures">
                                <input type="checkbox" [(ngModel)]="feature.isSelected"
                                    [ngModelOptions]="{standalone: true}" name="check">
                                {{feature.value}}
                            </label>
                        </div>
                    </div>



                    <!-- <div class="form-group  p-lg-2 mb-3">
                <label class="form-label select-label"> Features </label>
                <select class="form-control" aria-label="Default select example"
                    [(ngModel)]="viewModel.allFeatures">
                    <option  *ngFor="let feature of viewModel.allFeatures" value="{{feature.id}}">
                        {{feature.title}}
                    </option>
                </select>
            </div> -->
                </div>
                <div class="modal-footer">
                    <button type="submit" class="spyGlassBtn"> Save </button>
                </div>
            </form>

        </ng-template>
    </div>