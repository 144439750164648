import { TokenRequestSM } from "src/app/service-models/app/token/token-request-s-m";
import { BaseViewModel } from "../base.viewmodel";

export class AdminLoginViewModel implements BaseViewModel {
    PageTitle: string = 'Login';
    tokenRequest: TokenRequestSM = new TokenRequestSM();
    rememberMe: boolean = false;
    hidePassword: boolean = true;
    eyeDefault = 'default';
    selectedRole: string = '1';
    FormSubmitted = false;
    ValidationData = {
        loginId: [
            { type: 'required', message: 'Login Id is required' },
            // { type: 'maxlength', message: 'Login Id is max' },
            // { type: 'minlength', message: 'Login Id is min' },
        ],
        password: [
            { type: 'required', message: 'password is required' },
            // { type: 'maxlength', message: 'password  is max' },
            // { type: 'minlength', message: 'password  is min' },
        ],
        roleType: [
            { type: 'required', message: 'select one' }

        ]
    }
}

