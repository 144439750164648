import { RoleTypeSM } from "../service-models/app/enums/role-type-s-m.enum";
import { ClientCompanyDetailSM } from "../service-models/app/v1/client/client-company-detail-s-m";
import { ClientThemeSM } from "../service-models/app/v1/client/client-theme-s-m";
import { UserLicenseDetailsSM } from "../service-models/app/v1/license/user-license-details-s-m";
import { BaseViewModel } from "./base.viewmodel";

export class UserProfileMenuViewModel implements BaseViewModel {
  PageTitle: string = "Register";
  defaultSideMenu = 'defaultSideMenu';
  roleType: RoleTypeSM = RoleTypeSM.Unknown;
  menuItems: { forRole: RoleTypeSM; itemName: string; linkValue: LinkName; iconName: string; }[] =
    [
      {
        iconName: "bi bi-info-circle",
        itemName: "Company-Info",
        forRole: RoleTypeSM.CompanyAdmin,
        linkValue: LinkName.CompanyProfile,
      },
      {
        iconName: "bi bi-info-circle ",
        itemName: "Profile-Info",
        forRole: RoleTypeSM.CompanyInspector,
        linkValue: LinkName.InspectorProfile,
      },
      {
        iconName: "bi bi-info-circle ",
        itemName: "Profile-Info",
        forRole: RoleTypeSM.IndividualInspector,
        linkValue: LinkName.InspectorProfile,
      },
      {
        iconName: "bi bi-people",
        itemName: "Inspectors",
        forRole: RoleTypeSM.CompanyAdmin,
        linkValue: LinkName.InspectorManage,
      },
      {
        iconName: "bi bi-file-earmark-text",
        itemName: "My Subscription",
        forRole: RoleTypeSM.IndividualInspector,
        linkValue: LinkName.CompanyLicenseInfo,
      },
      {
        iconName: "bi bi-file-earmark-text",
        itemName: "My Subscription",
        forRole: RoleTypeSM.CompanyAdmin,
        linkValue: LinkName.CompanyLicenseInfo,
      },
      {
        iconName: "bi bi-person-check",
        itemName: "Assign Licenses",
        forRole: RoleTypeSM.CompanyAdmin,
        linkValue: LinkName.AssignLicense,
      },



    ];
  selectedLink: LinkName = 0;
  companyDetail: ClientCompanyDetailSM = new ClientCompanyDetailSM();
  LicenseDetails: ClientCompanyDetailSM = new ClientCompanyDetailSM();
  showTooltip: boolean = false;
  allThemes: ClientThemeSM[] = [];
}

export enum LinkName {
  None = 0,
  InspectorProfile = 1,
  CompanyProfile = 2,
  InspectorManage = 3,
  CompanyLicenseInfo = 4,
  AssignLicense = 5,
}
