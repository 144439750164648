import { CommonModule } from '@angular/common';
import {  Component, OnInit } from '@angular/core';
import { FormsModule, NgForm } from '@angular/forms';
import { Router, RouterLink } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AppConstants } from 'src/app-constants';
import { BaseComponent } from 'src/app/components/base.component';
import { AuthGuard } from 'src/app/guard/auth.guard';
import { LoginStatusSM } from 'src/app/service-models/app/enums/login-status-s-m.enum';
import { RoleTypeSM } from 'src/app/service-models/app/enums/role-type-s-m.enum';
import { LoginUserSM } from 'src/app/service-models/app/v1/app-users/login/login-user-s-m'
import { AccountService } from 'src/app/services/account.service';
import { CommonService } from 'src/app/services/common.service';
import { CompanyProfileService } from 'src/app/services/company-profile.service';
import { LogHandlerService } from 'src/app/services/log-handler.service';
import { ProfileInfoService } from 'src/app/services/profile-info.service';
import { StorageService } from 'src/app/services/storage.service';
import { UserLoginViewModel } from 'src/app/view-models/userlogin.viewmodel';


@Component({
  selector: 'app-login',
  templateUrl: './userlogin.component.html',
  styleUrls: ['./userlogin.component.scss'],
  imports: [
    FormsModule,
    CommonModule,
    RouterLink
  ],
  standalone: true,
})

export class UserLoginComponent extends BaseComponent<UserLoginViewModel> implements OnInit {

  constructor
    (
      commonService: CommonService,
      logService: LogHandlerService,
      public activeModal: NgbActiveModal,
      private accountService: AccountService,
      private router: Router,
      private authGuard: AuthGuard,
      private profileInfoService: ProfileInfoService,
      private companyProfileService: CompanyProfileService,
      private storageService: StorageService,
     
    ) {
    super(commonService, logService)
    this.viewModel = new UserLoginViewModel();
  }


  async ngOnInit() {
    await this.loadPageData();
  }


  override async loadPageData() {
    try {
      let userValid = await this.authGuard.IsTokenValid();
      if (userValid) {
        let user: LoginUserSM | "" = await this.storageService.getDataFromAnyStorage(AppConstants.DbKeys.LOGIN_USER);
        if (user !== "") {
          this.viewModel.tokenRequest.loginId = user.loginId;
          this.viewModel.tokenRequest.password = await this.storageService.getFromStorage(AppConstants.DbKeys.PASSWORD);
        }
      }
    } catch (error) {
      throw (error)
    } finally {
      await this._commonService.dismissLoader();
    }
  }


  click_TogglePassword() {
    this.viewModel.hidePassword = !this.viewModel.hidePassword;
    if (this.viewModel.eyeDefault == 'default') {
      this.viewModel.eyeDefault = 'eyeChange';
    } else {
      this.viewModel.eyeDefault = 'default';
    }
    return;
  }


  async change_LoginId(loginId: string) {
    setTimeout(async () => {
      let resp = await this.accountService.ValidateLoginId(loginId);
      this.viewModel.validUser = resp.successData;
      if (resp.successData.boolResponse == true) {
        this.viewModel.showSuccessMsg = true;
        this.viewModel.showErrorMsg = false;
      }
      else {
        this.viewModel.showSuccessMsg = false;
        this.viewModel.showErrorMsg = true;
      }
    }, 2000);
  }


  async registerIndividualUser(RegisterIndividualForm: NgForm) {
    this.viewModel.RegisterFormSubmitted = true;
    try {
      await this._commonService.presentLoading();
      if (RegisterIndividualForm.invalid) {
        this.viewModel.addInvalidFormClass = 'invalidFormStyle';
        return;
      }
      let roleType = RoleTypeSM.IndividualInspector;
      this.viewModel.register.userDetails.roleType = roleType;
      this.viewModel.register.userDetails.clientCompanyDetailId = 0;
      this.viewModel.register.userDetails.loginStatus = LoginStatusSM.Enabled;
      this.viewModel.register.userDetails.isEmailConfirmed = true;
      this.viewModel.register.userDetails.isPhoneNumberConfirmed = true;
      this.viewModel.register.userDetails.stripeCustomerId = '';
      let resp = await this.accountService.AddUser(this.viewModel.register.userDetails);
      if (resp.isError) {
        this._exceptionHandler.logObject(resp.errorData);
        this._commonService.showSweetAlertToast({ title: resp.errorData.displayMessage, icon: 'error' });
        return;
      } else {
        this._commonService.showInfoInModalPopup("success", "User Created Successfully", "Please Login Now");
        this.viewModel.tokenRequest.loginId = this.viewModel.register.userDetails.loginId;
        this.viewModel.tokenRequest.password = this.viewModel.register.userDetails.passwordHash;
        let LoginResp = await this.accountService.generateToken(this.viewModel.tokenRequest, this.viewModel.rememberMe);
        if (LoginResp.isError) {
          await this._exceptionHandler.logObject(LoginResp.errorData);
          this._exceptionHandler.logObject(LoginResp.errorData);
          this._commonService.showSweetAlertToast({ title: LoginResp.errorData.displayMessage, icon: 'error' });
        } else {
          this.activeModal.close(true);
          if (LoginResp.successData && LoginResp.successData.accessToken != null) {
            this._commonService.showSweetAlertToast({ title: 'Success', icon: 'success' });
            this._commonService.layoutViewModel.showProfile = true;
            this._commonService.layoutViewModel.isAuthenticated = true;
            this._commonService.layoutViewModel.roleType = LoginResp.successData.loginUserDetails.roleType;
            var userRoleType: any = RoleTypeSM[this._commonService.layoutViewModel.roleType];
            this._commonService.updateLayout(userRoleType, "");
            if (this.router.url == AppConstants.WebRoutes.PRICING || this.viewModel.currentRole == RoleTypeSM.CompanyAdmin) {
              this.router.navigate([`${AppConstants.WebRoutes.PRICING}`]);
            }
            else {

              this.router.navigate([`${AppConstants.WebRoutes.PROFILE}`]);
            }
            this.viewModel.currentRole = await this.authGuard.GetRoleFromToken();
            if (this.viewModel.currentRole == RoleTypeSM.CompanyInspector || this.viewModel.currentRole == RoleTypeSM.IndividualInspector) {
              await this.getCompanyInspectorProfilePicture();
              this._commonService.layoutViewModel.companyAdminProfilePicture = '';
            } else if (this.viewModel.currentRole == RoleTypeSM.CompanyAdmin) {
              await this.getCompanyAdminProfilePicture();
              this._commonService.layoutViewModel.inspectorProfilePicture = '';
            }

          }
        }
      }
    } catch (error) {
      this._commonService.showSweetAlertToast({ title: AppConstants.ErrorPrompts.Unknown_Error, icon: 'error' });
      throw error;
    } finally {
      await this._commonService.dismissLoader()
    }

  }

  async click_Login(loginForm: NgForm) {
    this.viewModel.LoginFormSubmitted = true;

    try {
      await this._commonService.presentLoading();
      if (loginForm.invalid)
        return;
      // if (this.viewModel.isCompany)
      //   this.viewModel.tokenRequest.roleType = RoleTypeSM.CompanyAdmin;
      // else
      //   this.viewModel.tokenRequest.roleType = RoleTypeSM.Inspector;
      let resp = await this.accountService.generateToken(this.viewModel.tokenRequest, this.viewModel.rememberMe);
      if (resp.isError) {
        await this._exceptionHandler.logObject(resp.errorData);
        this._exceptionHandler.logObject(resp.errorData);
        this._commonService.showSweetAlertToast({ title: resp.errorData.displayMessage, icon: 'error' });
      } else {
        this.activeModal.close(true);
        if (resp.successData && resp.successData.accessToken != null) {
          this._commonService.showSweetAlertToast({ title: 'Success', icon: 'success' });
          this._commonService.layoutViewModel.showProfile = true;
          this._commonService.layoutViewModel.isAuthenticated = true;
          this._commonService.layoutViewModel.roleType = resp.successData.loginUserDetails.roleType;
          var userRoleType: any = RoleTypeSM[this._commonService.layoutViewModel.roleType];
          this._commonService.updateLayout(userRoleType, "");
          if (this.router.url == AppConstants.WebRoutes.PRICING) {
            this.router.navigate([`${AppConstants.WebRoutes.PRICING}`]);
            location.reload();

          }
          else {
            this.router.navigate([`${AppConstants.WebRoutes.PROFILE}`]);
            await this._commonService.applyThemeGlobally();
          }
          this.viewModel.currentRole = await this.authGuard.GetRoleFromToken();
          if (this.viewModel.currentRole == RoleTypeSM.CompanyInspector || this.viewModel.currentRole == RoleTypeSM.IndividualInspector) {
            await this.getCompanyInspectorProfilePicture();
            this._commonService.layoutViewModel.companyAdminProfilePicture = '';
          } 
          else if (this.viewModel.currentRole == RoleTypeSM.CompanyAdmin) {
            await this.getCompanyAdminProfilePicture();
            this._commonService.layoutViewModel.inspectorProfilePicture = '';
          }
        }
        // this.router.navigate([this.redirectToPage]);
      }
    } catch (error) {
      this._commonService.showSweetAlertToast({ title: AppConstants.ErrorPrompts.Unknown_Error, icon: 'error' });
      throw error;
    } finally {
      await this._commonService.dismissLoader();
    }
  }


  async getCompanyInspectorProfilePicture() {
    try {
      await this._commonService.presentLoading();
      let resp = await this.profileInfoService.getInspectorProfile();
      if (resp.isError) {
        await this._exceptionHandler.logObject(resp.errorData);
        this._exceptionHandler.logObject(resp.errorData);
        this._commonService.showSweetAlertToast({ title: resp.errorData.displayMessage, icon: 'error' });
        return;
      }
      this._commonService.layoutViewModel.inspectorProfilePicture = resp.successData.profilePicturePath;
    } catch (error) {
      throw error;
    } finally {
      await this._commonService.dismissLoader();
    }
  }


  async getCompanyAdminProfilePicture() {
    try {
      await this._commonService.presentLoading();
      let resp1 = await this.companyProfileService.getCompanyProfile();
      if (resp1.isError) {
        this._exceptionHandler.logObject(resp1.errorData);
        this._commonService.showSweetAlertToast({ title: resp1.errorData.displayMessage, icon: 'error' });
      } else
        this._commonService.layoutViewModel.companyAdminProfilePicture = resp1.successData.companyLogoPath;
    } catch (error) {
      throw error;
    } finally {
      await this._commonService.dismissLoader();
    }
  }


  async openCompanySignUpModal() {
    this.activeModal.close(true); await this._commonService.presentCompanySignUpModal(this._commonService.layoutViewModel.currentRoute);
  }


  async openForgotPasswordModal() {
    this.activeModal.close(true);
    var x = await this._commonService.presentForgotPasswordModal(this._commonService.layoutViewModel.currentRoute);
    this._exceptionHandler.logObject(x);
  }

  async closeModal() {
    this.activeModal.close();
    window.location.reload();
  }


  switchToSignUp() {
    this.viewModel.containerClass = 'container right-panel-active';
    this.viewModel.RegisterFormSubmitted = false;
  }


  switchToSignIn() {
    this.viewModel.containerClass = 'container';
    this.viewModel.LoginFormSubmitted = false;
  }


  invalidForm() {
    this.viewModel.addInvalidFormClass = 'invalidFormStyle';
  }

}






