
import { UserLicenseDetailsSM } from "../service-models/app/v1/license/user-license-details-s-m";
import { BaseViewModel } from "./base.viewmodel";

export class LicenseInfoViewModel implements BaseViewModel {
    PageTitle: string = 'License Info';
    PageNo: number = 1;
    PageSize: number = 10;
    totalCount!:number;
    licenseInfo: UserLicenseDetailsSM[] = [];

}