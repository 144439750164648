import { CommonService } from "../services/common.service";
import { LogHandlerService } from "../services/log-handler.service";


export class BaseComponent<T>   {
    protected _commonService: CommonService;
    protected _exceptionHandler: LogHandlerService;
    viewModel!: T;
    constructor(commonService: CommonService, exceptionHandler: LogHandlerService) {
        this._commonService = commonService;
        this._exceptionHandler = exceptionHandler;
    }

    async loadPageData() {
    }

    // async show(textOrTpl: string | TemplateRef<any>, options: any = {}) {
    //     this.toasts.push({ textOrTpl, ...options });
    // }

    // async presentToast(message: string) {
    //     this.showToast = true;
    //     this.toasts.push({ message });
    // }

    // async remove(toast: any) {
    //     this.toasts = this.toasts.filter((t) => t !== toast);
    // }
}