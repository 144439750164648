
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { AppConstants } from 'src/app-constants';
import { AuthGuard } from 'src/app/guard/auth.guard';
import { LoginUserSM } from 'src/app/service-models/app/v1/app-users/login/login-user-s-m';
import { AccountService } from 'src/app/services/account.service';
import { CommonService } from 'src/app/services/common.service';
import { LogHandlerService } from 'src/app/services/log-handler.service';
import { StorageService } from 'src/app/services/storage.service';
import { AdminLoginViewModel } from 'src/app/view-models/admin/admin-login.viewmodal';
import { BaseComponent } from '../../base.component';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-adminlogin',
  templateUrl: './adminlogin.component.html',
  styleUrls: ['./adminlogin.component.css'],
})

export class AdminLoginComponent extends BaseComponent<AdminLoginViewModel> implements OnInit {
  constructor(commonService: CommonService,
    logService: LogHandlerService,
    private accountService: AccountService,
    private router: Router,
    private authGuard: AuthGuard,
    private storageService: StorageService) {
    super(commonService, logService);
    this.viewModel = new AdminLoginViewModel();
    // this.viewModel.tokenRequest = new TokenRequestSM();
    this._commonService.layoutViewModel.showLogin = false;
    this._commonService.layoutViewModel.showProfile = false;
    this._commonService.layoutViewModel.toggleWrapper = 'loginWrapper';
    this._commonService.layoutViewModel.showFooter = false;
    this._commonService.layoutViewModel.toggleContent = "content";
    this._commonService.layoutViewModel.showTopNav=true;
    // this._commonService.layoutViewModel.showSuperAdminTopBar = false;
  }


  async ngOnInit() {
    await this._commonService.presentLoading();
    setTimeout(async () => {
      await this._commonService.dismissLoader();
      await this.loadPageData();
    }, environment.animationTimeoutinMS);
  }

  override async loadPageData() {
    try {
      // this.viewModel.roleTypes = this._commonService.enumToStringArray(RoleTypeSM).filter(x => x == 'SuperAdmin' || x == 'SystemAdmin');
      let userValid = await this.authGuard.IsTokenValid();
      if (userValid) {
        let user: LoginUserSM | "" = await this.storageService.getDataFromAnyStorage(AppConstants.DbKeys.LOGIN_USER);
        if (user !== "") {
          this.viewModel.tokenRequest.loginId = user.loginId;
          this.viewModel.tokenRequest.password = await this.storageService.getFromStorage(AppConstants.DbKeys.PASSWORD);

        }
      }
    } catch (error) {
      throw (error)
    }
  }
  click_TogglePassword() {
    this.viewModel.hidePassword = !this.viewModel.hidePassword;
    if (this.viewModel.eyeDefault == 'default') {
      this.viewModel.eyeDefault = 'eyeChange';
    } else {
      this.viewModel.eyeDefault = 'default';
    }
    return;
  }


  async click_Login(loginForm: NgForm) {
    this.viewModel.FormSubmitted = true;
    try {
      if (loginForm.invalid)
        return;
      // await this._commonService.presentLoading();
      //have to do paging
      this.viewModel.tokenRequest.roleType = parseInt(this.viewModel.selectedRole);
      let resp = await this.accountService.generateToken(this.viewModel.tokenRequest, this.viewModel.rememberMe);
      if (resp.isError) {
        this._exceptionHandler.logObject(resp.errorData);
        this._commonService.showSweetAlertToast({ title: resp.errorData.displayMessage, icon: 'error' });
        return;
      }
      else {
        if (resp.successData.accessToken != null) {
          this._commonService.layoutViewModel.toggleWrapper = 'wrapper';
          this._commonService.showSweetAlertToast({ title: 'Success', icon: 'success' });
          this.router.navigate([AppConstants.WebRoutes.ADMIN.DASHBOARD]);
        }
      }
    } catch (error) {
      this._commonService.showSweetAlertToast({ title: AppConstants.ErrorPrompts.Unknown_Error, icon: 'error' });
      throw error;
    }
    finally {
      await this._commonService.dismissLoader()
    }
  }
}