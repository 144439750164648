     <div class="container-fluid mt-5 pt-5">
        <div class="message mt-5">
          <div class="text-container">
            <strong>404</strong>
            <p class="title">LOOKS LIKE YOU ARE LOST IN THE SPACE</p>
            <p class="message-text">
              The page you are looking for might be removed or is temporarily unavailable
            </p>
          </div>
        </div>
        <div class="box-astronaut">
          <img src="./assets/images/53292668-astronaut-cartoon.webp" alt="Astronaut" />
        </div>
      </div>
      