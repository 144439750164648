<div class="container-fluid">
    <div class="row">
        <div class="col-lg-11 col-md-6 col-sm-6 col-6">
            <nav aria-label="breadcrumb">

                <ul class="breadcrumb ">

                    <li class="breadcrumb-item" [ngClass]="viewModel.tabLocation == 0 ? 'active-tab' : ''">
                        <a (click)="updateTabLocation(0)">
                            <span> Overview </span>
                        </a>
                    </li>

                    <li class="breadcrumb-item " [ngClass]="viewModel.tabLocation == 1 ? 'active-tab' : ''">
                        <a (click)="updateTabLocation(1)">
                            <span> Users </span>
                        </a>
                    </li>

                    <li class="breadcrumb-item" [ngClass]="viewModel.tabLocation ==2 ? 'active-tab' : ''">
                        <a (click)="updateTabLocation(2)">
                            <span>Licenses</span>
                        </a>
                    </li>
                </ul>

            </nav>
        </div>
    </div>

    <div class="row justify-content-center" *ngIf="viewModel.tabLocation==0">
       <div class="col-lg-10 ">
            <div class="card ">
                <div class="card-header">
                    Company Information
                </div>
                <div class="card-body">
                    <div class="row mt-2">
                        <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                            <label for="Name" class="form-label">Name</label>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                            <input type="text" class="form-control" [(ngModel)]="viewModel.companyDetail.name">
                        </div>
                    </div>

                    <div class="row mt-2">
                        <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                            <label for="Name" class="form-label">Description</label>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                            <input type="text" class="form-control" [(ngModel)]="viewModel.companyDetail.description">
                        </div>
                    </div>

                    <div class="row mt-2">
                        <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                            <label for="Name" class="form-label">Email</label>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                            <input type="text" class="form-control" [(ngModel)]="viewModel.companyDetail.contactEmail">
                        </div>
                    </div>

                    <div class="row mt-2">
                        <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                            <label for="Name" class="form-label">Phone</label>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                            <input type="text" class="form-control"
                                [(ngModel)]="viewModel.companyDetail.companyMobileNumber">
                        </div>
                    </div>

                    <div class="row mt-2">
                        <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                            <label for="Name" class="form-label">Website</label>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                            <input type="text" class="form-control"
                                [(ngModel)]="viewModel.companyDetail.companyWebsite">
                        </div>
                    </div>

                    <div class="row mt-2">
                        <div class="col-lg-6 col-md-6 col-sm-12 col-6">
                            <label for="Name" class="form-label">Date of Establishment</label>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12 col-6">
                            <input type="text" class="form-control"
                                [(ngModel)]="viewModel.companyDetail.companyDateOfEstablishment">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div class="row" *ngIf="viewModel.tabLocation==1">
        <div class="table-title">
            <label class="inpectorsCount"> Total No. of Users:
                {{viewModel.totalCount}}</label>
            <table class="table mt-2 ">
                <thead>
                    <tr class="text-center">
                        <th class="firstTableHead">Login Id</th>
                        <th> First Name</th>
                        <th>Last Name</th>
                        <th>Email Id</th>
                        <th>Role </th>
                        <th class="lastTableHead">Login Status </th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="h5 text-center" *ngFor="let company of viewModel.companyUserList">
                        <td class="align-middle">{{company.loginId}}</td>
                        <td class="align-middle">{{company.firstName}}</td>
                        <td class="align-middle">{{company.lastName}}</td>
                        <td class="align-middle">{{company.emailId}}</td>
                        <td class="align-middle">{{company.roleType}}</td>

                        <td class="align-middle">
                            <div class="tw-toggle" [ngSwitch]="company.loginStatus">
                                <input checked="true" type="radio" *ngSwitchCase="'Enabled'">
                                <label (click)="updateLoginStatus('Enabled', company)"> Enabled </label>

                                <input checked="false" type="radio" *ngSwitchCase="'Disabled'">
                                <label (click)="updateLoginStatus('Disabled', company)"> Disabled </label>

                                <input checked="reset" type="radio" *ngSwitchCase="'PasswordResetRequired'">
                                <label (click)="updateLoginStatus('PasswordResetRequired', company)"> Reset </label>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>

            <div id="pagination"
                class="col-lg-12 col-md-12 col-sm-12 col-12  d-flex justify-content-center fixed-bottom">
                <ngb-pagination [collectionSize]="viewModel.totalCount" [(page)]="viewModel.PageNo"
                    [pageSize]="viewModel.PageSize" (pageChange)="loadPagedataWithPagination($event)"></ngb-pagination>
            </div>
        </div>
    </div>
  


    <div class="row" *ngIf="viewModel.tabLocation==2">
        <div class="table-title">
            <table class="table mt-2">
                <thead>
                    <tr class="text-center">
                        <th class="firstTableHead"> License Type</th>
                        <th>validity In Days</th>
                        <th>Paid Price </th>
                        <th>Issue Date</th>
                        <th class="lastTableHead">Expiry Date</th>
                        <!-- <th class="lastTableHead">Permission</th> -->
                    </tr>
                </thead>
                <tbody>
                    <tr class="h5 fw-normal text-center"
                    *ngFor="let companyLicense of viewModel.allCompanyLicenseDetails">
                    <td class="align-middle">{{companyLicense.featureGroupName}}</td>
                    <td class="align-middle">{{companyLicense.validityInDays}}</td>
                    <td class="align-middle">{{companyLicense.actualPaidPrice}}</td>

                    <td class="align-middle">{{companyLicense.startDateUTC | date: 'dd/MM/yyyy'}}</td>
                    <td class="align-middle">{{companyLicense.expiryDateUTC | date: 'dd/MM/yyyy'}}</td>
                    <!-- <td class="align-middle">
                        <div class="form-check form-switch">
                            <input mdbCheckbox class="form-check-input " type="checkbox" />
                        </div>
                    </td> -->
                </tr>
                </tbody>
            </table>

            <div id="pagination"
                class="col-lg-12 col-md-12 col-sm-12 col-12  d-flex justify-content-center fixed-bottom">
                <ngb-pagination [collectionSize]="viewModel.totalCount" [(page)]="viewModel.PageNo"
                    [pageSize]="viewModel.PageSize" (pageChange)="loadPagedataWithPagination($event)"></ngb-pagination>
            </div>
        </div>

    </div>

</div>