import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { DemoVideoSM } from '../service-models/app/v1/general/demo-video-s-m';
import { DemoVideoClient } from '../clients/demo-vidoes.client';
import { ApiResponse } from '../service-models/foundation/api-contracts/base/api-response';

@Injectable({
  providedIn: 'root'
})
export class DemoVideoService extends BaseService {

  constructor(private demoVideo: DemoVideoClient) {
    super();
  }
  async getDemoVideos(): Promise<ApiResponse<DemoVideoSM[]>> {
    return await this.demoVideo.GetDemoVideos();
  }
}
