import { Component } from '@angular/core';
import { CommonService } from './services/common.service';
import { AuthGuard } from './guard/auth.guard';
import { RoleTypeSM } from './service-models/app/enums/role-type-s-m.enum';
import { StorageService } from './services/storage.service';
import { LoginUserSM } from './service-models/app/v1/app-users/login/login-user-s-m';
import { AppConstants } from 'src/app-constants';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],

})
export class AppComponent {
  protected _commonService: CommonService;

  constructor(commonService: CommonService,
    private storageService: StorageService,
    private authGuard: AuthGuard,
  ) {
    this._commonService = commonService;
  }
  title = 'Survey Box';

  async ngOnInit() {
    await this.loadPageData();
  }

  async loadPageData() {
    try {
     
      await this.getLoggedInUser();
      if (this._commonService.layoutViewModel.roleType === RoleTypeSM.IndividualInspector ||
        this._commonService.layoutViewModel.roleType === RoleTypeSM.CompanyInspector ||
        this._commonService.layoutViewModel.roleType === RoleTypeSM.CompanyAdmin) {
        await this._commonService.applyThemeGlobally();
      } else {
        await this._commonService.loadDefaultTheme();
      }
    } catch (error) {
      throw error;
    }
  

  }

  async getLoggedInUser() {
    try {

      let userValid = await this.authGuard.IsTokenValid();
      if (userValid) {
        let user: LoginUserSM | "" = await this.storageService.getDataFromAnyStorage(AppConstants.DbKeys.LOGIN_USER);
        if (user !== "") {
          this._commonService.layoutViewModel.loginId = user.loginId;
          this._commonService.layoutViewModel.roleType = user.roleType;
        }

      }
    } catch (error) {
      throw (error)
    } finally {
      await this._commonService.dismissLoader();
    }
  }
}