<div class="container-fluid mt-5 pt-5">
    <div class="row">
        <div class="col-lg-9 col-md-12 col-sm-12 col-12 mx-auto">
            <div class="card mt-5">
                <div class="row">
                    <div class="col-lg-5">
                        <img src="assets/images/other-images/loginPage.jpg" alt="" class="img-fluid rounded"
                            id="centered-image">
                    </div>
                    <div class="col-lg-7 col-md-12 col-sm-12 col-12 signInContainer">
                        <form #loginForm="ngForm">
                            <h1 class="text-center mt-2  heading"> Sign In </h1>
                            <div class="form-group p-lg-3 radioBtn">
                                <div class="form-check form-check-inline ">
                                    <input class="form-check-input" type="radio" name="roleType" id="superad" value=1
                                        [ngModel]="viewModel.selectedRole" required #roleType="ngModel">
                                    <label class="form-check-label" for="superad">Super Admin</label>
                                </div>
                                <div class="form-check form-check-inline ">
                                    <input class="form-check-input" type="radio" name="roleType" id="sysad" value=2
                                        [ngModel]="viewModel.selectedRole" required #roleType="ngModel">
                                    <label class="form-check-label" for="sysad">System Admin</label>
                                </div>
                                <ng-container *ngFor="let validation of viewModel.ValidationData.roleType">
                                    <div class="alert alert-danger"
                                        *ngIf="(roleType.touched || viewModel.FormSubmitted) && roleType.hasError(validation.type)">
                                        {{ validation.message }}
                                    </div>
                                </ng-container>

                            </div>
                            <div class="form-floating">
                                <input type="text" class="form-control" [(ngModel)]="viewModel.tokenRequest.loginId"
                                    placeholder="Enter Username" #loginId="ngModel" required name="loginId" />
                                <label for="floatingInput"> Username
                                    <span> &#42; </span> </label>
                            </div>

                            <ng-container *ngFor="let validation of viewModel.ValidationData.loginId">
                                <div class="text-danger ms-lg-2 ms-md-2 ms-sm-2 ms-2"
                                    *ngIf="(loginId.touched || viewModel.FormSubmitted) && loginId.hasError(validation.type)">
                                    {{ validation.message }}
                                </div>
                            </ng-container>

                            <div class="password-container mt-lg-4 mt-md-3 mt-sm-3 mt-3 ">

                                <div class="form-floating password-container">
                                    <input [(ngModel)]="viewModel.tokenRequest.password" class="form-control "
                                        placeholder="Enter a valid password"
                                        [type]="viewModel.hidePassword ? 'password' : 'text'" #password="ngModel"
                                        required name="password" />
                                    <label for="floatingInputGroup1"> Password <span> &#42; </span> </label>
                                </div>

                                <span class="border-start-0" (click)="click_TogglePassword()">
                                    <i *ngIf="viewModel.hidePassword" class="bi bi-eye-fill"> </i>
                                    <i *ngIf="!viewModel.hidePassword" class="bi bi-eye-slash-fill"> </i>
                                </span>

                            </div>

                            <ng-container *ngFor="let validation of viewModel.ValidationData.password">
                                <div class="text-danger ms-lg-2 ms-md-2 ms-sm-2 ms-2"
                                    *ngIf="(password.touched || viewModel.FormSubmitted) && password.hasError(validation.type)">
                                    {{ validation.message }}
                                </div>
                            </ng-container>

                            <div class="checkbox-container pt-2 text-center">
                                <input type="checkbox" [(ngModel)]="viewModel.rememberMe"
                                    [ngModelOptions]="{standalone: true}" name="check">
                                <label for="remember-me">Remember Me ?</label>
                            </div>


                            <div class="signInBtn pt-3 pb-5">
                                <button type="button" class="spyGlassBtn" (window:keyup.enter)="click_Login(loginForm)"
                                    (click)="click_Login(loginForm)">Sign In</button>
                            </div>

                            <!-- <a class="forgotPasswordLink"> Forgot your password? </a> -->
                        </form>

                    </div>
                </div>
            </div>
        </div>
    </div>

</div>