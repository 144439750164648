import { SurveyBoxServiceModelBase } from '../../base/survey-box-service-model-base';

export class ClientCompanyAddressSM extends SurveyBoxServiceModelBase<number> {
    country!: string;
    state!: string;
    city!: string;
    address1!: string;
    address2!: string;
    pinCode!: string;
    clientCompanyDetailId!: number;
}
