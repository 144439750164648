import { Injectable } from "@angular/core";
import { AppConstants } from "src/app-constants";
import { ClientUserSM } from "../service-models/app/v1/app-users/client-user-s-m";
import { ApiRequest } from "../service-models/foundation/api-contracts/base/api-request";
import { ApiResponse } from "../service-models/foundation/api-contracts/base/api-response";
import { DeleteResponseRoot } from "../service-models/foundation/common-response/delete-response-root";
import { StorageService } from "../services/storage.service";
import { BaseApiClient } from "./base-client/base-api.client";
import { CommonResponseCodeHandler } from "./helpers/common-response-code-handler.helper";
import { StorageCache } from "./helpers/storage-cache.helper";



@Injectable({
    providedIn: 'root'
})
export class ProfileInfoClient extends BaseApiClient {
    constructor(storageService: StorageService, storageCache: StorageCache,
        commonResponseCodeHandler: CommonResponseCodeHandler) {
        super(storageService, storageCache, commonResponseCodeHandler)

    }

    // GetInspector = async (): Promise<ApiResponse<ClientUserSM[]>> => {
    //     let resp = await this.GetResponseAsync<number, ClientUserSM[]>
    //         (`${AppConstants.ApiUrls.USER_API_URL}`, 'GET');
    //     return resp;
    // }


    // GetInspectorById = async (Id: number): Promise<ApiResponse<ClientUserSM>> => {
    //     let resp = await this.GetResponseAsync<number, ClientUserSM>
    //         (`${AppConstants.ApiUrls.USER_API_URL}/${Id}`, 'GET');
    //     return resp;
    // }


    GetInspectorProfile = async (): Promise<ApiResponse<ClientUserSM>> => {
        let resp = await this.GetResponseAsync<number, ClientUserSM>
            (`${AppConstants.ApiUrls.USER_API_URL}/mine`, 'GET');
        return resp;
    }


    UpdateInspector = async (updateRequest: ApiRequest<ClientUserSM>): Promise<ApiResponse<ClientUserSM>> => {
        let resp = await this.GetResponseAsync<ClientUserSM, ClientUserSM>
            (`${AppConstants.ApiUrls.USER_API_URL}/${updateRequest.reqData.id}`, 'PUT', updateRequest);
        return resp;
    }

    UplodPicture = async (companyPictureRequest: ApiRequest<string>): Promise<ApiResponse<string>> => {
        let resp = await this.GetResponseAsync<string, string>
        (`${AppConstants.ApiUrls.USER_API_URL}/mine/ProfilePicture`,'POST', companyPictureRequest);
        return resp;
    }
    DeletePicture = async (id:number): Promise<ApiResponse<DeleteResponseRoot>> => {
        let resp = await this.GetResponseAsync<number, DeleteResponseRoot>
            (`${AppConstants.ApiUrls.USER_API_URL}/my/DeleteProfilePicture/${id}`, 'DELETE');
        return resp;
    }



}

