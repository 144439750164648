import { ClientUserAddressSM } from "../service-models/app/v1/app-users/client-user-address-s-m";
import { ClientUserSM } from "../service-models/app/v1/app-users/client-user-s-m";
import { ClientCompanyDetailSM } from "../service-models/app/v1/client/client-company-detail-s-m";
import { BoolResponseRoot } from "../service-models/foundation/common-response/bool-response-root";
import { BaseViewModel } from "./base.viewmodel";
export class ManageInspectorsviewModel implements BaseViewModel {
  PageTitle: string = 'Manage Inspectors';
  AddEditModalTitle: string = '';
  PageNo: number = 1;
  PageSize: number = 10;
  totalCount!: number;
  inspectors: ClientUserSM[] = [];
  inspectorsAddress:ClientUserAddressSM[]=[]
  singleInspector: ClientUserSM = new ClientUserSM();
  singleInspectorAddress: ClientUserAddressSM = new ClientUserAddressSM()
  companyDetail: ClientCompanyDetailSM = new ClientCompanyDetailSM();
  priorityTypes: string[] = [];
  selectedpriortyEnumItem: string = '';
  FormSubmitted: boolean = false;
  showSaveBtn: boolean = false;
  hidePassword: boolean = true;
  validUser: BoolResponseRoot = new BoolResponseRoot();
  showSuccessMsg: boolean = false;
  showErrorMsg: boolean = false;
  eyeDefault = 'default';
  ValidationData = {
    firstName: [
      { type: 'required', message: 'Please enter your first name' }
    ],
    lastName: [
      { type: 'required', message: 'Please enter your last name' }
    ],
    phone: [
      { type: "required", message: 'Please enter your phone number' }
    ],
    loginId: [
      { type: 'required', message: 'Please enter your loginId' }
    ],
    password: [
      { type: 'required', message: 'Please enter your password' }
    ],
    email: [
      { type: 'required', message: 'Please Enter your email' }
    ],
    address:[
      { type: 'required', message: 'Please Enter your address' }
    ],
    state:[
      { type: 'required', message: 'Please Enter your state' }
    ],
    city:[
      { type: 'required', message: 'Please Enter your city' }
    ],
    pin:[
      { type: 'required', message: 'Please Enter your pin' }
    ]
  }


}

//    priorityTypes: string[] = [];
//    selectedpriortyEnumItem: string = '';
//    showSaveBtn: boolean = false;
//    showUpdateBtn: boolean = false;