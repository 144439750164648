import { Injectable } from "@angular/core";
import { AppConstants } from "src/app-constants";
import {
  AdditionalRequestDetails,
  Authentication,
} from "../internal-models/additional-request-details";
import { ContactUsSM } from "../service-models/app/v1/general/contact-us-s-m";
import { ApiRequest } from "../service-models/foundation/api-contracts/base/api-request";
import { ApiResponse } from "../service-models/foundation/api-contracts/base/api-response";
import { OdataQueryFilter } from "../service-models/foundation/api-contracts/odata-query-filter";
import { IntResponseRoot } from "../service-models/foundation/common-response/int-response-root";
import { StorageService } from "../services/storage.service";
import { BaseApiClient } from "./base-client/base-api.client";
import { CommonResponseCodeHandler } from "./helpers/common-response-code-handler.helper";
import { StorageCache } from "./helpers/storage-cache.helper";
// import { ContactUsSM } from "../service-models/contact-us.s-m";

@Injectable({
  providedIn: "root",
})
export class ContactUsClient extends BaseApiClient {
  constructor(
    storageService: StorageService,
    storageCache: StorageCache,
    commonResponseCodeHandler: CommonResponseCodeHandler
  ) {
    super(storageService, storageCache, commonResponseCodeHandler);
  }

  /**Get all Contact list */
  GetAllContacts = async (): Promise<ApiResponse<ContactUsSM[]>> => {
    let resp = await this.GetResponseAsync<null, ContactUsSM[]>(
      `${AppConstants.ApiUrls.CONTACTUS_URL}`,
      "GET",
      null,
      new AdditionalRequestDetails<ContactUsSM[]>(false, Authentication.false)
    );
    return resp;
  };
  /**Add a new contact us form */
  AddContactUS = async (
    contactUsRequest: ApiRequest<ContactUsSM>
  ): Promise<ApiResponse<ContactUsSM>> => {
    let resp = await this.GetResponseAsync<ContactUsSM, ContactUsSM>(
      AppConstants.ApiUrls.CONTACTUS_URL,
      "POST",
      contactUsRequest,
      new AdditionalRequestDetails<ContactUsSM>(false, Authentication.false)
    );
    return resp;
  };
  GetAllContactsyOdata=async(queryFilter:OdataQueryFilter):Promise<ApiResponse<ContactUsSM[]>>=>{
    let finalUrl=this.ApplyQueryFilterToUrl(`${AppConstants.ApiUrls.CONTACTUS_URL}/odata`,queryFilter);
    let resp= await this.GetResponseAsync<null,ContactUsSM[]>(finalUrl,'GET')
    return resp;

}
GetAllContactsCount=async():Promise<ApiResponse<IntResponseRoot>>=>{
  let resp=await this.GetResponseAsync<null,IntResponseRoot>
  (`${AppConstants.ApiUrls.CONTACTUS_URL}/ContactUsCountResponse`,'GET')
   return resp;
}

}
