
<div class="container-fluid">
    <div class="table-title">
                 <label > Total No. of Companies: {{viewModel.totalCompanyCount}}</label>
    
        <table class="table mt-2">
            <thead>
                     <tr class="text-center">
                    <th class="firstTableHead">Id</th>
                    <th>Name</th>
                    <th>Website</th>
                    <th>Contact</th>
                    <th>Email</th>
                    <th>Login Status</th>
                    <th class="lastTableHead"><i class="bi bi-info-circle-fill"></i></th>

                </tr>
            </thead>
            <tbody>
               <tr class="h5  text-center" *ngFor="let company of viewModel.allCompanies">
                    <td class="align-middle">{{company.id}}</td>
                    <td class="align-middle">{{company.name}}</td>
                    <!-- <td class="align-middle" >{{company.description}}</td> -->
                    <td class="align-middle">{{company.companyWebsite}}</td>
                    <td class="align-middle">{{company.companyMobileNumber}}</td>
                    <td class="align-middle">{{company.contactEmail}}</td>
                    <td class="align-middle">
                        <div class="form-check form-switch">
                            <input mdbCheckbox class="form-check-input" (change)="updateLoginStatus($event,company)"
                                [checked]="company.isEnabled" type="checkbox" />
                        </div>
                    </td>

                    <!-- <td class="align-middle">{{company.}}</td> -->
                    <td class="align-middle">
                        <i class="bi bi-person-check-fill" routerLink="../companyOverview/{{company.id}}"> </i>
                    </td>

                </tr>
            </tbody>
        </table>


        <div id="pagination" class="col-lg-12 col-md-12 col-sm-12 col-12  d-flex justify-content-center fixed-bottom">
            <ngb-pagination [collectionSize]="viewModel.totalCompanyCount" [(page)]="viewModel.PageNo"
                [pageSize]="viewModel.PageSize" (pageChange)="loadPagedataWithPagination($event)"></ngb-pagination>
        </div>   
    </div>