<!-- <div class="container-fluid">
    <div class="row pb-lg-3 pb-md-3 pb-sm-3 pb-3 cardsRow">
        <div class="card m-lg-2 m-md-2 m-sm-2 m-2">
            <div class="card-body text-center py-0">
                <div class="cardHeading mb-lg-2 mb-md-2 mb-sm-2 mb-2">
                    <span class="icon">
                        <i class="bi bi-send"> </i>
                    </span>

                    <h5>
                        {{viewModel.featureGroup.title}}
                    </h5>

                    <label> <b> ${{viewModel.featureGroup.amount}} </b> /{{viewModel.featureGroup.validityInDays}} Days
                    </label>
                </div>

                <div class="features">
                    <ul class="list-unstyled">
                        <li *ngFor="let features of viewModel.featureGroup.features">
                            <i class="bi bi-check2-circle"> </i>
                            <span>
                                {{features.title}}
                            </span>
                        </li>

                    </ul>

                </div>
            </div>
            <button type="button" class="spyGlassBtn" (click)="click_PayNow()"> {{viewModel.btnPayText}} </button>
        </div>
    </div>
</div> -->

<div *ngIf="viewModel.activeLicense==null||viewModel.activeLicense.status=='null'||viewModel.emptydiv"></div>




<!-- Buy Now Page -->
<div class="container-fluid   mt-5 pt-5"
    *ngIf="viewModel.activeLicense&&viewModel.activeLicense.status=='inactive' && viewModel.activeLicense.isCancelled == false || viewModel.buy">
    <div class="row">
        <div class="col-lg-10 col-md-10 col-sm-12 col-12  mx-auto ">
            <div class="card  mt-2 pb-lg-2 pb-lg-2 pb-md-2 pb-sm-2 pb-2  paynowcard">
                <div class="card-header">
                     <h2 class="text-center">Payment Summary Page</h2>
                    <!-- <h2 class="text-center">You're purchasing {{viewModel.featureGroup.title}} license</h2> -->
                </div>
                <div class="card-body">
                    <h3 class="text-center mt-5 ">You're ready to use SpyGlass Inspection Software
                    </h3>
                    <br>
                    

                    <div class="card text-center payNowCardInfo" >
                        <div class="row  mt-3 ">
                            <div class="col-lg-6 col-md-12 col-sm-12 col-12 " >
                                <h4>You're Purchasing</h4>
                            </div>
                            <div class="col-lg-6 col-md-12 col-sm-12 col-12">
                                <h4><span>{{viewModel.featureGroup.title}}</span></h4>

                            </div>
                        </div>

                        <div class="row  mt-3 ">
                            <div class="col-lg-6 col-md-12 col-sm-12 col-12">
                                <h4>Amount</h4>
                            </div>
                            <div class="col-lg-6 col-md-12 col-sm-12 col-12">
                                <h4><span>{{viewModel.featureGroup.amount}}</span></h4>
                            </div>
                        </div>

                        <div class="row   mt-3 ">
                            <div class="col-lg-6 col-md-12 col-sm-12 col-12">
                                <h4>Validity In Days</h4>
                            </div>
                            <div class="col-lg-6 col-md-12 col-sm-12 col-12">
                                <h4><span>{{viewModel.featureGroup.validityInDays}}</span></h4>
                            </div>
                        </div>
                      
                
                    </div>
                    

<!--                   
                    <div class="select">
                        <h6 class="text-start">Select any payment mode</h6>
                        <select class="form-select form-select mb-3 select-wrapper" style="width: 50%;">
                            <option selected>Credit Card</option>
                            <option value="1">Dedit Card</option>
                            <option value="2">Netbanking</option>
                            <option value="2">Paypal</option>
                        </select>
                    </div> -->
                    <span class="text mt-4">
                        By clicking Pay Now,you agree to the terms and conditions of SpyGlass
                        inspection software starting today
                    </span>

                    <div class="text-center ">
                        <button type="button" class="spyGlassBtn pay__now mt-5" (click)="click_PayNow()"> Pay Now
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<!-- Upgrade Page -->
<div class="container-fluid upgradeCard  mt-5 pt-5"
    *ngIf="viewModel.activeLicense.status=='active'|| viewModel.upgrade">
    <div class="row centered">
        <div class="col-lg-4 col-md-12 col-12 px-5 mt-3 col-6 pb-3">
            <div class="card outerCard">
                <div class="card-header card-head">
                    <h2 class="text-center">{{viewModel.upgradeSubscriptionResponse.newPlanName}}</h2>
                    <h6 class="text-center">${{viewModel.upgradeSubscriptionResponse.newPlanPrice}}/month</h6>

                </div>


                <div class="features">
                    <div class="card-body text-center py-0">
                        <u> Included Features:</u>
                        <div class="features" style="height: 380px;">
                            <ul class="list-unstyled">
                                <li class="text-start" *ngFor="let features of viewModel.featureGroup.features">
                                    <i class="bi bi-check2-circle"> </i>
                                    <span>
                                        {{features.title}}
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <!-- <div class="text-center ">
                    <button type="button" class="spyGlassBtn pay__now" (click)="click_Upgrade()"> Upgrade Now </button>
                </div> -->
                <!-- <small class="text-center small"> From onwards,you
                    will be billed $399
                    monthly</small> -->
            </div>
        </div>
        <div class="col-lg-5  col-md-12 col-12 px-5 mt-4 col-6 currentPlan">
            <div class="card ">
                <div class="card-header card-head">
                    <h4 class="text-center">Current Plan Details</h4>
                </div>
                <div class="row pb-3 px-5 mt-3">
                    <div class="col">
                        <h4>{{viewModel.upgradeSubscriptionResponse.currentPlanName}}</h4>
                    </div>
                    <div class="col">
                        <span class="active">{{viewModel.activeLicense.status}}</span>
                    </div>
                </div>

                <div class="row pb-3 px-5">
                    <div class="col">
                        <h4 class="text-start">${{viewModel.upgradeSubscriptionResponse.currentPlanPrice}}</h4>
                    </div>
                    <div class="col">
                        <h5>{{viewModel.featureGroup.validityInDays}}days</h5>
                    </div>
                </div>



                <div class="row pb-3 px-5">
                    <div class="col">
                        <h4 class="text-start">Start Date</h4>
                    </div>
                    <div class="col">
                        <h5>{{viewModel.activeLicense.startDateUTC | date:'shortDate'}}</h5>
                    </div>
                </div>

                <div class="row pb-2 px-5">
                    <div class="col">
                        <h4 class="text-start">End Date</h4>
                    </div>
                    <div class="col">
                        <h5>{{viewModel.activeLicense.expiryDateUTC| date:'shortDate'}}
                        </h5>
                    </div>
                </div>
            </div>
            <h4 class="text-center mt-3" style="color: black;"> Prorated Amount:
                {{viewModel.upgradeSubscriptionResponse.proratedAmount}} 
            </h4>
            <h6 class="text-center" style="color: black;">Next Billing Date
                {{viewModel.upgradeSubscriptionResponse.nextBillingDate |date:'MM/d/yyyy'}}
            </h6>
            <div class="text-center mt-5">
                <button type="button" class="spyGlassBtn pay__now" (click)="click_Upgrade()">Upgrade to
                    {{viewModel.upgradeSubscriptionResponse.newPlanName}}</button>
            </div>
        </div>

    </div>
</div>














































<!-- 

   <div class="row"> 
        <div class="card col-lg-12 col-md-10 col-sm-12 col-12  m-auto">
            <div class="row">
                <div class="card col-lg-6 col-md-10 col-sm-12 col-12 pt-lg-3 text-center">
                    <h3>Your Current License</h3>

                    <h4 style="color: #6c8491;">{{viewModel.activeLicense.featureGroupName}}</h4>
                    <b>
                        <p>${{viewModel.activeLicense.actualPaidPrice}}/month</p>
                    </b><br> -->
<!-- <hr> -->


<!-- <div class="card text-center"> -->

<!-- <div class="card-body"> -->
<!-- <h5 class="card-title"></h5> -->
<!-- <div class="row mt-4">
                                <div class="col-7"> <label for="">Paid Price:</label> </div>
                                <div class="col-1"> <label for="">{{viewModel.activeLicense.actualPaidPrice}}</label>
                                </div>
                            </div>

                            <div class="row mt-4">
                                <div class="col-7"> <label for="">Status:</label> </div>
                                <div class="col-1 capitalize"> <label for="">{{viewModel.activeLicense.status}}</label>
                                </div>
                            </div>

                            <div class="row mt-4">
                                <div class="col-7"> <label for="">Start Date:</label> </div>
                                <div class="col-1"> <label for="">{{viewModel.activeLicense.startDateUTC |
                                        date:'shortDate'}}</label></div>
                            </div>

                            <div class="row mt-4">
                                <div class="col-7"> <label for="">End Date:</label> </div>
                                <div class="col-1"> <label for="">{{viewModel.activeLicense.expiryDateUTC |
                                        date:'shortDate'}}</label></div>
                            </div>

                            <div class="row mt-4">
                                <div class="col-7"> <label for="">Validity In Days:</label> </div>
                                <div class="col-1"> <label for="">{{viewModel.activeLicense.validityInDays
                                        }}</label></div>
                            </div> -->

<!-- </div> -->
<!-- <div class="card-footer" style="background-color: #116530; color: white;"> -->
<!-- {{viewModel.activeLicense.status}} -->
<!-- </div> -->
<!-- </div> -->
<!-- </div>

                <div class="card col-lg-6 col-md-10 col-sm-12 col-12 pt-lg-3 text-center ">


                    <h3>Upgrade To New License</h3>
                    <h4 style="color: #6c8491;">{{viewModel.featureGroup.title}}</h4>
                    <b>
                        <p>${{viewModel.featureGroup.amount}}/month</p>
                    </b><br>
                    <hr>

                    <div class="features">
                        <div class="card-body  py-0">Included Features:
                            <div class="features">
                                <ul class="list-unstyled">
                                    <li class="text-start" *ngFor="let features of viewModel.featureGroup.features">
                                        <i class="bi bi-check2-circle"> </i>
                                        <span>
                                            {{features.title}}
                                        </span>
                                    </li>

                                </ul>

                            </div>
                            <div class="text-center mt-5">
                                <button type="button" class="spyGlassBtn" (click)="click_PayNow()"> Pay Now </button>
                            </div>
                        </div>

                    </div>


                </div>
            </div>
        </div>
    </div> 
</div> -->