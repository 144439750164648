
import { FeatureGroupSM } from "../service-models/app/v1/license/feature-group-s-m";
import { BaseViewModel } from "./base.viewmodel";

export class SuccessPaymentViewModel implements BaseViewModel {
    PageTitle: string = 'Success Payment';
    paymentInfo!: PaymentStatusInfo;
    featureGroup: FeatureGroupSM = new FeatureGroupSM();
}

export interface PaymentStatusInfo {
    fgId: number;
    paymentDate: Date;
}