import { ResourceLocationSM } from "../service-models/app/enums/resource-location-s-m.enum";
import { ContactUsSM } from "../service-models/app/v1/general/contact-us-s-m";
import { FrequentlyAskedQuestionsSM } from "../service-models/app/v1/general/frequently-asked-questions-s-m";
import { WebsiteResourceSM } from "../service-models/app/v1/general/website-resource-s-m";
import { BaseViewModel } from "./base.viewmodel";

export class HomeViewModel implements BaseViewModel {
    websiteResourceList: WebsiteResourceSM[] = [];
    websiteResource: WebsiteResourceSM = new WebsiteResourceSM();

    homeMainImage: string = "";
    homeProductImage1: string = "";
    homeProductImage2: string = "";
    homeFaqImage: string = "";


    PageTitle: string = 'Home'
    fragment: string = '';
    teachers: ContactUsSM[] = [];
    singleContact: ContactUsSM = new ContactUsSM();
    faqs: FrequentlyAskedQuestionsSM[] = [];
    dummyImage: boolean = false;
    resourceLocation = new Array<string>();
    resourceType = new Array<string>();
    resourceLocationType = ResourceLocationSM;
    answerSection = 'answerSectionDefault';
    showUpIcon: boolean = false;
    showDownIcon: boolean = true;
    selectedFaqId: number = 0;
    FormSubmitted = false;


    ValidationData = {
        name: [
            { type: 'required', message: 'Name is required' },
            // { type: 'maxlength', message: 'Login Id is max' },
            // { type: 'minlength', message: 'Login Id is min' },
        ],
        emailId: [
            { type: 'required', message: 'Email Id is required' },
            // { type: 'maxlength', message: 'password  is max' },
            // { type: 'minlength', message: 'password  is min' },
        ],
        message: [
            { type: 'required', message: 'Message is required' },
            { type: 'maxlength', message: 'Maximum Character length is 20' },
            { type: 'minlength', message: 'Minimum Character length is 20' },
        ],
    }
}


