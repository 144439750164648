
<div class="container-fluid ">
    <div class="table-title">
        <div class="row">
            <div class="col-lg-4 col-md-12 col-12"> <label > Total No. of News: {{viewModel.totalCount}}</label></div>
            <div class="col-lg-8 col-md-12 col-12"> <button type="button" class="addBtnSuperAdmin"
                    (click)="click_OpenAddEditModal(addEditModal,0)"><i class="bi bi-plus"></i> Add
                    News</button></div>
        </div>

        <table class="table mt-2">
            <thead>
                <tr class="text-center ">
                    <th class="firstTableHead">Image</th>
                    <th>Title</th>
                    <th>Description</th>
                    <th>Start Date</th>
                    <th> End Date</th>
                    <th class="lastTableHead">Actions</th>
                </tr>
            </thead>
            <tbody>
                <tr class="h5 text-center" *ngFor="let news of viewModel.newsFeedList">
                    <td><img src="assets/images/productInfo1.jpg,{{news.iconToShow}}"></td>
                    <td class="align-middle">{{news.title}}</td>
                    <td class="align-middle">{{news.description}}</td>
                    <td class="align-middle">{{news.startDateToShow | date :'short':'locale'}}</td>
                    <td class="align-middle">{{news.endDateToShow| date :'short':'locale'}}</td>
                    <td class="align-middle">
                        <i class="bi bi-pencil-fill" (click)="click_OpenAddEditModal(addEditModal,news.id)"> </i>
                        &nbsp; <i class="bi bi-trash-fill" (click)="deleteNews(news.id)"></i>
                    </td>
                </tr>
            </tbody>
        </table>


        <div id="pagination" class="col-lg-12 col-md-12 col-sm-12 col-12  d-flex justify-content-center fixed-bottom">
            <ngb-pagination [collectionSize]="viewModel.totalCount" [(page)]="viewModel.PageNo"
                [pageSize]="viewModel.PageSize" (pageChange)="loadPagedataWithPagination($event)"></ngb-pagination>
        </div>

        <ng-template #addEditModal let-modal>
            <form #form_NewsFeed="ngForm" (ngSubmit)="form_addEditNews(form_NewsFeed)" class="InspectorForm">
                <div class="modal-header">
                    <h4 class="modal-title">{{viewModel.AddEditModalTitle}}</h4>
                    <button type="button" class="btn-close" aria-label="Close"
                        (click)="modal.dismiss('Cross click')"></button>
                </div>
                <div class="modal-body">
                    <div class="body">
                        <div class="form-group">
                            <label class="form-label"> Title </label>
                            <input [(ngModel)]="viewModel.singleNewsFeed.title" class="form-control" placeholder=" Enter title"
                                #title="ngModel" required name="title" />
                            <ng-container *ngFor="let validation of viewModel.ValidationData.title">
                                <div class="alert alert-danger"
                                    *ngIf="(title.touched || viewModel.FormSubmitted) && title.hasError(validation.type)">
                                    {{ validation.message }}
                                </div>
                            </ng-container>
                        </div>
                        <div class="form-group">
                            <label class="form-label" > Description </label>
                            <input [(ngModel)]="viewModel.singleNewsFeed.description" class="form-control"
                                placeholder="Enter description" #description="ngModel" required="" name="description" />
                            <ng-container *ngFor="let validation of viewModel.ValidationData.description">
                                <div class="alert alert-danger"
                                    *ngIf="(description.touched || viewModel.FormSubmitted) && description.hasError(validation.type)">
                                    {{ validation.message }}
                                </div>
                            </ng-container>

                        </div>


                        <div class="form-group">
                            <label  class="form-label"> Start Date </label>
                            <input type="datetime-local" [(ngModel)]="viewModel.singleNewsFeed.startDateToShow"
                                class="form-control" placeholder="Start Date" #startDateToShow="ngModel"
                                name="startDateToShow" min="{{viewModel.minStartDate}}"
                                [max]="viewModel.singleNewsFeed.endDateToShow" />
                        </div>

                        <div class="form-group">
                            <label  class="form-label"> End Date </label>
                            <input type="datetime-local" [(ngModel)]="viewModel.singleNewsFeed.endDateToShow"
                                class="form-control" placeholder="End Date" #endDateToShow="ngModel"
                                name="endDateToShow" [min]="viewModel.singleNewsFeed.startDateToShow" />
                        </div>

                        <div class="form-group">
                            <label  class="form-label"> Priority </label>
                            <select class="form-select form-control" aria-label="Default select example"
                                [(ngModel)]="viewModel.selectedpriortyEnumItem" #selectedpriortyEnumItem="ngModel"
                                name="selectedpriortyEnumItem">
                                <option *ngFor="let items of viewModel.priorityTypes" value="{{items}}">
                                    {{items}}
                                </option>
                            </select>
                        </div>

                        <div class="form-group">
                            <label  class="form-label" > Choose File </label>
                            <input type="file" [value]="viewModel.singleNewsFeed.iconToShow"
                                [(ngModel)]="viewModel.singleNewsFeed.iconToShow"
                                accept=".png,.jpg,.jpeg,.jpe,.jfif,.JPG,.PNG,.JPEG,.JPE,.JFIF,.BMP,.bmp"
                                class="form-control" placeholder="choose file" #chooseFile="ngModel" required
                                name="choosefile" (change)="uploadFile($event)" />
                            <ng-container *ngFor="let validation of viewModel.ValidationData.chooseFile">
                                <div class="alert alert-danger"
                                    *ngIf="(chooseFile.touched || viewModel.FormSubmitted) && chooseFile.hasError(validation.type)">
                                    {{ validation.message }}
                                </div>
                            </ng-container>
                        </div>
                    </div>

                </div>
                <div class="modal-footer">
                    <button class="spyGlassBtn" type="submit"> Save </button>
                </div>
            </form>
        </ng-template>
    </div>


