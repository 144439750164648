import { Component, OnInit, TemplateRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppConstants } from 'src/app-constants';
import { RoleTypeSM } from 'src/app/service-models/app/enums/role-type-s-m.enum';
import { ApiResponse } from 'src/app/service-models/foundation/api-contracts/base/api-response';
import { AccountService } from 'src/app/services/account.service';
import { CommonService } from 'src/app/services/common.service';
import { LogHandlerService } from 'src/app/services/log-handler.service';
import { ProfileInfoService } from 'src/app/services/profile-info.service';
import { ProfileInfoViewModel } from 'src/app/view-models/profie-info.viewModel';
import { BaseComponent } from '../../components/base.component';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-profile-info',
  templateUrl: './profile-info.component.html',
  styleUrls: ['./profile-info.component.scss']
})


export class ProfileInfoComponent extends BaseComponent<ProfileInfoViewModel> implements OnInit {

  constructor(commonService: CommonService, logService: LogHandlerService,
    public activeModal: NgbActiveModal,
    private profileInfoService: ProfileInfoService,
    private modalService: NgbModal,
  ) {
    super(commonService, logService)
    this.viewModel = new ProfileInfoViewModel();
    this._commonService.layoutViewModel.showProfile = true;
  }


  async ngOnInit() {
    await this._commonService.presentLoading();
    setTimeout(async () => {
      await this._commonService.dismissLoader();
      await this.loadPageData();
    }, environment.animationTimeoutinMS);

  }

  /**
   * Get inspector Profile Info
   * @returns 
   */

  override async loadPageData() {
    try {
      await this._commonService.presentLoading();
      let resp = await this.profileInfoService.getInspectorProfile();
      if (resp.isError) {
        await this._exceptionHandler.logObject(resp.errorData);
        this._exceptionHandler.logObject(resp.errorData);
        this._commonService.showSweetAlertToast({ title: resp.errorData.displayMessage, icon: 'error' });
        return;
      } else {
        this.viewModel.userDetails = resp.successData;
        if (this.viewModel.userDetails.profilePicturePath == "" || this.viewModel.userDetails.profilePicturePath == null) {
          this.viewModel.isProfilePicUploaded = false;
          this.viewModel.showDeleteBtn = false;
        } else {
          this.viewModel.isProfilePicUploaded = true;
          this.viewModel.showDeleteBtn = true;
        }
      }
    } catch (error) {
      this._commonService.showSweetAlertToast({ title: AppConstants.ErrorPrompts.Unknown_Error, icon: 'error' });
      throw error;
    }
    finally {
      await this._commonService.dismissLoader();
      // this._commonService.updateLayout(RoleTypeSM.Inspector, "");
    }
  }


  /**Update Inspector
   * @param userDetailsId
   * @returns success data
   */
  async updateInspector() {
    this.viewModel.FormSubmitted=true;
    try {
      await this._commonService.presentLoading();
      let resp = await this.profileInfoService.updateInspector(this.viewModel.userDetails);
      if (resp.isError) {
        await this._exceptionHandler.logObject(resp.errorData);
        this._exceptionHandler.logObject(resp.errorData);
        this._commonService.showSweetAlertToast({ title: resp.errorData.displayMessage, icon: 'error' });
        return;
      } else {
        if (resp.successData != null) {
          this._commonService.showSweetAlertToast({ title: 'Success', icon: 'success' });
          this.viewModel.userDetails = resp.successData;
        }
      }
    } catch (error) {
      this._commonService.showSweetAlertToast({ title: AppConstants.ErrorPrompts.Unknown_Error, icon: 'error' });
      throw error;
    } finally {
      await this._commonService.dismissLoader()
      this._commonService.updateLayout(RoleTypeSM.IndividualInspector, "");
    }
  }


  /**
   * Enable Editing
   */
  async update() {
    this.viewModel.isDisabled = false;
  }

  uploadFile(event: any) {
    this._commonService
      .convertFileToBase64(event.target.files[0])
      .subscribe((base64) => {
        let fileName = event.target.files[0].name;
        fileName.split("?")[0].split(".").pop();
        this.viewModel.userDetails.profilePicturePath = base64;
        if (this.viewModel.userDetails.profilePicturePath !== "" || this.viewModel.userDetails.profilePicturePath !== undefined) {
          this.viewModel.isProfilePicUploaded = true;
        }

      });
  }

  async click_OpenAddEditModal(InspectorModal: TemplateRef<any>, inspectorId: number) {
    this.viewModel.AddEditModalTitle = inspectorId > 0 ? 'Update Inspector' : 'Upload Picture';
    // this.viewModel.companyDetails = new ClientCompanyDetailSM()

    // if (inspectorId > 0) {
    //   // await this.getInspectorById(inspectorId);
    //   // await this.getInspectorAddressById(inspectorId)
    // }
    await this.modalService.open(InspectorModal).result.then(
      (result: any) => {
      },
      (reason: any) => {
      },
    );
  }

  async form_uploadPicture(inspectorForm: NgForm) {
    try {
      await this._commonService.presentLoading();
      let resp: ApiResponse<string>;
      resp = await this.profileInfoService.uploadProfilePicture(this.viewModel.userDetails.profilePicturePath);

      if (resp.isError) {

        await this._exceptionHandler.logObject(resp.errorData);
        this._commonService.showSweetAlertToast({ title: resp.errorData.displayMessage, icon: 'error' });
      }
      else {
        this.modalService.dismissAll();
        this._commonService.showSweetAlertToast({ title: 'Success', icon: 'success' });
        this.loadPageData();
      }
    } catch (error) {
      this._commonService.showSweetAlertToast({
        title: AppConstants.ErrorPrompts.Unknown_Error,
        icon: "error",
      });
      throw error;
    } finally {
      await this._commonService.dismissLoader()
    }

  }

  async deleteProfile(id: number) {
    let deleteConfirmation = await this._commonService.showSweetAlertConfirmation({
      title: `${AppConstants.DefaultMessages.DeleteAlertConfirmation} picture?`,
      text: " ",
      showCancelButton: true,
      icon: "warning"
    }
    );
    if (deleteConfirmation) {
      try {
        let resp = await this.profileInfoService.deleteProfilePicture(id);
        if (resp.isError) {
          await this._exceptionHandler.logObject(resp.errorData);
          this._commonService.showSweetAlertToast({ title: resp.errorData.displayMessage, icon: 'error' });

        } else {
          this.modalService.dismissAll();
          this._commonService.showSweetAlertToast({ title: 'Success', icon: 'success' });

          this.loadPageData();
          return;
        }
      } catch (error) {
        this._commonService.showSweetAlertToast({ title: 'Error', icon: 'error' });
        throw error;
      } finally {
        await this._commonService.dismissLoader();
      }
    }
  }


  async getCompanyInspectorProfilePicture() {
    try {
      await this._commonService.presentLoading();
      let resp = await this.profileInfoService.uploadProfilePicture(this.viewModel.userDetails.profilePicturePath);
      if (resp.isError) {
        await this._exceptionHandler.logObject(resp.errorData);
        this._exceptionHandler.logObject(resp.errorData);
        this._commonService.showSweetAlertToast({ title: resp.errorData.displayMessage, icon: 'error' });
        return;
      }
      this._commonService.layoutViewModel.inspectorProfilePicture = resp.successData;
    } catch (error) {

    }
  }
}
















