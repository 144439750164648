import { Injectable } from "@angular/core";
import { AppConstants } from "src/app-constants";
import { AdditionalRequestDetails, Authentication } from "../internal-models/additional-request-details";
import { FrequentlyAskedQuestionsSM } from "../service-models/app/v1/general/frequently-asked-questions-s-m";
import { ApiRequest } from "../service-models/foundation/api-contracts/base/api-request";
import { ApiResponse } from "../service-models/foundation/api-contracts/base/api-response";
import { OdataQueryFilter } from "../service-models/foundation/api-contracts/odata-query-filter";
import { QueryFilter } from "../service-models/foundation/api-contracts/query-filter";
import { DeleteResponseRoot } from "../service-models/foundation/common-response/delete-response-root";
import { IntResponseRoot } from "../service-models/foundation/common-response/int-response-root";
import { StorageService } from "../services/storage.service";
import { BaseApiClient } from "./base-client/base-api.client";
import { CommonResponseCodeHandler } from "./helpers/common-response-code-handler.helper";
import { StorageCache } from "./helpers/storage-cache.helper";



@Injectable({
    providedIn: 'root'
})
export class FrequentlyAskedQuestionClient extends BaseApiClient {
    constructor(storageService: StorageService, storageCache: StorageCache,
        commonResponseCodeHandler: CommonResponseCodeHandler) {
        super(storageService, storageCache, commonResponseCodeHandler)

    }
    /**Get Faq based on odata query */


    /**Get all Faq */
    GetAllFaq = async (): Promise<ApiResponse<FrequentlyAskedQuestionsSM[]>> => {
        let resp = await this.GetResponseAsync<null, FrequentlyAskedQuestionsSM[]>
            (`${AppConstants.ApiUrls.FAQs_API_Url}`, 'GET', null,
                new AdditionalRequestDetails<FrequentlyAskedQuestionsSM[]>(false, Authentication.false));
        return resp;
    }

    GetAllFaqCount = async (): Promise<ApiResponse<IntResponseRoot>> => {
        let resp = await this.GetResponseAsync<null, IntResponseRoot>
            (`${AppConstants.ApiUrls.FAQs_API_Url}/FAQsCountResponse`, "GET");
        return resp;
    }

    GetAllFaqByOdata = async (queryFilter: OdataQueryFilter): Promise<ApiResponse<FrequentlyAskedQuestionsSM[]>> => {
        let finalUrl = this.ApplyQueryFilterToUrl(`${AppConstants.ApiUrls.FAQs_API_Url}/odata`, queryFilter);
        let resp = await this.GetResponseAsync<null, FrequentlyAskedQuestionsSM[]>(finalUrl, 'GET');
        return resp;

    }

    /**Get teacher by id */
    GetFaqById = async (Id: number): Promise<ApiResponse<FrequentlyAskedQuestionsSM>> => {
        let resp = await this.GetResponseAsync<number, FrequentlyAskedQuestionsSM>
            (`${AppConstants.ApiUrls.FAQs_API_Url}/${Id}`, 'GET');
        return resp;
    }

    /**Add a new teacher */
    AddFaq = async (addRequest: ApiRequest<FrequentlyAskedQuestionsSM>): Promise<ApiResponse<FrequentlyAskedQuestionsSM>> => {
        let resp = await this.GetResponseAsync<FrequentlyAskedQuestionsSM, FrequentlyAskedQuestionsSM>
            (AppConstants.ApiUrls.FAQs_API_Url, 'POST', addRequest);
        return resp;
    }

    /**Update Teacher*/
    UpdateFaq = async (updateRequest: ApiRequest<FrequentlyAskedQuestionsSM>): Promise<ApiResponse<FrequentlyAskedQuestionsSM>> => {
        let resp = await this.GetResponseAsync<FrequentlyAskedQuestionsSM, FrequentlyAskedQuestionsSM>
            (`${AppConstants.ApiUrls.FAQs_API_Url}/${updateRequest.reqData.id}`, 'PUT', updateRequest);
        return resp;
    }

    /**delete teacher by id */
    DeleteFaqById = async (Id: number): Promise<ApiResponse<DeleteResponseRoot>> => {
        let resp = await this.GetResponseAsync<number, DeleteResponseRoot>
            (`${AppConstants.ApiUrls.FAQs_API_Url}/${Id}`, 'DELETE');
        return resp;
    }


}