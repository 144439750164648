import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { WebsiteResourceClient } from '../clients/website-resource.client';
import { AppConstants } from 'src/app-constants';
import { WebsiteResourceSM } from '../service-models/app/v1/general/website-resource-s-m';
import { ApiRequest } from '../service-models/foundation/api-contracts/base/api-request';
import { ApiResponse } from '../service-models/foundation/api-contracts/base/api-response';
import { DeleteResponseRoot } from '../service-models/foundation/common-response/delete-response-root';
import { IntResponseRoot } from '../service-models/foundation/common-response/int-response-root';
import { OdataQueryFilter } from '../service-models/foundation/api-contracts/odata-query-filter';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})

export class WebsiteResourceService extends BaseService {

  constructor(private webResourseClient: WebsiteResourceClient, private http: HttpClient) {
    super();
  }

  async getAllWebsiteResourcesbyOdata(queryFilter: OdataQueryFilter): Promise<ApiResponse<WebsiteResourceSM[]>> {
    return await this.webResourseClient.GetAllWebsiteResourceByOdata(queryFilter);
  }

  async getWebsiteResourceHomeImages(): Promise<ApiResponse<WebsiteResourceSM[]>> {
    return await this.webResourseClient.GetWebsiteResourceHomeImages();
  }

  async getWebsiteResourceProductPageImages(): Promise<ApiResponse<WebsiteResourceSM[]>> {
    return await this.webResourseClient.GetWebsiteResourceProductImages();
  }

  async getWebsiteResourceSampleReportImages(): Promise<ApiResponse<WebsiteResourceSM[]>> {
    return await this.webResourseClient.GetWebsiteResourceSampleReportImages();
  }

  async getWebsiteResourceHowToInstallImages(): Promise<ApiResponse<WebsiteResourceSM[]>> {
    return await this.webResourseClient.GetWebsiteResourceHowToInstallImages();
  }

  // async getWebsiteResourceProductCardImages(): Promise<ApiResponse<WebsiteResourceSM[]>> {
  //   return await this.webResourseClient.GetWebsiteResourceProductCardImages();
  // }

  async getWebsiteResourceById(id: number): Promise<ApiResponse<WebsiteResourceSM>> {
    if (id <= 0) {
      throw new Error(AppConstants.ErrorPrompts.Delete_Data_Error)
    }
    return await this.webResourseClient.GetWebsiteResourceById(id);
  }

  async deleteWebsiteResource(id: number): Promise<ApiResponse<DeleteResponseRoot>> {
    if (id <= 0) {
      throw new Error(AppConstants.ErrorPrompts.Delete_Data_Error)
    }
    return await this.webResourseClient.DeleteWebsiteResourceById(id);
  }

  /**Add  websiteresources  request to client */
  async addWebsiteResource(resourceId: WebsiteResourceSM): Promise<ApiResponse<WebsiteResourceSM>> {
    if (resourceId == null) {
      throw new Error(AppConstants.ErrorPrompts.Invalid_Input_Data);
    }
    else {
      let apiRequest = new ApiRequest<WebsiteResourceSM>();
      apiRequest.reqData = resourceId;
      return await this.webResourseClient.AddWebsiteResource(apiRequest);
    }
  }

  /**update websiteresources data by id  request to client */
  async updateWebsiteResource(resourceId: WebsiteResourceSM): Promise<ApiResponse<WebsiteResourceSM>> {
    if (resourceId == null) {
      throw new Error(AppConstants.ErrorPrompts.Invalid_Input_Data);
    } else {
      let apiRequest = new ApiRequest<WebsiteResourceSM>();
      apiRequest.reqData = resourceId;
      return await this.webResourseClient.UpdateWebsiteResource(apiRequest);
    }
  }

  async getWebsiteResourceCount(): Promise<ApiResponse<IntResponseRoot>> {
    return await this.webResourseClient.getWebsiteResourceCount()
  }

  /** Get Sample Reports Partial Data */
  getSampleReportsPartialData = async (): Promise<ApiResponse<WebsiteResourceSM[]>> => {
    return await this.webResourseClient.GetSampleReportsPartialData();
  }

  /** Get Sample Reports Document By Id */
  getSampleReportsDocumentById = async (id: number): Promise<ApiResponse<string>> => {
    return await this.webResourseClient.GetSampleReportsDocumentById(id);
  }

}
