import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { AppConstants } from "src/app-constants";
import { BaseComponent } from "src/app/components/base.component";
import { UserLicenseDetailsSM } from "src/app/service-models/app/v1/license/user-license-details-s-m";
import { CommonService } from "src/app/services/common.service";
import { LicenseInfoService } from "src/app/services/license-info.service";
import { LogHandlerService } from "src/app/services/log-handler.service";
import { PaymentService } from "src/app/services/payment.service";
import { SubscriptionViewModel } from "src/app/view-models/subscription.viewmodel";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-subscription-info",
  templateUrl: "./subscription-info.component.html",
  styleUrls: ["./subscription-info.component.scss"],
})
export class SubscriptionInfoComponent
  extends BaseComponent<SubscriptionViewModel>
  implements OnInit {
  @ViewChild('invoiceHistory') invoiceHistoryRef!: ElementRef;


  // @Input() companyDetail: ClientCompanyDetailSM = new ClientCompanyDetailSM();
  constructor(
    commonService: CommonService,
    logService: LogHandlerService,
    public activeModal: NgbActiveModal,
    private licenseInfoService: LicenseInfoService,
    private paymentService: PaymentService,

  ) {
    super(commonService, logService);
    this.viewModel = new SubscriptionViewModel();
    this._commonService.layoutViewModel.showProfile = true;
  }
  async ngOnInit(): Promise<void> {
    await this._commonService.presentLoading();
    setTimeout(async () => {
      await this._commonService.dismissLoader();
      await this.loadPageData();
    }, environment.animationTimeoutinMS);
  }

  override async loadPageData() {
    try {
      await this._commonService.presentLoading();
      let resp = await this.licenseInfoService.GetSubscription();
      if (resp.isError) {
        await this._exceptionHandler.logObject(resp.errorData);
        this._exceptionHandler.logObject(resp.errorData);
        this._commonService.showSweetAlertToast({
          title: resp.errorData.displayMessage,
          icon: "error",
        });
        return;
      } else {
        if (resp.successData == null)
          this.viewModel.LicenseDetails = new UserLicenseDetailsSM();
        else this.viewModel.LicenseDetails = resp.successData;


        if (resp.successData == null) {
          // show add subscription page
          this.viewModel.noActiveLicense = true;
          this.viewModel.emptydiv = true;
          this.viewModel.activeLicense = false;
        }
        else {
          // show  active License subscription page
          this.viewModel.activeLicense = true;
          this.viewModel.emptydiv = false;
          this.viewModel.noActiveLicense = false;
        }
      }
    } catch (error) {
      this._commonService.showSweetAlertToast({
        title: AppConstants.ErrorPrompts.Unknown_Error,
        icon: "error",
      });
      throw error;
    } finally {
      await this._commonService.dismissLoader();
    }
  }
  async click_ManageSubscription() {
    try {
      await this._commonService.presentLoading();
      let resp = await this.paymentService.ManageSubscription(
        window.location.href
      );
      if (resp.isError) {
        await this._exceptionHandler.logObject(resp.errorData);
        this._exceptionHandler.logObject(resp.errorData);
        this._commonService.showSweetAlertToast({
          title: resp.errorData.displayMessage,
          icon: "error",
        });
        return;
      } else {
        window.location.href = resp.successData.url;
      }
    } catch (error) {
      this._commonService.showSweetAlertToast({
        title: AppConstants.ErrorPrompts.Unknown_Error,
        icon: "error",
      });
      throw error;
    } finally {
      await this._commonService.dismissLoader();
    }
  }

  async downloadInvoiceReportDocument(Id: string) {
    try {
      await this._commonService.presentLoading();
      let resp = await this.paymentService.getInvoiceReportsDocumentById(Id);
      if (resp.isError) {
        this._exceptionHandler.logObject(resp.errorData);
        this._commonService.showSweetAlertToast({ title: resp.errorData.displayMessage, icon: "error" });
      } else {
        await this._commonService.downloadDocument(resp.successData, ".pdf", "invoice");
      }
    } catch (error) {
      throw error;
    } finally {
      await this._commonService.dismissLoader();
    }
  }


  async getAllInvoices() {
    try {

      await this._commonService.presentLoading();
      let resp = await this.paymentService.getAllInvoices();
      if (resp.isError) {
        await this._exceptionHandler.logObject(resp.errorData);
        this._commonService.showSweetAlertToast({ title: 'Error!', icon: 'error' });
      } else {
        this.viewModel.invoices = resp.successData;
      }
    } catch (error) {
      throw error;
    } finally {
      await this._commonService.dismissLoader()
    }
  }

  toggleInvoiceHistory() {
    if (!this.viewModel.showInvoiceHistoryTable) {
      this.getAllInvoices();
      this.invoiceHistoryRef.nativeElement.scrollIntoView({ behavior: 'smooth' });
    }
    else {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
    this.viewModel.showInvoiceHistoryTable = !this.viewModel.showInvoiceHistoryTable;

  }

}
