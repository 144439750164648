import { Injectable } from "@angular/core";
import { AppConstants } from "src/app-constants";
import { CheckoutSessionRequest } from "../service-models/app/v1/license/checkout-session-request";
import { CheckoutSessionResponse } from "../service-models/app/v1/license/checkout-session-response";
import { ApiRequest } from "../service-models/foundation/api-contracts/base/api-request";
import { ApiResponse } from "../service-models/foundation/api-contracts/base/api-response";
import { StorageService } from "../services/storage.service";
import { BaseApiClient } from "./base-client/base-api.client";
import { CommonResponseCodeHandler } from "./helpers/common-response-code-handler.helper";
import { StorageCache } from "./helpers/storage-cache.helper";
import { CustomerPortalResponse } from "../service-models/app/v1/license/customer-portal-response";
import { CustomerPortalRequest } from "../service-models/app/v1/license/customer-portal-request";
import { SubscriptionUpgradeRequestSM } from "../service-models/app/v1/license/subscription-upgrade-request-s-m";
import { SubscriptionUpgradeResponseSM } from "../service-models/app/v1/license/subscription-upgrade-response-s-m";
import { UserInvoiceSM } from "../service-models/app/v1/license/user-invoice-s-m";


@Injectable({
    providedIn: 'root'
})
export class PaymentClient extends BaseApiClient {

    constructor(storageService: StorageService, storageCache: StorageCache,
        commonResponseCodeHandler: CommonResponseCodeHandler) {
        super(storageService, storageCache, commonResponseCodeHandler)
    }

    GenerateCheckoutSessionMine = async (apiRequest: ApiRequest<CheckoutSessionRequest>): Promise<ApiResponse<CheckoutSessionResponse>> => {
        let resp = await this.GetResponseAsync<CheckoutSessionRequest, CheckoutSessionResponse>
            (`${AppConstants.ApiUrls.PAYMENT_URL}/mine/checkout`,"POST", apiRequest);
        return resp;
    }

    ManageSubscription = async (apiRequest: ApiRequest<CustomerPortalRequest>): Promise<ApiResponse<CustomerPortalResponse>> => {
        let resp = await this.GetResponseAsync<CustomerPortalRequest, CustomerPortalResponse>
            (`${AppConstants.ApiUrls.PAYMENT_URL}/mine/StripeCustomerPortal`, "POST", apiRequest);
        return resp;
    }
    UpgradeSubscription = async (apiRequest: ApiRequest<SubscriptionUpgradeRequestSM>): Promise<ApiResponse<SubscriptionUpgradeResponseSM>> => {
        let resp = await this.GetResponseAsync<SubscriptionUpgradeRequestSM, SubscriptionUpgradeResponseSM>
            (`${AppConstants.ApiUrls.PAYMENT_URL}/subscription/Upgrade`, "POST", apiRequest);
        return resp;
    }
    UpgradeSubscriptionInfo = async (apiRequest: ApiRequest<SubscriptionUpgradeRequestSM>): Promise<ApiResponse<SubscriptionUpgradeResponseSM>> => {
        let resp = await this.GetResponseAsync<SubscriptionUpgradeRequestSM, SubscriptionUpgradeResponseSM>
            (`${AppConstants.ApiUrls.PAYMENT_URL}/subscription/UpgradeInfo`, "POST", apiRequest);
        return resp;
    }
   
   
    GetInvoiceReportDocumentById =  async (id: ApiRequest<string>): Promise<ApiResponse<string>> => {
        let resp = await this.GetResponseAsync<string, string>
        (`${AppConstants.ApiUrls.PAYMENT_URL}/download/stripeInvoice`,'POST', id);
        return resp;
    }

    GetAllInvoices= async (): Promise<ApiResponse<UserInvoiceSM[]>> => {
        let resp = await this.GetResponseAsync<number, UserInvoiceSM[]>
            (`${AppConstants.ApiUrls.INVOICE_URL}/my/Invoices`, 'GET');
        return resp;
    }
}
