import { Component, OnInit, TemplateRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppConstants } from 'src/app-constants';
import { FrequentlyAskedQuestionsSM } from 'src/app/service-models/app/v1/general/frequently-asked-questions-s-m';
import { ApiResponse } from 'src/app/service-models/foundation/api-contracts/base/api-response';
import { OdataQueryFilter } from 'src/app/service-models/foundation/api-contracts/odata-query-filter';
import { CommonService } from 'src/app/services/common.service';
import { FrequentlyAskedQuestionService } from 'src/app/services/freq-asked-question.service';
import { LogHandlerService } from 'src/app/services/log-handler.service';
import { AdminFreqAskedQuesViewModel } from 'src/app/view-models/admin/admin-freq-asked-ques.viewmodel';
import { BaseComponent } from '../../base.component';

@Component({
  selector: 'app-admin-freq-asked-ques',
  templateUrl: './admin-freq-asked-ques.component.html',
  styleUrls: ['./admin-freq-asked-ques.component.scss']
})
export class AdminFreqAskedQuesComponent extends BaseComponent<AdminFreqAskedQuesViewModel> implements OnInit {

  constructor(
    private modalService: NgbModal, private faqService: FrequentlyAskedQuestionService,
    commonService: CommonService, logService: LogHandlerService) {
    super(commonService, logService);
    this.viewModel = new AdminFreqAskedQuesViewModel();
  }
  //pager
  
  async ngOnInit() {
    await this.loadPageData();
    
  }
  /**
   * get all FAQ's data 
   * @returns success.data
   */
  override async loadPageData() {
    try {
      await this._commonService.presentLoading();
     
      //have to do paging
      let queryFilter = new OdataQueryFilter();
      queryFilter.skip = (this.viewModel.PageNo - 1) * this.viewModel.PageSize;
      queryFilter.top = this.viewModel.PageSize;
      let resp = await this.faqService.getAllFaqByOdata(queryFilter);
      if (resp.isError) {
        this._exceptionHandler.logObject(resp.errorData);
        this._commonService.showSweetAlertToast({ title: resp.errorData.displayMessage, icon: 'error' });
        return;
      }
      else {
        await this.getFaqCount();
        this.viewModel.faqs = resp.successData;
      }
    } catch (error) {
      this._commonService.showSweetAlertToast({ title: AppConstants.ErrorPrompts.Unknown_Error, icon: 'error' });
      throw error;
    }
    finally {
      await this._commonService.dismissLoader()
    }
  }

  async getFaqCount(){
    try {
      await this._commonService.presentLoading();
       let resp = await this.faqService.getFaqCount();
       if (resp.isError) {
        this._exceptionHandler.logObject(resp.errorData);
        this._commonService.showSweetAlertToast({ title: resp.errorData.displayMessage, icon: 'error' });
        return;
      }
      else {
        this.viewModel.totalCount = resp.successData.intResponse;
      }
    } catch (error) {
      this._commonService.showSweetAlertToast({ title: AppConstants.ErrorPrompts.Unknown_Error, icon: 'error' });
      throw error;
    }
    finally {
      await this._commonService.dismissLoader()
    }

  }
  /**
   * open ADD,Edit FAQ's modal 
   * @param faqModal 
   * @param faqId 
   */
  async click_OpenAddEditModal(faqModal: TemplateRef<any>, faqId: number) {
    this.viewModel.AddEditModalTitle = faqId > 0 ? 'Update Faq' : 'Add Faq';
    this.viewModel.singleFaq = new FrequentlyAskedQuestionsSM();
    this.viewModel.FormSubmitted = false;
    if (faqId > 0)
      await this.getFaqById(faqId);
    await this.modalService.open(faqModal).result.then(

    );
  }
  /**
   * Get FAQ's data by id
   * @param faqId 
   * @returns success message
   */
  async getFaqById(faqId: number) {
    try {
      await this._commonService.presentLoading();
      //have to do paging
      let resp = await this.faqService.getFaqById(faqId);
      if (resp.isError) {
        await this._exceptionHandler.logObject(resp.errorData);
        this._commonService.showSweetAlertToast({ title: 'Error!', icon: 'error' });
      }
      else {
        this.viewModel.singleFaq = resp.successData;
      }
    } catch (error) {
      throw error;
    }
    finally {
      await this._commonService.dismissLoader()
    }
  }
  /**
   *This function is used ADD and Update FAQ's 
   * @param faqForm 
   * @returns success message
   */
  async form_addEditFaq(faqForm: NgForm) {
    this.viewModel.FormSubmitted = true;
    try {
      if (faqForm.invalid)
        return;
      await this._commonService.presentLoading();
      let resp: ApiResponse<FrequentlyAskedQuestionsSM>;
      if (this.viewModel.singleFaq.id && this.viewModel.singleFaq.id > 0)
        resp = await this.faqService.update_Faq(this.viewModel.singleFaq);
      else
        resp = await this.faqService.AddFaq(this.viewModel.singleFaq);
      if (resp.isError) {
        await this._exceptionHandler.logObject(resp.errorData);
        this._commonService.showSweetAlertToast({ title: resp.errorData.displayMessage, icon: 'error' });

      }
      else {
        this.modalService.dismissAll();
        this._commonService.showSweetAlertToast({ title: 'Success', icon: 'success' });

        this.loadPageData();
      }
    } catch (error) {
      this._commonService.showSweetAlertToast({
        title: AppConstants.ErrorPrompts.Unknown_Error,
        icon: "error",
      });
      throw error;
    } finally {
      await this._commonService.dismissLoader()
    }
  }
  /**
   * deletes faq by id
   * @param faqId 
   * @returns 
   */
  async deleteFaq(faqId: number) {

    let deleteConfirmation = await this._commonService.showSweetAlertConfirmation({
      title: `${AppConstants.DefaultMessages.DeleteAlertConfirmation} FAQ?`,
      text: " ",
      showCancelButton: true,
      icon: "warning"
    }
    );
    if (deleteConfirmation) {
      try {
        let resp = await this.faqService.deleteFaqById(faqId);
        if (resp.isError) {
          await this._exceptionHandler.logObject(resp.errorData);
          this._commonService.showSweetAlertToast({ title: resp.errorData.displayMessage, icon: 'error' });

        } else {
          this._commonService.showSweetAlertToast({ title: 'Success', icon: 'success' });

          this.loadPageData();
          return;
        }
      } catch (error) {
        this._commonService.showSweetAlertToast({
          title: AppConstants.ErrorPrompts.Unknown_Error,
          icon: "error",
        });
        throw error;
      } finally {
        await this._commonService.dismissLoader();
      }
    }
  }

  async loadPagedataWithPagination(event: any) {
    this.viewModel.PageNo = event;
    await this.loadPageData();
  }

}
