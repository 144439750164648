import { Injectable } from "@angular/core";
import { AppConstants } from "src/app-constants";
import { NewsFeedSM } from "../service-models/app/v1/general/news-feed-s-m";
import { ApiRequest } from "../service-models/foundation/api-contracts/base/api-request";
import { ApiResponse } from "../service-models/foundation/api-contracts/base/api-response";
import { OdataQueryFilter } from "../service-models/foundation/api-contracts/odata-query-filter";
import { DeleteResponseRoot } from "../service-models/foundation/common-response/delete-response-root";
import { IntResponseRoot } from "../service-models/foundation/common-response/int-response-root";
import { StorageService } from "../services/storage.service";
import { BaseApiClient } from "./base-client/base-api.client";
import { CommonResponseCodeHandler } from "./helpers/common-response-code-handler.helper";
import { StorageCache } from "./helpers/storage-cache.helper";


@Injectable({
    providedIn: 'root'
})
export class NewsFeedClient extends BaseApiClient {
    constructor(storageService: StorageService, storageCache: StorageCache,
        commonResponseCodeHandler: CommonResponseCodeHandler) {
        super(storageService, storageCache, commonResponseCodeHandler)


    }
    /**Get news based on odata query */
    GetAllNewsByOdata = async (queryFilter: OdataQueryFilter): Promise<ApiResponse<NewsFeedSM[]>> => {
        let finalUrl = this.ApplyQueryFilterToUrl(`${AppConstants.ApiUrls.NEWS_FEED_API_URL}/odata`, queryFilter);
        let resp = await this.GetResponseAsync<null, NewsFeedSM[]>(finalUrl, 'GET')
        return resp;
    }
 

    /**Get all News */
    GetAllNews = async (): Promise<ApiResponse<NewsFeedSM[]>> => {
        let resp = await this.GetResponseAsync<number, NewsFeedSM[]>
            (`${AppConstants.ApiUrls.NEWS_FEED_API_URL}`, 'GET');
        return resp;
    }

    /**Get News by id */
    GetNewsById = async (Id: number): Promise<ApiResponse<NewsFeedSM>> => {
        let resp = await this.GetResponseAsync<number, NewsFeedSM>
            (`${AppConstants.ApiUrls.NEWS_FEED_API_URL}/${Id}`, 'GET');
        return resp;
    }

    /**Add a new News */
    AddNews = async (addNewsRequest: ApiRequest<NewsFeedSM>): Promise<ApiResponse<NewsFeedSM>> => {
        let resp = await this.GetResponseAsync<NewsFeedSM, NewsFeedSM>
            (AppConstants.ApiUrls.NEWS_FEED_API_URL, 'POST', addNewsRequest);
        return resp;
    }

    /**Update News*/
    UpdateNews = async (updateNewsRequest: ApiRequest<NewsFeedSM>): Promise<ApiResponse<NewsFeedSM>> => {
        let resp = await this.GetResponseAsync<NewsFeedSM, NewsFeedSM>
            (`${AppConstants.ApiUrls.NEWS_FEED_API_URL}/${updateNewsRequest.reqData.id}`, 'PUT', updateNewsRequest);
        return resp;
    }

    /**delete News by id */
    DeleteNewsById = async (Id: number): Promise<ApiResponse<DeleteResponseRoot>> => {
        let resp = await this.GetResponseAsync<number, DeleteResponseRoot>
            (`${AppConstants.ApiUrls.NEWS_FEED_API_URL}/${Id}`, 'DELETE');
        return resp;
    }
    GetAllNewsCount=async():Promise<ApiResponse<IntResponseRoot>>=>{
        let resp=await this.GetResponseAsync<null,IntResponseRoot>
        (`${AppConstants.ApiUrls.NEWS_FEED_API_URL}/NewsFeedCountResponse`,'GET')
         return resp;
    }

}