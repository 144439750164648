<div class="container-fluid">
    <div class="table-title">
        <div class="row">
            <div class="col-lg-5 col-md-12 col-12"> <label > Total No. of website Resource's: {{viewModel.totalCount}}</label></div>
            <div class="col-lg-7 col-md-12 col-12"> <button type="button" class="addBtnSuperAdmin"
                    (click)="click_OpenAddEditModal(addEditModal,0)"><i class="bi bi-plus"></i>Add Resources
                    </button></div>
        </div>

        <table class="table mt-2">
            <thead>
                <tr class="text-center ">
                    <th class="firstTableHead">Image</th>
                    <th>Title</th>
                    <th>File location</th>
                    <th>File Type </th>
                    <th class="lastTableHead">Actions</th>
                </tr>
            </thead>
            <tbody>
                <tr class="h5 text-center" *ngFor="let websiteResource of viewModel.websiteResourceList ">

                    <td class="align-middle">
                        <img src="{{'assets/images/productInfo1.jpg,' + websiteResource.resourceFile}}">
                    </td>
                    <td class="align-middle">{{websiteResource.resourceTitle}}</td>
                    <td class="align-middle">{{websiteResource.resourceLocation}}</td>
                    <td class="align-middle">{{websiteResource.resourceType}}</td>
                    <td class="align-middle">
                        <i class="bi bi-pencil-fill" (click)="click_OpenAddEditModal(addEditModal,websiteResource.id)">
                        </i>
                        &nbsp; <i class="bi bi-trash-fill" (click)="deleteWebsiteResource(websiteResource.id)"></i>
                    </td>
                </tr>
            </tbody>
        </table>


        <div id="pagination" class="col-lg-12 col-md-12 col-sm-12 col-12  d-flex justify-content-center fixed-bottom">
            <ngb-pagination [collectionSize]="viewModel.totalCount" [(page)]="viewModel.PageNo"
                [pageSize]="viewModel.PageSize" (pageChange)="loadPagedataWithPagination($event)"></ngb-pagination>
        </div>

        <ng-template #addEditModal let-modal>
            <form #form_WebsiteResource="ngForm" (ngSubmit)="form_addEditWebsiteResource(form_WebsiteResource)">
                <div class="modal-header">
                    <h4 class="modal-title">{{viewModel.AddEditModalTitle}}</h4>
                    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
                </div>
                <div class="modal-body">
                    <div>
                        <div class="form-group  mb-1">
                            <label class="form-label"> Title </label>
                            <input [(ngModel)]="viewModel.singleWebsiteResource.resourceTitle" class="form-control"
                                placeholder="title" #title="ngModel" required name="title" />
                            <ng-container *ngFor="let validation of viewModel.ValidationData.title">
                                <div class="alert alert-danger"
                                    *ngIf="(title.touched || viewModel.FormSubmitted) && title.hasError(validation.type)">
                                    {{ validation.message }}
                                </div>
                            </ng-container>
                        </div>
        
        
                        <div class="form-group  mb-1">
                            <label class="form-label"> Location </label>
                            <select class="form-select" [(ngModel)]="viewModel.singleWebsiteResource.resourceLocation"
                                name="location">
                                <option *ngFor="let items of viewModel.resourceLocation" value="{{items}}">
                                    {{items}}
                                </option>
                            </select>
                        </div>
        
                        <div class="form-group  mb-1">
                            <label class="form-label"> File Type </label>
                            <select class="form-select" selected aria-label="Default select example"
                                [(ngModel)]="viewModel.singleWebsiteResource.resourceType" name="type"
                                (change)="change_resourceType()">
                                <option *ngFor="let items of viewModel.resourceType" value="{{items}}">
                                    {{items}}
                                </option>
                            </select>
                        </div>
                        <div class="form-group  mb-1">
                            <label class="form-label"> Choose File </label>
                            <input type="file" [(ngModel)]="viewModel.singleWebsiteResource.resourceFile" #chooseFile="ngModel"
                                class="form-control" placeholder="choose file" name="uploaded file To show"
                                [accept]="viewModel.chooseFileType" (change)="uploadFile($event)" />
                            <ng-container *ngFor="let validation of viewModel.ValidationData.chooseFile">
                                <div class="alert alert-danger"
                                    *ngIf="(chooseFile.touched || viewModel.FormSubmitted) && title.hasError(validation.type)">
                                    {{ validation.message }}
                                </div>
                            </ng-container>
                        </div>
                    </div>
                    <!-- <a (click)="showAdditionalDetailfields()" class="text-primary h6"><u>Add More Reports Details  </u> </a> -->
                    <!-- Add Additional Reports Details -->
                    <div *ngIf="viewModel.additionalReportsFields">
                        <div class="form-group  mb-1">
                            <label class="form-label"> Title </label>
                            <input [(ngModel)]="viewModel.WRAdditionalData.ReportTitle" class="form-control"
                                placeholder="e-g Rental Reports" required name="ReportsTitle" />
                        </div>
                        <div class="form-group  mb-1">
                            <label class="form-label"> Name </label>
                            <input [(ngModel)]="viewModel.WRAdditionalData.ClientName" class="form-control"
                                placeholder="e-g John Carter" required name="Name" #name="ngModel" />
                            <ng-container *ngFor="let validation of viewModel.ValidationData.name">
                                <div class="alert alert-danger"
                                    *ngIf="(name.touched || viewModel.FormSubmitted) && name.hasError(validation.type)">
                                    {{ validation.message }}
                                </div>
                            </ng-container>
                        </div>
                        <div class="form-group  mb-1">
                            <label class="form-label"> Street </label>
                            <input [(ngModel)]="viewModel.WRAdditionalData.ClientStreet" class="form-control"
                                placeholder="e-g 66 SweetStreet" required name="Street" #street="ngModel" />
                            <ng-container *ngFor="let validation of viewModel.ValidationData.street">
                                <div class="alert alert-danger"
                                    *ngIf="(street.touched || viewModel.FormSubmitted) && street.hasError(validation.type)">
                                    {{ validation.message }}
                                </div>
                            </ng-container>
                        </div>
                        <div class="form-group  mb-1">
                            <label class="form-label"> City </label>
                            <input [(ngModel)]="viewModel.WRAdditionalData.ClientCity" class="form-control"
                                placeholder="e-g Manhattan " required name="City" #city="ngModel" />
                            <ng-container *ngFor="let validation of viewModel.ValidationData.city">
                                <div class="alert alert-danger"
                                    *ngIf="(city.touched || viewModel.FormSubmitted) && city.hasError(validation.type)">
                                    {{ validation.message }}
                                </div>
                            </ng-container>
                        </div>
                        <div class="form-group  mb-1">
                            <label class="form-label"> State </label>
                            <input [(ngModel)]="viewModel.WRAdditionalData.ClientState" class="form-control"
                                placeholder="e-g New York" required name="State" #state="ngModel" />
                            <ng-container *ngFor="let validation of viewModel.ValidationData.state">
                                <div class="alert alert-danger"
                                    *ngIf="(state.touched || viewModel.FormSubmitted) && state.hasError(validation.type)">
                                    {{ validation.message }}
                                </div>
                            </ng-container>
                        </div>
        
                        <div class="form-group  mb-1">
                            <label class="form-label"> Zip Code</label>
                            <input [(ngModel)]="viewModel.WRAdditionalData.ClientZip" class="form-control"
                                placeholder="e-g 910310" required name="Zip Code" pattern=^[0-9]+$ #zipcode="ngModel" />
                            <ng-container *ngFor="let validation of viewModel.ValidationData.zipcode">
                                <div class="alert alert-danger"
                                    *ngIf="(zipcode.touched || viewModel.FormSubmitted) && zipcode.hasError(validation.type)">
                                    {{ validation.message }}
                                </div>
                            </ng-container>
                        </div>
                        <div class="form-group  mb-1">
                            <label class="form-label"> Inspector </label>
                            <input [(ngModel)]="viewModel.WRAdditionalData.Inspector" class="form-control"
                                placeholder="e-g Buyer" required name="Client" #client="ngModel" />
                            <ng-container *ngFor="let validation of viewModel.ValidationData.client">
                                <div class="alert alert-danger"
                                    *ngIf="(client.touched || viewModel.FormSubmitted) && client.hasError(validation.type)">
                                    {{ validation.message }}
                                </div>
                            </ng-container>
                        </div>
                        <div class="form-group  mb-1">
                            <label class="form-label"> Agent</label>
                            <input [(ngModel)]="viewModel.WRAdditionalData.Agent" class="form-control"
                                placeholder="e-g Commercial" required name="Agent" #agent="ngModel" />
                            <ng-container *ngFor="let validation of viewModel.ValidationData.agent">
                                <div class="alert alert-danger"
                                    *ngIf="(agent.touched || viewModel.FormSubmitted) && agent.hasError(validation.type)">
                                    {{ validation.message }}
                                </div>
                            </ng-container>
                        </div>
                        <div class="form-group  mb-1">
                            <label class="form-label"> Fee</label>
                            <input [(ngModel)]="viewModel.WRAdditionalData.Fee" class="form-control" placeholder="e-g $500"
                                required pattern=^[0-9]+$ name="Fee" #fee="ngModel" />
                            <ng-container *ngFor="let validation of viewModel.ValidationData.fee">
                                <div class="alert alert-danger"
                                    *ngIf="(fee.touched || viewModel.FormSubmitted) && fee.hasError(validation.type)">
                                    {{ validation.message }}
                                </div>
                            </ng-container>
        
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button class="spyGlassBtn" type="submit"> Save </button>
                </div>
            </form>
        </ng-template>
    </div>




