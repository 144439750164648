import { Component, OnInit } from '@angular/core';
import { AppConstants } from 'src/app-constants';
import { TokenRequestSM } from 'src/app/service-models/app/token/token-request-s-m';
import { CommonService } from 'src/app/services/common.service';
import { LogHandlerService } from 'src/app/services/log-handler.service';
import { SampleService } from 'src/app/services/sample.service';
import { SamplePageViewModel } from 'src/app/view-models/sample.page.viewmodel';
import { BaseComponent } from '../../base.component';

@Component({
  selector: 'app-sample-page',
  templateUrl: './sample-page.component.html',
  styleUrls: ['./sample-page.component.scss']
})
export class SamplePageComponent extends BaseComponent<SamplePageViewModel> implements OnInit {

  /**
   *
   */
  constructor(commonService: CommonService, logService: LogHandlerService, private sampleService: SampleService) {
    super(commonService, logService);

  }

  async ngOnInit() {
    this.viewModel = new SamplePageViewModel();
    this.viewModel.tokenRequest = new TokenRequestSM()
  }

  async GenerateToken() {
    try {
      await this._commonService.presentLoading();
      //have to do paging
      let resp = await this.sampleService.generateToken(this.viewModel.tokenRequest);
      if (resp.isError) {
        this._exceptionHandler.logObject(resp.errorData);
        this._commonService.showSweetAlertToast({ title: resp.errorData.displayMessage, icon: 'error' });
        return;
      }
      else {
        if (resp.successData.accessToken != null)
          this.viewModel.tokenResponse = resp.successData;
      }
    } catch (error) {
      this._commonService.showSweetAlertToast({ title: AppConstants.ErrorPrompts.Unknown_Error, icon: 'error' });
      throw error;
    }
    finally {
      await this._commonService.dismissLoader()
    }
  }

  async presentAlert() {
    // let x = await this._commonService.presentConfirmAlert({
    //   title: 'Test',
    //   subTitle: 'Test Sub',
    //   message: 'Test Message',
    //   showModal: true
    // })
    // this._exceptionHandler.logObject(x);
  }

}
