export enum ResourceLocationSM {
    HomePageBackgroundImage = 1,
    OurProductFirstImage = 2,
    OurProductSecondImage = 3,
    ProductCardFirstImage = 4,
    ProductCardSecondImage = 5,
    ProductCardThirdImage = 6,
    CustomImage = 7,
    SampleReports = 8
}
