<div class="container-fluid">

    <div class="row">

        <div class="col-lg-12 mb-lg-4 mb-md-4 mb-sm-4 mb-4 ps-4 profileHeader">

            <div class="card p-lg-3 p-md-3 p-sm-3 p-3">
                <span> Welcome </span>
                <h1> {{viewModel.companyDetails.name}} </h1>
            </div>

        </div>

        <div class="col-lg-8 col-md-8 col-sm-12 col-12 ps-4 firstColumn">

            <div class="card p-4">

                <div class="row">

                    <div class="col-lg-12 pb-3">
                        <h1> General Information </h1>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <label class="labels"> Company Name </label>
                        <input type="text" class="form-control" [(ngModel)]="viewModel.companyDetails.name" name="name"
                       disabled
                            placeholder="Enter Company Name" value="">
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <label class="labels"> Company Website </label>
                        <input type="text" class="form-control" [(ngModel)]="viewModel.companyDetails.companyWebsite"
                            name="website" placeholder="Enter Company Website" value="" disabled>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <label class="labels"> Company Email </label>
                        <input type="email" class="form-control" [(ngModel)]="viewModel.companyDetails.contactEmail"
                            name="email" placeholder="Enter Company Email" value="" disabled>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <label class="labels"> Phone Number </label>
                        <input type="text" class="form-control"
                            [(ngModel)]="viewModel.companyDetails.companyMobileNumber" name="phone"
                            placeholder="Enter Company Phone" value=" " >
                    </div>

                </div>

                <div class="row">
                    <div class="col-lg-12 py-4">
                        <h1> Address Details </h1>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <label class="labels"> Address </label>
                        <input type="text" class="form-control" [(ngModel)]="viewModel.companyAddressDetails.address1"
                            name="address" placeholder="Enter Address" value="">
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <label class="labels"> Country </label>
                        <input type="text" class="form-control" [(ngModel)]="viewModel.companyAddressDetails.country"
                            name="country" placeholder="Enter Country" value="">
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <label class="labels"> State </label>
                        <input type="text" class="form-control" [(ngModel)]="viewModel.companyAddressDetails.state"
                            name="state" placeholder="Enter State" value="">
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <label class="labels"> City </label>
                        <input type="text" class="form-control" [(ngModel)]="viewModel.companyAddressDetails.city"
                            name="city" placeholder="Enter City" value="">
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <label class="labels"> Pin Code </label>
                        <input type="text" class="form-control" [(ngModel)]="viewModel.companyAddressDetails.pinCode"
                            name="pincode" placeholder="Enter Pin Code" value="">
                    </div>

                </div>

                <div class="row mt-5">

                    <div class="col-lg-12 text-end">
                        <button class="spyGlassBtn" type="button" (click)="saveProfileDetails()"> Save Profile </button>
                    </div>

                </div>

            </div>

        </div>

        <div class="col-lg-4 col-md-4 col-sm-12 col-12 ps-4 secondColumn">

            <div class="card firstCard p-lg-2 p-md-2 p-sm-2 p-2 mt-lg-0 mt-md-0 mt-sm-4 mt-4">

                <div class="profileContent" *ngIf="viewModel.isProfilePicUploaded">

                    <img src="{{'data:image/jpg;base64,' + viewModel.companyDetails.companyLogoPath }}" alt="profilePic"
                        class="image">

                    <span class="d-flex flex-column align-items-center companyTitle">
                        {{viewModel.companyDetails.name}}
                    </span>

                    <button type="button" class="spyGlassBtn d-lg-none d-md-none d-sm-block d-block m-auto"
                        (click)="click_OpenAddEditModal(addEditModal,0)"> Edit </button>

                    <div class="overlay">
                        <a (click)="click_OpenAddEditModal(addEditModal,0)" class="editIcon" title="Change Picture">
                            <i class="bi bi-camera "></i>
                        </a>
                    </div>

                </div>

                <div class="profileContent" *ngIf="!viewModel.isProfilePicUploaded">

                    <img src="assets/images/other-images/userr.jpg" alt="Avatar" class="image">

                    <span class="d-flex flex-column align-items-center companyTitle">
                        {{viewModel.companyDetails.name}}
                    </span>

                    <div class="overlay">
                        <a (click)="click_OpenAddEditModal(addEditModal,0)" class="editIcon" title="Change Picture">
                            <i class="bi bi-camera"></i>
                        </a>
                    </div>

                </div>

            </div>

            <div class="card secondCard p-lg-2 p-md-2 p-sm-2 p-2 mt-lg-4 mt-md-4 mt-sm-4 mt-4">

                <div class="socialMediaLinks text-center">

                    <span> <i class="bi bi-instagram"></i> </span>
                    <span> <i class="bi bi-linkedin"></i> </span>
                    <span> <i class="bi bi-youtube"></i> </span>
                    <span> <i class="bi bi-facebook"></i> </span>
                    <span> <i class="bi bi-pinterest"></i> </span>

                    <a href="https://companywebsite.com" class="d-block mt-3 text-start"> <i
                            class="bi bi-box-arrow-right"></i> See Website </a>

                </div>

            </div>

        </div>

    </div>


    <ng-template #addEditModal let-modal>

        <form #form_Inspector="ngForm" (ngSubmit)="form_addEditInspector(form_Inspector)">

            <div class="modal-header">
                <h4 class="modal-title">{{viewModel.AddEditModalTitle}}</h4>
                <button type="button" class="btn-close" aria-label="Close"
                    (click)="modal.dismiss('Cross click')"></button>
            </div>

            <div class="modal-body">
                <img src="{{'data:image/jpg;base64,' + viewModel.companyDetails.companyLogoPath }}" alt="profilePic"
                    class="img-fluid " *ngIf="viewModel.isProfilePicUploaded">

                <img src="assets/images/other-images/userr.jpg" alt="Avatar" class="img-fluid w-50 d-block m-auto"
                    *ngIf="!viewModel.isProfilePicUploaded">

                <input [(ngModel)]="viewModel.companyDetails.companyLogoPath" type="file"
                    accept=".png,.jpg,.jpeg,.jpe,.jfif,.JPG,.PNG,.JPEG,.JPE,.JFIF,.BMP,.bmp" class="form-control mt-3"
                    placeholder="upload" #chooseFile="ngModel" required name="file" value=""
                    (change)="uploadFile($event)" />
            </div>

            <div class="row modalFooter">
                <div class="col-lg-12 p-4">
                    <i class="bi bi-trash-fill deleteIcon" title="Remove" (click)="deleteProfile()"
                        *ngIf="viewModel.showDeleteBtn"></i>
                    <button type="submit" class="spyGlassBtn float-end" (click)="getCompanyAdminProfilePicture()"> Save
                    </button>
                </div>
            </div>

        </form>

    </ng-template>


</div>



<!-- <div class="row">

        <div class="col-lg-4 col-md-6 col-sm-6 col-6 text-sm-center">

            <div class="row">
                <div class="col-6">
                    <img class="rounded-circle img-fluid"
                        src="{{'data:image/jpg;base64,' + viewModel.companyDetails.companyLogoPath }}">
                </div>
                <div class="col-6 mt-5">
                    <button class="btn btn-success btn-sm one" (click)="click_OpenAddEditModal(addEditModal,0)">Change
                        Photo</button><br><br>
                    <button class="btn btn-danger btn-sm one" (click)="deleteProfile()">Delete Photo</button>
                </div>
            </div>

            <span class="d-block text-start"> {{viewModel.companyDetails.name}}</span>
            <span class="d-block text-start"> {{viewModel.companyDetails.companyWebsite}} </span>

        </div>

        <div class="col-lg-4 col-md-6 col-sm-12 col-12 mt-5">

            <label> Name </label>
            <input type="text" [(ngModel)]="viewModel.companyDetails.name" name="name" class="form-control"
                placeholder="Enter Name">

            <label> Website </label>
            <input type="text" [(ngModel)]="viewModel.companyDetails.companyWebsite" name="website" class="form-control"
                placeholder="Enter Website">

            <label> Company Email </label>
            <input type="email" [(ngModel)]="viewModel.companyDetails.contactEmail" name="email" class="form-control"
                placeholder="Enter Email Address">

            <label> Phone Number </label>
            <input type="number" [(ngModel)]="viewModel.companyDetails.companyMobileNumber" name="phone"
                class="form-control" placeholder=" Enter Phone number">

            <label> Pin Code </label>
            <input type="number" [(ngModel)]="viewModel.companyAddressDetails.pinCode" name="pincode"
                class="form-control" placeholder="Enter Pincode">

        </div>

        <div class="col-lg-4 col-md-12 col-sm-12 col-12 mt-5">

            <label> Address </label>
            <input type="text" [(ngModel)]="viewModel.companyAddressDetails.address1" name="address"
                class="form-control" placeholder="Enter Company Address">

            <label> Country </label>
            <input type="text" [(ngModel)]="viewModel.companyAddressDetails.country" name="country" class="form-control"
                placeholder="Enter Country">

            <label> State </label>
            <input type="text" [(ngModel)]="viewModel.companyAddressDetails.state" name="state" class="form-control"
                placeholder="Enter State">

            <label> City </label>
            <input type="text" [(ngModel)]="viewModel.companyAddressDetails.city" name="city" class="form-control"
                placeholder="Enter City">

        </div>

        <div class="col-lg-12 col-md-12 col-sm-12 col-12 mt-3 text-end">

            <button type="button" class="spyGlassBtn" (click)="saveProfileDetails()">
                Save Profile
            </button>

        </div>

    </div> -->