import { WebsiteResourceSM } from "../service-models/app/v1/general/website-resource-s-m";
import { WRadditionalData } from "./admin/website-resource.viewmodel";
import { BaseViewModel } from "./base.viewmodel";

export class ReportsViewModel implements BaseViewModel {
  PageTitle: string = 'Sample-Report';

  samplereportsDetailslist: WebsiteResourceSM[] = [];
  additionalDetailsList: WRadditionalData[] = [];
  downloadReports: boolean = true;
  downloadDummyReports: boolean = false;
  fileUrl: any;

  sampleReportMainImage: string = '';
  sampleReportImage: string = '';
  sampleReportImage1: string = '';
  sampleReportImage2: string = '';
  sampleReportImage3: string = '';



}
