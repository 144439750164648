import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AppConstants } from 'src/app-constants';
import { StorageService } from '../services/storage.service';
import decode from 'jwt-decode';
import { AccountService } from '../services/account.service';
import { CommonService } from '../services/common.service';
import { RoleTypeSM } from '../service-models/app/enums/role-type-s-m.enum';
const jwtHelper = new JwtHelperService();
@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(
    private storageService: StorageService,
    private accountService: AccountService,
    private commonService: CommonService,
    public router: Router) {
  }
  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean> {
    const expectedRole: RoleTypeSM[] = route.data['allowedRole'];
    this.commonService.layoutViewModel.currentRoute = state.url;
    if (await this.IsTokenValid()) {
      this.commonService.layoutViewModel.isAuthenticated = true;
      let tokenRole = await this.GetRoleFromToken();
      let validForRoute = expectedRole.filter(x => x == tokenRole);
      if (validForRoute.length > 0) {
        this.commonService.updateLayout(validForRoute[0], this.commonService.layoutViewModel.currentRoute);
        return true;
      } else {
        this.commonService.showSweetAlertToast({ title: 'Not Authorized, Please logout first.' });
        switch (tokenRole) {
          case RoleTypeSM.IndividualInspector:
          case RoleTypeSM.CompanyInspector:
          case RoleTypeSM.CompanyAdmin: {
            this.commonService.updateLayout(tokenRole, this.commonService.layoutViewModel.currentRoute);
            this.router.navigate([AppConstants.WebRoutes.HOME]);
            break;
          }
          case RoleTypeSM.SuperAdmin:
          case RoleTypeSM.SystemAdmin: {
            this.commonService.updateLayout(tokenRole, this.commonService.layoutViewModel.currentRoute);
            this.router.navigate([AppConstants.WebRoutes.ADMIN.DASHBOARD]);
            break;
          }
          case RoleTypeSM.Unknown:
            break;
          default: {
            this.accountService.logoutUser();
            this.commonService.updateLayout(RoleTypeSM.Unknown, this.commonService.layoutViewModel.currentRoute);
            this.router.navigate([AppConstants.WebRoutes.HOME]);
            break;
          }
        }
        return false;
      }
    } else {
      this.accountService.logoutUser();
      this.commonService.layoutViewModel.isAuthenticated = false;
      this.commonService.updateLayout(RoleTypeSM.Unknown, this.commonService.layoutViewModel.currentRoute);
      if (expectedRole.filter(x => x == RoleTypeSM.Unknown).length > 0) {
        return true;
      }
      this.commonService.showSweetAlertToast({ title: 'Not Authorized.' });
      this.router.navigate([AppConstants.WebRoutes.HOME]);
      return false;
    }
  }


  async canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean> {
    const token: string = await this.storageService.getFromStorage(AppConstants.DbKeys.ACCESS_TOKEN);
    return !jwtHelper.isTokenExpired(token);
  }

  async IsTokenValid(): Promise<boolean> {
    const token: string = await this.storageService.getDataFromAnyStorage(AppConstants.DbKeys.ACCESS_TOKEN);
    if (token != undefined && token != null && token != "")
      return !jwtHelper.isTokenExpired(token);
    return false;
  }


  async GetRoleFromToken(): Promise<RoleTypeSM> {
    let resp: any = RoleTypeSM.Unknown;
    try {
      const token: string = await this.storageService.getDataFromAnyStorage(AppConstants.DbKeys.ACCESS_TOKEN);
      if (token != undefined && token != null && token != "") {
        const tokenPayload: any = await jwtHelper.decodeToken(token);;
        resp = RoleTypeSM[tokenPayload[AppConstants.Token_Info_Keys.Role]];
      }
    } catch (error) {
      throw error;
    }
    this.commonService.layoutViewModel.roleType = resp;
    return resp;
  }

  async IsRoleForRoute(expectedRole: any): Promise<boolean> {
    const token: string = await this.storageService.getDataFromAnyStorage(AppConstants.DbKeys.ACCESS_TOKEN);
    const tokenPayload: any = decode(token);
    let tokenRole = RoleTypeSM[tokenPayload[AppConstants.Token_Info_Keys.Role]];
    if (tokenRole === expectedRole)
      return true;
    return false;
  }

  async GetCompanyIdFromToken(): Promise<number> {
    let resp: number = 0;
    try {
      const token: string = await this.storageService.getDataFromAnyStorage(AppConstants.DbKeys.ACCESS_TOKEN);
      if (token != undefined && token != null && token != "") {
        const tokenPayload: any = await jwtHelper.decodeToken(token);;
        resp = tokenPayload[AppConstants.Token_Info_Keys.CompanyId];
      }
    } catch (error) {
      throw error;
    }
    return resp;
  }
}
