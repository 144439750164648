import { Injectable } from "@angular/core";
import { LicenseInfoClient } from "../clients/license-info.client";
import { UserLicenseDetailsSM } from "../service-models/app/v1/license/user-license-details-s-m";
import { ApiResponse } from "../service-models/foundation/api-contracts/base/api-response";
import { OdataQueryFilter } from "../service-models/foundation/api-contracts/odata-query-filter";
import { IntResponseRoot } from "../service-models/foundation/common-response/int-response-root";

import { BaseService } from "./base.service";
import { AppConstants } from "src/app-constants";
import { ApiRequest } from "../service-models/foundation/api-contracts/base/api-request";
import { ClientUserExtededUserLicense } from "../service-models/app/v1/license/client-user-exteded-user-license";
import { ActiveDeactiveCompanyInspectorLicenseSM } from "../service-models/app/v1/license/active-deactive-company-inspector-license-s-m (1)";

@Injectable({
  providedIn: "root",
})
export class LicenseInfoService extends BaseService {
  constructor(private licenseInfoClient: LicenseInfoClient) {
    super();
  }
  /**get all License data  request to client */

  async getAllLicenseInfo(): Promise<ApiResponse<UserLicenseDetailsSM[]>> {
    return await this.licenseInfoClient.GetAllLicenseInfo();
  }
  async getAllLicenseInfoByOdata(
    queryFilter: OdataQueryFilter
  ): Promise<ApiResponse<UserLicenseDetailsSM[]>> {
    return await this.licenseInfoClient.GetAllLicenseInfoByOdata(queryFilter);
  }

  async getLicenseInfoCount(): Promise<ApiResponse<IntResponseRoot>> {
    return await this.licenseInfoClient.GetAllLicenseInfoCount();
  }

  async GetSubscription(): Promise<ApiResponse<UserLicenseDetailsSM>> {
    return await this.licenseInfoClient.GetMySubscription();
  }
  async GetPreviousCompanySubscription(): Promise<
    ApiResponse<UserLicenseDetailsSM[]>
  > {
    return await this.licenseInfoClient.GetMyPreviousCompanySubscription();
  }

  async GetMyPreviousIndividualInspectorSubscription(): Promise<ApiResponse<UserLicenseDetailsSM[]> > {
    return await this.licenseInfoClient.GetMyPreviousIndividualInspectorSubscription();
  }

  async getAllCompanyInspectorsCount(): Promise<ApiResponse<IntResponseRoot>> {
    return await this.licenseInfoClient.GetAllCompanyInspectorsCount();
  }

  async AddLicenseToInspectors( req: ActiveDeactiveCompanyInspectorLicenseSM): Promise<ApiResponse<UserLicenseDetailsSM>> {
    if (req == null) {
      throw new Error(AppConstants.ErrorPrompts.Invalid_Input_Data);
    } else {
      let apiRequest = new ApiRequest<ActiveDeactiveCompanyInspectorLicenseSM>();
      apiRequest.reqData = req;
      return await this.licenseInfoClient.AddLicenseToInspectors(apiRequest);
    }
  }
  
  async GetActiveInspectorLicense (): Promise<ApiResponse<ClientUserExtededUserLicense[]>> {
    return await this.licenseInfoClient.GetActiveInspectorLicense();
  }
}
