import { ClientUserSM } from "../service-models/app/v1/app-users/client-user-s-m";
import { FeatureGroupSM } from "../service-models/app/v1/license/feature-group-s-m";
import { FeatureSM } from "../service-models/app/v1/license/feature-s-m";
import { SubscriptionUpgradeRequestSM } from "../service-models/app/v1/license/subscription-upgrade-request-s-m";
import { SubscriptionUpgradeResponseSM } from "../service-models/app/v1/license/subscription-upgrade-response-s-m";
import { UserLicenseDetailsSM } from "../service-models/app/v1/license/user-license-details-s-m";
import { BaseViewModel } from "./base.viewmodel";
export class PricingViewModel implements BaseViewModel {
  PageTitle: string = 'Features';
  FeatureGroup: FeatureGroupSM = new FeatureGroupSM();
  FeatureGroupList!: FeatureGroupSM[];
  // features: FeatureSM = new FeatureSM();

  allFeatures: FeatureSM[] = [];
  libraryFeatures: FeatureSM[] = [];
  inspectorFeatures: FeatureSM[] = [];
  reportFeatures: FeatureSM[] = [];
  otherFeatures: FeatureSM[] = [];
  enabled: string = 'Enabled';
  disabled: string = 'Disabled';
  SelectedFeatureEnabled: boolean = false;
  SelectedFeatureValue: string = '';
  SelectedFeatureValueCount: string = '';
  activeLicense: UserLicenseDetailsSM = new UserLicenseDetailsSM();
  upgradeSubscription: SubscriptionUpgradeRequestSM = new SubscriptionUpgradeRequestSM();
  user: ClientUserSM = new ClientUserSM();
  upgradeSubscriptionResponse: SubscriptionUpgradeResponseSM = new SubscriptionUpgradeResponseSM();
  checkActiveLicense!: boolean;
  checkValidUser!: boolean;
  buyBtn: string = 'Buy Now';
  purchasedBtn: string = 'Purchased';
  showTable: boolean = false;

  // isDisabled:boolean=false;
  // roleType = new Array<string>();
  // currentRoleType: any = '';
  // UserLicense!: UserLicenseDetailsSM;

  isExpandLibraryFeatures: boolean = false;
  isExpandInspectorFeatures: boolean = false
  isExpandReportFeatures: boolean = false;
  isExpandOtherFeatures: boolean = false;
}

