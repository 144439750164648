import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppConstants } from 'src/app-constants';
import { PriorityTypeSM } from 'src/app/service-models/app/enums/priority-type-s-m.enum';
import { ClientUserAddressSM } from 'src/app/service-models/app/v1/app-users/client-user-address-s-m';
import { ClientUserSM } from 'src/app/service-models/app/v1/app-users/client-user-s-m';
import { ClientCompanyDetailSM } from 'src/app/service-models/app/v1/client/client-company-detail-s-m';
import { ApiResponse } from 'src/app/service-models/foundation/api-contracts/base/api-response';
import { OdataQueryFilter } from 'src/app/service-models/foundation/api-contracts/odata-query-filter';
import { CommonService } from 'src/app/services/common.service';
import { LogHandlerService } from 'src/app/services/log-handler.service';
import { ManageInspectorsService } from 'src/app/services/manage-inspectors.service';
import { ManageInspectorsviewModel } from 'src/app/view-models/manage-inspectors.viewModel';
import { BaseComponent } from '../../components/base.component';
import { AccountService } from 'src/app/services/account.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-manage-inspectors',
  templateUrl: './manage-inspectors.component.html',
  styleUrls: ['./manage-inspectors.component.scss']
})
export class ManageInspectorsComponent extends BaseComponent<ManageInspectorsviewModel> implements OnInit {

  @Input() companyDetail: ClientCompanyDetailSM = new ClientCompanyDetailSM();

  constructor(
    private modalService: NgbModal, private manageInspectorService: ManageInspectorsService,
    private accountService: AccountService,
    public activeModal: NgbActiveModal,
    commonService: CommonService, logService: LogHandlerService) {
    super(commonService, logService);
    this.viewModel = new ManageInspectorsviewModel();
  }


  async ngOnInit(): Promise<void> {
    await this._commonService.presentLoading();
    setTimeout(async () => {
      await this._commonService.dismissLoader();
      await this.getEnumsArray();
      await this.loadPageData();
    }, environment.animationTimeoutinMS);

  }


  override async loadPageData() {
    try {
      await this._commonService.presentLoading();

      let queryFilter = new OdataQueryFilter();
      queryFilter.skip = (this.viewModel.PageNo - 1) * this.viewModel.PageSize;
      queryFilter.top = this.viewModel.PageSize;
      let resp = await this.manageInspectorService.getAllCompanyInspectorsByOdata(this.companyDetail.id, queryFilter);
      resp.successData
      if (resp.isError) {
        this._exceptionHandler.logObject(resp.errorData);
        this._commonService.showSweetAlertToast({ title: resp.errorData.displayMessage, icon: 'error' });
        return;
      } else {
        await this.getCompanyInspectorsCount(this.companyDetail.id);
        this.viewModel.inspectors = resp.successData;
      }
    } catch (error) {
      this._commonService.showSweetAlertToast({ title: AppConstants.ErrorPrompts.Unknown_Error, icon: 'error' });
      throw error;
    } finally {
      await this._commonService.dismissLoader()
    }

  }
  async getCompanyInspectorsCount(id: number) {
    try {
      await this._commonService.presentLoading();

      let resp = await this.manageInspectorService.getCompanyInspectorsCount(id);

      if (resp.isError) {
        this._exceptionHandler.logObject(resp.errorData);
        this._commonService.showSweetAlertToast({ title: resp.errorData.displayMessage, icon: 'error' });
        return;
      }
      else {
        this.viewModel.totalCount = resp.successData.intResponse;
      }
    } catch (error) {
      this._commonService.showSweetAlertToast({ title: AppConstants.ErrorPrompts.Unknown_Error, icon: 'error' });
      throw error;
    }
    finally {
      await this._commonService.dismissLoader()
    }

  }

  async getInspectorById(InspectorId: number) {
    try {
      await this._commonService.presentLoading();
      let resp = await this.manageInspectorService.getInspectorById(InspectorId);
      if (resp.isError) {
        await this._exceptionHandler.logObject(resp.errorData);
        this._commonService.showSweetAlertToast({ title: 'Error!', icon: 'error' });
      } else {
        this.viewModel.singleInspector = resp.successData;
      }
    } catch (error) {
      throw error;
    } finally {
      await this._commonService.dismissLoader()
    }

  }

  async getInspectorAddressById(InspectorId: number) {
    try {
      await this._commonService.presentLoading();

      let resp = await this.manageInspectorService.getInspectorAddressById(InspectorId);
      if (resp.successData !== null) {
        if (resp.isError) {
          await this._exceptionHandler.logObject(resp.errorData);
          this._commonService.showSweetAlertToast({ title: 'Error!', icon: 'error' });
        } else {

          this.viewModel.singleInspectorAddress = resp.successData;

        }
      }
    } catch (error) {
      throw error;
    } finally {
      await this._commonService.dismissLoader()
    }

  }

  async form_addEditInspector(inspectorForm: NgForm) {
    this.viewModel.FormSubmitted = true;
    try {
      if (inspectorForm.invalid)
        return;
      await this._commonService.presentLoading();
      let resp: ApiResponse<ClientUserSM>;
      let resp1: ApiResponse<ClientUserAddressSM>
      if (this.viewModel.singleInspector.id && this.viewModel.singleInspector.id > 0) {
        resp = await this.manageInspectorService.updateInspector(this.viewModel.singleInspector, this.viewModel.singleInspector.id);

      }
      else {
        this.viewModel.singleInspector.isEmailConfirmed = true;
        this.viewModel.singleInspector.isPhoneNumberConfirmed = true;
        this.viewModel.singleInspector.stripeCustomerId = '';
        resp = await this.manageInspectorService.AddInspector(this.viewModel.singleInspector);
      }
      if (resp.isError) {
        await this._exceptionHandler.logObject(resp.errorData);
        this._commonService.showSweetAlertToast({ title: resp.errorData.displayMessage, icon: 'error' });
        return
      }
      else {
        this.modalService.dismissAll();
        this._commonService.showSweetAlertToast({ title: 'Success', icon: 'success' });
      }

      if (this.viewModel.singleInspectorAddress.id && this.viewModel.singleInspectorAddress.id > 0) {
        resp1 = await this.manageInspectorService.updateInspectorAddress(this.viewModel.singleInspectorAddress, this.viewModel.singleInspectorAddress.id)
      }
      else {
        this.viewModel.singleInspectorAddress.clientUserId = resp.successData.id;
        this.viewModel.singleInspector.id = resp.successData.id;

        resp1 = await this.manageInspectorService.addInspectorAddress(this.viewModel.singleInspectorAddress, this.viewModel.singleInspector.id);
      }
      if (resp1.isError) {
        await this._exceptionHandler.logObject(resp.errorData);
        this._commonService.showSweetAlertToast({ title: resp.errorData.displayMessage, icon: 'error' });
        return
      }
      else {
        this.modalService.dismissAll();
        this._commonService.showSweetAlertToast({ title: 'Success', icon: 'success' });
        this.loadPageData();
      }
    } catch (error) {
      throw error;
    } finally {
      await this._commonService.dismissLoader()
    }
  }


  async deleteInspector(inspectorId: number) {
    let deleteConfirmation = await this._commonService.showSweetAlertConfirmation({
      title: `${AppConstants.DefaultMessages.DeleteAlertConfirmation} Inspector?`,
      text: " ",
      showCancelButton: true,
      icon: "warning"
    }
    );
    if (deleteConfirmation) {
      try {
        let resp = await this.manageInspectorService.deleteInspectorById(inspectorId);
        if (resp.isError) {
          await this._exceptionHandler.logObject(resp.errorData);
          this._commonService.showSweetAlertToast({ title: resp.errorData.displayMessage, icon: 'error' });

        } else {
          this._commonService.showSweetAlertToast({ title: 'Success', icon: 'success' });

          this.loadPageData();
          return;
        }
      } catch (error) {
        this._commonService.showSweetAlertToast({ title: 'Error', icon: 'error' });
        throw error;
      } finally {
        await this._commonService.dismissLoader();
      }
    }
  }

  async click_OpenAddEditModal(InspectorModal: TemplateRef<any>, inspectorId: number) {
    this.viewModel.AddEditModalTitle = inspectorId > 0 ? 'Update Inspector' : 'Add Inspector';
    this.viewModel.singleInspector = new ClientUserSM()
    this.viewModel.singleInspectorAddress = new ClientUserAddressSM()
    if (inspectorId > 0) {
      await this.getInspectorById(inspectorId);
      await this.getInspectorAddressById(inspectorId)
    }
    await this.modalService.open(InspectorModal).result.then(
      (result: any) => {
      },
      (reason: any) => {
      },
    );
  }
  getEnumsArray() {
    this.viewModel.priorityTypes = this._commonService.enumToStringArray(PriorityTypeSM);
  }

  async loadPagedataWithPagination(event: any) {
    this.viewModel.PageNo = event;
    await this.loadPageData();

  }
  click_TogglePassword() {
    this.viewModel.hidePassword = !this.viewModel.hidePassword;
    if (this.viewModel.eyeDefault == 'default') {
      this.viewModel.eyeDefault = 'eyeChange';
    } else {
      this.viewModel.eyeDefault = 'default';
    }
    return;
  }

  async change_LoginId(loginId: string) {
    setTimeout(async () => {
      let resp = await this.accountService.ValidateLoginId(loginId);
      this.viewModel.validUser = resp.successData;
      if (resp.successData.boolResponse == true) {
        this.viewModel.showSuccessMsg = true;
        this.viewModel.showErrorMsg = false;
      }
      else {
        this.viewModel.showSuccessMsg = false;
        this.viewModel.showErrorMsg = true;
      }
    }, 2000);
  }

}
