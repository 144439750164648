import { Injectable } from '@angular/core';
import { AppConstants } from 'src/app-constants';
import { FeatureGroupClient } from '../clients/feature-group.client';
import { FeatureGroupSM } from '../service-models/app/v1/license/feature-group-s-m';
import { FeatureSM } from '../service-models/app/v1/license/feature-s-m';
import { ApiRequest } from '../service-models/foundation/api-contracts/base/api-request';
import { ApiResponse } from '../service-models/foundation/api-contracts/base/api-response';
import { OdataQueryFilter } from '../service-models/foundation/api-contracts/odata-query-filter';
import { DeleteResponseRoot } from '../service-models/foundation/common-response/delete-response-root';
import { IntResponseRoot } from '../service-models/foundation/common-response/int-response-root';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class FeatureGroupService extends BaseService {

  constructor(private featureGroupClient: FeatureGroupClient) {
    super();
  }
  /**get all featureGroup data  request to client */


  async getAllFeatureGroupbyOdata(queryFilter:OdataQueryFilter):Promise<ApiResponse<FeatureGroupSM[]>>{
    return await this.featureGroupClient.GetFeatureGroupByOdata(queryFilter);

  }
  async getFeatureGroupCount():Promise<ApiResponse<IntResponseRoot>>{
    return await this.featureGroupClient.GetAllFeatureGroupCount()
  }

  async getAllFeatureGroup(): Promise<ApiResponse<FeatureGroupSM[]>> {
    return await this.featureGroupClient.GetAllFeatureGroup();
  }
  
  async getAllFeatureGroupExtended(): Promise<ApiResponse<FeatureGroupSM[]>> {
    return await this.featureGroupClient.GetAllFeatureGroupExtended();
  }
  /**get all featureGroup data by id  request to client */

  async getFeatureGroupById(id: number): Promise<ApiResponse<FeatureGroupSM>> {
    if (id <= 0) {
      throw new Error(AppConstants.ErrorPrompts.Delete_Data_Error)
    }
    return await this.featureGroupClient.GetFeatureGroupById(id);
  }

  /**get featureGroup data by id  request to client */

  async getFeatureGroupByIdExtended(id: number): Promise<ApiResponse<FeatureGroupSM>> {
    if (id <= 0) {
      throw new Error(AppConstants.ErrorPrompts.Delete_Data_Error)
    }
    return await this.featureGroupClient.GetFeatureGroupByIdExtended(id);
  }
  /**add  featureGroup  request to client */

  async SendFeatureGroup(featureGroup: FeatureGroupSM): Promise<ApiResponse<FeatureGroupSM>> {
    if (featureGroup == null) {
      throw new Error(AppConstants.ErrorPrompts.Invalid_Input_Data);
    }
    else {
      let apiRequest = new ApiRequest<FeatureGroupSM>();
      apiRequest.reqData = featureGroup;
      return await this.featureGroupClient.AddFeatureGroup(apiRequest);
    }


  }
  /**get all featureGroup data  request to client */

  async updateFeatureGroup(featureGroup: FeatureGroupSM): Promise<ApiResponse<FeatureGroupSM>> {
    if (featureGroup == null) {
      throw new Error(AppConstants.ErrorPrompts.Invalid_Input_Data);
    }
    else {
      let apiRequest = new ApiRequest<FeatureGroupSM>();
      apiRequest.reqData = featureGroup;
      return await this.featureGroupClient.UpdateFeatureGroup(apiRequest);
    }


  }


  /**delete featureGroup by id  request to client */

  async deleteFeatureGroupById(id: number): Promise<ApiResponse<DeleteResponseRoot>> {
    if (id <= 0) {
      throw new Error(AppConstants.ErrorPrompts.Delete_Data_Error)
    }
    return await this.featureGroupClient.DeleteFeatureGroupById(id);
  }
}