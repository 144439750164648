<div class="container-fluid">

    <div class="row">

        <div class="col-lg-12 mb-lg-4 mb-md-4 mb-sm-4 mb-4 ps-4 profileHeader">

            <div class="card p-lg-3 p-md-3 p-sm-3 p-3">
                <span> Welcome </span>
                <h1> {{viewModel.userDetails.loginId}} </h1>
            </div>

        </div>

        <div class="col-lg-8 col-md-8 col-sm-12 col-12 ps-4 firstColumn">

            <div class="card p-4">

                <div class="row">

                    <div class="col-lg-12 pb-3">
                        <h1> Personal Details </h1>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <label class="labels"> First Name </label>
                        <input type="text" [(ngModel)]="viewModel.userDetails.firstName" #firstName="ngModel" required
                            pattern="^[a-zA-Z0-9']+$" name="firstName" minlength="3" maxlength="20" class="form-control"
                            placeholder="First Name">
                        <ng-container *ngFor="let validation of viewModel.ValidationData.firstName">
                            <div class="text-danger ms-lg-2 ms-md-2 ms-sm-2 ms-2"
                                *ngIf="(firstName.touched || viewModel.FormSubmitted) && firstName.hasError(validation.type)">
                                {{ validation.message }}
                            </div>
                        </ng-container>
                    </div>



                    <div class="col-lg-6 col-md-6">
                        <label class="labels"> Middle Name </label>
                        <input type="text" [(ngModel)]="viewModel.userDetails.middleName" name="name"
                            class="form-control" placeholder=" Middle Name">
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <label class="labels"> Last Name </label>
                        <input type="text" [(ngModel)]="viewModel.userDetails.lastName" name="lastName"
                            class="form-control" placeholder="Last Name" #lastName="ngModel" required
                            pattern="^[a-zA-Z0-9']+$" name="firstName" minlength="3" maxlength="20">
                        <ng-container *ngFor="let validation of viewModel.ValidationData.lastName">
                            <div class="text-danger ms-lg-2 ms-md-2 ms-sm-2 ms-2"
                                *ngIf="(lastName.touched || viewModel.FormSubmitted) && lastName.hasError(validation.type)">
                                {{ validation.message }}
                            </div>
                        </ng-container>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <label class="labels"> Email Id </label>
                        <input type="text" [(ngModel)]="viewModel.userDetails.emailId" name="email" class="form-control"
                            #email="ngModel" required
                            pattern="^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$"
                            placeholder="Email Id Name">
                        <ng-container *ngFor="let validation of viewModel.ValidationData.emailId">
                            <div class="text-danger ms-lg-2 ms-md-2 ms-sm-2 ms-2"
                                *ngIf="(email.touched || viewModel.FormSubmitted) && email.hasError(validation.type)">
                                {{ validation.message }}
                            </div>
                        </ng-container>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <label class="labels"> Login Id </label>
                        <input type="text" [(ngModel)]="viewModel.userDetails.loginId" name="name" class="form-control"
                            name="name" class="form-control" #phone="ngModel" required placeholder="Login Id" disabled>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <label class="labels"> Phone </label>
                        <input type="text" [(ngModel)]="viewModel.userDetails.phoneNumber" name="phone"
                            class="form-control" placeholder="Phone" >
                       
                    </div>

                </div>


                <!-- <div class="row">

                    <div class="col-lg-12 py-4">
                        <h1> General Information </h1>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <label class="labels"> User Name </label>
                        <input type="text" [(ngModel)]="viewModel.userDetails.loginId" name="loginId"
                            class="form-control" placeholder="login id">
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <label class="labels"> Enter Password to Save Changes </label>
                        <input type="password" [(ngModel)]="viewModel.userDetails.passwordHash" name="password"
                            class="form-control" placeholder="Enter Password">
                    </div>

                </div> -->


                <div class="row mt-5">

                    <div class="col-lg-12 text-end">
                        <button class="spyGlassBtn" type="button" (click)="updateInspector()"> Save Profile </button>
                    </div>

                </div>

            </div>

        </div>


        <div class="col-lg-4 col-md-4 col-sm-12 col-12 ps-4 secondColumn">

            <div class="card firstCard p-lg-2 p-md-2 p-sm-2 p-2 mt-lg-0 mt-md-0 mt-sm-4 mt-4">

                <div class="profileContent" *ngIf="viewModel.isProfilePicUploaded">

                    <img src="{{'data:image/jpg;base64,' + viewModel.userDetails.profilePicturePath }}" alt="profilePic"
                        class="image">

                    <span class="d-flex flex-column align-items-center companyTitle">
                        {{viewModel.userDetails.loginId}}
                    </span>

                    <button type="button" class="spyGlassBtn d-lg-none d-md-none d-sm-block d-block m-auto"
                        (click)="click_OpenAddEditModal(addEditModal,0)"> Edit </button>

                    <div class="overlay">
                        <a (click)="click_OpenAddEditModal(addEditModal,0)" class="editIcon" title="Change Picture">
                            <i class="bi bi-camera "></i>
                        </a>
                    </div>

                </div>

                <div class="profileContent" *ngIf="!viewModel.isProfilePicUploaded">

                    <img src="assets/images/other-images/userr.jpg" alt="Avatar" class="image">

                    <span class="d-flex flex-column align-items-center companyTitle">
                        {{viewModel.userDetails.loginId}}
                    </span>

                    <div class="overlay">
                        <a (click)="click_OpenAddEditModal(addEditModal,0)" class="editIcon" title="Change Picture">
                            <i class="bi bi-camera"></i>
                        </a>
                    </div>

                </div>

            </div>

            <div class="card secondCard p-lg-2 p-md-2 p-sm-2 p-2 mt-lg-4 mt-md-4 mt-sm-4 mt-4">

                <div class="socialMediaLinks text-center">

                    <span> <i class="bi bi-instagram"></i> </span>
                    <span> <i class="bi bi-linkedin"></i> </span>
                    <span> <i class="bi bi-youtube"></i> </span>
                    <span> <i class="bi bi-facebook"></i> </span>
                    <span> <i class="bi bi-pinterest"></i> </span>

                </div>

            </div>

        </div>

    </div>




    <ng-template #addEditModal let-modal>

        <form #form_Inspector="ngForm" (ngSubmit)="form_uploadPicture(form_Inspector)">

            <div class="modal-header">
                <h4 class="modal-title">{{viewModel.AddEditModalTitle}}</h4>
                <button type="button" class="btn-close" aria-label="Close"
                    (click)="modal.dismiss('Cross click')"></button>
            </div>

            <div class="modal-body">
                <img src="{{'data:image/jpg;base64,' + viewModel.userDetails.profilePicturePath }}" alt="profilePic"
                    class="img-fluid" *ngIf="viewModel.isProfilePicUploaded">

                <img src="assets/images/other-images/userr.jpg" alt="Avatar" class="img-fluid w-50 d-block m-auto"
                    *ngIf="!viewModel.isProfilePicUploaded">

                <input [(ngModel)]="viewModel.userDetails.profilePicturePath" type="file"
                    accept=".png,.jpg,.jpeg,.jpe,.jfif,.JPG,.PNG,.JPEG,.JPE,.JFIF,.BMP,.bmp" class="form-control mt-3"
                    placeholder="upload new image" #chooseFile="ngModel" required name="file" value=""
                    (change)="uploadFile($event)" />
            </div>

            <div class="row modalFooter">

                <div class="col-lg-12 p-4">
                    <i class="bi bi-trash-fill deleteIcon" title="Remove"
                        (click)="deleteProfile(this.viewModel.userDetails.id)" *ngIf="viewModel.showDeleteBtn"></i>
                    <button type="submit" class="spyGlassBtn float-end" (click)="getCompanyInspectorProfilePicture()">
                        Save
                    </button>
                </div>

            </div>

        </form>

    </ng-template>

</div>
































<!-- <div class="row">

        <div class="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
            <h5> Individual Info </h5>
        </div>

    </div>

    <div class="row">

        <div class="col-lg-4 col-md-6 col-sm-12 col-12 text-sm-center">
            <div class="row">
                <div class="col-6">
                    <img class="rounded-circle img-fluid"
                        src="{{'data:image/jpg;base64,' + viewModel.userDetails.profilePicturePath }}">

                </div>
                <div class="col-6 mt-5">
                    <button class="btn btn-success btn-sm one" (click)="click_OpenAddEditModal(addEditModal,0)">Change
                        Photo</button><br><br>
                    <button class="btn btn-danger btn-sm one"
                        (click)="deleteProfile(this.viewModel.userDetails.id)">Delete Photo</button>
                </div>

            </div>
            <span class="d-block text-start"> {{viewModel.userDetails.firstName}} </span>
            <span class="d-block text-start"> {{viewModel.userDetails.loginId}} </span>


        </div>

        <div class="col-lg-4 col-md-6 col-sm-12 col-12 mt-lg-4 mt-md-2 mt-sm-2">

            <label> First Name </label>
            <input type="text" [(ngModel)]="viewModel.userDetails.firstName" name="name" class="form-control"
                placeholder="Enter Name">

            <label> Middle Name </label>
            <input type="text" [(ngModel)]="viewModel.userDetails.middleName" name="name" class="form-control"
                placeholder="Enter Name">

            <label> Last Name </label>
            <input type="text" [(ngModel)]="viewModel.userDetails.lastName" name="name" class="form-control"
                placeholder="Enter Name">

            <label> Email Id </label>
            <input type="text" [(ngModel)]="viewModel.userDetails.emailId" name="name" class="form-control"
                placeholder="Enter Name">

            <label> Phone </label>
            <input type="text" [(ngModel)]="viewModel.userDetails.phoneNumber" name="name" class="form-control"
                placeholder="Enter Name">

        </div>

        <div class="col-lg-4 col-md-6 col-sm-12 col-12 mt-lg-4 mt-md-2 mt-sm-2">

            <label class="labels"> User Name </label>
            <input type="text" [(ngModel)]="viewModel.userDetails.loginId" name="loginId" class="form-control"
                placeholder="login id">

            <label class="labels"> Enter Password to Save Changes </label>
            <input type="password" [(ngModel)]="viewModel.userDetails.passwordHash" name="password" class="form-control"
                placeholder="Enter Password">

        </div>

        <div class="col-lg-12 col-md-12 col-sm-12 col-12 text-end pt-lg-3 pt-md-3 pt-sm-3 pt-3">
            <button type="button" class="spyGlassBtn" (click)="updateInspector()">
                Save Profile
            </button>
        </div>

    </div> -->