
<div class="row">

    <div class="mb-3">
        <label class="form-label">User Name</label>
        <input type="text" class="form-control" placeholder="Username" [(ngModel)]="viewModel.tokenRequest.loginId"
            required="title">
    </div>
    <div class="mb-3">
        <label class="form-label">Password</label>
        <input type="password" class="form-control" placeholder="Password" [(ngModel)]="viewModel.tokenRequest.password"
            required="title">
    </div>
    <div class="mb-3">
        <button class="btn btn-primary" (click)="GenerateToken()"> Generate Token</button>
    </div>
</div>


<div class="mb-3">
    <button class="btn btn-primary" (click)="presentAlert()"> Sample Confirm Alert</button>
</div>