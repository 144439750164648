import { Injectable } from '@angular/core';
import { AppConstants } from 'src/app-constants';
import { CompaniesClient } from '../clients/companies.client';
import { ClientCompanyDetailSM } from '../service-models/app/v1/client/client-company-detail-s-m';
import { ApiRequest } from '../service-models/foundation/api-contracts/base/api-request';
import { ApiResponse } from '../service-models/foundation/api-contracts/base/api-response';
import { OdataQueryFilter } from '../service-models/foundation/api-contracts/odata-query-filter';
import { IntResponseRoot } from '../service-models/foundation/common-response/int-response-root';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class CompaniesService extends BaseService {



  constructor(private companiesClient: CompaniesClient) {
    super();

  }
  async getAllCompanies(): Promise<ApiResponse<ClientCompanyDetailSM[]>> {
    return await this.companiesClient.GetAllCompanies();
  }

  async getAllCompaniesByOdata(queryFilter: OdataQueryFilter): Promise<ApiResponse<ClientCompanyDetailSM[]>> {
    return await this.companiesClient.GetAllCompaniesByOdata(queryFilter);
  }
  async getCompanyCount():Promise<ApiResponse<IntResponseRoot>>{
    return await this.companiesClient.GetAllCompanyCount()
  }

  async updateLoginStatus(status:ClientCompanyDetailSM):Promise<ApiResponse<ClientCompanyDetailSM>> {
    if (status == null) {
      throw new Error(AppConstants.ErrorPrompts.Invalid_Input_Data);
    }
    else {
      let apiRequest = new ApiRequest<ClientCompanyDetailSM>();
      apiRequest.reqData = status;
      return await this.companiesClient.UpdateLoginStatus(apiRequest);
    }
  }

}