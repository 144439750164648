import { Injectable } from '@angular/core';
import { AppConstants } from 'src/app-constants';
import { AccountsClient } from '../clients/accounts.client';
import { RoleTypeSM } from '../service-models/app/enums/role-type-s-m.enum';

import { ClientUserAddressSM } from '../service-models/app/v1/app-users/client-user-address-s-m';
import { ClientUserSM } from '../service-models/app/v1/app-users/client-user-s-m';
import { ClientCompanyAddressSM } from '../service-models/app/v1/client/client-company-address-s-m';
import { ClientCompanyDetailSM } from '../service-models/app/v1/client/client-company-detail-s-m';
import { ApiRequest } from '../service-models/foundation/api-contracts/base/api-request';
import { ApiResponse } from '../service-models/foundation/api-contracts/base/api-response';
import { BoolResponseRoot } from '../service-models/foundation/common-response/bool-response-root';
import { BaseService } from './base.service';
import { CommonService } from './common.service';
import { StorageService } from './storage.service';
import { TokenRequestSM } from '../service-models/app/token/token-request-s-m';
import { TokenResponseSM } from '../service-models/app/token/token-response-s-m';
import { ResetPasswordRequestSM } from '../service-models/app/v1/app-users/login/reset-password-request-s-m';
import { ForgotPasswordSM } from '../service-models/app/v1/app-users/forgot-password-s-m';
import { ValidatePasswordLinkStatusSM } from '../service-models/app/enums/validate-password-link-status-s-m.enum';
import { IntResponseRoot } from '../service-models/foundation/common-response/int-response-root';
import { LoginUserSM } from '../service-models/app/v1/app-users/login/login-user-s-m';


@Injectable({
  providedIn: 'root'
})
export class AccountService extends BaseService {

  constructor(private accountClient: AccountsClient,
    private storageService: StorageService, private commonService: CommonService) {
    super();
  }

  // async generateToken(tokenReq: TokenRequestSM, loginViewModel: LoginViewModel): Promise<ApiResponse<TokenResponseSM>> {
  //   if (tokenReq == null || tokenReq.loginId == null)// null checks
  //   {
  //     throw new Error(AppConstants.ErrorPrompts.Invalid_Input_Data);
  //   }
  //   else {
  //     let apiRequest = new ApiRequest<TokenRequestSM>();
  //     tokenReq.companyCode = '123';
  //     tokenReq.roleType = RoleTypeSM.ClientAdmin;
  //     apiRequest.reqData = tokenReq;
  //     var resp = await this.accountClient.GenerateToken(apiRequest);
  //     if (resp == null || resp.successData.accessToken == '') {
  //       return resp;
  //     } else if (loginViewModel.rememberMe == true) {
  //       // save to local storage
  //       this.storageService.setToStorage(AppConstants.DbKeys.ACCESS_TOKEN, resp.successData.accessToken);
  //       this.storageService.setToStorage(AppConstants.DbKeys.LOGIN_USER, resp.successData.loginUserDetails);
  //       this.storageService.setToStorage(AppConstants.DbKeys.LOGIN_USER, resp.successData.expiresUtc);
  //       this.storageService.setToStorage(AppConstants.DbKeys.LOGIN_USER, resp.successData.clientCompantId);
  //       this.storageService.setToStorage(AppConstants.DbKeys.REMEMBER_ME, loginViewModel.rememberMe)

  //     // } else if (loginViewModel.rememberMe == false) {
  //     //   // save to session storage
  //     //   this.storageService.saveToSessionStorage(AppConstants.DbKeys.ACCESS_TOKEN, resp.successData.accessToken);
  //     //   this.storageService.saveToSessionStorage(AppConstants.DbKeys.TOKEN_EXPIRY, resp.successData.expiresUtc.toString());
  //     //   this.storageService.saveToSessionStorage(AppConstants.DbKeys.LOGIN_USER, JSON.stringify(resp.successData.loginUserDetails));
  //     //   this.storageService.saveToSessionStorage(AppConstants.DbKeys.COMPANY_CODE, resp.successData.clientCompantId)
  //     //   this.storageService.saveToSessionStorage(AppConstants.DbKeys.REMEMBER_ME, JSON.stringify(loginViewModel.rememberMe));
  //       return resp;

  //     // }

  //   }
  //   }
  // }

  // async isUserTokenValid(): Promise<boolean> {
  //   return true;
  //   // return await this.authGuard.IsTokenValid();
  // }
  async generateToken(tokenReq: TokenRequestSM, rememberMe: boolean): Promise<ApiResponse<TokenResponseSM>> {
    if (tokenReq == null || tokenReq.loginId == null)// null checks
    {
      throw new Error(AppConstants.ErrorPrompts.Invalid_Input_Data);
    }
    else {
      let apiRequest = new ApiRequest<TokenRequestSM>();
      //dummy
      // tokenReq.companyCode == undefined ? '123' : tokenReq.companyCode;
      // if (tokenReq.roleType == RoleTypeSM.Inspector)
      // tokenReq.companyCode = '123';
      apiRequest.reqData = tokenReq;
      var resp = await this.accountClient.GenerateToken(apiRequest);
      if (!resp.isError && resp.successData != null) {
        this.storageService.saveToStorage(AppConstants.DbKeys.REMEMBER_PWD, rememberMe);
        if (rememberMe) {
          this.storageService.saveToStorage(AppConstants.DbKeys.ACCESS_TOKEN, resp.successData.accessToken);
          this.storageService.saveToStorage(AppConstants.DbKeys.LOGIN_USER, resp.successData.loginUserDetails);
          this.storageService.saveToStorage(AppConstants.DbKeys.COMPANY_CODE, tokenReq.companyCode);
          // this.storageService.saveToStorage(AppConstants.DbKeys.PASSWORD, tokenReq.password);
        }
        else {
          this.storageService.saveToSessionStorage(AppConstants.DbKeys.ACCESS_TOKEN, resp.successData.accessToken);
          this.storageService.saveToSessionStorage(AppConstants.DbKeys.LOGIN_USER, resp.successData.loginUserDetails);
          this.storageService.saveToSessionStorage(AppConstants.DbKeys.COMPANY_CODE, tokenReq.companyCode);
        }
      }
      return resp;
    }
  }
  async logoutUser() {
    try {
      this.storageService.removeFromSessionStorage(AppConstants.DbKeys.ACCESS_TOKEN);
      this.storageService.removeFromSessionStorage(AppConstants.DbKeys.LOGIN_USER);
      this.storageService.removeFromSessionStorage(AppConstants.DbKeys.COMPANY_CODE);
      this.storageService.removeFromSessionStorage(AppConstants.DbKeys.REMEMBER_PWD);
    }
    catch (err) {
      this.storageService.clearSessionStorage();
    }
    try {
      this.storageService.removeFromStorage(AppConstants.DbKeys.ACCESS_TOKEN);
      this.storageService.removeFromStorage(AppConstants.DbKeys.LOGIN_USER);
      this.storageService.removeFromStorage(AppConstants.DbKeys.COMPANY_CODE);
      this.storageService.removeFromStorage(AppConstants.DbKeys.REMEMBER_PWD);
    } catch (error) {
      this.storageService.clearStorage();
    }
    this.commonService.updateLayout(RoleTypeSM.Unknown, "");
    await this.commonService.loadDefaultTheme();

  }

  // async getTokenFromStorage(): Promise<string> {
  //   let remMe: boolean = await this.storageService.getFromStorage(AppConstants.DbKeys.REMEMBER_PWD);
  //   if (remMe && remMe == true)
  //     return await this.storageService.getFromStorage(AppConstants.DbKeys.ACCESS_TOKEN);
  //   return await this.storageService.getFromSessionStorage(AppConstants.DbKeys.ACCESS_TOKEN);
  // }

  // async getUserFromStorage(): Promise<LoginUserSM | ""> {
  //   let remMe: boolean = await this.storageService.getFromStorage(AppConstants.DbKeys.REMEMBER_PWD);
  //   if (remMe && remMe == true)
  //     return await this.storageService.getFromStorage(AppConstants.DbKeys.LOGIN_USER);
  //   return await this.storageService.getFromSessionStorage(AppConstants.DbKeys.LOGIN_USER);
  // }

  async AddUser(register: ClientUserSM): Promise<ApiResponse<ClientUserSM>> {
    if (register == null) {
      throw new Error(AppConstants.ErrorPrompts.Invalid_Input_Data);
    }

    else {
      let apiRequest = new ApiRequest<ClientUserSM>();
      apiRequest.reqData = register;

      return this.accountClient.RegisterUser(apiRequest);

    }
  }
  async AddCompany(register: ClientCompanyDetailSM): Promise<ApiResponse<ClientCompanyDetailSM>> {
    if (register == null) {
      throw new Error(AppConstants.ErrorPrompts.Invalid_Input_Data);
    }
    else {
      let apiRequest = new ApiRequest<ClientCompanyDetailSM>();
      apiRequest.reqData = register;
      return this.accountClient.RegisterCompany(apiRequest);

    }
  }

  async AddCompanyAddress(register: ClientCompanyAddressSM): Promise<ApiResponse<ClientCompanyAddressSM>> {
    if (register == null) {
      throw new Error(AppConstants.ErrorPrompts.Invalid_Input_Data);
    }
    else {
      let apiRequest = new ApiRequest<ClientCompanyAddressSM>();
      apiRequest.reqData = register;
      return await this.accountClient.AddCompanyAddress(apiRequest);
    }
  }

  async AddUserAddress(userAddress: ClientUserAddressSM): Promise<ApiResponse<ClientUserAddressSM>> {
    if (userAddress == null) {
      throw new Error(AppConstants.ErrorPrompts.Invalid_Input_Data);
    }
    else {
      let apiRequest = new ApiRequest<ClientUserAddressSM>();
      apiRequest.reqData = userAddress;
      return await this.accountClient.AddUserAddress(apiRequest);
    }
  }


  async ValidateLoginId(loginId: string): Promise<ApiResponse<BoolResponseRoot>> {
    return await this.accountClient.ValidateLoginId(loginId);
  }

  async UpdatePassword(updatePassword: ResetPasswordRequestSM): Promise<ApiResponse<ResetPasswordRequestSM>> {
    if (updatePassword == null) {
      throw new Error(AppConstants.ErrorPrompts.Invalid_Input_Data);
    }
    else {
      let apiRequest = new ApiRequest<ResetPasswordRequestSM>();
      apiRequest.reqData = updatePassword;
      return await this.accountClient.UpdatePassword(apiRequest);
    }

  }
  async Send_forgotPassword(password: ForgotPasswordSM): Promise<ApiResponse<ForgotPasswordSM>> {
    if (password == null) {
      throw new Error(AppConstants.ErrorPrompts.Invalid_Input_Data);
    }
    else {
      let apiRequest = new ApiRequest<ForgotPasswordSM>();
      apiRequest.reqData = password;
      return await this.accountClient.SendForgotPassword(apiRequest);
    }

  }
 

    async validateAuthCode(authCode: string): Promise<ApiResponse<IntResponseRoot>> {
        return await this.accountClient.ValidateAuthCode(authCode);
      }
 
}






