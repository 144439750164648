
import { NewsFeedSM } from "src/app/service-models/app/v1/general/news-feed-s-m";
import { BaseViewModel } from "../base.viewmodel";
export class NewsFeedViewModel implements BaseViewModel {
  PageTitle: string = 'News Feed';
  AddEditModalTitle: string = '';
  PageNo: number = 1;
  PageSize: number = 10;
  totalCount!:number;
  newsFeedList: NewsFeedSM[] = [];
  singleNewsFeed: NewsFeedSM = new NewsFeedSM();
  priorityTypes: string[] = [];
  selectedpriortyEnumItem: string = 'Normal';
  FormSubmitted: boolean = false;
  minStartDate = new Date().toISOString().slice(0, -8);
  ValidationData = {
    title: [
      { type: 'required', message: 'Title is required' },
    ],
    description: [
      { type: 'required', message: 'Description is required' },
    ],
    chooseFile:[
      {
        type: 'required', message: 'Choose file is required' 
      }
    ]
  }
}