import { SelectItem } from "src/app/internal-models/common-models";
import { FeatureGroupSM } from "src/app/service-models/app/v1/license/feature-group-s-m";
import { FeatureSM } from "src/app/service-models/app/v1/license/feature-s-m";
import { BaseViewModel } from "../base.viewmodel";
export class FeatureGroupViewModel implements BaseViewModel {
  PageTitle: string = 'Feature Group';
  PageNo: number = 1;
  PageSize: number = 10;
  totalCount!:number;
  AddEditModalTitle: string = '';
  featureGroupList: FeatureGroupSM[] = [];
  featureGroup: FeatureGroupSM = new FeatureGroupSM();
  features = new Array<string>();
  allFeatures: SelectItem[] = [];
  singleFeature: FeatureSM = new FeatureSM();
  FormSubmitted: boolean = false;
  ValidationData = {
    title: [
      { type: 'required', message: 'Title is required' },
    ],
    description: [
      { type: 'required', message: 'Description is required' },
    ],
    validity: [
      { type: 'required', message: 'Validity is required' },
      { type: 'pattern', message: 'validity should be in numbers only' }
    ],
    stripePriceId:[
      {type:'required',message:'stripePriceId is required'}
    ]


  }

}