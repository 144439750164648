
import { FeatureGroupSM } from "../service-models/app/v1/license/feature-group-s-m";
import { BaseViewModel } from "./base.viewmodel";

export class FailedPaymentViewModel implements BaseViewModel{
    PageTitle:string= 'Failed payment';
    PageNo?: number | undefined;
    PageSize?: number | undefined;
    paymentInfo!:PaymentStatusInfo
    emailId:string='renosoftwares@gmail.com'
    featureGroup: FeatureGroupSM=new FeatureGroupSM();
    

}
export interface PaymentStatusInfo {
    fgId: number;
    paymentDate: Date;
}