<section class="sampleMainSection d-flex justify-content-center align-items-center">

    <div class="container">

        <div class="row">

            <div class="col-lg-6 col-md-10 col-12 d-flex flex-column justify-content-center align-items-center" data-aos="fade-right" data-aos-duration="3000">
                <div class="sampleMainSectionText" data-aos="fade-up">

                    <h2> Creating reports that are easy to read, concise, and informative </h2>

                    <span>
                        The key focus of SpyGlass Inspection Software is its exceptional report generation, effectively
                        communicating inspection findings to home buyers, sellers, and agents. Through collaboration and
                        feedback, we deliver the easiest-to-read reports in the industry
                    </span>

                    <button class="spyGlassBtn d-block mt-lg-5 mt-md-5 mt-sm-5 mt-5" data-aos="fade-up"
                        data-aos-delay="100"(click)="navigateToViewReport('viewReport')"> View Report </button>

                </div>
            </div>

            <div class="col-lg-6 col-12">
                <div class="sampleMainSectionImage" data-aos="fade-down" data-aos-delay="1000">
                    <img src="{{'data:image/webp;base64,' + viewModel.sampleReportMainImage }}" class="img-fluid"
                        alt="sample-report">
                </div>
            </div>

        </div>

    </div>

</section>



<section class="sampleSecondSection" data-aos="flip-right" data-aos-easing="ease-out-cubic" data-aos-duration="1000">

    <div class="container">

        <div class="row">

            <div class="col-lg-6 col-md-12 col-sm-12 col-12 firstColumn">

                <div class="card p-0">
                    <img src="{{'data:image/webp;base64,' + viewModel.sampleReportImage}}" class="img-fluid" alt="image"
                        loading="lazy">
                </div>

            </div>

            <div class="col-lg-6 col-md-12 col-sm-12 col-12 mx-auto secondColumn">
                <h3 class="text-center mt-5" >About Report's</h3>
                <div class="secondSectionText">

                    

                    <span class="text">
                        Whether you require the flexibility of editing and customization with MS Word or the portability
                        and consistent formatting of PDF, our Software offers the flexibility to generate inspection
                        reports in both formats.<br/><br/>
                    
                            We understand that different users have varying preferences and
                            requirements when it comes to report formats, thus providing options to choose between these two
                            widely used formats to cater to their specific needs.
                        
                    
                    </span>

                </div>

            </div>

        </div>

    </div>

</section>



<section class="sampleThirdSection" >

    <div class="container"id="viewReport">

        <div class="row firstRow" data-aos="fade-up" data-aos-duration="3000">

            <div class="col-lg-12 col-md-12 col-sm-12 col-12">

                <div class="sampleThirdSectionHeading d-flex justify-content-center align-items-center">
                    <h2> Preview our generated report below to get a glimpse of its appearance</h2>
                </div>

                <table class="table table-striped mt-lg-5">

                    <thead>
                        <tr>
                            <th class="firstTableHead text-center">Title</th>
                            <th class="text-center">Name</th>
                            <th class="text-center">Street</th>
                            <th class="text-center">City</th>
                            <th class="text-center">State</th>
                            <th class="text-center">Zip Code</th>
                            <th class="text-center">Inspector</th>
                            <th class="text-center">Fee</th>
                            <th class="lastTableHead text-center">Download</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr *ngFor="let data of viewModel.additionalDetailsList">
                            <td class="text-center">{{data.ReportTitle}}</td>
                            <td class="text-center">{{data.ClientName}}</td>
                            <td class="text-center">{{data.ClientStreet}}</td>
                            <td class="text-center">{{data.ClientCity}}</td>
                            <td class="text-center">{{data.ClientState}}</td>
                            <td class="text-center">{{data.ClientZip}}</td>
                            <td class="text-center">{{data.Inspector}}</td>
                            <td class="text-center">{{data.Fee}}</td>
                            <td class=" action-Btn text-center" *ngIf="this.viewModel.downloadReports">
                                <a (click)="downloadSampleReportDocument(data.websiteReportsResourceId)"
                                    download={{data.ReportTitle}} target="_blank">
                                    <i class="bi bi-download" style="font-size: 16px; font-weight: bold;"
                                        aria-hidden="true"></i>
                                </a>
                            </td>
                            <td class="reportstable action-Btn" *ngIf="this.viewModel.downloadDummyReports">
                                <a download="dummyReport" target="_blank" href={{data.ReportPath}}>
                                    <i class="bi bi-download bi-xl bi-solid " aria-hidden="true"></i>
                                </a>
                            </td>

                        </tr>

                    </tbody>

                </table>

                <!-- <div class="sampleThirdSectionText text-center">
                    <span> Click on the reports below to view real examples of Home Inspector Pro in use. There are an
                        infinite
                        number of ways to customize your inspection reports
                    </span>
                </div> -->

            </div>

        </div>

        <div class="row secondRow mt-lg-5">

            <div data-aos="flip-right" data-aos-easing="ease-out-cubic" data-aos-duration="1000"
                class="col-lg-6 col-md-6 col-sm-12 col-12 secondRowTextColumn order-lg-1 order-md-1 order-sm-1 order-1">
                <span> Our reports are designed to present information in a clear and concise manner </span>
            </div>

            <div data-aos="flip-left" data-aos-easing="ease-out-cubic" data-aos-duration="1000"
            class="col-lg-6 col-md-6 col-sm-12 col-12 text-center order-lg-2 order-md-2 order-sm-2 order-2">
                <img src="{{'data:image/webp;base64,' + viewModel.sampleReportImage1}}" class="image"
                    alt="reports-image" loading="lazy">
            </div>

            <div data-aos="flip-left" data-aos-easing="ease-out-cubic" data-aos-duration="1000"
            class="col-lg-6 col-md-6 col-sm-12 col-12 text-center order-lg-3 order-md-3 order-sm-4 order-4">
                <img src="{{'data:image/webp;base64,' + viewModel.sampleReportImage2}}" class="image"
                    alt="reports-image" loading="lazy">
            </div>

            <div data-aos="flip-right" data-aos-easing="ease-out-cubic" data-aos-duration="1000"
                class="col-lg-6 col-md-6 col-sm-12 col-12 secondRowTextColumn order-lg-4 order-md-4 order-sm-3 order-3">
                <span> No compromise on providing comprehensive information, includes all relevant inspection findings
                </span>
            </div>

            <div
             data-aos="flip-right" data-aos-easing="ease-out-cubic" data-aos-duration="3000"
                class="col-lg-6 col-md-6 col-sm-12 col-12 secondRowTextColumn order-lg-5 order-md-5 order-sm-5 order-5" 
               >
                <span> Clear headings, subheadings, and action nodes to organize the information logically, making it
                    effortless for readers to locate specific details </span>
            </div>

            <div 
            data-aos="flip-left" data-aos-easing="ease-out-cubic" data-aos-duration="3000"
             class="col-lg-6 col-md-6 col-sm-12 col-12 text-center order-lg-5 order-md-5 order-sm-5 order-5">
                <img src="{{'data:image/webp;base64,' + viewModel.sampleReportImage3}}" class="image"
                    alt="reports-image" loading="lazy">
            </div>

        </div>

    </div>

</section>