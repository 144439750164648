<footer [ngClass]="[_commonService.layoutViewModel.footer]">

    <div class="container">

        <div class="row">

            <div class="col-lg-5 col-12 me-auto mb-5 mb-lg-0 footerFirstColumn">

                <div class="d-flex align-items-center">

                    <img src="/assets/images/Top-Nav&Footer-Images/surveyLogoTransparent.webp"
                        class="footerLogoImage img-fluid" alt="logo-image">

                    <span> SpyGlass </span>

                </div>
                <small class="col-8">Explore our pricing page, where you can choose from a range of licenses tailored to your
                    requirements, granting you access to our powerful desktop software.</small>

            </div>

            <div class="col-lg-4 col-12 footerSecondColumn">

                <div class="pageLinks">
                    <h2>Links</h2>
                    <span routerLink="/home"> Home </span>
                    <span routerLink="/pricing"> Pricing </span>
                    <span routerLink="/product-info"> Product-Info </span>
                    <span routerLink="/reports"> Sample Reports </span>
                    <span routerLink="/how-to-install"> How to install </span>
                    <span routerLink="/demo-videos"> Demo videos </span>

                </div>

            </div>

            <div class="col-lg-3 col-12 text-end">

                <div class="copyrightText">

                    <a href="http://reg-surveybox.renosoftwares.com/desktop_setup.zip" target="_blank">

                        <span> Copyright <br> <i class="bi bi-c-circle"></i> SpyGlass Inspection <br> All Rights
                            Reserved
                        </span>

                    </a>
                </div>
            </div>

        </div>
        <hr>

        <!-- <div class="row text-end">
            <div class="copyrightText">

                <a href="http://reg-surveybox.renosoftwares.com/desktop_setup.zip" target="_blank">

                    <span> Copyright <br> <i class="bi bi-c-circle"></i> SpyGlass Inspection <br> All Rights Reserved
                    </span>

                </a>
            </div>
        </div> -->

        <div class=" footerThirdColumn">

            <div class=" text-center footerSocialLinks">
                <!-- <h2 class="text-center">Socail Network</h2> -->

                <span>
                    <i class="bi bi-facebook"></i>
                </span>

                <span>
                    <i class="bi bi-instagram"></i>
                </span>

                <span>
                    <i class="bi bi-twitter"></i>
                </span>

                <span>
                    <i class="bi bi-youtube"></i>
                </span>

            </div>
            <!-- <ul class="list-unstyled list-group list-group-horizontal footerSocialLinks ">

                <li class="list-group-item">
                    <i class="bi bi-facebook"></i>
                </li>

                <li class="list-group-item">
                    <i class="bi bi-instagram"></i>
                </li>

                <li class="list-group-item">
                    <i class="bi bi-twitter"></i>
                </li>

            </ul> -->
        </div>
    </div>



    <!-- <div class="col-lg-4 col-md-4 col-sm-6 col-12" id="socialNetwork">

        <div class="socialNetworkTitle"> Social Network </div>

        <p class="py-2"> Get in Touch with us on our Social Network </p>

        <div class="social-links py-3">

            <span>
                <i class="fab fa-twitter fa-2xl me-2"> </i>
            </span>

            <span>
                <i class="fab fa-instagram fa-2xl me-2"> </i>
            </span>

            <span>
                <i class="fab fa-facebook-f fa-2xl me-2"> </i>
            </span>

            <span>
                <i class="fab fa-linkedin-in fa-2xl me-2"> </i>
            </span>

        </div>

    </div> -->
    <!-- <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path fill="var(--path)" fill-opacity="1"
            d="M0,224L34.3,192C68.6,160,137,96,206,90.7C274.3,85,343,139,411,144C480,149,549,107,617,122.7C685.7,139,754,213,823,240C891.4,267,960,245,1029,224C1097.1,203,1166,181,1234,160C1302.9,139,1371,117,1406,106.7L1440,96L1440,320L1405.7,320C1371.4,320,1303,320,1234,320C1165.7,320,1097,320,1029,320C960,320,891,320,823,320C754.3,320,686,320,617,320C548.6,320,480,320,411,320C342.9,320,274,320,206,320C137.1,320,69,320,34,320L0,320Z">
        </path>
    </svg> -->

</footer>