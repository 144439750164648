import { LoginStatusSM } from "src/app/service-models/app/enums/login-status-s-m.enum";
import { ClientUserSM } from "src/app/service-models/app/v1/app-users/client-user-s-m";
import { ClientCompanyDetailSM } from "src/app/service-models/app/v1/client/client-company-detail-s-m";
import { UserLicenseDetailsSM } from "src/app/service-models/app/v1/license/user-license-details-s-m";
import { BaseViewModel } from "../base.viewmodel";


export class CompanyOverviewViewModel implements BaseViewModel {
    PageTitle: string = 'Company overview';
    PageNo: number = 1;
    PageSize: number = 10;
    totalCount!: number ;
    companyUserList: ClientUserSM[] = [];
    companyUser: ClientUserSM = new ClientUserSM();
    companyDetailList: ClientCompanyDetailSM[] = [];
    companyDetail: ClientCompanyDetailSM = new ClientCompanyDetailSM();
    allCompanyLicenseDetails: UserLicenseDetailsSM[] = [];
    LicenseDetails: UserLicenseDetailsSM=new UserLicenseDetailsSM();
    isDisabled: boolean = true;
    tabLocation: CompanyOverviewTabs = CompanyOverviewTabs.overview;
}
export enum CompanyOverviewTabs {
    overview = 0,
    users = 1,
    license = 2,
}