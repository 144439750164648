
import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AppConstants } from 'src/app-constants';
import { AccountService } from 'src/app/services/account.service';
import { CommonService } from 'src/app/services/common.service';
import { LogHandlerService } from 'src/app/services/log-handler.service';
import { TopNavViewModel } from 'src/app/view-models/top-nav.viewmodel';
import { ProfileInfoService } from 'src/app/services/profile-info.service';
import { CompanyProfileService } from 'src/app/services/company-profile.service';
import { AuthGuard } from 'src/app/guard/auth.guard';
import { RoleTypeSM } from 'src/app/service-models/app/enums/role-type-s-m.enum';

@Component({
  selector: 'app-top-nav',
  templateUrl: './top-nav.component.html',
  styleUrls: ['./top-nav.component.css']
})

export class TopNavComponent implements OnInit {
  viewModel!: TopNavViewModel;

  protected _commonService: CommonService

  @ViewChild('stickyMenu') stickyMenu!: ElementRef;

  constructor(
    private commonService: CommonService,
    private exceptionHandler: LogHandlerService,
    private accountService: AccountService,
    private router: Router,
    private profileInfoService: ProfileInfoService,
    private companyProfileService: CompanyProfileService,
    private authGuard: AuthGuard,

  ) {
    this._commonService = commonService;
    this.viewModel = new TopNavViewModel();
  }


  async ngOnInit() {
    await this.loadPageData();
  }


  async ngAfterViewInit() {
    await this._commonService.presentLoading();
    this._commonService.layoutViewModel.elementPosition = this.stickyMenu.nativeElement.offsetTop;
  }


  @HostListener('window:scroll', [])
  onWindowScroll() {
    if (window.scrollY > 90) {
      this._commonService.layoutViewModel.stickyMenu = true;
    } else {
      this._commonService.layoutViewModel.stickyMenu = false;
    }
  }


  public navigateToSection() {
    var hashValue = "#contactUs";
    window.location.assign("/home" + hashValue);
  }


  async loadPageData() {
    try {
      await this.authGuard.GetRoleFromToken();
      if (this._commonService.layoutViewModel.roleType == RoleTypeSM.CompanyInspector || this._commonService.layoutViewModel.roleType == RoleTypeSM.IndividualInspector) {
        let resp = await this.profileInfoService.getInspectorProfile();
        if (resp.isError) {
          await this.exceptionHandler.logObject(resp.errorData);
          this.exceptionHandler.logObject(resp.errorData);
          this._commonService.showSweetAlertToast({ title: resp.errorData.displayMessage, icon: 'error' });
          return;
        }
        this._commonService.layoutViewModel.inspectorProfilePicture = resp.successData.profilePicturePath;
      }
      else if (this._commonService.layoutViewModel.roleType == RoleTypeSM.CompanyAdmin) {
        let resp1 = await this.companyProfileService.getCompanyProfile();
        if (resp1.isError) {
          this.exceptionHandler.logObject(resp1.errorData);
          this._commonService.showSweetAlertToast({ title: resp1.errorData.displayMessage, icon: 'error' });
        }
        this.commonService.layoutViewModel.companyAdminProfilePicture = resp1.successData.companyLogoPath;
      }
   

    }
    catch (error) {
      this._commonService.showSweetAlertToast({ title: AppConstants.ErrorPrompts.Unknown_Error, icon: 'error' });
      throw error;
    }


    // this._commonService.updateLayout(RoleTypeSM.IndividualInspector, "");

  }

  async click_Logout(isSuperAdmin: boolean) {
    this.accountService.logoutUser();
    this._commonService.showSweetAlertToast({ title: 'Success', icon: 'success' });
    if (isSuperAdmin) {
      this.router.navigate([AppConstants.WebRoutes.ADMIN.LOGIN]);
    }
    else {
      this.router.navigate([AppConstants.WebRoutes.HOME]);

    }
    this.loadPageData();

  }


  click_ToggleLeftMenu() {
    if (this._commonService.layoutViewModel.superAdminSideMenu == 'defaultSuperAdminSideMenu') {
      this._commonService.layoutViewModel.superAdminSideMenu = 'miniSideMenu';
      this._commonService.layoutViewModel.toggleContent = "toggleSuperAdminContent";
    } else {
      this._commonService.layoutViewModel.superAdminSideMenu = 'defaultSuperAdminSideMenu';
      this._commonService.layoutViewModel.toggleWrapper = 'wrapper';
      this._commonService.layoutViewModel.toggleContent = "superAdminContent";
    }
    return;

  }

  async toggleMenu() {
    if (this._commonService.layoutViewModel.toggleUserProfileWrapper == 'userProfileWrapper') {
      this._commonService.layoutViewModel.toggleUserProfileWrapper = 'toggleUserProfileWrapper';
      this._commonService.layoutViewModel.toggleUserProfileContent = 'toggleUserProfileContent';
      this._commonService.layoutViewModel.toggleUserProfileSideMenu = 'toggleUserProfileSideMenu';
      this._commonService.layoutViewModel.toggleContent = "toggleContent";
    } else {
      this._commonService.layoutViewModel.toggleUserProfileWrapper = 'userProfileWrapper';
      this._commonService.layoutViewModel.toggleUserProfileContent = 'userProfileContent';
      this._commonService.layoutViewModel.toggleUserProfileSideMenu = 'userProfileSideMenu';
      this._commonService.layoutViewModel.toggleContent = "content";
    }
  }

  async click_showLoginModal() {
    var x = await this._commonService.presentLoginModal(this._commonService.layoutViewModel.currentRoute);
    this.exceptionHandler.logObject(x);
  }


  @ViewChild("myEventRef") myEventRef!: ElementRef;
  loseFocus() {
    this.myEventRef.nativeElement.blur();
  }
}


