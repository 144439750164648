<div class="container-fluid">
    <div class="table-title">
        <div class="row">
            <div class="col-lg-4 col-md-12 col-12"> <label >  Total Number of Faq's: {{viewModel.totalCount}}</label></div>
            <div class="col-lg-8 col-md-12 col-12"> <button type="button" class="addBtnSuperAdmin"
                    (click)="click_OpenAddEditModal(addEditModal,0)"><i class="bi bi-plus"></i> Add
                    Faq</button></div>
        </div>

        <table class="table mt-2">
        
                <thead>
                    <tr class="text-center">
                        <th class="firstTableHead">Question</th>
                        <th>Question Type</th>
                        <th>Answer</th>
                        <th class="lastTableHead">Actions</th>
                    </tr>
                </thead>
            
            <tbody>
                <tr class="h5  text-center" *ngFor="let faq of viewModel.faqs">
                    <td class="align-middle">{{faq.question}}</td>
                    <td class="align-middle">{{faq.questionType}}</td>
                    <td class="align-middle">{{faq.answer}}</td>
                    <td class="align-middle">
                        <i class="bi bi-pencil-fill" (click)="click_OpenAddEditModal(addEditModal,faq.id)"> </i>
                        &nbsp; <i class="bi bi-trash-fill" (click)="deleteFaq(faq.id)"></i>
                    </td>

                </tr>
            </tbody>
        </table>


        <div id="pagination" class="col-lg-12 col-md-12 col-sm-12 col-12  d-flex justify-content-center fixed-bottom">
            <ngb-pagination [collectionSize]="viewModel.totalCount" [(page)]="viewModel.PageNo"
                [pageSize]="viewModel.PageSize" (pageChange)="loadPagedataWithPagination($event)"></ngb-pagination>
        </div>

        
<ng-template #addEditModal let-modal>
    <form #form_Faq="ngForm" (ngSubmit)="form_addEditFaq(form_Faq)">
        <div class="modal-header">
            <h4 class="modal-title">{{viewModel.AddEditModalTitle}}</h4>
            <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
        </div>
        <div class="modal-body">
            <div class="form-group p-lg-2 mb-3">
                <label class="form-label"> Question </label>
                <input [(ngModel)]="viewModel.singleFaq.question" class="form-control" placeholder="Question"
                    #question="ngModel" required name="question" />
                <ng-container *ngFor="let validation of viewModel.ValidationData.question">
                    <div class="alert alert-danger"
                        *ngIf="(question.touched || viewModel.FormSubmitted) && question.hasError(validation.type)">
                        {{ validation.message }}
                    </div>
                </ng-container>
            </div>
            <div class="form-group p-lg-2 mb-3">
                <label class="form-label"> Question Type</label>
                <input [(ngModel)]="viewModel.singleFaq.questionType" class="form-control" placeholder="Question Type"
                    #questionType="ngModel" required name="questionType" />
            </div>
            <div class="form-group p-lg-2 mb-3">
                <label class="form-label"> Answer </label>
                <input [(ngModel)]="viewModel.singleFaq.answer" class="form-control" placeholder="Answer"
                    #answer="ngModel" required name="answer" />
                <ng-container *ngFor="let validation of viewModel.ValidationData.answer">
                    <div class="alert alert-danger"
                        *ngIf="(answer.touched || viewModel.FormSubmitted) && answer.hasError(validation.type)">
                        {{ validation.message }}
                    </div>
                </ng-container>
            </div>
        </div>
        <div class="modal-footer">
            <button type="submit" class="spyGlassBtn"> Save </button>
        </div>
    </form>

</ng-template>



