import { Injectable } from "@angular/core";
import { AppConstants } from "src/app-constants";
import { ClientUserAddressSM } from "../service-models/app/v1/app-users/client-user-address-s-m";
import { ClientUserSM } from "../service-models/app/v1/app-users/client-user-s-m";
import { ClientCompanyAddressSM } from "../service-models/app/v1/client/client-company-address-s-m";
import { ApiRequest } from "../service-models/foundation/api-contracts/base/api-request";
import { ApiResponse } from "../service-models/foundation/api-contracts/base/api-response";
import { OdataQueryFilter } from "../service-models/foundation/api-contracts/odata-query-filter";
import { DeleteResponseRoot } from "../service-models/foundation/common-response/delete-response-root";
import { IntResponseRoot } from "../service-models/foundation/common-response/int-response-root";
import { StorageService } from "../services/storage.service";
import { BaseApiClient } from "./base-client/base-api.client";
import { CommonResponseCodeHandler } from "./helpers/common-response-code-handler.helper";
import { StorageCache } from "./helpers/storage-cache.helper";



@Injectable({
    providedIn: 'root'
})
export class ManageInspectorsClient extends BaseApiClient {
    constructor(storageService: StorageService, storageCache: StorageCache,
        commonResponseCodeHandler: CommonResponseCodeHandler) {
        super(storageService, storageCache, commonResponseCodeHandler)

    }
    /**Get company inspectors based on odata query */
    GetAllCompanyInspectorsByOdata = async (Id:number,queryFilter: OdataQueryFilter): Promise<ApiResponse<ClientUserSM[]>> => {
        let finalUrl = this.ApplyQueryFilterToUrl(`${AppConstants.ApiUrls.USER_API_URL}/odata`, queryFilter);
        finalUrl+=`&& $filter=clientcompanydetailid eq ${Id} and  RoleType eq 'CompanyInspector'`
        let resp = await this.GetResponseAsync<null, ClientUserSM[]>(finalUrl, 'GET');
        return resp;
      }
  
    /**Get all company inspectors */
    GetCompanyInspectors = async (): Promise<ApiResponse<ClientUserSM[]>> => {
        let resp = await this.GetResponseAsync<number, ClientUserSM[]>
            (`${AppConstants.ApiUrls.USER_API_URL}/my`, 'GET');
        return resp;
    }

      /**Get company inspectors count*/
      GetAllCompanyInspectorsCount =async(Id:number):Promise<ApiResponse<IntResponseRoot>>=>{
        let resp=await this.GetResponseAsync<null,IntResponseRoot>
        (`${AppConstants.ApiUrls.USER_API_URL}/CompanyInspectorsCountResponse/${Id}`,"GET");
        return resp; 
       }


    /**Get Inspector by id */
    GetInspectorById = async (Id: number): Promise<ApiResponse<ClientUserSM>> => {
        let resp = await this.GetResponseAsync<number, ClientUserSM>
            (`${AppConstants.ApiUrls.USER_API_URL}/${Id}`, 'GET');
        return resp;
    }
   

    //  GetInspectorById = async (): Promise<ApiResponse<ClientUserSM>> => {
    //     let resp = await this.GetResponseAsync<number, ClientUserSM>
    //         (`${AppConstants.ApiUrls.USER_API_URL}/mine`, 'GET');
    //     return resp;
    // }
    /**Add a new Inspector */
    AddInspector = async (addInspectorRequest: ApiRequest<ClientUserSM>): Promise<ApiResponse<ClientUserSM>> => {
        let resp = await this.GetResponseAsync<ClientUserSM, ClientUserSM>
            (`${AppConstants.ApiUrls.USER_API_URL}/my/CompanyInspector`, 'POST', addInspectorRequest);
        return resp;
    }


    /**Update Inspector*/
    UpdateInspector = async (updateRequest: ApiRequest<ClientUserSM>,id:number): Promise<ApiResponse<ClientUserSM>> => {
        let resp = await this.GetResponseAsync<ClientUserSM, ClientUserSM>
            (`${AppConstants.ApiUrls.USER_API_URL}/my/CompanyInspector/${id}`, 'PUT', updateRequest);
        return resp;
    }
    
    // /api/v1/ClientUser/my/CompanyInspector/1


    /**delete Inspector by id */
    DeleteInspectorById = async (Id: number): Promise<ApiResponse<DeleteResponseRoot>> => {
        let resp = await this.GetResponseAsync<number, DeleteResponseRoot>
            (`${AppConstants.ApiUrls.USER_API_URL}/${Id}`, 'DELETE');
        return resp;
    }
    GetInspectorAddressbyId = async (id:number): Promise<ApiResponse<ClientUserAddressSM>> => {
        let resp = await this.GetResponseAsync<number, ClientUserAddressSM>
            (`${AppConstants.ApiUrls.INSPECTOR_ADDRESS_API_URL}/${id}`, 'GET');
        return resp;
    }
    AddInspectorAddress = async (companyAddressRequest: ApiRequest<ClientUserAddressSM>,id:number): Promise<ApiResponse<ClientUserAddressSM>> => {
        let resp = await this.GetResponseAsync<ClientUserAddressSM, ClientUserAddressSM>
            (`${AppConstants.ApiUrls.INSPECTOR_ADDRESS_API_URL}/${id}`, 'POST', companyAddressRequest);
        return resp;
    }
    UpdateInspectorAddress = async (updateRequest: ApiRequest<ClientUserAddressSM>,id:number): Promise<ApiResponse<ClientUserAddressSM>> => {
        let resp = await this.GetResponseAsync<ClientUserAddressSM, ClientUserAddressSM>
            (`${AppConstants.ApiUrls.INSPECTOR_ADDRESS_API_URL}/${id}`, 'PUT', updateRequest);
        return resp;
    }

}