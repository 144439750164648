
<div class="container-fluid">
    <div class="table-title">
        <div class="row">
            <div class="col-lg-4 col-md-12 col-12"> <label > Total No. of Features: {{viewModel.totalCount}}</label></div>
            <div class="col-lg-8 col-md-12 col-12"> <button type="button" class="addBtnSuperAdmin"
                    (click)="click_OpenAddEditModal(addEditModal,0)"><i class="bi bi-plus"></i> Add
                    Feature</button></div>
        </div>

        <table class="table mt-2">
            <thead>
                <tr class="text-center">
                    <th class="firstTableHead">Title</th>
                    <th>Description</th>
                    <th>Amount</th>
                    <th class="lastTableHead">Actions</th>
                </tr>
            </thead>
            <tbody>
                <tr class="h5 fw-normal text-center" *ngFor="let features of viewModel.features">
                    <td class="align-middle">{{features.title}}</td>
                    <td class="align-middle">{{features.description}}</td>
                    <td class="align-middle">{{features.price}}</td>
                    <td class="align-middle">
                        <i class="bi bi-pencil-fill" (click)="click_OpenAddEditModal(addEditModal,features.id)"> </i>
                        &nbsp; <i class="bi bi-trash-fill" (click)="deletefeatureById(features.id)"></i>
                    </td>

                </tr>
            </tbody>
        </table>


        <div id="pagination" class="col-lg-12 col-md-12 col-sm-12 col-12  d-flex justify-content-center fixed-bottom">
            <ngb-pagination [collectionSize]="viewModel.totalCount" [(page)]="viewModel.PageNo"
                [pageSize]="viewModel.PageSize" (pageChange)="loadPagedataWithPagination($event)"></ngb-pagination>
        </div>

        <ng-template #addEditModal let-modal>
            <form #form_featureGroupForm="ngForm" (ngSubmit)="form_addEditFeatures(form_featureGroupForm)">
                <div class="modal-header">
                    <h4 class="modal-title">{{viewModel.AddEditModalTitle}}</h4>
                    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
                </div>
                <div class="modal-body">
                    <div class="form-group p-lg-2 mb-3">
                        <label class="form-label"> Title </label>
                        <input [(ngModel)]="viewModel.singleFeature.title" class="form-control" placeholder="Add title"
                            #title="ngModel" required name="title" />
                        <ng-container *ngFor="let validation of viewModel.ValidationData.title">
                            <div class="alert alert-danger "
                                *ngIf="(title.touched || viewModel.FormSubmitted) && title.hasError(validation.type)">
                                {{ validation.message }}
                            </div>
                        </ng-container>
                    </div>
                    <div class="form-group p-lg-2 mb-3">
                        <label class="form-label"> Description</label>
                        <input [(ngModel)]="viewModel.singleFeature.description" class="form-control"
                            placeholder="Add Description" #description="ngModel" required name="description" />
                        <ng-container *ngFor="let validation of viewModel.ValidationData.description">
                            <div class="alert alert-danger"
                                *ngIf="(description.touched || viewModel.FormSubmitted) && description.hasError(validation.type)">
                                {{ validation.message }}
                            </div>
                        </ng-container>
                    </div>
        
        
                    <div class="form-group p-lg-2 mb-3">
                        <label class="form-label"> Price</label>
                        <input type="text" [(ngModel)]="viewModel.singleFeature.price" class="form-control"
                            placeholder="Add price" #price="ngModel" required pattern=^[0-9]+$ name="price" />
                        <ng-container *ngFor="let validation of viewModel.ValidationData.price">
                            <div class="alert alert-danger"
                                *ngIf="(price.touched || viewModel.FormSubmitted) && price.hasError(validation.type)">
                                {{ validation.message }}
                            </div>
                        </ng-container>
                    </div>
        
                </div>
                <div class="modal-footer">
                    <button type="submit" class="spyGlassBtn"> Save </button>
                </div>
            </form>
        </ng-template>
    </div>