import { Component, OnInit } from '@angular/core';
import { howToInstallViewModel } from 'src/app/view-models/how-to-install.viewmodel';
import { BaseComponent } from '../../base.component';
import { CommonService } from 'src/app/services/common.service';
import { LogHandlerService } from 'src/app/services/log-handler.service';
import { ViewportScroller } from '@angular/common';
import { WebsiteResourceService } from 'src/app/services/website-resource.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-how-to-install',
  templateUrl: './how-to-install.component.html',
  styleUrls: ['./how-to-install.component.scss']
})

export class HowToInstallComponent extends BaseComponent<howToInstallViewModel> implements OnInit {

  constructor(
    commonService: CommonService,
    logService: LogHandlerService,
    private viewportScroller: ViewportScroller,
    private websiteResourceService: WebsiteResourceService,
  ) {
    super(commonService, logService);
    this.viewModel = new howToInstallViewModel();
    this._commonService.layoutViewModel.toggleWrapper = 'homeWrapper';
    this._commonService.layoutViewModel.toggleContent = 'content';
    this._commonService.layoutViewModel.showTopNav=true;
  }

  async ngOnInit() {
    await this._commonService.presentLoading();
    await setTimeout(async () => {
      await this._commonService.dismissLoader();
      await this.loadPageData();
    }, environment.animationTimeoutinMS); 
  }

  override async loadPageData() {
    try {
     
      let resp = await this.websiteResourceService.getWebsiteResourceHowToInstallImages();
      if (resp.isError) {
        await this._exceptionHandler.logObject(resp.errorData);
        await this._commonService.showSweetAlertToast({ title: resp.errorData.displayMessage, icon: 'error' });
      } else {
        this.viewModel.howToInstallMainImage = resp.successData[4].resourceFile;
        this.viewModel.howToInstallImage1 = resp.successData[0].resourceFile;
        this.viewModel.howToInstallImage2 = resp.successData[1].resourceFile;
        this.viewModel.howToInstallImage3 = resp.successData[2].resourceFile;
        this.viewModel.howToInstallImage4 = resp.successData[3].resourceFile;
      }
    } catch (error) {
      throw error;
    } finally {
      await this._commonService.dismissLoader();
    }
  }


  public navigateToSteps(elementId: string): void {
    this.viewportScroller.scrollToAnchor(elementId);
  }


}
