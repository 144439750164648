import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';


@Component({
  selector: 'app-left-side-bar',
  templateUrl: './left-side-bar.component.html',
  styleUrls: ['./left-side-bar.component.scss']
})

export class LeftSideBarComponent implements OnInit {
  protected _commonService: CommonService;
  constructor(commonService: CommonService, private router: Router) {
    this._commonService = commonService;
  }
  
  ngOnInit(): void {
   
   
  }

  

}

