import { Component, OnInit, TemplateRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppConstants } from 'src/app-constants';
import { PriorityTypeSM } from 'src/app/service-models/app/enums/priority-type-s-m.enum';
import { NewsFeedSM } from 'src/app/service-models/app/v1/general/news-feed-s-m';
import { ApiResponse } from 'src/app/service-models/foundation/api-contracts/base/api-response';
import { OdataQueryFilter } from 'src/app/service-models/foundation/api-contracts/odata-query-filter';
import { CommonService } from 'src/app/services/common.service';
import { LogHandlerService } from 'src/app/services/log-handler.service';
import { NewsFeedService } from 'src/app/services/news-feed.service';
import { NewsFeedViewModel } from 'src/app/view-models/admin/news-feed.viewmodel';
import { BaseComponent } from '../../base.component';

@Component({
  selector: 'app-news-feed',
  templateUrl: './news-feed.component.html',
  styleUrls: ['./news-feed.component.css']
})
export class NewsFeedComponent extends BaseComponent<NewsFeedViewModel> implements OnInit {
  

  /**
   * comments here and summary of the function
   *
   */
  constructor(
    private modalService: NgbModal, private newsfeedService: NewsFeedService,
    commonService: CommonService, logService: LogHandlerService) {
    super(commonService, logService);
    this.viewModel = new NewsFeedViewModel();
  }

  async ngOnInit() {
    await this.loadPageData();
  }
  /**
   * Fetch all data for news feed
   * 
   */
  override async loadPageData() {
    try {
      await this._commonService.presentLoading();
    
      //convert enum value to string
      this.viewModel.priorityTypes = this._commonService.enumToStringArray(PriorityTypeSM);
      //have to do paging
      let queryFilter=new OdataQueryFilter();
      queryFilter.skip=(this.viewModel.PageNo -1)* this.viewModel.PageSize;
      queryFilter.top=this.viewModel.PageSize;
      let resp = await this.newsfeedService.getAllNewsbyOdata(queryFilter);
      if (resp.isError) {
        await this._exceptionHandler.logObject(resp.errorData);
        this._commonService.showSweetAlertToast({ title: resp.errorData.displayMessage, icon: 'error' });
      }
      else { 
         await this.getNewsCount();
        this.viewModel.newsFeedList = resp.successData;
      }
    } catch (error) {
      this._commonService.showSweetAlertToast({
        title: AppConstants.ErrorPrompts.Unknown_Error,
        icon: "error",
      });
      throw error;
    }
    finally {
      await this._commonService.dismissLoader()
    }
  }
  /**
   * get newsfeed by id
   * @param newsId 
   * @returns success.data of selected id newsfeed data
   */
  async getNewsById(newsId: number) {
    try {
      await this._commonService.presentLoading();
      //have to do paging
      let resp = await this.newsfeedService.getNewsById(newsId);
      if (resp.isError) {
        await this._exceptionHandler.logObject(resp.errorData);
        this._commonService.showSweetAlertToast({ title: resp.errorData.displayMessage, icon: 'error' });
      }
      else {
        this.viewModel.singleNewsFeed = resp.successData;
      }
    } catch (error) {
      this._commonService.showSweetAlertToast({
        title: AppConstants.ErrorPrompts.Unknown_Error,
        icon: "error",
      });
      // throw error;
    }
    finally {
      await this._commonService.dismissLoader()
    }
  }
  /**
   * open add,edit modal for newsfeed
   * @param content 
   * @param newsId 
   */
  async click_OpenAddEditModal(content: TemplateRef<any>, newsId: number) {
    this.viewModel.AddEditModalTitle = newsId > 0 ? 'Update News' : 'Add News';
    this.viewModel.singleNewsFeed = new NewsFeedSM();
    this.viewModel.FormSubmitted = false;
    if (newsId > 0)
     await this.getNewsById(newsId);
     await this.modalService.open(content).result.then();
  }


  /**
   * ADD ,Update newsfeed 
   * @param newsFeedForm 
   * @returns success message
   */
  async form_addEditNews(newsFeedForm: NgForm) {
    this.viewModel.FormSubmitted = true;

    try {
      if (newsFeedForm.invalid)
        return;
      await this._commonService.presentLoading();
      let resp: ApiResponse<NewsFeedSM>;
      if (this.viewModel.singleNewsFeed.id && this.viewModel.singleNewsFeed.id > 0)
        resp = await this.newsfeedService.update_News(this.viewModel.singleNewsFeed);
      else
        resp = await this.newsfeedService.Send_News(this.viewModel.singleNewsFeed);
      if (resp.isError) {
        await this._exceptionHandler.logObject(resp.errorData);
        this._commonService.showSweetAlertToast({ title: resp.errorData.displayMessage, icon: 'error' });
      }
      else {
        this.modalService.dismissAll();
        this._commonService.showSweetAlertToast({ title: 'Success', icon: 'success' });
        this.loadPageData();
      }
    } catch (error) {
      this._commonService.showSweetAlertToast({
        title: AppConstants.ErrorPrompts.Unknown_Error,
        icon: "error",
      });
      throw error;
    } finally {
      await this._commonService.dismissLoader()
    }
  }
  /**
   * 
   * @param newsId delete newsfeed by id
   * @returns success
   */
  async deleteNews(newsId: number) {

    let deleteConfirmation = await this._commonService.showSweetAlertConfirmation({
      title: `${AppConstants.DefaultMessages.DeleteAlertConfirmation} NewsFeed?`,
      text: " ",
      showCancelButton: true,
      icon: "warning"
    }
    );
    if (deleteConfirmation) {
      try {
        let resp = await this.newsfeedService.deleteNewsById(newsId);
        if (resp.isError) {
          await this._exceptionHandler.logObject(resp.errorData);
          this._commonService.showSweetAlertToast({ title: resp.errorData.displayMessage, icon: 'error' });
        } else {
          this._commonService.showSweetAlertToast({ title: 'Success', icon: 'success' });
          this.loadPageData();
          return;
        }
      } catch (error) {
        this._commonService.showSweetAlertToast({
          title: AppConstants.ErrorPrompts.Unknown_Error,
          icon: "error",
        });

        throw error;
      } finally {
        await this._commonService.dismissLoader();
      }
    }
  }
  /**
   * this function used to upload and convert files into base64 string
   * @param event 
   * @returns base64 string
   */
  uploadFile(event: any) {
    this._commonService
      .convertFileToBase64(event.target.files[0])
      .subscribe((base64) => {
        let  fileName = event.target.files[0].name;
        fileName.split("?")[0].split(".").pop();
       this.viewModel.singleNewsFeed.iconToShow = base64;
  
      });
  }
  
  async getNewsCount() {
    try {
      await this._commonService.presentLoading();
      let resp = await this.newsfeedService.getNewsCount();
      if (resp.isError) {
        this._exceptionHandler.logObject(resp.errorData);
        this._commonService.showSweetAlertToast({ title: resp.errorData.displayMessage, icon: 'error' });
        return;
      }
      else {
        this.viewModel.totalCount = resp.successData.intResponse;
      }
    } catch (error) {
      this._commonService.showSweetAlertToast({ title: AppConstants.ErrorPrompts.Unknown_Error, icon: 'error' });
      throw error;
    }
    finally {
      await this._commonService.dismissLoader()
    }
  }
  async loadPagedataWithPagination(event: any) {
    this.viewModel.PageNo = event;
    await this.loadPageData();
  }
}
