import { Injectable } from '@angular/core';
import { AppConstants } from 'src/app-constants';
import { FeaturesClient } from '../clients/features.client';
import { FeatureSM } from '../service-models/app/v1/license/feature-s-m';
import { UserLicenseDetailsSM } from '../service-models/app/v1/license/user-license-details-s-m';
import { ApiRequest } from '../service-models/foundation/api-contracts/base/api-request';
import { ApiResponse } from '../service-models/foundation/api-contracts/base/api-response';
import { OdataQueryFilter } from '../service-models/foundation/api-contracts/odata-query-filter';
import { DeleteResponseRoot } from '../service-models/foundation/common-response/delete-response-root';
import { IntResponseRoot } from '../service-models/foundation/common-response/int-response-root';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class FeatureService extends BaseService {

  constructor(private featureClient: FeaturesClient) {
    super();
  }


  /**get all features data  request to client */

  async getAllFeatures(): Promise<ApiResponse<FeatureSM[]>> {
    return await this.featureClient.GetAllFeatures();
  }
  async getAllFeaturesbyOdata(queryFilter: OdataQueryFilter): Promise<ApiResponse<FeatureSM[]>> {
    return await this.featureClient.GetAllFeaturesByOdata(queryFilter);

  }
  async getFeaturesCount(): Promise<ApiResponse<IntResponseRoot>> {
    return await this.featureClient.GetAllFeaturesCount()
  }

  /**get all feature data by id  request to client */

  async getFeatureById(id: number): Promise<ApiResponse<FeatureSM>> {
    if (id <= 0) {
      throw new Error(AppConstants.ErrorPrompts.Delete_Data_Error)
    }
    return await this.featureClient.GetFeatureById(id);
  }

  /**add  feature  request to client */

  async SendFeature(featureGroup: FeatureSM): Promise<ApiResponse<FeatureSM>> {
    if (featureGroup == null) {
      throw new Error(AppConstants.ErrorPrompts.Invalid_Input_Data);
    }
    else {
      let apiRequest = new ApiRequest<FeatureSM>();
      apiRequest.reqData = featureGroup;
      return await this.featureClient.AddFeature(apiRequest);
    }


  }
  /**update  feature  request to client */
  async updateFeature(feature: FeatureSM): Promise<ApiResponse<FeatureSM>> {
    if (feature == null) {
      throw new Error(AppConstants.ErrorPrompts.Invalid_Input_Data);
    }
    else {
      let apiRequest = new ApiRequest<FeatureSM>();
      apiRequest.reqData = feature;
      return await this.featureClient.UpdateFeature(apiRequest);
    }
  }

  /**delete feature by id  request to client */

  async deleteFeatureById(id: number): Promise<ApiResponse<DeleteResponseRoot>> {
    if (id <= 0) {
      throw new Error(AppConstants.ErrorPrompts.Delete_Data_Error)
    }
    return await this.featureClient.DeleteFeatureById(id);
  }
  async getActiveLicense(): Promise<ApiResponse<UserLicenseDetailsSM>> {
    return await this.featureClient.GetActiveLicense();
  }

}