
<div class="container-fluid">

<div class="row px-4">
  <div class="col-lg-3 col-md-4 col-sm-6 text-center p-2">
    <div class="card">
      <div class="card-body">
        <i class="fa fa-line-chart"></i>
        <h5 class="card-title">Sales</h5>
        <div class="info"></div>
        <p class="card-text">With supporting text below as a natural lead-in to additional content.</p>
      </div>
    </div>
  </div>
  <div class="col-lg-3 col-md-4 col-sm-6 text-center p-2">
    <div class="card">
      <div class="card-body">
        <i class="fa fa-users " aria-hidden="true"></i>
        <h5 class="card-title">Active</h5>
        <p class="card-text">With supporting text below as a natural lead-in to additional content.</p>
      </div>
    </div>
  </div>
  <div class="col-lg-3 col-md-4 col-sm-12 text-center p-2">
    <div class="card">
      <div class="card-body">
        <i class="fa fa-user-times" aria-hidden="true"></i>
        <h5 class="card-title">Unsubscribed</h5>
        <p class="card-text">With supporting text below as a lead-in to additional content.</p>

      </div>
    </div>
  </div>
  <div class="col-lg-3 col-md-12 col-sm-12 text-center p-2">
    <div class="card">
      <div class="card-body">
        <i class="fa fa-dollar"></i>
        <h5 class="card-title">Revenue</h5>
        <p class="card-text">With supporting text below as a natural lead-in to additional content.</p>

      </div>
    </div>
  </div>
</div>

<div class="row">

  <div class="col-lg-6 col-md-12 col-sm-12 text-cente">
    <canvas id="MyChart">{{ myChart }}</canvas>
  </div>
  <div class="col-lg-6 col-md-12 col-sm-12 text-center my-5   p-3 ">
    <div class="row ">
      <div class="col-lg-6 col-md-6 col-sm-6 text-center h-50  p-2">
        <div class="card">
          <div class="card-body">
            <i class='fas fa-shopping-cart'></i>
            <h5 class="card-title">Cart</h5>
            <p class="card-text">With supporting text below as a natural lead-in to additional content.</p>

          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-6 text-center   p-2">
        <div class="card">
          <div class="card-body">
            <i class="fa fa-undo"></i>
            <h5 class="card-title">Refunds</h5>
            <p class="card-text">With supporting text below as a natural lead-in to additional content.</p>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6 col-md-6 col-sm-6 text-center p-2 ">
        <div class="card">
          <div class="card-body">
            <i class="fa fa-dollar"></i>
            <h5 class="card-title">Total Earnings</h5>
            <p class="card-text">With supporting text below as a natural lead-in to additional content.</p>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-6 text-center p-2 ">
        <div class="card">
          <div class="card-body">
            <i class="fa fa-question-circle"></i>
            <h5 class="card-title">Sales Queries</h5>
            <p class="card-text">With supporting text below as a natural lead-in to additional content.</p>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>

</div>