import { Injectable } from "@angular/core";
import { AppConstants } from "src/app-constants";
import { AdditionalRequestDetails, Authentication } from "../internal-models/additional-request-details";

import { ClientCompanyDetailSM } from "../service-models/app/v1/client/client-company-detail-s-m";
import { ApiRequest } from "../service-models/foundation/api-contracts/base/api-request";
import { ApiResponse } from "../service-models/foundation/api-contracts/base/api-response";
import { OdataQueryFilter } from "../service-models/foundation/api-contracts/odata-query-filter";
import { QueryFilter } from "../service-models/foundation/api-contracts/query-filter";
import { IntResponseRoot } from "../service-models/foundation/common-response/int-response-root";
import { StorageService } from "../services/storage.service";
import { BaseApiClient } from "./base-client/base-api.client";
import { CommonResponseCodeHandler } from "./helpers/common-response-code-handler.helper";
import { StorageCache } from "./helpers/storage-cache.helper";



@Injectable({
  providedIn: 'root'
})
export class CompaniesClient extends BaseApiClient {
  constructor(storageService: StorageService, storageCache: StorageCache,
    commonResponseCodeHandler: CommonResponseCodeHandler) {
    super(storageService, storageCache, commonResponseCodeHandler)

  }
  GetAllCompanies = async (): Promise<ApiResponse<ClientCompanyDetailSM[]>> => {
    let resp = await this.GetResponseAsync<null, ClientCompanyDetailSM[]>(
      `${AppConstants.ApiUrls.COMPANY_URL}`, "GET", null, new AdditionalRequestDetails<ClientCompanyDetailSM[]>(false, Authentication.false));
    return resp;
  }

  GetAllCompaniesByOdata = async (queryFilter: OdataQueryFilter): Promise<ApiResponse<ClientCompanyDetailSM[]>> => {
    let finalUrl = this.ApplyQueryFilterToUrl(`${AppConstants.ApiUrls.COMPANY_URL}/odata`, queryFilter);
    let resp = await this.GetResponseAsync<null, ClientCompanyDetailSM[]>(finalUrl, 'GET');
    return resp;
  }

  UpdateLoginStatus = async (updateStatusRequest: ApiRequest<ClientCompanyDetailSM>): Promise<ApiResponse<ClientCompanyDetailSM>> => {
    let resp = await this.GetResponseAsync<ClientCompanyDetailSM, ClientCompanyDetailSM>
      (`${AppConstants.ApiUrls.COMPANY_URL}/EnableDisableCompany/${updateStatusRequest.reqData.id}/${updateStatusRequest.reqData.isEnabled}`, 'PUT', updateStatusRequest);
    return resp;
  }
  GetAllCompanyCount = async (): Promise<ApiResponse<IntResponseRoot>> => {
    let resp = await this.GetResponseAsync<null, IntResponseRoot>
      (`${AppConstants.ApiUrls.COMPANY_URL}/ClientCompanyDetailCountResponse`, "GET");
    return resp;
  }
}
