import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AppConstants } from 'src/app-constants';
import { ContactUsSM } from 'src/app/service-models/app/v1/general/contact-us-s-m';
import { CommonService } from 'src/app/services/common.service';
import { ContactUsService } from 'src/app/services/contact-us.service';
import { FrequentlyAskedQuestionService } from 'src/app/services/freq-asked-question.service';
import { LogHandlerService } from 'src/app/services/log-handler.service';
import { WebsiteResourceService } from 'src/app/services/website-resource.service';
import { HomeViewModel } from 'src/app/view-models/home.viewmodel';
import { BaseComponent } from '../../base.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})


export class HomeComponent extends BaseComponent<HomeViewModel> implements OnInit {


  constructor(commonService: CommonService,
    logService: LogHandlerService,
    private websiteResourceService: WebsiteResourceService,
    private contactUsService: ContactUsService,
    private faqService: FrequentlyAskedQuestionService,
    private exceptionHandler: LogHandlerService,
  ) {
    super(commonService, logService);
    this.viewModel = new HomeViewModel();
    this._commonService.layoutViewModel.toggleWrapper = 'homeWrapper';
    this._commonService.layoutViewModel.toggleTopNav = 'homeTopNav';
    this._commonService.layoutViewModel.toggleContent = 'content';
    this._commonService.layoutViewModel.showFooter = true;
    this._commonService.layoutViewModel.showTopNav = true;
  }

  async ngOnInit() {
    await this._commonService.presentLoading();
    setTimeout(async () => {
      await this.click_GetFaq();
      await this.loadPageData();
      await this._commonService.dismissLoader();
    }, environment.animationTimeoutinMS);
  }

  override async loadPageData() {
    try {

      let resp = await this.websiteResourceService.getWebsiteResourceHomeImages();
      if (resp.isError) {
        await this._exceptionHandler.logObject(resp.errorData);
        await this._commonService.showSweetAlertToast({ title: resp.errorData.displayMessage, icon: 'error' });
      } else {
        this.viewModel.homeMainImage = resp.successData[0].resourceFile;
        this.viewModel.homeProductImage1 = resp.successData[1].resourceFile;
        this.viewModel.homeProductImage2 = resp.successData[2].resourceFile;
        this.viewModel.homeFaqImage = resp.successData[3].resourceFile;
      }
    } catch (error) {
      throw error;
    } finally {
      await this._commonService.dismissLoader();
    }
  }


  async Click_SendContactUs(contactForm: NgForm) {
    this.viewModel.FormSubmitted = true;
    try {
      await this._commonService.presentLoading();
      if (contactForm.invalid)
        return;
      let resp = await this.contactUsService.Send_ContactUS(this.viewModel.singleContact);
      if (resp.isError) {
        this._exceptionHandler.logObject(resp.errorData);
        this._commonService.showSweetAlertToast({ title: resp.errorData.displayMessage, icon: 'error' });
        return;
      } else {
        if (resp.successData != null)
          this._commonService.showSweetAlertToast({ title: 'Success', icon: 'success' });
        this.viewModel.singleContact = new ContactUsSM();
        this.viewModel.FormSubmitted = false;
      }
    } catch (error) {
      this._commonService.showSweetAlertToast({ title: AppConstants.ErrorPrompts.Unknown_Error, icon: 'error' });
      throw error;
    } finally {
      await this._commonService.dismissLoader()
    }
  }


  async click_GetFaq() {
    try {
      await this._commonService.presentLoading();
      let resp = await this.faqService.getAllFaq();
      if (resp.isError) {
        this._exceptionHandler.logObject(resp.errorData);
        this._commonService.showSweetAlertToast({ title: resp.errorData.displayMessage, icon: 'error' });
        return;
      } else {
        this.viewModel.faqs = resp.successData;
      }
    } catch (error) {
      this._commonService.showSweetAlertToast({ title: AppConstants.ErrorPrompts.Unknown_Error, icon: 'error' });
      throw error;
    } finally {
      await this._commonService.dismissLoader();
    }
  }


  async showAnswer(faqId: number) {
    if (faqId > 0) {
      this.viewModel.selectedFaqId = faqId;
    } else {
      this.viewModel.selectedFaqId = this.viewModel.selectedFaqId;
    }
  }


  async click_showLoginModal() {
    var x = await this._commonService.presentLoginModal(this._commonService.layoutViewModel.currentRoute);
    this.exceptionHandler.logObject(x);
  }


}

