import { SurveyBoxServiceModelBase } from '../../base/survey-box-service-model-base';
import { PriorityTypeSM } from '../../enums/priority-type-s-m.enum';

export class NewsFeedSM extends SurveyBoxServiceModelBase<number> {
    title!: string;
    description!: string;
    iconToShow!: string;
    startDateToShow!: Date;
    endDateToShow!: Date;
    priorityTypeSM!: PriorityTypeSM;
}
