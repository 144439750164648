import { Injectable } from "@angular/core";
import { AppConstants } from "src/app-constants";
import { AdditionalRequestDetails, Authentication } from "../internal-models/additional-request-details";
import { TokenRequestSM } from "../service-models/app/token/token-request-s-m";
import { TokenResponseSM } from "../service-models/app/token/token-response-s-m";
import { ClientUserAddressSM } from "../service-models/app/v1/app-users/client-user-address-s-m";
import { ClientUserSM } from "../service-models/app/v1/app-users/client-user-s-m";
import { ClientCompanyAddressSM } from "../service-models/app/v1/client/client-company-address-s-m";
import { ClientCompanyDetailSM } from "../service-models/app/v1/client/client-company-detail-s-m";
import { ApiRequest } from "../service-models/foundation/api-contracts/base/api-request";
import { ApiResponse } from "../service-models/foundation/api-contracts/base/api-response";
import { BoolResponseRoot } from "../service-models/foundation/common-response/bool-response-root";
import { StorageService } from "../services/storage.service";
import { BaseApiClient } from "./base-client/base-api.client";
import { CommonResponseCodeHandler } from "./helpers/common-response-code-handler.helper";
import { StorageCache } from "./helpers/storage-cache.helper";
import { ResetPasswordRequestSM } from "../service-models/app/v1/app-users/login/reset-password-request-s-m";
import { ForgotPasswordSM } from "../service-models/app/v1/app-users/forgot-password-s-m";
import { IntResponseRoot } from "../service-models/foundation/common-response/int-response-root";

@Injectable({
    providedIn: 'root'
})
export class AccountsClient extends BaseApiClient {

    constructor(storageService: StorageService, storageCache: StorageCache,
        commonResponseCodeHandler: CommonResponseCodeHandler) {
        super(storageService, storageCache, commonResponseCodeHandler)
    }
    GenerateToken = async (tokenRequestSM: ApiRequest<TokenRequestSM>): Promise<ApiResponse<TokenResponseSM>> => {
        let resp = await this.GetResponseAsync<TokenRequestSM, TokenResponseSM>
            (`${AppConstants.ApiUrls.ACCOUNT_URL}/ValidateLoginAndGenerateToken`, 'POST', tokenRequestSM,
                new AdditionalRequestDetails<TokenResponseSM>(false, Authentication.false));
        return resp;
    }

    RegisterUser = async (registerUser: ApiRequest<ClientUserSM>): Promise<ApiResponse<ClientUserSM>> => {
        let resp = await this.GetResponseAsync<ClientUserSM, ClientUserSM>
            (`${AppConstants.ApiUrls.USER_API_URL}`, 'POST', registerUser,
                new AdditionalRequestDetails<ClientUserSM>(false, Authentication.false));;

        return resp;
    }

    RegisterCompany = async (registerCompany: ApiRequest<ClientCompanyDetailSM>): Promise<ApiResponse<ClientCompanyDetailSM>> => {
        let resp = await this.GetResponseAsync<ClientCompanyDetailSM, ClientCompanyDetailSM>
            (`${AppConstants.ApiUrls.COMPANY_URL}`, 'POST', registerCompany,
                new AdditionalRequestDetails<ClientCompanyDetailSM>(false, Authentication.false));
        return resp;
    }

    AddCompanyAddress = async (registerCompanyAddress: ApiRequest<ClientCompanyAddressSM>): Promise<ApiResponse<ClientCompanyAddressSM>> => {
        let resp = await this.GetResponseAsync<ClientCompanyAddressSM, ClientCompanyAddressSM>
            (`${AppConstants.ApiUrls.COMPANY_ADDRESS_API_URL}`, 'POST', registerCompanyAddress,
                new AdditionalRequestDetails<ClientCompanyAddressSM>(false, Authentication.false));
        return resp;
    }


    AddUserAddress = async (registerUserAddress: ApiRequest<ClientUserAddressSM>): Promise<ApiResponse<ClientUserAddressSM>> => {
        let resp = await this.GetResponseAsync<ClientUserAddressSM, ClientUserAddressSM>
            (`${AppConstants.ApiUrls.USER_ADDRESS_API_URL}`, 'POST', registerUserAddress,
                new AdditionalRequestDetails<ClientUserAddressSM>(false, Authentication.false));
        return resp;
    }



    ValidateLoginId = async (loginId: string): Promise<ApiResponse<BoolResponseRoot>> => {
        let resp = await this.GetResponseAsync<null, BoolResponseRoot>(`${AppConstants.ApiUrls.USER_API_URL}/ValidateLoginId?loginId=${loginId}`, 'GET', null,
            new AdditionalRequestDetails<BoolResponseRoot>(false, Authentication.false));
        return resp;
    }

    UpdatePassword = async (
        updatePasswordRequest: ApiRequest<ResetPasswordRequestSM>
    ): Promise<ApiResponse<ResetPasswordRequestSM>> => {
        let resp = await this.GetResponseAsync<ResetPasswordRequestSM, ResetPasswordRequestSM>(
            `${AppConstants.ApiUrls.USER_API_URL}/UpdatePassword`,
            "POST",
            updatePasswordRequest,
            new AdditionalRequestDetails<ResetPasswordRequestSM>(false, Authentication.false)
        );
        return resp;
    };

    SendForgotPassword = async (
        contactUsRequest: ApiRequest<ForgotPasswordSM>
    ): Promise<ApiResponse<ForgotPasswordSM>> => {
        let resp = await this.GetResponseAsync<ForgotPasswordSM, ForgotPasswordSM>(
            `${AppConstants.ApiUrls.USER_API_URL}/ForgotPassword`,
            "POST",
            contactUsRequest,
            new AdditionalRequestDetails<ForgotPasswordSM>(false, Authentication.false)
        );
        return resp;
    };

    //update this accordingly
    ValidatePasswordResetCode = async (
        contactUsRequest: ApiRequest<ForgotPasswordSM>
    ): Promise<ApiResponse<ForgotPasswordSM>> => {
        let resp = await this.GetResponseAsync<ForgotPasswordSM, ForgotPasswordSM>(
            `${AppConstants.ApiUrls.USER_API_URL}/ForgotPassword`,
            "POST",
            contactUsRequest,
            new AdditionalRequestDetails<ForgotPasswordSM>(false, Authentication.false)
        );
        return resp;
    };

      
      ValidateAuthCode = async (authCode: string): Promise<ApiResponse<IntResponseRoot>> => {
        let url = `${AppConstants.ApiUrls.USER_API_URL}/ValidatePassword?authcode=${encodeURIComponent(authCode)}`;
        let resp = await this.GetResponseAsync<null, IntResponseRoot>(url,'GET', null,
          new AdditionalRequestDetails<IntResponseRoot>(false, Authentication.false)
        );
        console.log(url);
        return resp;
      };
      
     
}
