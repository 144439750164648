<section class=" mainHomeSection  d-flex justify-content-center align-items-center">


    <div class="container">

        <div class="row">

            <div class="col-lg-6 col-md-8 col-sm-12 col-12 order-lg-1 order-md-1 order-sm-2 order-2">


                <div class="mainHomeSectionText">

                    <div class="mainHomeSectionHeading">
                        <h1> SpyGlass </h1>
                    </div>

                    <h5> Inspection Management Tool for Properties, Houses, Etc </h5>

                    <div class="btnGetStarted ms-lg-3 mt-lg-4" *ngIf="_commonService.layoutViewModel.showLogin">
                        <button class="spyGlassBtn" (click)="click_showLoginModal()"> Get Started </button>
                    </div>

                </div>

            </div>

            <div
                class="col-lg-6 col-md-4 col-sm-12 col-12 position-relative text-lg-start text-md-start text-sm-center text-center d-lg-block d-md-block d-sm-block order-lg-2 order-md-2 order-sm-1 order-1">
                <img src="{{'data:image/webp;base64,' + viewModel.homeMainImage }}" class="img-fluid logoImage"
                    data-aos="zoom-in" data-aos-duration="1500" alt="image" loading="lazy">
            </div>

        </div>

        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
            <path fill="var(--path)" fill-opacity="1"
                d="M0,160L24,160C48,160,96,160,144,138.7C192,117,240,75,288,64C336,53,384,75,432,106.7C480,139,528,181,576,208C624,235,672,245,720,240C768,235,816,213,864,186.7C912,160,960,128,1008,133.3C1056,139,1104,181,1152,202.7C1200,224,1248,224,1296,197.3C1344,171,1392,117,1416,90.7L1440,64L1440,0L1416,0C1392,0,1344,0,1296,0C1248,0,1200,0,1152,0C1104,0,1056,0,1008,0C960,0,912,0,864,0C816,0,768,0,720,0C672,0,624,0,576,0C528,0,480,0,432,0C384,0,336,0,288,0C240,0,192,0,144,0C96,0,48,0,24,0L0,0Z">
            </path>
        </svg>

    </div>

</section>


<section class="productInfo">

    <div class="container">

        <div class="row" id="product">

            <span class="text-center text-secondary mb-lg-3 mb-md-3 mb-sm-3 mb-2"> Our platform offers a comprehensive
                suite of features and tools to simplify the inspection process and enhance efficiency
            </span>


            
            <div class="col-lg-6 col-md-6 col-sm-12 col-12 p-lg-3 p-md-3 p-sm-4 p-3 firstColumn" data-aos="fade-right"
                data-aos-duration="1500">

                <div class="card m-auto">
                    
                        <img src="{{'data:image/webp;base64,' + viewModel.homeProductImage1 }}" alt="product-info-image"
                            class="img-fluid" >
                    
                    <div class="imageText">

                        <p>
                            With its robust set of features, empowers users to create detailed inspection reports,
                            import libraries, print reports, and access a range of additional tools such as a newsfeed,
                            scheduler, contacts, documents, contracts, and support.
                        </p>

                        <button type="button" class="spyGlassBtn float-end" routerLink="/product-info"> See Product
                        </button>

                    </div>

                </div>

                <ul class="list-unstyled ms-lg-5 ms-md-5 ms-sm-5 ms-5 mt-lg-5 mt-md-5 mt-sm-5 mt-5">

                    <li>
                        <i class="bi bi-bookmark-check-fill fs-2 p-2"></i>
                        Comprehensive Data Management
                    </li>

                    <li>
                        <i class="bi bi-bookmark-check-fill fs-2 p-2"></i>
                        Enhanced Customer Experience
                    </li>

                    <li>
                        <i class="bi bi-bookmark-check-fill fs-2 p-2"></i>
                        Scalable Solution and Streamlined Reporting
                    </li>

                </ul>

            </div>

            <div class="col-lg-6 col-md-6 col-sm-12 col-12 p-lg-3 p-md-3 p-sm-4 p-3 secondColumn" data-aos="fade-left"
                data-aos-duration="1500">

                <p>
                    Introducing Spyglass, the ultimate tool for empowering home inspectors and ensuring the structural
                    integrity and safety of residential and commercial buildings. Spyglass revolutionizes the inspection
                    process, enabling inspectors to identify major and minor defects, safety issues, and potential
                    maintenance problems with ease. With Spyglass, inspectors can deliver comprehensive reports that not
                    only protect property dealers' investments but also elevate their business to new heights.
                </p>

                <div class="card m-auto">

                    <img src="{{'data:image/webp;base64,' + viewModel.homeProductImage2 }}" alt="product-info-image"
                        class="img-fluid" loading="lazy">

                    <div class="imageText">

                        <p>
                            Users can add/edit photos in both reports and libraries, providing visual documentation and
                            enhancing the clarity of inspection findings. It also allows users to store inspector
                            information and track purchased license details .
                        </p>

                        <button type="button" class="spyGlassBtn float-end" routerLink="/product-info"> See Product
                        </button>

                    </div>

                </div>

            </div>

        </div>

    </div>

</section>


<section class="productFeatures">

    <div class="container">

        <div class="row">

            <div class="col-lg-12 col-12 mx-auto">

                <div class="productFeaturesHeading d-flex justify-content-center align-items-center mb-4">
                    <h2 class="text-white ms-4 mb-0"> Product Features </h2>
                </div>

                <div class="row pt-lg-5" data-aos="fade-left" data-aos-duration="1500">

                    <div class="col-lg-5 col-12 mx-auto">

                        <div class="productFeaturesCard">

                            <div class="d-flex flex-wrap align-items-center border-bottom mb-4 pb-3">
                                <h3 class="mb-0"> Defect Identification </h3>
                            </div>

                            <p>Empowers inspectors to accurately identify major and minor defects, ensuring thorough
                                examinations for optimal safety and quality in properties</p>

                            <a href="#" class="productFeaturesButton btn mt-3"> Discover More </a>

                            <div class="productFeaturesIconSection d-flex justify-content-center align-items-center">
                                <i class="productFeaturesIcon bi-globe"></i>
                            </div>

                        </div>

                    </div>

                    <div class="col-lg-5 col-12 mx-auto">

                        <div class="productFeaturesCard">

                            <div class="d-flex flex-wrap align-items-center border-bottom mb-4 pb-3">
                                <h3 class="mb-0"> Easy Report Generation </h3>
                            </div>

                            <p>
                                Simplifies inspection reporting, allowing easy recording of findings, narratives,
                                photos, and annotations for efficient, professional reports
                            </p>

                            <a href="#" class="productFeaturesButton btn mt-3"> Discover More </a>

                            <div class="productFeaturesIconSection d-flex justify-content-center align-items-center">
                                <i class="productFeaturesIcon bi-lightbulb"></i>
                            </div>

                        </div>

                    </div>

                    <div class="col-lg-5 col-12 mx-auto">

                        <div class="productFeaturesCard">

                            <div class="d-flex flex-wrap align-items-center border-bottom mb-4 pb-3">
                                <h3 class="mb-0"> Rich Customer Experience</h3>
                            </div>

                            <p>
                                Thorough inspections, accurate reporting, proactive maintenance, and increased trust,
                                resulting in cost savings and higher satisfaction
                            </p>

                            <a href="#" class="productFeaturesButton btn mt-3"> Discover More </a>

                            <div class="productFeaturesIconSection d-flex justify-content-center align-items-center">
                                <i class="productFeaturesIcon bi-phone"></i>
                            </div>

                        </div>

                    </div>

                    <div class="col-lg-5 col-12 mx-auto">

                        <div class="productFeaturesCard">

                            <div class="d-flex flex-wrap align-items-center border-bottom mb-4 pb-3">
                                <h3 class="mb-0"> User-Friendly Interface </h3>
                            </div>

                            <p>
                                Provides an intuitive and user-friendly interface, enhancing usability for inspectors
                                and ensuring a seamless experience with effective feature utilization
                            </p>

                            <a href="#" class="productFeaturesButton btn mt-3"> Discover More </a>

                            <div class="productFeaturesIconSection d-flex justify-content-center align-items-center">
                                <i class="productFeaturesIcon bi-google"></i>
                            </div>

                        </div>

                    </div>

                </div>

            </div>

        </div>

    </div>

</section>


<section class="faqSection">

    <div class="container">

        <div class="row">

            <div class="col-lg-12 col-md-12 col-sm-12 col-12 text-center">

                <div class="faqHeadingText">
                    <h2> Frequently Asked Questions? </h2>
                </div>

                <div class="faqImageContainer text-center">
                    <img src="{{'data:image/webp;base64,' + viewModel.homeFaqImage }}" alt="faq-image" class="img-fluid"
                        loading="lazy">
                </div>

            </div>

            <div class="col-lg-10 col-md-8 col-sm-12 col-12 mx-auto">

                <div class="card p-lg-4 p-md-3 p-sm-3 p-2 m-lg-3 m-md-3 m-sm-3 m-2" *ngFor="let faq of viewModel.faqs">

                    <span class="text-start">
                        {{faq.question}}
                        <i class="bi bi-chevron-down float-end" (click)="viewModel.selectedFaqId = faq.id;"
                            *ngIf="viewModel.selectedFaqId != faq.id"></i>
                        <i class="bi bi-chevron-up float-end" (click)="viewModel.selectedFaqId =0"
                            *ngIf="viewModel.selectedFaqId == faq.id"></i>
                    </span>

                    <span [ngClass]="viewModel.selectedFaqId == faq.id? '' : 'answerSectionDefault'" [id]="faq.id">
                        {{faq.answer}}
                    </span>

                </div>

            </div>

        </div>

    </div>

</section>


<section class="contactUs">

    <div class="container ">

        <div name="contactUs" id="contactUs" class="pb-5 mb-5"></div>

        <div class="row">

            <div class="col-lg-6 ">

                <div class="contactSectionHeading">

                    <div class="contactHeadingText">
                        <h6>Contact Us</h6>
                    </div>

                    <h3> Feel free to contact us </h3>

                    <div class="contactDetails mb-lg-0 mb-md-0 mb-sm-0 mb-5">

                        <span class="design">
                            <br>
                            <em></em>
                        </span>

                        <h5> Contact Details </h5>

                        <span> 9791234567 </span>

                        <span> abcd12@renosoftwares.com </span>
                        <i class="bi bi-chevron-right"></i>

                    </div>

                </div>

            </div>

            <div class="col-lg-6 ">

                <div class="contactUsForm">

                    <form #form_contact="ngForm" class="contactForm">

                        <div class="row">

                            <div class="col-lg-12 mb-4">
                                <input [(ngModel)]="viewModel.singleContact.name" type="text" class="form-control"
                                    placeholder="Your Name" #name="ngModel" name="name" required />


                                <ng-container *ngFor="let validation of viewModel.ValidationData.name">
                                    <div class="text-danger"
                                        *ngIf="(name.touched || viewModel.FormSubmitted) && name.hasError(validation.type)">
                                        {{ validation.message }}
                                    </div>
                                </ng-container>

                            </div>

                            <div class="col-lg-12 mb-4">

                                <input [(ngModel)]="viewModel.singleContact.emailId" type="email" class="form-control"
                                    placeholder="Your Email *" #emailId="ngModel" name="emailId" required />

                                <ng-container *ngFor="let validation of viewModel.ValidationData.emailId">
                                    <div class="text-danger"
                                        *ngIf="(emailId.touched || viewModel.FormSubmitted) && emailId.hasError(validation.type)">
                                        {{ validation.message }}
                                    </div>
                                </ng-container>

                            </div>

                            <div class="col-lg-12 mb-4">

                                <textarea [(ngModel)]="viewModel.singleContact.message" class="form-control"
                                    placeholder="Your Message * " #message="ngModel" name="message" required
                                    minlength="20" maxlength="300" style="width: 100%; height: 130px; display:flex">
                                    </textarea>

                                <ng-container *ngFor="let validation of viewModel.ValidationData.message">
                                    <div class="text-danger"
                                        *ngIf="(message.touched || viewModel.FormSubmitted) && message.hasError(validation.type)">
                                        {{ validation.message }}
                                    </div>
                                </ng-container>

                            </div>

                            <div class="col-lg-12 text-center">
                                <button type="button" (click)="Click_SendContactUs(form_contact)">
                                    Send Message
                                </button>
                            </div>

                        </div>

                    </form>

                </div>

            </div>

        </div>

    </div>

</section>