import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
// import * as moment from 'moment';
import { AppConstants } from 'src/app-constants';
import { CheckoutSessionResponse } from 'src/app/service-models/app/v1/license/checkout-session-response';
import { FeatureGroupSM } from 'src/app/service-models/app/v1/license/feature-group-s-m';
import { CommonService } from 'src/app/services/common.service';
import { FeatureGroupService } from 'src/app/services/feature-group.service';
import { LogHandlerService } from 'src/app/services/log-handler.service';
import { PaymentService } from 'src/app/services/payment.service';
import { StorageService } from 'src/app/services/storage.service';
import { PricingInfoViewModel } from 'src/app/view-models/pricing-info.viewmodel';
import { PaymentStatusInfo } from 'src/app/view-models/success-payment.viewmodel';
import { BaseComponent } from '../../base.component';
import { PaymentMode } from 'src/app/service-models/app/enums/payment-mode.enum';
import { FeatureService } from 'src/app/services/features.service';
import * as moment from 'moment';
import { ProfileInfoService } from 'src/app/services/profile-info.service';
import { environment } from 'src/environments/environment';
// import { format, parseISO } from 'date-fns';

declare const Stripe: any;

@Component({
  selector: 'app-pricing-info',
  templateUrl: './pricing-info.component.html',
  styleUrls: ['./pricing-info.component.scss']
})
export class PricingInfoComponent extends BaseComponent<PricingInfoViewModel> {

  constructor(commonService: CommonService,
    logService: LogHandlerService,
    private storageService: StorageService,
    private featureGroupService: FeatureGroupService,
    private featureService: FeatureService,
    private activatedRoute: ActivatedRoute,
    private paymentService: PaymentService,
    private router: Router,
    private profileInfoService: ProfileInfoService


  ) {
    super(commonService, logService);
    this._commonService.layoutViewModel.showFooter = false;
    this._commonService.layoutViewModel.showSuperAdminLeftMenu = false;
    this._commonService.layoutViewModel.toggleWrapper = 'homeWrapper';
    this._commonService.layoutViewModel.toggleTopNav = 'homeTopNav';
    this._commonService.layoutViewModel.showTopNav = true;
  }

  async ngOnInit() {

    this.viewModel = new PricingInfoViewModel();
    this.viewModel.featureGroup = new FeatureGroupSM();
    // this.viewModel.feature = new FeatureSM();

    // this.viewModel.inspectors = [];
    let Id = Number(this.activatedRoute.snapshot.paramMap.get("Id"));
    if (Id == undefined) {
      this._commonService.showSweetAlertToast({ text: "error" });
    } else {
      // let paymentStatus = Number(this.activatedRoute.snapshot.paramMap.get("Status"));
      // if (paymentStatus != undefined) {
      //   if (paymentStatus == PaymentStatus.Failure)
      //     this.viewModel.btnPayText = "Try Again";
      //   else if (paymentStatus == PaymentStatus.Success)
      //     //success page or move to subscription page
      //     //update api to update user license for the page
      //     alert("payment Success");
      // }
      this.viewModel.featureGroup.id = Id;
      await this.loadPageData();
      if (this.viewModel.activeLicense.stripeSubscriptionId != null)
        this.click_UpgradeInfo();

      // console.log(paymentStatus)
    }
  }

  override async loadPageData(): Promise<void> {
    try {
      await this._commonService.presentLoading();
      await setTimeout(async () => {
        await this._commonService.dismissLoader();
      }, environment.animationTimeoutinMS);
      //check if user is valid for this license and set the company name or user name as per role
      this.viewModel.user = await this.storageService.getDataFromAnyStorage(AppConstants.DbKeys.LOGIN_USER);
      this.viewModel.customerName = `${this.viewModel.user.lastName} ${this.viewModel.user.firstName}`;
      let resp = await this.featureGroupService.getFeatureGroupByIdExtended(this.viewModel.featureGroup.id);
      let resp1 = await this.featureService.getActiveLicense();
      if (resp.isError) {
        await this._exceptionHandler.logObject(resp.errorData);
        this._commonService.showSweetAlertToast({ title: resp.errorData.displayMessage, icon: 'error' });
        return;
      }
      else {
        this.viewModel.featureGroup = resp.successData;

        if (resp1.isError) {
          await this._exceptionHandler.logObject(resp.errorData);
          this._commonService.showSweetAlertToast({ title: resp.errorData.displayMessage, icon: 'error' });
          return;
        }
        else {
          if (resp1.successData != null)
            this.viewModel.activeLicense = resp1.successData;

          if (resp1.successData == null) {
            // show buy now page
            this.viewModel.upgrade = false;
            this.viewModel.emptydiv = false;
            this.viewModel.buy = true;
          }
          else {
            // show upgrade page
            this.viewModel.upgrade = true;
            this.viewModel.emptydiv = true;
            this.viewModel.buy = false;
          }
        }
      }
    } catch (error) {
      throw error;
    } finally {
      await this._commonService.dismissLoader();
    }
  }


  // throw new Error('Method not implemented.');

  async click_PayNow() {
    try {
      await this._commonService.presentLoading();
      let urlInfo: PaymentStatusInfo = { fgId: this.viewModel.featureGroup.id, paymentDate: moment().toDate() };

      // let urlInfo: PaymentStatusInfo = {
      //   fgId: this.viewModel.featureGroup.id,
      //   paymentDate: parseISO(format(new Date(), 'yyyy-MM-dd HH:mm:ss'))
      // };
      let baseUrl = window.location.href.substring(0, window.location.href.indexOf(AppConstants.WebRoutes.PRICING));
      let urlText = btoa(this._commonService.encrypt(JSON.stringify(urlInfo)));
      // payment mode TODO Aurooj
      let resp = await this.paymentService.GenerateCheckoutSessionDetails({
        priceId: this.viewModel.featureGroup.stripePriceId,
        failureUrl: `${baseUrl}/${AppConstants.WebRoutes.PAYMENT_FAIL_URL}/${urlText}`,
        successUrl: `${baseUrl}/${AppConstants.WebRoutes.PAYMENT_SUCCESS_URL}/${urlText}`,
        paymentMode: PaymentMode.Card
      });
      if (resp.isError) {
        await this._exceptionHandler.logObject(resp.errorData);
        this._commonService.showSweetAlertToast({ title: resp.errorData.displayMessage, icon: 'error' });
        return;
      } else {
        await this.redirectToCheckout(resp.successData);
        return;
      }
    } catch (error) {
      throw error;
    } finally {
      await this._commonService.dismissLoader();
    }
  }

  async click_Upgrade() {
    try {
      await this._commonService.presentLoading();
      this.viewModel.upgradeSubscription.stripeSubscriptionId = this.viewModel.activeLicense.stripeSubscriptionId;
      this.viewModel.upgradeSubscription.stripeCustomerId = this.viewModel.user.stripeCustomerId;
      this.viewModel.upgradeSubscription.newStripePriceId = this.viewModel.featureGroup.stripePriceId;

      let resp = await this.paymentService.UpgradeSubscription(this.viewModel.upgradeSubscription);
      if (resp.isError) {
        await this._exceptionHandler.logObject(resp.errorData);
        this._commonService.showSweetAlertToast({ title: resp.errorData.displayMessage, icon: 'error' });
        return;
      }
      this._commonService.showSweetAlertToast({ title: 'Success', icon: 'success' });
      this.router.navigate([`${AppConstants.WebRoutes.PRICING}`]);
    }
    catch (error) {
      throw error;
    } finally {
      await this._commonService.dismissLoader();
    }
  }

  async click_UpgradeInfo() {
    try {
      await this._commonService.presentLoading();
      await this.getUser();
      this.viewModel.upgradeSubscription.stripeSubscriptionId = this.viewModel.activeLicense.stripeSubscriptionId;
      this.viewModel.upgradeSubscription.stripeCustomerId = this.viewModel.user.stripeCustomerId;
      this.viewModel.upgradeSubscription.newStripePriceId = this.viewModel.featureGroup.stripePriceId;

      let resp = await this.paymentService.UpgradeSubscriptionInfo(this.viewModel.upgradeSubscription);
      if (resp.isError) {
        await this._exceptionHandler.logObject(resp.errorData);
        this._commonService.showSweetAlertToast({ title: resp.errorData.displayMessage, icon: 'error' });
        return;
      }
      this.viewModel.upgradeSubscriptionResponse = resp.successData;
    }
    catch (error) {
      throw error;
    } finally {
      await this._commonService.dismissLoader();
    }
  }


  async redirectToCheckout(successData: CheckoutSessionResponse) {
    // await this.stripeScriptTag.setPublishableKey(successData.publicKey);
    // let stripeOptions: stripe.StripeServerCheckoutOptions
    const stripe = Stripe(successData.publicKey);
    await stripe.redirectToCheckout({
      sessionId: successData.sessionId
    });
    // let resp = await this.stripeScriptTag.StripeInstance.redirectToCheckout({
    //   sessionId: successData.sessionId
    // });
    // console.log(resp)
    // return;
  }

  async getUser() {
    try {
      await this._commonService.presentLoading();
      let resp = await this.profileInfoService.getInspectorProfile();
      if (resp.isError) {
        await this._exceptionHandler.logObject(resp.errorData);
        this._exceptionHandler.logObject(resp.errorData);
        this._commonService.showSweetAlertToast({ title: resp.errorData.displayMessage, icon: 'error' });
        return;
      } else {
        this.viewModel.user = resp.successData;
      }
    } catch (error) {
      this._commonService.showSweetAlertToast({ title: AppConstants.ErrorPrompts.Unknown_Error, icon: 'error' });
      throw error;
    }
    finally {
      await this._commonService.dismissLoader();
    }
  }
}
