<div [ngClass]="[_commonService.layoutViewModel.toggleUserProfileWrapper]">
    <nav [ngClass]="[_commonService.layoutViewModel.toggleUserProfileSideMenu]">

        <ul class="list-unstyled">

            <ng-container *ngFor="let menuItem of viewModel.menuItems">

                <li routerLinkActive="active" [class]="viewModel.selectedLink == menuItem.linkValue?'active':''"
                    (click)="viewModel.selectedLink = menuItem.linkValue"
                    *ngIf="_commonService.layoutViewModel.roleType == menuItem.forRole">

                    <div class="activeClass">
                        <i [class]="menuItem.iconName" class="ms-3"></i>

                        <span class="ms-3"> {{menuItem.itemName}} </span>

                    </div>
                </li>
            </ng-container>


         
            <ng-container>
                <div class="selectThemeClass"
                    *ngIf="_commonService.layoutViewModel.roleType ==3 || _commonService.layoutViewModel.roleType ==4">
                    <div  (click)="getAllThemes()">
                    <i class="bi bi-brightness-high ms-3 " (mouseenter)="viewModel.showTooltip = true"
                        (mouseleave)="viewModel.showTooltip = false" [attr.title]="'Change Theme '"
                        [attr.data-placement]="'left'" [ngClass]="{ 'tooltip-show': viewModel.showTooltip }"></i>
                    <span class="selecttheme">Select Theme</span>
                    </div>
                    <ul class=" sub-menu" >
                        <li>
                            <a *ngFor="let themeName of viewModel.allThemes" (click)="updateTheme(themeName)">
                                {{ themeName.name }}
                            </a>

                        </li>

                        <li>
                        </li>
                    </ul>
                </div>
            </ng-container>

        </ul>
    </nav>


    <div [ngClass]="[_commonService.layoutViewModel.toggleUserProfileContent]">
        <app-manage-inspectors [companyDetail]="viewModel.companyDetail" *ngIf="viewModel.selectedLink == 3">
        </app-manage-inspectors>
        <app-company-profile *ngIf="viewModel.selectedLink == 2" (newItemEvent)="recieveFromChild($event)">
        </app-company-profile>
        <app-profile-info *ngIf="viewModel.selectedLink == 1"></app-profile-info>
        <app-subscription-info *ngIf="viewModel.selectedLink == 4 "></app-subscription-info>
        <app-assign-license *ngIf="viewModel.selectedLink == 5 "></app-assign-license>
    </div>
</div>