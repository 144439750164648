import { Injectable } from "@angular/core";
import { AppConstants } from "src/app-constants";
import { AdditionalRequestDetails,Authentication,} from "../internal-models/additional-request-details";
import { StorageService } from "../services/storage.service";
import { BaseApiClient } from "./base-client/base-api.client";
import { CommonResponseCodeHandler } from "./helpers/common-response-code-handler.helper";
import { StorageCache } from "./helpers/storage-cache.helper";
import { DemoVideoSM } from "../service-models/app/v1/general/demo-video-s-m";
import { ApiResponse } from "../service-models/foundation/api-contracts/base/api-response";
// import { ContactUsSM } from "../service-models/contact-us.s-m";

@Injectable({
  providedIn: "root",
})
export class DemoVideoClient extends BaseApiClient {
  constructor(
    storageService: StorageService,
    storageCache: StorageCache,
    commonResponseCodeHandler: CommonResponseCodeHandler
  ) {
    super(storageService, storageCache, commonResponseCodeHandler);
  }
  GetDemoVideos = async (): Promise<ApiResponse< DemoVideoSM[]>> => {
    let resp = await this.GetResponseAsync<null,  DemoVideoSM[]>
        (`${AppConstants.ApiUrls.DEMO_VIDEOS}`, 'GET', null,
            new AdditionalRequestDetails< DemoVideoSM[]>(false, Authentication.false));
    return resp;
}

}
