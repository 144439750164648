import { environment } from "src/environments/environment";

// TODO: MOVE ALL CONSTANTS HERE
export const AppConstants =
{
    ApiUrls: {
        TEST_URL: 'test/test',
        LOG_URL: 'log',
        ACCOUNT_URL: '/api/Token',
        DUMMY_TEACHER_URL: '/api/v1/DummyTeacher',
        DUMMY_STUDENT_URL: 'api/token',
        FAQs_API_Url: '/api/v1/FrequentlyAskedQuestions',
        NEWS_FEED_API_URL: '/api/v1/NewsFeed',
        WEBSITE_RESOURCE_URL: '/api/v1/WebsiteResource',
        CONTACTUS_URL: '/api/v1/ContactUs',
        USER_API_URL: '/api/v1/ClientUser',
        USER_ADDRESS_API_URL: '/api/v1/ClientUserAddress',
        COMPANY_URL: '/api/v1/ClientCompanyDetail',
        COMPANY_ADDRESS_API_URL: '/api/v1/ClientCompanyAddress',
        INSPECTOR_ADDRESS_API_URL:'api/v1/ClientUserAddress',
        FEATURES_API_URL: '/api/v1/Feature',
        FEATURE_GROUP_URL: 'api/v1/FeatureGroup',
        USER_LICENSEINFO_URL: 'api/v1/UserLicenseDetails',
        PAYMENT_URL: 'api/v1/Payment',
        INVOICE_URL:'api/v1/Invoice',
        DEMO_VIDEOS:'api/v1/SypGlassDemoVideos',
        CLIENTTHEME_URL:'api/v1/ClientTheme'

    },
    DbKeys: {
        ACCESS_TOKEN: 'ACCESS_TOKEN',
        TOKEN_EXPIRY: 'TOKEN_EXPIRY',
        LOGIN_USER: 'LOGIN_USER',
        COMPANY_CODE: 'COMPANY_CODE',
        REMEMBER_PWD: 'REMEMBER_PWD',
        API_RESP_CACHE: '',
        PASSWORD: 'pwd'

    },
    DbDefaultValues: {
    },
    ErrorPrompts: {
        App_StartError: 'Error occured. Please restart the application.',
        Load_Data_Error: 'Error in loading data. Please try again.',
        Invalid_Input_Data: 'Invalid data.Please try again.',
        Unknown_Error: 'Error occured. Please try again.',
        Network_Error: 'Please check network and try again.',
        Save_Data_Error: 'Error in saving. Please try again.',
        Delete_Data_Error: 'Error in delete. Please try again.',
        Permission_Error: 'Permission denied.',
        Unauthorized_User: 'User not authorized. Please relogin.'
    },
    HeadersName: {
        ApiType: 'targetapitype',
        DevApk: 'isdeveloperapk',
        AppVersion: 'appversion',
        CORS_ALLOW_ORIGIN: "Access-Control-Allow-Origin",
        CORS_ALLOW_METHODS: "Access-Control-Allow-Methods",
        CORS_ALLOW_CREDENTIALS: "Access-Control-Allow-Credentials",

    },
    HeadersValue: {
        ApiType: 'abcd',
        DevApk: (!environment.production).toString(),
        AppVersion: environment.applicationVersion,
        CORS_ALLOW_ORIGIN: "http://localhost:4200",
        CORS_ALLOW_METHODS: "GET, POST, OPTIONS, DELETE, PUT",
        CORS_ALLOW_CREDENTIALS: "true",
    },

    SuccessMessages: {
        Data_Saved_Success: 'Save Success',
        Details_Updated: 'Details Updated Successfully',
    },
    DefaultMessages: {
        DeleteAlertConfirmation: "Are you sure you want to delete ",
    }
    , WebRoutes: {
        HOME: '/home',
        PRICING:'/pricing',
        PRICINGINFO: '/pricing-info',
        PROFILE: '/profile',
        ADMIN: {
            LOGIN: '/admin/login',
            DASHBOARD: '/admin/dashboard',
            NEWSFEED: '/admin/newsfeed',
            FAQS: '/admin/faqs',
            WebsiteResource: '/admin/WebsiteResource',
            ContactUs: '/admin/ContactUs',
            FeatureGroup: '/admin/featuregroup',
            Features: '/admin/features',
            LicenseInfo: '/admin/licenseInfo',
            Companies: '/admin/companies'
        },
        PAYMENT_FAIL_URL: `/failure`,
        PAYMENT_SUCCESS_URL: `/success`,
        RESETPASSWORD:'/reset-password',
        UNAUTHORIZED:'**'


    },
    Token_Info_Keys: {
        Role: 'http://schemas.microsoft.com/ws/2008/06/identity/claims/role',
        Expiry: 'exp',
        CompanyCode: 'clCd',
        Audience: 'aud',
        CompanyId: 'clId',
        RecordId: 'dbRId',
        EmailAddress: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress',
        UserName: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name',
        GivenName: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname',
        Issuer: 'iss',
        TokenStart: 'nbf'
    }

};
