import { Injectable } from "@angular/core";
import { AppConstants } from "src/app-constants";
import { AdditionalRequestDetails, Authentication } from "../internal-models/additional-request-details";
import { ClientUserSM } from "../service-models/app/v1/app-users/client-user-s-m";
import { ClientCompanyDetailSM } from "../service-models/app/v1/client/client-company-detail-s-m";
import { UserLicenseDetailsSM } from "../service-models/app/v1/license/user-license-details-s-m";
import { ApiRequest } from "../service-models/foundation/api-contracts/base/api-request";
import { ApiResponse } from "../service-models/foundation/api-contracts/base/api-response";
import { OdataQueryFilter } from "../service-models/foundation/api-contracts/odata-query-filter";
import { BoolResponseRoot } from "../service-models/foundation/common-response/bool-response-root";
import { IntResponseRoot } from "../service-models/foundation/common-response/int-response-root";
import { StorageService } from "../services/storage.service";
import { BaseApiClient } from "./base-client/base-api.client";
import { CommonResponseCodeHandler } from "./helpers/common-response-code-handler.helper";
import { StorageCache } from "./helpers/storage-cache.helper";



@Injectable({
    providedIn: 'root'
})
export class CompanyOverviewClient extends BaseApiClient {
    constructor(storageService: StorageService, storageCache: StorageCache,
        commonResponseCodeHandler: CommonResponseCodeHandler) {
        super(storageService, storageCache, commonResponseCodeHandler)

    }
    GetAllUsersOfCompany = async (Id: number): Promise<ApiResponse<ClientUserSM[]>> => {
        let resp = await this.GetResponseAsync<number, ClientUserSM[]>
            (`${AppConstants.ApiUrls.USER_API_URL}/Company/${Id}`, 'GET', null,
                new AdditionalRequestDetails<ClientUserSM[]>(false, Authentication.false));
        return resp;
    }

    GetAllUsersByOdata = async (Id:number,queryFilter: OdataQueryFilter): Promise<ApiResponse<ClientUserSM[]>> => {
        let finalUrl = this.ApplyQueryFilterToUrl(`${AppConstants.ApiUrls.USER_API_URL}/odata`, queryFilter);
        finalUrl+=`&& $filter=clientcompanydetailid eq ${Id}`
        let resp = await this.GetResponseAsync<null, ClientUserSM[]>(finalUrl, 'GET');
        return resp;
      }

    GetCompanyProfile = async (Id:number): Promise<ApiResponse<ClientCompanyDetailSM>> => {
        let resp = await this.GetResponseAsync<number, ClientCompanyDetailSM>
            (`${AppConstants.ApiUrls.COMPANY_URL}/${Id}`, 'GET');
        return resp;
    }

    GetCompanyLicenses=async(Id:number):Promise<ApiResponse<UserLicenseDetailsSM[]>>=>{``
        let resp=await this.GetResponseAsync<number,UserLicenseDetailsSM[]>
        (`${AppConstants.ApiUrls.USER_LICENSEINFO_URL}/LicenseByCompanyId/${Id}`,'GET');
        return resp;        
 }
    updateLoginStatus = async (updateStatusRequest: ApiRequest<ClientUserSM>): Promise<ApiResponse<BoolResponseRoot>> => {
        let resp = await this.GetResponseAsync<number, BoolResponseRoot>
            (`${AppConstants.ApiUrls.USER_API_URL}/EnableDisableLoginStatus/${updateStatusRequest.reqData.id}/${updateStatusRequest.reqData.loginStatus}`, 'PUT');
        return resp;
    }
    GetAllUsersCount =async(Id:number):Promise<ApiResponse<IntResponseRoot>>=>{
        let resp=await this.GetResponseAsync<null,IntResponseRoot>
        (`${AppConstants.ApiUrls.USER_API_URL}/ClientUsersCountResponse/${Id}`,"GET");
        return resp;
       }
  
}