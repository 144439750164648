import { ClientUserSM } from "../service-models/app/v1/app-users/client-user-s-m";
import { ActiveDeactiveCompanyInspectorLicenseSM } from "../service-models/app/v1/license/active-deactive-company-inspector-license-s-m (1)";
import { ClientUserExtededUserLicense } from "../service-models/app/v1/license/client-user-exteded-user-license";
import { UserInvoiceSM } from "../service-models/app/v1/license/user-invoice-s-m";
import { UserLicenseDetailsSM } from "../service-models/app/v1/license/user-license-details-s-m";

import { BaseViewModel } from "./base.viewmodel";

export class SubscriptionViewModel implements BaseViewModel {
  PageTitle: string = "My Subscription";
  PageNo: number = 1;
  PageSize: number = 10;
  ActiveDeactiveLicense: ActiveDeactiveCompanyInspectorLicenseSM = new ActiveDeactiveCompanyInspectorLicenseSM();
  LicenseDetails: UserLicenseDetailsSM = new UserLicenseDetailsSM();
  LicenseDetailsList: UserLicenseDetailsSM[] = [];
  MyLicenses: ClientUserExtededUserLicense[] = [];
  inspectors: ClientUserSM[] = [];
  showPreviousTransactionTable: boolean = false;
  userDetails: ClientUserSM = new ClientUserSM();
  totalCount!: number;
  list: number = 0;
  activeLicense: boolean = false;
  noActiveLicense: boolean = false;
  emptydiv: boolean = false;
  invoices!:UserInvoiceSM[];
 showInvoiceHistoryTable:boolean=false;
 LicenseCount!:number;

  
}
