<div class="container" *ngIf="viewModel.LicenseDetails.status=='active' || viewModel.activeLicense">
    <!-- <div class="table-title"> -->
    <div class="row">
        <div class="col-sm-7">
            <label class="text-start px-2">This license can be assigned to {{viewModel.LicenseCount}} inspectors
                only</label>
        </div>

    </div>
    <!-- </div> -->
    <table class="table mt-2">
        <thead>
            <tr>
                <th class="text-center firstTableHead">First Name</th>
                <th class="text-center">Last Name</th>
                <th class="text-center">Login id</th>
                <th class="text-center lastTableHead">Assign License</th>
            </tr>
        </thead>
        <tbody>

            <tr *ngFor="let inspector of viewModel.MyLicenses">
                <td class="text-center">{{inspector.firstName}}</td>
                <td class="text-center">{{inspector.lastName}}</td>
                <td class="text-center">{{inspector.loginId}}</td>
                <td class=" text-center">
                    <div class="form-check form-switch">
                        <input mdbCheckbox class="form-check-input"
                            (change)="AssignLicenseToInspector(inspector.clientUserId, $event)"
                            [checked]="inspector.status=='active'" type="checkbox" />
                    </div>
                </td>

            </tr>
        </tbody>
    </table>
</div>

<div *ngIf="viewModel.LicenseDetails==null||viewModel.LicenseDetails.status=='null'||viewModel.emptydiv"></div>

<div class="container  animated-image"
    *ngIf="viewModel.LicenseDetails&&viewModel.LicenseDetails.status=='canceled' || viewModel.noActiveLicense ">
    <div class="row">
        <div class="col-lg-9 col-md-10 col-sm-12 col-12  mx-auto  ">
            <div class="card  mt-2 pb-lg-2 pb-lg-2 pb-md-2 pb-sm-2 pb-2  cardone">
                <div class="card-header  ">
                    <h2 class="text-center"> To assign licenses, start with a subscription</h2>
                </div>

                <div class="row">
                    <!-- <div class="col-lg-6 col-md-12 col-sm-12 col-12 d-flex justify-content-end m-0 mt-3 imageColumn">
                        <img src="assets/images/other-images/ourProduct.webp" alt="Image" class="img-fluid">

                    </div> -->
                    <div class="col-lg-12 col-md-12 col-sm-12 col-12 mt-5 px-5">
                        <h3 class="text">Subscribe to access licenses.</h3><br />
                        <h6>It appears that you haven't subscribed to any licenses yet. To assign a license, kindly
                            proceed with the purchase of a license. You can do this by visiting pricing page. Once you have obtained the necessary licenses,
                            you'll be able to assign them as needed to ensure proper access and usage of the software.
                        </h6>
                        <button class="spyGlassBtn mt-5 " routerLink="/pricing">Get your License here</button>
                    </div>
                   
                </div>

            </div>
        </div>
    </div>
</div>